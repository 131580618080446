<script lang="ts">
  import { onDestroy, onMount, type ComponentType } from "svelte";
  import { serverlessRoutes } from "../../lib/constants";
  import { getWithJwt } from "../../lib/requests";
  import ProfileData from "../../components/ProfileData.svelte";
  import Svg from "../../components/UI/Svg.svelte";
  import MealPlansView from "./ProfileScreenViews/MealPlansView.svelte";
  import PaymentView from "./ProfileScreenViews/ProgressViews/PaymentView.svelte";
  import WorkoutPlansView from "./ProfileScreenViews/WorkoutPlansView.svelte";
  import { currentClient } from "../../stores/currentClient";
  import GoalsScreen from "../Progress/GoalsScreen.svelte";
  import { push, replace } from "svelte-spa-router";
  import { cantDoExercises, trainerChatWith } from "../../stores/trainerStores";
  import { querystring } from "svelte-spa-router";
  import MeasurementsScreen from "../Progress/MeasurementsScreen.svelte";
  import Progress from "../../components/Progress/Progress.svelte";
  import Spinner from "../../components/UI/Spinner.svelte";
  import { Capacitor } from "@capacitor/core";
  import { trainerClientsStore } from "stores";
  import { ButtonComponent } from "ui";
  import { isClient } from "lib";

  let params: any;
  let progressView = "MEASUREMENTS";
  let sessionId: number | null = null;

  const viewMap: { [key: string]: ComponentType } = {
    payment: PaymentView,
    goals: GoalsScreen,
    progress: MeasurementsScreen,
    diet: MealPlansView,
    training: WorkoutPlansView,
  };
  const queryObject: URLSearchParams = new URLSearchParams($querystring);
  const queryIterator: IterableIterator<string> = queryObject.values();
  const selectedView: string = queryIterator.next().value;

  let view = $querystring === "" ? WorkoutPlansView : viewMap[selectedView];

  // const setView = async (v: string) => {
  // queryObject.set("view", v);
  // queryObject.delete("type");
  // if (v === "training" || v === "diet") queryObject.set("type", "active");
  // replace(`/profile/${$currentClient.id}?${queryObject.toString()}`);
  // };

  const navLinks = [
    {
      icon: "money",
      isActive: false,
      async onView() {
        await replace(`/profile/${$currentClient.id}?view=payment`);

        // if (!window.location.href.includes("view=payment")) {
        //   replace(`/profile/${$currentClient.id}?view=payment`);
        // }

        navLinks[0].isActive = true;
        view = PaymentView;
      },
    },
    {
      icon: "flag",
      isActive: false,
      async onView() {
        await replace(`/profile/${$currentClient.id}?view=goals`);
        // if (!window.location.href.includes("view=payment")) {
        //   replace(`/profile/${$currentClient.id}?view=goals`);
        // }
        navLinks[1].isActive = true;
        view = GoalsScreen;
      },
    },
    {
      icon: "chart-line",
      isActive: false,
      async onView() {
        await replace(`/profile/${$currentClient.id}?view=progress`);
        // if (!window.location.href.includes("view=payment")) {
        //   replace(`/profile/${$currentClient.id}?view=progress`);
        // }
        navLinks[2].isActive = true;
        view = Progress;
      },
    },
    {
      icon: "utensils",
      isActive: false,
      async onView(): Promise<void> {
        // setView("view=diet&type=active");
        if (!window.location.href.includes("view=diet")) {
          await replace(`/profile/${$currentClient.id}?view=diet&type=active`);
          navLinks[3].isActive = true;
          view = MealPlansView;
        }
        // if (!window.location.href.includes("view=diet")) {
        //   replace(`/profile/${$currentClient.id}?view=diet&type=active`);
        // }
        // navLinks[3].isActive = true;
        // view = MealPlansView;
      },
    },
    {
      icon: "dumbbell",
      isActive: false,
      async onView(): Promise<void> {
        if (!window.location.href.includes("view=training")) {
          await replace(
            `/profile/${$currentClient.id}?view=training&type=active`
          );
          navLinks[4].isActive = true;
          view = WorkoutPlansView;
        }
        // if (!window.location.href.includes("view=training")) {
        //   replace(`/profile/${$currentClient.id}?view=training&type=active`);
        // }
      },
    },
    {
      icon: "message",
      isActive: false,
      async onView() {
        $trainerChatWith = {
          clientId: $currentClient.id,
          clientName: $currentClient.name,
          avatarUrl: $currentClient.imageUrl,
          sessionId,
        };
        localStorage.setItem(
          "trainerChatWith",
          JSON.stringify($trainerChatWith)
        );
        push(`/chat`);
      },
    },
  ];

  const resetActive = (): void => {
    navLinks.forEach((navLink): void => {
      navLink.isActive = false;
    });
  };

  const fetchProfile = async () => {
    const url = `${serverlessRoutes.TRAINER_CLIENT}/${params.id}/profile`;
    try {
      const res = await getWithJwt(url);
      $currentClient = res.data;
    } catch (err) {
      console.error(err);
    }
  };

  const fetchSessionId = async () => {
    const url = `${serverlessRoutes.SESSION}/session-with-user/${params.id}`;
    try {
      const res = await getWithJwt(url);
      sessionId = res.data.sessionId;
    } catch (err) {
      console.error(err);
    }
  };

  // const unsubscribe = params.subscribe((param) => {
  //   if (param?.id) {
  //     if (!user) {
  //       fetchProfile(+param.id);
  //     }
  //   }
  // });

  const fetchCantDoExercises = async (): Promise<void> => {
    if (!$currentClient?.id) return;
    const url = `${serverlessRoutes.EXERCISE}/cant-do-exercises?userId=${$currentClient.id}&take=10&skip=0`;
    try {
      const res = await getWithJwt(url);
      $cantDoExercises.exercises = res.data.exercises.map((exercise: any) => ({
        ...exercise,
        details: JSON.parse(exercise.details),
      }));
      $cantDoExercises.exerciseIds = res.data.exercises.map(
        ({ id }: any) => id
      );
    } catch (error) {
      console.error(error);
    }
  };

  // onDestroy(unsubscribe);
  onMount(async () => {
    // $currentClient = $trainerClientsStore.data.find(
    //   (client) => client.id === parseInt(params.id)
    // );
    if (!$currentClient?.id) {
      await fetchProfile();
    }

    const { href } = window.location;

    if (href.includes("view")) {
      const view = href.split("?")[1].split("&")[0].split("=")[1];

      if (view === "payment") {
        await navLinks[0].onView();
      } else if (view === "goals") {
        await navLinks[1].onView();
      } else if (view === "progress") {
        await navLinks[2].onView();
      } else if (view === "diet") {
        await navLinks[3].onView();
      } else if (view === "training") {
        await navLinks[4].onView();
      }
    } else {
      await navLinks[4].onView();
    }

    await fetchSessionId();
    await fetchCantDoExercises();
  });

  onDestroy(() => {
    $currentClient = {};
  });

  export { params };
</script>

<div>
  {#if $currentClient && $currentClient.id}
    {#if Capacitor.getPlatform() === "ios"}
      <div class="mt-16" />
      <div class="mt-2" />
    {/if}
    <ProfileData />
    <div class="p-4 flex flex-col gap-4">
      <div class="flex justify-evenly">
        {#if $currentClient}
          {#each navLinks as navLink}
            <ButtonComponent
              isActive={navLink.isActive}
              isPill
              on:click={() => {
                resetActive();
                navLink.onView();
              }}
            >
              <Svg name={navLink.icon} size={19} customColor={"bg-white"} />
            </ButtonComponent>
          {/each}
        {/if}
      </div>

      <div>
        <svelte:component this={view} />
      </div>
    </div>
  {:else}
    <Spinner />
  {/if}
</div>
