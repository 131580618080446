import { api, getWithJwt, postWithJwt, serverlessRoutes } from "lib";
import { currentClient, loadedWorkouts, user } from "stores";
import { showAlert } from "../stores/showAlertStore";
import { get, writable } from "svelte/store";

const trainerClients = writable<Array<any>>([]);
const trainerPrograms = writable<Array<any>>([]);
const trainerWorkouts = writable<Array<any>>([]);
const trainerChatSessions = writable<Array<any>>([]);
const trainerClientGroups = writable<Array<any>>([]);
const trainerClientPrograms = writable<Array<any>>([]);

interface TrainerClientWorkouts {
  data: Array<any>;
  count: number;
  hasMore: boolean;
  isFetching: boolean;
  isFetchingMore: boolean;
  page: number;
  searchValue: string;
  searchTimeout: NodeJS.Timeout | undefined;
  filter: {
    search: string;
    template: number;
    sort: string | undefined;
    tags: Array<any>;
  };
}
const trainerClientWorkoutsCreate = () => {
  const { set, subscribe, update } = writable<TrainerClientWorkouts>({
    data: [],
    count: 0,
    hasMore: false,
    isFetching: false,
    isFetchingMore: false,
    page: 0,
    searchValue: "",
    searchTimeout: undefined,
    filter: {
      search: "",
      template: 0,
      sort: undefined,
      tags: [],
    },
  });

  const _getHasMore = (store: TrainerClientWorkouts): boolean => {
    return store.data.length < store.count;
  };

  const fetchData = async (): Promise<void> => {
    // http://localhost:5173/#/profile/33121?view=training&type=active
    const clientId =
      get(currentClient).id ||
      window.location.href.split("/")[5]?.split("?")[0];

    if (!clientId) {
      showAlert.set({ message: "Došlo je do greške", color: "red-400" });
      return;
    }

    update((store) => {
      store.page = 0;
      return store;
    });

    const response = await getWithJwt(
      `${api}/program_journal/new?take=7&skip=0&clientId=${clientId}&default=1&template=0`
    );

    loadedWorkouts.set(
      response.data.reduce((acc: any, workout: any): any => {
        acc[workout.id] = workout;
        return acc;
      }, {})
    );

    update((store) => {
      store.data = response.data;
      store.count = response.count;
      store.hasMore = _getHasMore(store);
      return store;
    });
  };

  const fetchMoreData = async (): Promise<void> => {
    const clientId =
      get(currentClient).id || window.location.href.split("/")[5].split("?")[0];

    update((store) => {
      store.page += 1;
      return store;
    });

    const response = await getWithJwt(
      `${api}/program_journal/new?take=7&skip=${
        get({ subscribe }).page * 7
      }&clientId=${clientId}&default=1&template=0`
    );

    loadedWorkouts.update((store) => {
      response.data.forEach((workout) => {
        store[workout.id] = workout;
      });

      return store;
    });

    update((store) => {
      store.data = [...store.data, ...response.data];
      store.hasMore = _getHasMore(store);
      return store;
    });
  };

  return {
    set,
    subscribe,
    update,
    fetchData,
    fetchMoreData,
  };
};
const trainerClientWorkouts = trainerClientWorkoutsCreate();

const trainerList = writable<any>();
const trainerChatWith = writable<any>({});
const tags = writable<Array<ITag>>([]);
const remindersMap = writable<any>({});
const clientTagsMap = writable<any>({});

const cantDoExercises = writable<{
  exercises: Array<any>;
  exerciseIds: Array<any>;
  workoutIds: Array<any>;
}>({
  exercises: [],
  exerciseIds: [],
  workoutIds: [],
});

const tagIds = new Map<string, number>();
tags.subscribe((res) => {
  res.forEach((tag: any): void => {
    tagIds.set(tag.note, tag.id);
  });
});

export {
  trainerClients,
  trainerPrograms,
  trainerWorkouts,
  trainerChatSessions,
  trainerClientGroups,
  trainerClientPrograms,
  trainerClientWorkouts,
  trainerList,
  trainerChatWith,
  remindersMap,
  tags,
  clientTagsMap,
  cantDoExercises,
};
