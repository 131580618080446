<script lang="ts">
  import DateIncrementer from "../../components/Progress/DateIncrementer.svelte";
  import TopMenu from "../../components/TopMenu.svelte";
  import {entities} from "../../lib/constants";
  import { ButtonGroup } from "ui";

  let selectedDate = new Date();
  const today = new Date();
  let incrementWeekCounter = 1;

  const incrementDate = (n: number) => {
    if (n > 0 && selectedDate >= today) {
      return;
    }
    selectedDate.setDate(selectedDate.getDate() + n);
    selectedDate = selectedDate;
    const passedWeek = new Date();
    passedWeek.setDate(passedWeek.getDate() - incrementWeekCounter * 7);
    if (selectedDate < passedWeek) {
      // do stuff?
    }
  };
</script>

<TopMenu entity={entities.CLIENTS} />
<div class="flex flex-col gap-4">
  <DateIncrementer
    incrementDate="{incrementDate}"
    selectedDateDecremented="{selectedDate}"/>

  <div class="flex justify-center">
    <ButtonGroup buttons={[
      {title: "Nedelja", onClick: () => {}},
      {title: "Mesec", onClick: () => {}},
      {title: "Tri meseca", onClick: () => {}},
    ]}/>
  </div>
</div>
