<script lang="ts">
  import TopMenu from "../../components/TopMenu.svelte";
  import { parseDate } from "../../lib/parseDate";
  import { dialogTypes } from "../../lib/constants";
  import Spinner from "../../components/UI/Spinner.svelte";
  import MealExpansionPanel from "../../components/Diet/MealExpansionPanel.svelte";
  import { dialogData } from "../../stores/dialogDataStore";
  import { onDestroy, onMount } from "svelte";
  import { translate } from "lib";
  import Macros from "../../components/Diet/Macros.svelte";
  import { Capacitor } from "@capacitor/core";
  import { ButtonComponent, InfiniteScroll } from "ui";
  import { activeMealsStore, journalMealsStore, user } from "stores";

  let isLoading = false;

  const userStoreUnsubscribe = user.subscribe(async () => {
    if ($journalMealsStore.items.length > 0) return;

    isLoading = true;

    await journalMealsStore.fetchData();
    journalMealsStore.generateMealMap();

    isLoading = false;
  });

  const onFetchMoreDiary = async () => {
    await journalMealsStore.fetchMoreData();
    journalMealsStore.generateMealMap();
  };

  onDestroy(userStoreUnsubscribe);

  onMount(async () => {
    if (!$activeMealsStore.items.length) {
      await activeMealsStore.fetchData();
    }
  });
</script>

<TopMenu entity="DIET" />
<div
  class="p-4 flex flex-col gap-4 bg-white dark:bg-zinc-800 text-slate-900 dark:text-slate-100"
>
  {#if isLoading}
    <Spinner />
  {:else}
    <div class="flex justify-between">
      {#if $activeMealsStore.items.length}
        <ButtonComponent
          on:click={() => {
            $dialogData.type = dialogTypes.CREATE_EDIT_MEAL;
            $dialogData.data = {
              mealPlanId: $activeMealsStore.items[0]?.mealPlanId,
              // fetchData: activeMealsStore.fetchData,
            };
          }}
        >
          {translate("ADD_MEAL")}
        </ButtonComponent>
      {/if}
      <!-- {#if $clientJournalMeals && $clientJournalMeals.length > 0}
        <ButtonComponent
          execFunction={() =>
            exportToPdf($user.id, "diet_journal", "DietJournal.pdf")}
          >{translate("EXPORT_TO_PDF")}</ButtonComponent
        >
      {/if} -->
    </div>
    <div class="flex flex-col gap-4">
      {#each Object.values($journalMealsStore.map) as day}
        <span class="text-xs font-semibold">{parseDate(day[0].finishedAt)}</span
        >
        <div
          class="flex flex-col gap-4 p-2 border border-slate-200 dark:border-zinc-600 rounded-md"
        >
          <div class="p-1 flex flex-col">
            <Macros
              isCaloriesVisible
              isBarVisible
              calories={day.reduce((sum, i) => (sum += i.calories), 0)}
              carbs={day.reduce((sum, i) => (sum += i.carbs), 0)}
              fats={day.reduce((sum, i) => (sum += i.fats), 0)}
              protein={day.reduce((sum, i) => (sum += i.protein), 0)}
            />
          </div>
          {#each day as meal}
            <MealExpansionPanel isCommentable {meal} />
          {/each}
        </div>
      {/each}
    </div>
    <InfiniteScroll
      hasMore={$journalMealsStore.hasMore}
      isFetchingMore={$journalMealsStore.isFetchingMore}
      on:loadMore={onFetchMoreDiary}
    />

    {#if Capacitor.getPlatform() === "ios"}
      <div class="mb-14"></div>
    {/if}
  {/if}
  {#if Capacitor.isNativePlatform()}
    <div class="mb-12"></div>
  {/if}
  {#if Capacitor.getPlatform() === "ios"}
    <div class="pb-16"></div>
  {/if}
</div>
