<!-- <div class="w-full bg-gray-300 h-2 rounded overflow-hidden">
  <div class="bg-primary-500 h-full animate-slide"></div>
</div>

<style>
  @keyframes slide {
    0% {
      transform: translateX(-100%);
    }
    50% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(100%);
    }
  }

  .animate-slide {
    animation: slide 1.5s ease-in-out infinite;
    width: 50%;
  }
</style> -->

<!-- <p class="text-center mb-8">Scroll down on the Show Code tab to see the <code class="bg-white p-2 rounded-lg">tailwind.config.js</code> custom code and the <code class="bg-white p-2 rounded-lg">final component</code> syntax for accomplishing this result.</p> -->

<div class="w-full">
  <div class="h-2 w-full bg-white overflow-hidden rounded-md">
    <div class="progress w-full h-full bg-primary-500 left-right rounded-full"></div>
  </div>
</div>

<style>
  .progress {
    animation: progress 2s infinite linear;
  }

  .left-right {
    transform-origin: 0% 50%;
  }

  @keyframes progress {
    0% {
      transform: translateX(0) scaleX(0);
    } 40% {
      transform: translateX(0) scaleX(0.4);
    } 100% {
      transform: translateX(100%) scaleX(0.5);
    }
  }
</style>