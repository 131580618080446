<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import { slide } from "svelte/transition";
  import { translate } from "lib";

  interface MenuItem {
    title: string;
  }

  const dispatch = createEventDispatcher();

  let title: string;
  let menuItems: Array<MenuItem> = [];
  let isDropdownVisible = false;

  const onToggleDropdown = (): void => {
    isDropdownVisible = !isDropdownVisible;
  };

  const onSelectMenuItem = (menuItem: MenuItem): void => {
    onToggleDropdown();
    dispatch("selectMenuItem", menuItem);
  };

  export { title, menuItems };
</script>

<div class="relative">
  <button
    class="flex items-center button text-white focus:ring-4 focus:outline-none bg-primary-500 hover:bg-primary-800 focus:ring-primary-300 dark:bg-primary-500 dark:hover:bg-primary-700 dark:focus:ring-primary-800 font-medium rounded-lg px-4 py-2.5"
    on:click={onToggleDropdown}
  >
    {translate(title)}
    <svg
      class="w-2.5 h-2.5 ms-3"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 10 6"
    >
      <path
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="m1 1 4 4 4-4"
      />
    </svg>
  </button>

  <!-- Dropdown menu -->
  {#if isDropdownVisible}
    <div
      in:slide
      out:slide
      class="absolute bottom-12 left-0 z-10 w-256 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-zinc-700"
    >
      <ul class="py-2 text-sm text-gray-700 dark:text-gray-200">
        {#each menuItems as menuItem}
          <li on:click={() => onSelectMenuItem(menuItem)}>
            <a
              class="text-xs block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
            >
              {translate(menuItem.title)}
            </a>
          </li>
        {/each}
      </ul>
    </div>
  {/if}
</div>

<!-- <button
  id="dropdownTopButton"
  data-dropdown-toggle="dropdownTop"
  data-dropdown-placement="top"
  class="me-3 mb-3 md:mb-0 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
  type="button"
  >Dropdown top <svg
    class="w-2.5 h-2.5 ms-3"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 10 6"
  >
    <path
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M9 5 5 1 1 5"
    />
  </svg>
</button>

<div
  id="dropdownTop"
  class={`absolute z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-zinc-700 top-0`}
>
  <ul
    class="py-2 text-sm text-gray-700 dark:text-gray-200"
    aria-labelledby="dropdownTopButton"
  >
    {#each menuItems as item}
      <li>
        <a
          href="#"
          class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
        >
          {translate(item.title)}
        </a>
      </li>
    {/each}
  </ul>
</div> -->
