<script lang="ts">
  import { onMount } from "svelte";
  import { translate } from "lib";

  interface MacroPercentages {
    carbs: string;
    protein: string;
    fats: string;
  }

  let carbs: number;
  let protein: number;
  let fats: number;
  let calories: number;
  let isBarVisible = false;
  let isCaloriesVisible = false;

  let percentages: MacroPercentages = {
    carbs: "0",
    protein: "0",
    fats: "0",
  };

  const getValue = (value: number): string => value ? value.toFixed(1) : "0";

  onMount((): void => {
    percentages = calories === 0 ? {
      carbs: "0",
      protein: "0",
      fats: "0"
    } : {
      carbs: getValue(((carbs * 4) / calories) * 100),
      protein: getValue(((protein * 4) / calories) * 100),
      fats: getValue(((fats * 9) / calories) * 100)
    };
  });

  export { carbs, protein, fats, calories, isBarVisible, isCaloriesVisible };
</script>

<div class="flex flex-col gap-1">
  <div
    class="
      flex
      items-center
      justify-evenly
      leading-tight
      text-center
      text-xxs
    "
  >

    <div>
      <div>{translate("CARBS")}</div>
      <div class="text-sm text-emerald-400">
        <span data-cy="carbs">{getValue(carbs)}</span> ({percentages.carbs}%)
      </div>
    </div>

    <div>
      <div>{translate("PROTEIN")}</div>
      <div class="text-sm text-sky-400">
        <span data-cy="protein">{getValue(protein)}</span> ({percentages.protein}%)
      </div>
    </div>

    <div>
      <div>{translate("FATS")}</div>
      <div class="text-sm text-rose-400">
        <span data-cy="fats">{getValue(fats)}</span> ({percentages.fats}%)
      </div>
    </div>

    {#if isCaloriesVisible}
      <div>
        <div>{translate("CALORIES")}</div>
        <div class="text-sm text-amber-400" data-cy="calories">{getValue(calories)}</div>
      </div>
    {/if}

  </div>

  {#if isBarVisible}
    <div class="flex">
      <div class="h-[2px] bg-emerald-400" style:flex-basis="{percentages.carbs}%" />
      <div class="h-[2px] bg-sky-400" style:flex-basis="{percentages.protein}%" />
      <div class="h-[2px] bg-rose-400" style:flex-basis="{percentages.fats}%" />
    </div>
  {/if}
</div>
