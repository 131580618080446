<script lang="ts">
  import { currentClient } from "stores";
  import type MenuItem from "../../interfaces/MenuItem";
  import { api, dialogTypes } from "../../lib/constants";
  import { deleteWithJwt } from "../../lib/requests";
  import { translate } from "../../lib/translate";
  import { clientFasts } from "../../stores/clientStores";
  import { dialogData } from "../../stores/dialogDataStore";
  import { showAlert } from "../../stores/showAlertStore";
  import { theme } from "../../stores/themeStore";
  import { user } from "../../stores/userStore";
  import More from "../UI/More.svelte";
  import { parseDate } from "../../lib/parseDate";

  export let fastId: number;
  export let date: string;
  export let totalTimeFasted: string;

  const deleteFast = async () => {
    try {
      let url: string;

      if ($currentClient && $currentClient.id) {
        url = `${api}/fasting_tracker/${fastId}?userId=${$currentClient.id}`;
      } else {
        url = `${api}/fasting_tracker/${fastId}?userId=${$user.id}`;
      }

      const res = await deleteWithJwt(url);

      if (res === "Success") {
        $clientFasts = $clientFasts.filter((f) => f.id !== fastId);
        $showAlert.message = `${translate("SUCCESSFULLY_DELETED")} fast.`;
      }
    } catch (err) {}
  };

  const menuItems: MenuItem[] = [
    {
      title: "DELETE",
      executeFunction: () => {
        $dialogData.type = dialogTypes.CONFIRM_DELETE;
        $dialogData.data = { executeFunction: deleteFast, title: "fast" };
      },
      icon: "delete",
    },
  ];
</script>

<div
  class="p-2 flex flex-col border rounded-md border-slate-200 dark:border-zinc-600 transition-shadow duration-[400ms] ease-linear"
>
  <div class={`flex-row center-space-between`}>
    <div class="mgl-12 title flex-row">
      <div class="flex-row center-center">
        {parseDate(date)}
      </div>
    </div>
    <div>{totalTimeFasted}</div>
    <More myClasses="mr-3" {menuItems} />
  </div>
</div>

<style>
  .animation {
    animation-name: expand;
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
  }
  @keyframes expand {
    from {
      height: 40px;
    }
    to {
      height: fit-content;
    }
  }
  .animation-reverse {
    animation-name: contract;
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
  }
  @keyframes contract {
    from {
      height: 400px;
    }
    to {
      height: 40px;
    }
  }
  .title {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
</style>
