import {get, writable} from "svelte/store";
import {serverlessRoutes, storeUtil} from "lib";
import {muscleGroupRequestMap} from "../lib/constants";
import type { Exercise, ItemsStore } from "interfaces";

interface TrainerExercisesStore extends ItemsStore<Exercise> {
  filter: {
    search: string;
    sort: any;
    template: number | undefined;
    tags: Array<string>;
  };
}


const SerbianSpecialCharMap = {
  ć: "c",
  č: "c",
  đ: "dj",
  ž: "z",
  š: "s",
}

const regexp = new RegExp(Object.keys(SerbianSpecialCharMap).join("|"), "g");

const createSearchString = (text) => text
  .toLowerCase()
  .split(" ")
  .join(" ")
  .replace(regexp, (substring) => SerbianSpecialCharMap[substring])
  .replace(/[^\w\s]/gi, "");



const trainerExercisesStoreCreate = () => {
  const {set, subscribe, update} = writable<TrainerExercisesStore>({
    items: [],
    count: 0,
    hasMore: false,
    isFetching: false,
    isFetchingMore: false,
    skip: 0,
    searchTimeout: undefined,
    filter: {
      search: "",
      sort: undefined,
      template: 1,
      tags: []
    }
  });

  const createUrl = (): string => {
    const {skip, filter} = get({subscribe});

    let template = "";
    let sort = "";
    let name = "";
    let muscleGroup = "";

    if (filter.template === 1) {
      template = "&default=1&template=1";
    } else {
      template = "&default=1&template=0";
    }

    if (filter.sort !== undefined) {
      if (filter.sort === "NAME_ASC") {
        sort = "&orderBy=name&order=ASC";
      } else if (filter.sort === "NAME_DESC") {
        sort = "&orderBy=name&order=DESC";
      } else if (filter.sort === "DATE_ASC") {
        sort = "&orderBy=id&order=ASC";
      } else {
        sort = "&orderBy=id&order=DESC";
      }
    }

    if (filter.search) {
      name = `&name=${createSearchString(filter.search)}`;
    }

    if (filter.tags.length) {
      muscleGroup = `&muscleGroup=%5B${filter.tags
        .map((filter): string => `%22${muscleGroupRequestMap.get(filter)}%22`)
        .join(",")}%5D`;
    }

    return `${serverlessRoutes.EXERCISE}/list/v2?take=8&skip=${
      skip
    }${template}${name}${muscleGroup}${sort}`;
  };

  const {
    add,
    replace,
    remove,
    fetchData,
    fetchMoreData,
    search,
    loadCache
  } = storeUtil<Exercise, TrainerExercisesStore>(
    "trainerExercisesCache", update, createUrl
  );

  return {
    set,
    subscribe,
    update,
    add,
    replace,
    remove,
    fetchData,
    fetchMoreData,
    search,
    loadCache
  };
};

const trainerExercisesStore = trainerExercisesStoreCreate();

export {trainerExercisesStore};
