<script>
  import {
    Table,
    TableBody,
    TableBodyCell,
    TableBodyRow,
  } from "flowbite-svelte";
  import { translate } from "../../lib/translate";

  export let fastingStats;
</script>

<Table>
  <TableBody>
    <TableBodyRow>
      <TableBodyCell>{translate("TOTAL_FASTS")}</TableBodyCell>
      <TableBodyCell>{fastingStats?.totalFasts}</TableBodyCell>
    </TableBodyRow>
    <TableBodyRow>
      <TableBodyCell>{translate("AVERAGE")} (7d)</TableBodyCell>
      <TableBodyCell>{fastingStats?.weeklyAverage}</TableBodyCell>
    </TableBodyRow>
    <TableBodyRow>
      <TableBodyCell>{translate("THE_LONGEST_FAST")}</TableBodyCell>
      <TableBodyCell>{fastingStats?.longestFast}</TableBodyCell>
    </TableBodyRow>
    <TableBodyRow>
      <TableBodyCell>{translate("CURRENT_STREAK")}</TableBodyCell>
      <TableBodyCell>{fastingStats?.bestStreak}</TableBodyCell>
    </TableBodyRow>
    <TableBodyRow>
      <TableBodyCell>{translate("TOTAL_FASTS")}</TableBodyCell>
      <TableBodyCell>{fastingStats?.currentStreak}</TableBodyCell>
    </TableBodyRow>
  </TableBody>
</Table>
