<script lang="ts">
  import { onDestroy, onMount } from "svelte";
  import { Capacitor } from "@capacitor/core";

  import { dialogTypes, getWithJwt, serverlessRoutes, translate } from "lib";
  import { ButtonComponent, ButtonGroup, InfiniteScroll, MiniSpinner, Search } from "ui";

  import { user } from "../../stores/userStore";
  import { trainerPrograms } from "../../stores/trainerStores";
  import { dialogData } from "../../stores/dialogDataStore";
  import { trainerProgramsCount } from "stores";
  import { isLoadingMore } from "../../stores/isLoadingMoreStore";

  import TopMenu from "../../components/TopMenu.svelte";
  import ProgramComponent from "../../components/Training/ProgramComponent.svelte";

  import type { IButtonGroup } from "interfaces";

  let isFetching = false;
  let searchValue = "";
  let timeout: NodeJS.Timeout;
  let page = 0;
  let templateType: "MY" | "LIBRARY" | "GROUPS" = "MY";

  $: hasMore = $trainerPrograms.length < $trainerProgramsCount;

  const createUrl = (): string => {
    let template = "";
    let name = "";

    if (templateType === "MY") {
      template = "&template=0&group=0";
    } else if (templateType === "LIBRARY") {
      template = "&template=1&group=0";
    } else {
      template = "&template=0&group=1";
    }

    if (searchValue) {
      name = `&name=${searchValue}`;
    }

    return `${serverlessRoutes.FETCH_CLIENT_PROGRAMS}/list?take=15&skip=${page * 15}${template}${name}`;
  };

  const onFetchData = async (): Promise<void> => {
    if (!$user || isFetching) {
      return;
    }

    isFetching = true;
    page = 0;

    try {
      const response = await getWithJwt(createUrl());
      $trainerPrograms = response.data.data;
      $trainerPrograms = $trainerPrograms.sort(
        (a, b) => a.position - b.position
      );
      $trainerProgramsCount = response.data.count;

      localStorage.setItem(
        "trainerProgramsCache",
        JSON.stringify({
          programs: $trainerPrograms,
          count: $trainerProgramsCount,
        })
      );
    } catch (error) {
      console.error(error);
    }

    isFetching = false;
  };

  const onLoadMore = async (): Promise<void> => {
    if (!$user) {
      return;
    }

    $isLoadingMore = true;
    page += 1;

    try {
      const response = await getWithJwt(createUrl());
      $trainerPrograms = [...$trainerPrograms, ...response.data.data];
      $trainerPrograms = $trainerPrograms.sort(
        (a, b) => a.position - b.position
      );
    } catch (error) {
      console.error(error);
      $isLoadingMore = false;
    }

    $isLoadingMore = false;
  };

  const unsubscribe = user.subscribe((res) => {
    onFetchData();
  });

  const onSearch = (): void => {
    clearTimeout(timeout);
    timeout = setTimeout(async (): Promise<void> => {
      onFetchData();
    }, 1000);
  };

  const onAddTrainingProgram = (): void => {
    $dialogData.data = {};
    $dialogData.type = dialogTypes.TRAINING_PROGRAM;
  };

  const onMyPrograms = async () => {
    if (templateType === "MY") {
      return;
    }

    templateType = "MY";
    await onFetchData();
  };

  const onLibrary = async () => {
    if (templateType === "LIBRARY") {
      return;
    }

    templateType = "LIBRARY";
    await onFetchData();
  };

  const onGroups = async () => {
    if (templateType === "GROUPS") {
      return;
    }

    templateType = "GROUPS";
    await onFetchData();
  };

  const buttonGroup: IButtonGroup[] = [
    { title: translate("MY_PROGRAMS"), onClick: onMyPrograms },
    { title: translate("LIBRARY"), onClick: onLibrary },
    { title: translate("GROUPS"), onClick: onGroups },
  ];

  onDestroy(() => {
    unsubscribe();
    $isLoadingMore = false;
  });

  onMount(() => {
    const trainerProgramsCache = localStorage.getItem("trainerProgramsCache");
    if (trainerProgramsCache) {
      const { programs, count } = JSON.parse(trainerProgramsCache);
      $trainerPrograms = programs;
      $trainerProgramsCount = count;
      isFetching = false;
    }
  });
</script>

<TopMenu entity="TRAINING" />
<div class="p-4 flex flex-col gap-4">
  <div class="flex gap-4">
    <Search
      cy="search-programs-input"
      placeholder={translate("SEARCH_TRAINING_PROGRAMS")}
      bind:value={searchValue}
      on:input={onSearch}
    />
    <ButtonComponent cy="add-program-button" myClasses="whitespace-nowrap" on:click={onAddTrainingProgram}>
      {translate("ADD")}
      {translate("PROGRAM").toLowerCase()}
    </ButtonComponent>
  </div>

  <div class="flex justify-center">
    <ButtonGroup buttons={buttonGroup} />
  </div>

  <div class="text-center">
    {translate("TOTAL_PROGRAMS_COUNT")}: <b>{$trainerProgramsCount}</b>
  </div>

  {#each $trainerPrograms as program}
    <ProgramComponent {program} />
  {/each}

  {#if $isLoadingMore}
    <div class="flex justify-center">
      <MiniSpinner />
    </div>
  {/if}

  <InfiniteScroll {hasMore} on:loadMore={onLoadMore} />

  {#if Capacitor.isNativePlatform()}
    <div class="mb-12"></div>
  {/if}
</div>
