<script lang="ts">
  import { fetchUser, post, serverlessRoutes, isClient } from "lib";
  import { showAlert, user } from "stores";
  import { onMount } from "svelte";
  import { replace } from "svelte-spa-router";

  export let params = {};

  onMount(async () => {
    if ($user) {
      replace("/training/program");
    }
    await signin();
  });

  const signin = async () => {
    const hash = params.hash;
    const data = { email: hash, password: "DJV@BQz37qnVqt6D?6NY" };
    const url = serverlessRoutes.SIGNIN;
    try {
      const res = await post(url, data);
      if (res.accessToken) {
        localStorage.setItem("authJwt", res.accessToken);
        const fetchedUser = await fetchUser();
        if (!isClient(fetchedUser)) {
          replace("/clients/list");
        } else {
          replace("/training/program");
        }
      } else {
        $showAlert.message = res.message;
      }
      // return res;
    } catch (err) {
      $showAlert.message = err.message;
    }
  };
</script>
