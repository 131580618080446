<script lang="ts">
    import { DatePicker } from "date-picker-svelte";
  import { slide } from "svelte/transition";
  import { Svg } from "ui";

  let passwordInputElement: HTMLInputElement;
  let datePickerElement: HTMLInputElement;
  let passwordIcon = "eye-slash";

  let type: "text" | "email" | "password" | "date" | "number" = "text";
  let label: string;
  let placeholder = "";
  let disabled = false;
  let value: string | number;
  let error: string;
  let step = "1";

  let suffix = "";

  let min = "1920-01-01";
  let max = "";

  let className = `
    block w-full dark:bg-zinc-700 rounded-md border-0 py-2 px-4 ${suffix || type === "password" ? "pr-12" : ""} text-gray-900 dark:text-white ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 dark:focus:ring-primary-500 sm:text-sm sm:leading-6
  `;

  const onTogglePasswordVisible = (): void => {
    if (passwordInputElement.type === "password") {
      passwordInputElement.type = "text";
      passwordIcon = "eye";
    } else {
      passwordInputElement.type = "password";
      passwordIcon = "eye-slash";
    }
  };

  let isCalendarVisible = false;

  const onShowDatePicker = (): void => {
    // datePickerElement.showPicker();
    isCalendarVisible = !isCalendarVisible;
  };

  export { type, label, placeholder, error, disabled, value, suffix, min, max };
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-no-static-element-interactions -->
<div class="relative pb-4 flex flex-col" on:click>
  <!-- {#if label && label !== ""} -->
  <label
    for={label}
    class="h-4 ml-1 text-xs flex items-center leading-none font-medium text-gray-900 dark:text-gray-100"
  >
    {label}:
  </label>
  <!-- {/if} -->
  <!-- <div class={`relative rounded-md shadow-sm ${label !== "" ? "mt-2" : ""}`}> -->
  {#if type === "password"}
    <input
      {disabled}
      class={className}
      type="password"
      id={label}
      placeholder={placeholder || label}
      autocomplete="on"
      bind:this={passwordInputElement}
      bind:value
      on:input
    />
    <div class="absolute top-[50%] right-4 translate-y-[-50%]">
      <Svg name={passwordIcon} size={16} on:click={onTogglePasswordVisible} />
    </div>
  {:else if type === "email"}
    <input
      bind:value
      {disabled}
      type="email"
      id={label}
      autocomplete="email"
      class={className}
      placeholder={placeholder || label}
      on:input
    />
  {:else if type === "date"}
    {#if isCalendarVisible}
      <div class="fixed top-0 left-0 w-full h-full z-[49]" style="background-color: rgba(0, 0, 0, 0.66)" on:click={() => isCalendarVisible = false}></div>
      <div class="fixed z-50 top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
        <DatePicker value={value ? new Date(value) : new Date()} on:select={(e) => {value = e.detail.toISOString().slice(0, 10); isCalendarVisible = false}}/>
      </div>
    {/if}
    <input
      class={className}
      type="date"
      id={label}
      {min}
      {max}
      bind:this={datePickerElement}
      bind:value
      {disabled}
      on:input
      on:click|preventDefault={onShowDatePicker}
      
    />
  {:else if type === "number"}
    <input
      type="number"
      class={className}
      id={label}
      placeholder={placeholder || label}
      step="0.1"
      bind:value
      on:input
    />
  {:else}
    <input
      bind:value
      {disabled}
      type="text"
      id={label}
      autocomplete="off"
      class={className}
      placeholder={placeholder || label}
      on:input
    />
  {/if}
  {#if suffix}
    <div class="absolute top-[50%] right-4 translate-y-[-50%]">
      {suffix}
      <!-- <Svg name={passwordIcon} size={16} on:click={onTogglePasswordVisible}/> -->
    </div>
  {/if}
  <!-- </div> -->
  {#if error}
    <div
      in:slide
      out:slide
      class="h-4 ml-1 flex items-center leading-none absolute bottom-0 left-0 text-xs text-red-500"
    >
      {error}
    </div>
  {/if}
</div>

<style>
  input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
  }

  :global(.date-time-picker) {
    width: 288px;
    border-radius: 8px !important;
    padding: 16px !important;
    padding-top: 20px !important;
  }

  :global(.date-time-picker select) {
    box-shadow: none !important;
  }

  :global(.date-time-picker option) {
    background-color: var(--date-picker-background);
    color: var(--date-picker-foreground);
  }
</style>
