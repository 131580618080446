<script lang="ts">
  import { api, serverlessRoutes } from "../../lib/constants";
  import { getWithJwt, postWithJwt } from "../../lib/requests";
  import { translate } from "../../lib/translate";
  import Svg from "../UI/Svg.svelte";
  import Comment from "./Comment.svelte";
  import { createEventDispatcher, onMount } from "svelte";
  import { trainerClientPrograms, trainerClientWorkouts } from "stores";

  export let entity: string;
  export let entityId: number;
  export let comments: any[];
  export let commentCount: number = 0;
  export let commentableId: number = 0;
  export let mealPlanId: number = 0;
  export let isExpanded: boolean = false;

  const dispatch = createEventDispatcher();

  let payload: string = "";

  const urls = {
    MEAL: `${api}/meal_plan/${mealPlanId}/${entity.toLowerCase()}/${entityId}/comment`,
    WORKOUT: `${api}/${entity.toLowerCase()}/${entityId}/comment`,
    EXERCISE: `${api}/${entity.toLowerCase()}/${entityId}/comment`,
  };

  const fetchComments = async () => {
    if (
      entity === "MEAL" &&
      comments.length === 0 &&
      // isExpanded === true &&
      commentableId !== 0
    ) {
      if (!commentableId) return;
      try {
        const url = `${serverlessRoutes.FETCH_COMMENTS}?commentableId=${commentableId}`;
        const res = await getWithJwt(url);

        comments = res.data;

        // const meal = $loadedMealsStore.find((meal) => meal.mealPlanId === mealPlanId);
        // meal.commentable = res.data;
        // $loadedMealsStore = $loadedMealsStore;
      } catch (err) {}
    }
  };

  const onToggleComments = async () => {
    isExpanded = !isExpanded;

    if (
      isExpanded &&
      comments &&
      !comments.length &&
      entity === "MEAL" &&
      commentableId &&
      commentCount > 0
    ) {
      const url = `${serverlessRoutes.FETCH_COMMENTS}?commentableId=${commentableId}`;
      const res = await getWithJwt(url);
      // const meal = $loadedMealsStore.find((meal) => meal.mealPlanId === mealPlanId);

      // if (meal) {
      //   meal.commentable = {comments: res.data};
      // }

      comments = res.data;
    }
  };

  const submit = async () => {
    try {
      const res = await postWithJwt(urls[entity], {
        commentedID: entityId,
        text: payload,
      });

      if (entity === "WORKOUT" || entity === "EXERCISE") {
        // console.log("AAAA");

        // $trainerClientPrograms.forEach((program) => {
        //   program.workouts.forEach((workout) => {
        //     if (workout.id === entityId) {
        //       if (
        //         workout.commentable?.comments &&
        //         workout.commentable?.comments.length > 0
        //       ) {
        //         workout.commentable.comments = [...comments, res];
        //       } else {
        //         workout.commentableId = res.commentableId;
        //         workout.commentable = { comments: [res] };
        //       }
        //     } else {
        //       workout.exercises.forEach((exercise) => {
        //         if (exercise.id === entityId) {
        //           if (
        //             exercise.commentable?.comments &&
        //             exercise.commentable?.comments.length > 0
        //           ) {
        //             exercise.commentable.comments = [...comments, res];
        //           } else {
        //             exercise.commentableId = res.commentableId;
        //             exercise.commentable = { comments: [res] };
        //           }
        //         }
        //       });
        //     }
        //   });
        // });

        // console.log("EEEEE");
        // console.log($trainerClientWorkouts);

        // $trainerClientWorkouts.data.forEach((workout) => {
        //   if (workout.id === entityId) {
        //     console.log({workout});
        //     if (
        //       workout.commentable?.comments &&
        //       workout.commentable?.comments.length > 0
        //     ) {
        //       workout.commentable.comments = [...comments, res];
        //     } else {
        //       workout.commentableId = res.commentableId;
        //       workout.commentable = { comments: [res] };
        //     }
        //   } else {
        //     workout.exercises.forEach((exercise) => {
        //       console.log({exercise});

        //       if (exercise.id === entityId) {
        //         if (
        //           exercise.commentable?.comments &&
        //           exercise.commentable?.comments.length > 0
        //         ) {
        //           exercise.commentable.comments = [...comments, res];
        //         } else {
        //           exercise.commentableId = res.commentableId;
        //           exercise.commentable = { comments: [res] };
        //         }
        //       }
        //     });
        //   }
        // });
      }

      if (comments && comments.length > 0) {
        comments = [...comments, res];
      } else {
        comments = [res];
      }
    } catch (err) {}
  };

  const onDelete = (event: CustomEvent<{ id: number }>): void => {
    // $trainerClientPrograms.forEach((program) => {
    //   program.workouts.forEach((workout) => {
    //     if (workout.id === entityId) {
    //       if (workout.commentable.comments) {
    //         workout.commentable.comments = workout
    //           .commentable.comments.filter((comment) => comment.id !== event.detail.id);

    //       }
    //     } else {
    //       workout.exercises.forEach((exercise) => {
    //         if (exercise.id === entityId) {
    //           if (exercise.commentable.comments) {
    //             exercise.commentable.comments = exercise
    //           .commentable.comments.filter((comment) => comment.id !== event.detail.id);
    //           }
    //         }
    //       });
    //     }
    //   });
    // });
    // }
    // Object.values($loadedWorkouts).forEach((workout) => {
    //   workout.exercises.forEach((exercise) => {
    //     if (exercise.id === entityId) {
    //       if (
    //         exercise.commentable?.comments &&
    //         exercise.commentable?.comments.length > 0
    //       ) {
    //         exercise.commentable.comments =
    //           exercise.commentable.comments.filter(
    //             (comment) => comment.id !== event.detail.id
    //           );
    //       }
    //     }
    //   });
    //   if (workout.id === entityId) {
    //     if (comments && comments.length > 0) {
    //       workout.commentable.comments = workout.commentable.comments.filter(
    //         (comment) => comment.id !== event.detail.id
    //       );
    //     }
    //   }
    // });

    // $loadedMealsStore.forEach((meal) => {
    //   const comment = meal.commentable?.comments.find((comment) => comment.id === event.detail.id);

    //   if (comment) {
    //     const index = meal.commentable.comments.indexOf(comment);
    //     meal.commentable.comments.splice(index, 1);
    //   }
    // });
    // $loadedMealsStore = $loadedMealsStore;

    comments = comments.filter((comment) => comment.id !== event.detail.id);

    dispatch("delete");
  };

  onMount(async (): Promise<void> => {
    // if (!comments?.length)
    await fetchComments();
  });
</script>

<!-- svelte-ignore a11y-no-static-element-interactions -->
<!-- svelte-ignore a11y-click-events-have-key-events -->
<div class="flex flex-col gap-4">
  <div class="flex">
    <div class="text-xs text-slate-900 dark:text-slate-100">
      {translate(`${entity}_COMMENTS`)} ({commentCount
        ? commentCount
        : comments
          ? comments.length
          : 0})
    </div>

    <div on:click={onToggleComments}>
      {#if !isExpanded}
        <Svg name="down-caret" size={16} />
      {:else}
        <Svg name="up-caret" size={16} />
      {/if}
    </div>
  </div>

  {#if isExpanded}
    <div class="flex flex-col gap-2">
      {#if comments}
        {#each comments as comment (comment.id)}
          <Comment {comment} on:delete={onDelete} />
        {/each}
      {/if}
    </div>
    <div class="flex items-center gap-4">
      <input
        type="text"
        class="input grow bg-slate-50 dark:bg-zinc-900 text-slate-900 dark:text-slate-100 border-slate-200 dark:border-zinc-600"
        bind:value={payload}
        style="width: 80%"
        placeholder={`${translate("COMMENT_HERE")}...`}
      />
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <!-- svelte-ignore a11y-no-static-element-interactions -->
      <Svg
        customColor="bg-primary-500 dark:bg-primary-500"
        name="right-arrow-button"
        size={26}
        on:click={() => {
          submit();
          payload = "";
        }}
      />
    </div>
  {/if}
</div>
