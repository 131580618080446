<script lang="ts">
  import { afterUpdate, onDestroy, onMount } from "svelte";
  import ChatInput from "../../components/Chat/ChatInput.svelte";
  import ChatTopMenu from "../../components/Chat/ChatTopMenu.svelte";
  import FileMessage from "../../components/Chat/FileMessage.svelte";
  import Message from "../../components/Chat/Message.svelte";
  import { api, dialogTypes, serverlessRoutes } from "../../lib/constants";
  import {
    deleteWithJwt,
    getWithJwt,
    postFormDataWithJwt,
    postWithJwt,
    putWithJwt,
  } from "../../lib/requests";
  import { user } from "../../stores/userStore";
  import { showAlert } from "../../stores/showAlertStore";
  import {
    unreadMessages,
    clientChatSessionId,
    notSeenSessionIds,
  } from "../../stores/chatSessionsStore";
  import MessageMenu from "../../components/Chat/MessageMenu.svelte";
  import { translate } from "../../lib/translate";
  import { dialogData } from "../../stores/dialogDataStore";
  import * as Sentry from "@sentry/browser";
  import { App } from "@capacitor/app";
  import { chatMessages } from "../../stores/chatMessagesStore";
  import {
    clientTrainerAvatarUrl,
    clientTrainerId,
  } from "../../stores/clientStores";
  import { isClient } from "../../lib/roles";
  import { trainerChatWith } from "../../stores/trainerStores";
  import { Capacitor } from "@capacitor/core";
  import { isLoadingMore } from "../../stores/isLoadingMoreStore";
  import { payload } from "../../stores/payloadStore";
  import { parseDateWithTime } from "../../lib/parseDateWithTime";
  import AiSummary from "../../components/Chat/AiSummary.svelte";
  import { bookmarkedMessages } from "../../stores/bookmarkedMessages";
  import AiSuggestion from "../../components/Chat/AiSuggestion.svelte";
  import { uploadImageCloudflare } from "../../lib/cloudflare/uploadImageCloudflare";
  import {
    isVideoReady,
    uploadVideoToCloudflareStreams,
  } from "../../lib/cloudflare/uploadVideoCloudflare";
  import { IndeterminateProgressBarComponent } from "ui";
  import ChatSearch from "../../components/Chat/ChatSearch.svelte";
  import { socketV2 } from "../../stores/socketStore";

  export let params = {};

  let isFileUploading = false;
  let showAiSummary: boolean;
  let showAiSuggestion: boolean;
  let avatarUrl = "";
  let isLoading = false;
  let trainersName = "";
  let selectedMessage = null;
  let isEditingMessage: boolean = false;
  let timer;
  let sessionId: number;
  const messageSendingIds: number[] = [];
  let searchMode = false;

  $: searchMode && fetchData(20000);

  const touchduration = 500;
  let total: number;
  let skip = 0;
  let unSeenMessageId: number | null = null;

  const isMine = (id: number, payload) => {
    return $user.id === id;
  };

  const generateRef = (): string => {
    return (
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15) +
      "-" +
      Date.now() +
      "-" +
      $clientTrainerId +
      "-" +
      $user.id
    );
  };

  const uploadFile = async (files: File[] | null) => {
    if (!files) return;

    for (const file of files) {
      isFileUploading = true;

      let tmpPayload = "";
      const isVideo = file.type.includes("video");

      if (isVideo) {
        const uploadResult = await uploadVideoToCloudflareStreams(file, true);

        if (!uploadResult.videoUrl && uploadResult.error) {
          isFileUploading = false;
          $showAlert.message = uploadResult?.error;
        }

        const interval = setInterval(async (): Promise<void> => {
          const isReady = await isVideoReady(uploadResult.videoUrl);

          if (isReady === true) {
            tmpPayload = `:video:${uploadResult?.videoUrl}`;
            isFileUploading = false;
            await sendMessage(tmpPayload);
            clearInterval(interval);
          }
        }, 4000);
      } else {
        const uploadRes = await uploadImageCloudflare(file, true);
        isFileUploading = false;
        if (!uploadRes.imageUrl) return;
        tmpPayload = `:cloudflareimg:${uploadRes.imageUrl}`;
        await sendMessage(tmpPayload);
      }
    }
  };

  const sendMessageV2 = async (payload: string) => {
    if (isEditingMessage && selectedMessage.id) {
      editMessageRequest(payload);
    } else {
      const otherUserId = isClient($user)
        ? $clientTrainerId
        : $trainerChatWith.clientId;
      const url = `${serverlessRoutes.MESSAGE}`;
      const message = {
        payload,
        sessionId,
        senderId: $user.id,
      };
      const data = {
        message,
        receiverId: otherUserId,
        senderName: $user.name,
      };

      try {
        await postWithJwt(url, data);
        setSeen();
      } catch (err) {
        Sentry.captureException(err);
        $showAlert.color = "red-400";
        $showAlert.message = "Došlo je do greške";
      }
    }
  };

  const sendMessage = async (payload: string) => {
    if (isEditingMessage && selectedMessage.id) {
      editMessageRequest(payload);
    } else {
      let formData = new FormData();
      const otherUserId = isClient($user)
        ? $clientTrainerId
        : $trainerChatWith.clientId;
      const url = `${api}/chat/message/private/${otherUserId}`;
      const ref = generateRef();

      formData.append("payload", payload);
      formData.append("senderId", `${$user.id}`);
      formData.append("ref", ref);
      formData.append("status", `1`);

      try {
        const res = await postFormDataWithJwt(url, formData, "POST", 3, 500);
        setSeen();
      } catch (err) {
        Sentry.captureException(err);
        $showAlert.color = "red-400";
        $showAlert.message = "Došlo je do greške";
      }
    }
  };

  const socketConnect = () => {
    if ($socketV2.connected === true && sessionId) {
      $socketV2.emit("subscribe", { event: "message", id: sessionId });
      $socketV2.off("message");
      $socketV2.on("message", (message) => {
        if (!$chatMessages.find((m) => m.id === message.id))
          $chatMessages = [...$chatMessages, message];
        setSeen();
        setTimeout(() => {
          forceScrollToBottom();
        });
      });
    }
  };

  const unsubscribeSocket = socketV2.subscribe((sock) => {
    socketConnect();
  });

  const socketDisconnect = () => {
    if ($socketV2.connected === true && sessionId) {
      $socketV2.emit("unsubscribe", { event: "message", id: sessionId });
      $socketV2.off("message");
    }
  };

  const copyToClipboard = (payload: string) => {
    navigator.clipboard.writeText(payload);
    selectedMessage = null;
    $showAlert.message = `${translate("SUCCESSFULLY_COPIED")} ${translate(
      "MESSAGE"
    ).toLowerCase()}`;
  };

  const editMessage = () => {
    $payload = selectedMessage.payload;
    isEditingMessage = true;
  };

  const editMessageRequest = async (payload: string) => {
    try {
      const url = `${api}/chat/message/${selectedMessage.id}`;
      const res = await putWithJwt(url, { payload });
      if (res === "Success") {
        $showAlert.message = `${translate("SUCCESSFULLY_EDITED")} ${translate(
          "MESSAGE"
        ).toLowerCase()}`;
        isEditingMessage = false;
        const index = $chatMessages.findIndex(
          (messages) => messages.id === selectedMessage.id
        );
        const tmpMessage = { ...$chatMessages[index] };
        tmpMessage.payload = payload;
        $chatMessages[index] = tmpMessage;
        selectedMessage = null;
      }
    } catch (err) {}
  };

  const touchstart = (
    messageId: number,
    messagePayload: string,
    timestamp: any,
    isMine: boolean
  ) => {
    timer = setTimeout(() => {
      selectedMessage = {
        id: messageId,
        payload: messagePayload,
        timestamp,
        isMine,
      };
    }, touchduration);
  };

  const touchend = () => {
    if (timer) clearTimeout(timer);
  };

  const clearSlectedMessage = () => {
    selectedMessage = null;
    isEditingMessage = false;
  };

  const deleteMessage = async () => {
    try {
      const url = `${api}/chat/message/${selectedMessage.id}`;
      const res = await deleteWithJwt(url);
      if (res === "Success") {
        $chatMessages = $chatMessages.filter(
          (message) => message.id !== selectedMessage.id
        );
        $showAlert.message = `${translate("SUCCESSFULLY_DELETED")} ${translate(
          "MESSAGE"
        )}`;
        selectedMessage = null;
      }
    } catch (err) {}
  };

  const showConfirmDeleteDialog = () => {
    $dialogData.type = dialogTypes.CONFIRM_DELETE;
    $dialogData.data = {
      executeFunction: deleteMessage,
      title: translate("MESSAGE"),
    };
  };

  const setSeen = async () => {
    const url = `${api}/chat/session/${sessionId}/set_seen`;
    try {
      const res = await postWithJwt(url, { seen: true });
      if (isClient($user)) {
        $unreadMessages = 0;
      } else {
        if (
          $notSeenSessionIds?.find(
            (notSeenClientId) => notSeenClientId === sessionId
          )
        ) {
          $unreadMessages -= 1;
        }
      }

      // isClient($user) ? ($unreadMessages = 0) : ($unreadMessages -= 1);
      if (!isClient($user)) {
        $notSeenSessionIds = $notSeenSessionIds.filter(
          (notSeenSessionId) => notSeenSessionId !== +sessionId
        );
      }
    } catch (err) {}
  };

  const fetchData = async (take = 25) => {
    if (!isLoading && $user && sessionId && sessionId !== 0) {
      isLoading = true;
      const url = `${serverlessRoutes.FETCH_MESSAGES}/v2?take=${take}&skip=${skip}&sessionId=${sessionId}`;
      try {
        const data = await getWithJwt(url);

        if (!isClient($user)) {
          unSeenMessageId = data.data.unSeenMessageId;
        }

        total = data.data.count;
        data.data.messages.forEach((msg) => {
          if (!$chatMessages.find((m) => m.id === msg.id)) {
            $chatMessages.push(msg);
            //$chatMessages = $chatMessages;
          }
        });
        $chatMessages = $chatMessages.sort((msgA, msgB) => msgA.id - msgB.id);
        // $chatMessages.forEach((message) => {

        // });
        isLoading = false;

        // forceScrollToBottom();
        setTimeout(() => {
          forceScrollToBottom();
        });
      } catch (err) {
        Sentry.captureException(err);
      }
    }
  };

  const calculateTimeDifference = (d1, d2) => {
    let date1 = new Date(d1);
    let date2 = new Date(d2);

    let differenceInMilliseconds = date2 - date1;
    let differenceInHours = differenceInMilliseconds / (1000 * 60 * 60);

    return differenceInHours >= 8;
  };

  const timeHeaderMap: any = {};

  const unsubscribeMessages = chatMessages.subscribe((res) => {
    if (res && res.length > 0) {
      // setTimeout(() => {
      //   if (window.scrollY > 300) {
      //     forceScrollToBottom();
      //   }
      // }, 1000);
      if (res.length > 1) {
        for (let i = 1; i < res.length; i++) {
          if (calculateTimeDifference(res[i - 1].createdAt, res[i].createdAt)) {
            timeHeaderMap[res[i].id] = true;
          }
        }
      }
    }
  });

  const fetchMoreData = async () => {
    $isLoadingMore = true;
    skip += 15;
    const url = `${serverlessRoutes.FETCH_MESSAGES}/v2?take=25&skip=${skip}&sessionId=${sessionId}`;
    try {
      const data = await getWithJwt(url);
      // $chatMessages = [...data.data.messages, ...$chatMessages];
      data.data.messages.forEach((msg) => {
        if (!$chatMessages.find((m) => m.id === msg.id)) {
          $chatMessages.push(msg);
          // $chatMessages = $chatMessages;
        }
      });
      $chatMessages = $chatMessages.sort((msgA, msgB) => msgA.id - msgB.id);
      $isLoadingMore = false;
    } catch (err) {
      $isLoadingMore = false;
    }
  };

  const unsubscribeUser = user.subscribe((res) => {
    if (res) {
      if (isClient($user)) {
        trainersName = res?.trainer?.name ? res?.trainer?.name : "Trener";
      } else {
        if (!$trainerChatWith.sessionId) {
          $trainerChatWith = JSON.parse(
            localStorage.getItem("trainerChatWith")
          );
        }
        sessionId = $trainerChatWith.sessionId;
        trainersName = $trainerChatWith.clientName;
        $chatMessages = [];
        avatarUrl = $trainerChatWith.avatarUrl;
        socketConnect();
      }
      fetchData();
    }
  });

  const unsubscribeAvatarUrl = clientTrainerAvatarUrl.subscribe((res) => {
    if (!res || !$user) return;
    avatarUrl = isClient($user) ? res : "/default-avatar.png";
  });

  const unsubscribeSessionId = clientChatSessionId.subscribe((res) => {
    if (res) {
      sessionId = $clientChatSessionId;
      socketConnect();
    }
    if ($user && res && skip === 0) fetchData();
  });

  const bookmarkMessage = async () => {
    const url = `${serverlessRoutes.BOOKMARK}`;
    try {
      const createdAt = selectedMessage.timestamp
        .replace("T", " ")
        .replace("Z", "");
      const response = await postWithJwt(url, {
        messageId: selectedMessage.id,
        sessionId,
        createdAt,
        payload: selectedMessage.payload,
      });
      const id = response.data[0];

      $bookmarkedMessages = [
        ...$bookmarkedMessages,
        {
          id,
          messageId: selectedMessage.id,
          sessionId,
          createdAt,
          payload: selectedMessage.payload,
        },
      ];

      $showAlert.message = `${translate("SUCCESSFULLY_BOOKMARKED_MESSAGE")}`;
    } catch (err) {
    } finally {
      selectedMessage = null;
    }
  };

  let lastScrollHeight: number;
  let currentScrollHeight: number;
  let blockScroll = false;

  onMount((): void => {
    if (Capacitor.isNativePlatform()) {
      App.addListener("appStateChange", async ({ isActive }) => {
        if (isActive) {
          skip = 0;
          await fetchData();
        }
      });
    }

    window.onscroll = async (): Promise<void> => {
      if (!lastScrollHeight && !currentScrollHeight) {
        lastScrollHeight = document.body.scrollHeight;
        currentScrollHeight = document.body.scrollHeight;
      }

      if (window.scrollY <= 0) {
        if ($chatMessages.length < total && !blockScroll) {
          blockScroll = true;

          await fetchMoreData();

          currentScrollHeight = document.body.scrollHeight;

          const top = currentScrollHeight - lastScrollHeight;

          window.scrollTo({ top });

          lastScrollHeight = currentScrollHeight;
        }
      } else if (window.scrollY > 0) {
        blockScroll = false;
      }
    };

    showAiSuggestion = localStorage.getItem("showAiSuggestion") === "true";
    showAiSummary = localStorage.getItem("showAiSummary") === "true";

    // setTimeout(forceScrollToBottom);
    // setTimeout(forceScrollToBottom, 500);
    setSeen();
  });

  const scrollToBottom = () => {
    if (skip !== 0) return; // Ovo mora zbog fetch load more jer se skroluje na gore
    window.scrollTo(0, document.body.scrollHeight);
  };

  const forceScrollToBottom = () => {
    window.scrollTo(0, document.body.scrollHeight);
  };

  afterUpdate(() => {
    if (Capacitor.isNativePlatform()) {
      //scrollToBottom();
    }
  });

  // onMount((): void => {
  // });

  onDestroy(() => {
    App.removeAllListeners();
    unsubscribeUser();
    unsubscribeSessionId();
    unsubscribeAvatarUrl();
    unsubscribeMessages();
    unsubscribeSocket();
    socketDisconnect();
    $trainerChatWith = {};
    localStorage.removeItem("trainerChatWith");
    window.onscroll = (): void => {};
    $bookmarkedMessages = [];
  });

  const isFile = (message) => {
    return (
      (message.files && message.files.length > 0) ||
      (message.payload && message.payload.includes("cloudflarestream")) ||
      (message.payload && message.payload.includes(":cloudflareimg:"))
    );
  };
</script>

{#if $user}
  {#if !searchMode}
    <ChatTopMenu
      bind:searchMode
      {trainersName}
      {avatarUrl}
      {sessionId}
      clientId={!isClient($user) ? $trainerChatWith.clientId : null}
    />
  {:else}
    <ChatSearch bind:searchMode />
  {/if}
  <div class="bg-white dark:bg-zinc-800">
    <!-- {#if isLoading}
    <Spinner />
  {:else} -->
    {#if Capacitor.isNativePlatform()}
      <div class="mt-20"></div>
    {/if}
    {#if Capacitor.getPlatform() === "ios"}
      <div class="pt-10"></div>
    {/if}
    <div class="mt-4"></div>
    <!-- {#if $chatMessages.length < total}
      <LoadMoreButton isReverse={true} {fetchMoreData} />
    {/if} -->

    <!-- <InfiniteScroll hasMore={$chatMessages.length < total} on:loadMore={fetchMoreData} reverse /> -->
    <div class="relative flex-col custom-height">
      {#each $chatMessages as message}
        {#if isFile(message)}
          <FileMessage
            payload={message.payload}
            files={message.files}
            id={message.id}
            timestamp={message.createdAt}
            {scrollToBottom}
            {touchstart}
            {touchend}
            isBottomImage={$chatMessages[$chatMessages.length - 1]?.id ===
              message.id}
            isMine={isMine(message.senderId)}
          />
          <!-- {scrollToBottom} -->
        {:else}
          <div class="relative flex flex-col">
            {#if timeHeaderMap[message.id]}
              <span
                class="text-xxs text-center mt-4 mb-2 text-slate-900 dark:text-slate-300"
                >{parseDateWithTime(message.createdAt)}</span
              >
            {/if}
            <Message
              timestamp={message.createdAt}
              id={message.id}
              {touchstart}
              {touchend}
              payload={message.payload}
              isMine={isMine(message.senderId, message.payload)}
            />
            {#if !isClient($user) && unSeenMessageId && unSeenMessageId === message.id}
              <img
                class="mt-1 mr-2 h-4 w-4 rounded-full self-end"
                src={avatarUrl ? avatarUrl : "/default-avatar.png"}
                alt="Avatar"
              />
            {/if}
          </div>
        {/if}
      {/each}
      {#if isFileUploading}
        <!-- <div class="flex flex-row items-center justify-center my-4">
          <MiniSpinner />
        </div> -->
        <div
          class="bg-white dark:bg-zinc-800 fixed bottom-[57px] left-[50%] translate-x-[-50%] w-full pt-2 max-w-[640px]"
        >
          <div class="text-center text-xxs">{translate("UPLOADING_FILE")}</div>
          <IndeterminateProgressBarComponent />
        </div>
      {/if}
      {#if !Capacitor.isNativePlatform()}
        <!-- <div class="mb-4" /> -->
      {/if}
    </div>
    <!-- {/if} -->
    {#if selectedMessage}
      <MessageMenu
        {bookmarkMessage}
        {copyToClipboard}
        {clearSlectedMessage}
        {editMessage}
        {showConfirmDeleteDialog}
        {selectedMessage}
      />
    {/if}
    <ChatInput
      {avatarUrl}
      {isFileUploading}
      clientName={trainersName}
      {sendMessage}
      {uploadFile}
    />
    {#if showAiSummary}
      <AiSummary {sessionId} />
    {/if}
    {#if showAiSuggestion && !isClient($user) && $chatMessages[$chatMessages.length - 1]?.senderId !== $user.id}
      <AiSuggestion {sendMessage} {sessionId} />
    {/if}
  </div>
{/if}

<style>
  .chat-margin-bottom {
    margin-bottom: calc(40px + calc(env(safe-area-inset-bottom) * 1.3));
  }
  .chat-margin-top {
    margin-bottom: calc(40px + calc(env(safe-area-inset-bottom) * 0.8));
  }
  .custom-height {
    min-height: calc(100vh - 48px - 48px);
  }
  .xd {
    transform: translateX(-50%);
  }
</style>
