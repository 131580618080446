import { get } from "svelte/store";
import { stats } from "../stores/finishWorkoutStats";
import type { FinishWorkoutStats } from "../interfaces/FinishWorkoutStats";
import { generateFinishExerciseArray } from "./generateFinishExerciseArray";
import { journalWorkoutsStore, loadedWorkouts, trainerClientWorkouts, user } from "stores";
import { isClient } from "./roles";

export const generateStats = (workoutId: number) => {
  let statsRef = get(stats);
  if (statsRef[0]?.stats[0]?.workoutId !== workoutId) {
    stats.set([]);
    // statsRef = [];
  } else if (statsRef.length > 0) {
    return;
  }
  const loadedWorkoutRef = get(loadedWorkouts);
  const statArr = [];

  const workout = get(trainerClientWorkouts).data.find((w) => w.id === workoutId);

  loadedWorkoutRef[workoutId].exercises.forEach((exercise) => {
  // workout?.exercises.forEach((exercise) => {
    if (exercise.entityType === 1) return;
    if (exercise.modelType === "superset") {
      exercise.supersetExercises.forEach((ex) => {
        const finishWorkoutStat: FinishWorkoutStats = {
          exerciseId: ex.id,
          name: ex.name,
          stats: [],
        };
        finishWorkoutStat.stats = generateFinishExerciseArray(ex, workoutId);
        statArr.push(finishWorkoutStat);
      });
      // stats = stats;
    } else {
      const finishWorkoutStat: FinishWorkoutStats = {
        exerciseId: exercise.id,
        name: exercise.name,
        stats: [],
      };
      finishWorkoutStat.stats = generateFinishExerciseArray(
        exercise,
        workoutId
      );
      statArr.push(finishWorkoutStat);
    }
  });

  const userRef = get(user);
  if (isClient(userRef) && userRef.trainer.id === 47803) {
    statArr.forEach((stat) => {
      stat.stats.forEach((set) => {
        set.reps = null;
      });
    });
  }

  stats.set([...statArr]);
};
