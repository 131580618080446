import { getWithJwt } from "lib";

export interface CloudflareGenerateResponse {
  uploadUrl: string;
  uid: string;
}

export const generateCloudflareStreamsUploadUrl =
  async (): Promise<CloudflareGenerateResponse | null> => {
    const generateUrl = "https://streams.trainme-api.workers.dev/api/generate";
    try {
      const res = await getWithJwt(generateUrl);
      const uploadUrl = res.result.uploadURL;
      const uid = res.result.uid;

      return { uploadUrl, uid };
    } catch (err) {
      return null;
    }
  };

export const generateCloudflareImagesUploadUrl = async (
  requireSignedURLs: boolean
): Promise<CloudflareGenerateResponse | null> => {
  const generateUrl = `https://images.trainme-api.workers.dev/api/generate?requireSignedURLs=${requireSignedURLs}`;
  try {
    const res = await getWithJwt(generateUrl);
    const uploadUrl = res.result.uploadURL;
    const uid = res.result.uid;

    return { uploadUrl, uid };
  } catch (err) {
    return null;
  }
};
