<script lang="ts">
  import { user } from "../../stores/userStore";
  import Avatar from "../UI/Avatar.svelte";
  import { parseDate } from "../../lib/parseDate";
  import { isClient } from "../../lib/roles";
  import {
    clientAvatarUrl,
    clientTrainerAvatarUrl,
  } from "../../stores/clientStores";
  import { createEventDispatcher, onMount } from "svelte";
  import { currentClient, dialogData, showAlert } from "stores";
  import { api, deleteWithJwt, dialogTypes, translate } from "lib";
  import { More } from "ui";
  import type { MenuItem } from "interfaces";

  export let comment: any;

  const dispatch = createEventDispatcher();

  let menuItems: MenuItem[] = [
    {
      title: "EDIT",
      icon: "edit",
      executeFunction(): void {
        $dialogData.data = {
          comment,
          executeFunction(response: any): void {
            comment = response;
          },
        };
        $dialogData.type = dialogTypes.EDIT_COMMENT;
      },
    },
    {
      title: "DELETE",
      icon: "delete",
      executeFunction(): void {
        $dialogData.data = {
          title: translate("COMMENT"),
          async executeFunction(): Promise<void> {
            try {
              await deleteWithJwt(`${api}/comment/${comment.id}`);

              $showAlert.color = "black";
              $showAlert.message = `
                ${translate("SUCCESSFULLY_DELETED")}
                ${translate("COMMENT").toLowerCase()}
              `;

              $dialogData.type = "";
              $dialogData.data = {};

              dispatch("delete", { id: comment.id });
            } catch (error) {
              console.error(error);
            }
          },
        };
        $dialogData.type = dialogTypes.CONFIRM_DELETE;
      },
    },
  ];

  let commentElement: HTMLDivElement;

  const otherUserName = isClient($user)
    ? $user.trainer.name
    : $currentClient
      ? $currentClient.name
      : "";
  const isMine = $user.id === comment.userId;
  const avatarUrl = isMine ? $clientAvatarUrl : $clientTrainerAvatarUrl;

  onMount((): void => {
    if (comment.userId !== $user.id) {
      menuItems = [];
    }
  });
</script>

<div class="relative flex items-start gap-2" bind:this={commentElement}>
  <div class="flex shrink-0 basis-12 flex-col justify-between">
    <Avatar {avatarUrl} size={48} alt="Avatar" />
  </div>
  <div
    class="p-2 flex flex-col gap-2 grow rounded-md text-slate-900 dark:text-slate-100 border border-slate-200 dark:border-zinc-600"
  >
    <div class="flex items-center justify-between">
      <div class="leading-none font-bold">
        {isMine ? $user.name : otherUserName}
      </div>
      <More {menuItems} />
    </div>
    <div class="text-xs text-justify leading-normal">{comment.text}</div>
    <div class="text-xxs self-end leading-none">
      {parseDate(comment.createdAt)}
    </div>
  </div>
</div>
