<script lang="ts">
  import { api, putWithJwt, translate } from "lib";
  import { user } from "stores";
  import { ButtonComponent, ExpansionPanel, Input, Textarea } from "ui";

  let disabled = true;
  let isLoading = false;

  const form = {
    defaultPassword:   { value: "", error: "" },
    welcomeMail:  { value: "", error: "" }
  };

  const onInput = (key: keyof typeof form): void => {
    if (key === "defaultPassword") { // 4 - 20
      if (form.defaultPassword.value.length < 4) {
        form.defaultPassword.error = "FIELD_MINIMUM_4";
      } else if (form.defaultPassword.value.length > 20) {
        form.defaultPassword.error = "FIELD_MAXIMUM_20";
      } else {
        form.defaultPassword.error = "";
      }
    } else if (key === "welcomeMail") { // 0 - 5000
      if (form.welcomeMail.value.length > 5000) {
        form.welcomeMail.error = "FIELD_MAXIMUM_5000";
      } else {
        form.welcomeMail.error = "";
      }
    }

    disabled = Object.values(form).some((field) => field.error !== "");
  };

  const onSubmit = async () => {
    try {
      await putWithJwt(`${api}/user/trainer_options`, {
        defaultPassword: form.defaultPassword.value,
        welcomeMail: form.welcomeMail.value
      });
    } catch (error) {
      console.error(error);
    }
  };
</script>

<ExpansionPanel title="{translate("CLIENT_SETTINGS")}">
  <div class="flex flex-col gap-4">

    <div class="text-xs text-justify">
      {translate("SETTINGS_CLIENTS_NOTE")}
    </div>

    <div>
      <p class="mb-1">{translate("NEW_CLIENT_DEFAULT_PASSWORD")}:</p>
      <Input
        name="amount"
        placeholder={translate("NEW_CLIENT_DEFAULT_PASSWORD")}
        bind:value={form.defaultPassword.value}
        onInput={() => onInput("defaultPassword")}
      />
      {#if form.defaultPassword.error}
        <span class="text-red-500 text-xs">
          {translate(form.defaultPassword.error)}
        </span>
      {/if}
    </div>

    <div>
      <p class="mb-1">{translate("WELCOMING_EMAIL")}:</p>
      <Textarea
        placeholder="{translate("WELCOMING_EMAIL")}"
        bind:value={form.welcomeMail.value}
        on:input={() => onInput("welcomeMail")}
      />
      {#if form.welcomeMail.error}
        <span class="text-red-500 text-xs">
          {translate(form.welcomeMail.error)}
        </span>
      {/if}
    </div>

    <div class="flex justify-center mb-2">
      <ButtonComponent on:click={onSubmit} {isLoading} {disabled}>
        {translate("UPDATE")}
      </ButtonComponent>
    </div>

  </div>
</ExpansionPanel>
