<script lang="ts">
  import { location } from "svelte-spa-router";
  import { trainerClientGroups } from "stores";
  import ClientListItem from "../../../components/ClientListItem.svelte";

  const groupId = parseInt($location.split("/clients/groups/")[1]);
  const group = $trainerClientGroups.find(
    (group): boolean => group.id === groupId
  );
  const { participants } = group.chatSession;
</script>

<div class="p-4 flex flex-col gap-4">
  <p class="text-center">
    Ukupan broj klijenata u grupi: <b>{participants.length}</b>
  </p>

  {#each participants as participant}
    <ClientListItem isInsideGroup client={participant.user} />
  {/each}
</div>
