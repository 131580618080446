<script lang="ts">
  import { push } from "svelte-spa-router";
  import { parseDate } from "../lib/parseDate";
  import {
    dialogData,
    showAlert,
    trainerClientGroups,
    trainerClientGroupsCount,
  } from "stores";
  import { api, deleteWithJwt, dialogTypes, translate } from "lib";
  import More from "./UI/More.svelte";
  import type { MenuItem } from "../interfaces/MenuItem";

  export let group: any;

  const description = group.chatSession.lastMsg.split("{");

  let menuItems: MenuItem[] = [
    {
      title: "ASSIGN_MEAL_PLAN",
      icon: "utensils",
      executeFunction(): void {
        $dialogData.data = { group };
        $dialogData.type = dialogTypes.ASSIGN_MEAL_PLAN;
      },
    },
    {
      title: "ASSIGN_TRAINING_PROGRAM",
      icon: "dumbbell",
      executeFunction(): void {
        $dialogData.data = { group };
        $dialogData.type = dialogTypes.ASSIGN_WORKOUT_PLAN;
      },
    },
    {
      title: "EDIT",
      icon: "edit",
      executeFunction(): void {
        $dialogData.data = { group };
        $dialogData.type = dialogTypes.CREATE_EDIT_GROUP;
      },
    },
    {
      title: "DELETE",
      icon: "delete",
      executeFunction(): void {
        $dialogData.data = {
          async executeFunction(): Promise<void> {
            try {
              await deleteWithJwt(`${api}/client_group/${group.id}`);

              $trainerClientGroups = $trainerClientGroups.filter(
                ({ id }) => id !== group.id
              );
              $trainerClientGroupsCount -= 1;

              $showAlert.color = "black";
              $showAlert.message = `
              ${translate("SUCCESSFULLY_DELETED")}
              ${translate("GROUP_A")}
            `;
            } catch (error) {
              console.error(error);
              $showAlert.color = "red-400";
              $showAlert.message = translate("ERROR_DELETING_GROUP");
            }
          },
          title: translate("GROUP_U"),
        };
        $dialogData.type = dialogTypes.CONFIRM_DELETE;
      },
    },
  ];

  const avatar =
    group.avatarUrl === ""
      ? "https://moj-trening.com/assets/images/defaults/avatar.png"
      : group.avatarUrl;
</script>

<div
  class="
    flex
    justify-between
    border
    rounded-md
    border-slate-200
    dark:border-zinc-600
  "
>
  <!-- svelte-ignore a11y-no-static-element-interactions -->
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <div class="flex" on:click={() => push(`/clients/groups/${group.id}`)}>
    <img
      src="default-group.png"
      alt="Group Avatar"
      class="m-4 h-16 w-16 rounded-full"
    />

    <div class="flex flex-col justify-center">
      <div style="font-size: small">{group.name}</div>
      <div style="font-size: smaller;">
        {description[0]}
        {translate(description[1].split("}")[0])}
      </div>
      <div style="font-size: smaller;">
        {new Date(group.chatSession.updatedAt).toLocaleTimeString("en-GB")}
      </div>
      <!-- <div style="font-size: smaller; color: gray;">
        {translate("REGISTERED")}: {parseDate(group.createdAt)}
      </div> -->
    </div>
  </div>
  <div class="m-3">
    <More {menuItems} />
  </div>
</div>
