<script lang="ts">
  import { trainerChatWith } from "../../stores/trainerStores";
  import { parseDateWithTime } from "../../lib/parseDateWithTime";
  import {
    notSeenSessionIds,
    unreadMessages,
  } from "../../stores/chatSessionsStore";
  import Avatar from "../UI/Avatar.svelte";
  import SeenButton from "../SeenButton.svelte";
  import { isClient } from "../../lib/roles";
  import { api, postWithJwt } from "lib";
  import { user } from "../../stores/userStore";
  import Svg from "../UI/Svg.svelte";

  export let session;

  $: unread = $notSeenSessionIds?.find(
    (notSeenClientId) => notSeenClientId === session.sessionId
  );

  const route = () => {
    $trainerChatWith = {
      clientId: session.clientId,
      clientName: session.clientName,
      avatarUrl: session.avatarUrl,
      sessionId: session.sessionId,
    };
    localStorage.setItem("trainerChatWith", JSON.stringify($trainerChatWith));
  };

  const toggleSeen = async () => {
    const url = `${api}/chat/session/${session.sessionId}/set_seen`;
    try {
      const res = await postWithJwt(url, { seen: unread ? true : false });
      isClient($user)
        ? ($unreadMessages = 0)
        : ($unreadMessages += unread ? -1 : +1);
      if (!isClient($user)) {
        if (unread) {
          $notSeenSessionIds = $notSeenSessionIds.filter(
            (notSeenSessionId) => notSeenSessionId !== +session.sessionId
          );
        } else {
          $notSeenSessionIds.push(session.sessionId);
          $notSeenSessionIds = $notSeenSessionIds;
        }
      }
    } catch (err) {}
  };

  const getLastMessage = (lastMsg: string) => {
    if (lastMsg?.includes("imagedelivery")) {
      return "Je poslao sliku";
    } else if (lastMsg?.includes(":video:")) {
      return "Je poslao video";
    } else if (lastMsg) {
      return lastMsg;
    } else {
      return "Je poslao poruku";
    }
  };
</script>

<a
  href={`/#/chat`}
  on:click={() => route()}
  class={`link ${unread ? "bg-slate-50 dark:bg-zinc-700" : "bg-white dark:bg-zinc-800"}`}
>
  <div class="flex-row center-space-between">
    <div class="flex-row center-center">
      <Avatar
        avatarUrl={session.avatarUrl}
        alt="User Avatar"
        size={56}
        myClasses="mg-8"
      />
      <div class="flex-col">
        <div class="flex-row">
          <span
            class={`flex flex-row items-center ${unread ? "font-medium" : ""}`}
          >
            {session.clientName}
            {#if session.clientArchivedAt}
              <span class="text-xxs mx-1"> (Arhiviran) </span>
              <Svg name="warning" customColor="bg-red-500" size={14} />
            {/if}
          </span>
          {#if unread}
            <div class="ml-2 rounded-full bg-red-500 h-3 w-3" />
          {/if}
        </div>
        <span class={`last-message ${unread ? "font-medium" : ""}`}>
          {getLastMessage(session.lastMsg)}
          <!-- {session.lastMsg?.includes(":video:") ? "Video" : session.lastMsg} -->
        </span>
      </div>
    </div>
    <div class="flex flex-col items-end">
      <SeenButton isSeen={unread ? false : true} {toggleSeen} />
      <div class="mr-2 text-xxs">
        {parseDateWithTime(session.updatedAt)}
      </div>
    </div>
  </div>
</a>

<style>
  .last-message {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 160px;
    height: 1.2em;
    white-space: nowrap;
    font-size: small;
  }
  .link {
    width: 100%;
    text-decoration: none;
  }
</style>
