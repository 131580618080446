import {quadInOut} from "svelte/easing";
import type {SlideParams} from "svelte/transition";

const flip = {
  duration: 333,
};

const slide: SlideParams = {
  duration: 333,
  easing: quadInOut
};

const animations = {flip, slide};

export {animations};
