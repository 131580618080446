<script lang="ts">
  import Svg from "../UI/Svg.svelte";
  import { api, dialogTypes, serverlessRoutes } from "../../lib/constants";
  import {
    deleteWithJwt,
    patchWithJwt,
    postWithJwt,
    putWithJwt,
  } from "../../lib/requests";
  import { showAlert } from "../../stores/showAlertStore";
  import dateString from "../../lib/dateString";
  import type { MenuItem } from "interfaces";
  import { currentClient, dialogData, user } from "stores";
  import { createEventDispatcher, onMount } from "svelte";
  import { Chart } from "chart.js";
  import {CheckboxComponent} from "ui";
  import More from "../UI/More.svelte";
  import { translate } from "lib";
  import { isClient } from "../../lib/roles";

  export let title: string;
  export let entityId: number;
  export let steps: number;
  export let isExpanded: boolean = false;
  export let isDraggable: boolean = false;
  export let map;
  export let selectedDate: any;
  export let fetchHabitData;
  let lock = false;

  let chartElement: HTMLCanvasElement;

  const dispatch = createEventDispatcher();

  let skip: number = 7;

  let checkedSteps = 0;
  $: checkedSteps = getValueFromMap(entityId, map, selectedDate);

  const getValueFromMap = (id: number, map, date) => {
    const object = map[dateString(date)];
    if (object) {
      const foundObject = object.find((obj) => Object.keys(obj)[0] === `${id}`);
      if (foundObject) return foundObject[id].value;
      return null;
    }
    return null;
  };

  const createHabitData = async (i: number) => {
    const url = `${api}/habit/post/${entityId}`;
    const data = {
      value: i + 1,
      habitId: entityId,
      createdAt: selectedDate.toISOString(),
    };
    try {
      const res = await postWithJwt(url, { habitData: data });
      fetchHabitData();
      lock = false;
    } catch (err) {
      $showAlert.message = "Something went wrong.";
      $showAlert.color = "red";
      lock = false;
    }
  };

  const updateHabitData = async (i: number, foundObject) => {
    const habitId = Object.keys(foundObject)[0];
    const habitDataId = foundObject[habitId].id;
    const url = `${api}/habit_data/${habitDataId}`;
    const habitData = {
      value: i + 1,
      habitId,
      id: habitDataId,
      createdAt: selectedDate.toISOString(),
    };
    try {
      const res = await putWithJwt(url, { habitData });
      fetchHabitData();
      map = map;
      lock = false;
    } catch (err) {
      $showAlert.message = "Something went wrong.";
      $showAlert.color = "red";
      lock = false;
    }
  };

  const check = async (i: number) => {
    if (!lock) {
      lock = true;
      if (checkedSteps > i) {
        i = i - 1;
      }
      checkedSteps = i;
      const objects = map[dateString(selectedDate)];
      const foundObject = objects?.find(
        (obj) => Object.keys(obj)[0] === `${entityId}`
      );
      if (foundObject) {
        updateHabitData(i, foundObject);
        return;
      }
      createHabitData(i);
    }
  };

  let incrementWeekCounter = 1;
  const today = new Date();

  const incrementDate = (n: number) => {
    if (n > 0 && selectedDate >= today) {
      return;
    }
    selectedDate.setDate(selectedDate.getDate() + n);
    selectedDate = selectedDate;
    const passedWeek = new Date();
    passedWeek.setDate(passedWeek.getDate() - incrementWeekCounter * 7);
    if (selectedDate < passedWeek) {
      incrementWeekCounter += 1;
      // fetchHabitData();
    }
  };

  const onExpand = (): void => {
    isExpanded = !isExpanded;
  };

  const deleteHabit = async () => {
    const url = `${serverlessRoutes.HABITS}?habitId=${entityId}`;
    try {
      await deleteWithJwt(url);
      dispatch("deleteHabit", {
        id: entityId,
      });
    } catch (err) {
      $showAlert.message = "Something went wrong.";
      $showAlert.color = "red";
    }
  };

  const updateHabit = async (habit) => {
    const url = `${serverlessRoutes.HABITS}`;
    try {
      await patchWithJwt(url, habit);
      dispatch("updateHabit", habit);
      $showAlert.message = translate("HABIT_UPDATED_SUCCESSFULLY");
    } catch (err) {
      $showAlert.message = "Something went wrong.";
      $showAlert.color = "red";
    }
  };

  const menuItems: MenuItem[] = [
    {
      icon: "edit",
      title: "EDIT",
      executeFunction(): void {
        $dialogData.type = dialogTypes.CREATE_EDIT_HABIT;
        $dialogData.data = {
          habitToUpdate: { id: entityId, title, steps },
          executeFunction: updateHabit,
        };
      },
    },
  ];

  if (!isClient($user)) {
    menuItems.push({
      icon: "delete",
      title: "DELETE",
      executeFunction(): void {
        $dialogData.data = {
          title: `${translate("HABITU")} ${title}`,
          executeFunction: deleteHabit,
        };
        $dialogData.type = dialogTypes.CONFIRM_DELETE;
      },
    });
  }

  let chart: Chart;

  const onWeek = (): void => {
    skip = 7;
    chart.data.labels = ["Pon", "Uto", "Sre", "Cet", "Pet", "Sub", "Ned"];
    chart.data.datasets = [
      {
        label: "Dataset week",
        data: [1, 20, 3, 40, 5, 60, 7],
        fill: false,
        borderWidth: 2,
        borderColor: "#319795",
      },
    ];

    chart.update();
  };

  const onMonth = (): void => {
    skip = 30;
    chart.data.labels = [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
      "30",
      "31",
    ];
    chart.data.datasets = [
      {
        label: "Dataset month",
        data: [
          1, 20, 3, 40, 5, 60, 7, 80, 9, 100, 11, 120, 13, 140, 15, 160, 17,
          180, 19, 200, 21, 220, 23, 240, 25, 260, 27, 280, 29, 300, 31,
        ],
        fill: false,
        borderWidth: 2,
        borderColor: "#319795",
      },
    ];

    chart.update();
  };

  const onThreeMonths = (): void => {
    skip = 90;
    chart.data.labels = ["Oktobar", "Novembar", "Decembar"];
    chart.data.datasets = [
      {
        label: "Dataset month",
        data: [1, 20, 3],
        fill: false,
        borderWidth: 2,
        borderColor: "#319795",
      },
    ];

    chart.update();
  };

  onMount((): void => {
    chart = new Chart(chartElement, {
      type: "line",
      options: {
        plugins: {
          legend: {
            display: false,
          },
        },
      },
      data: {
        labels: ["Pon", "Uto", "Sre", "Cet", "Pet", "Sub", "Ned"],
        datasets: [
          {
            label: "Dataset neki",
            data: [1, 20, 3, 40, 5, 60, 7],
            fill: false,
            borderWidth: 2,
            borderColor: "#319795",
          },
        ],
      },
    });
    // chart.data.
  });
</script>

<!-- svelte-ignore a11y-no-static-element-interactions -->
<!-- svelte-ignore a11y-click-events-have-key-events -->
<div
  class="p-2 flex flex-col border rounded-md border-slate-200 dark:border-zinc-600 transition-shadow duration-[400ms] ease-linear"
  class:shadow={isExpanded}
>
  <div class="flex items-center justify-between">
    <div class="title flex">
      <div class="flex gap-2 center-center" on:click={onExpand}>
        {#if isDraggable}
          <Svg name="drag" size={16} />
        {/if}
        {title}
      </div>
    </div>

    <div class="flex justify-end grow">
      {#each { length: steps } as _, i}
        <div on:click={() => check(i)} class="m-1">
          <CheckboxComponent value={i < checkedSteps} />
        </div>
      {/each}
    </div>

    {#if $currentClient.id}
      <div class="flex mx-1">
        <More {menuItems} />
        <!-- <div class="chevron" class:isExpanded on:click={onExpand}>
        <Svg name="down-caret" size={16} />
      </div> -->
      </div>
    {/if}
  </div>

  <!-- {#if isExpanded} -->
  <!-- <div style:display={isExpanded ? "initial" : "none"}>
    <DateIncrementer
      {incrementDate}
      selectedDateDecremented={selectedDate}
      {skip}
    />
    <div class="flex justify-between">
      <div class:colortext={skip === 7} on:click={onWeek}>Nedelja</div>
      <div class:colortext={skip === 30} on:click={onMonth}>Mesec</div>
      <div class:colortext={skip === 90} on:click={onThreeMonths}>
        Tri meseca
      </div>
    </div>
    <canvas bind:this={chartElement} />
  </div> -->
  <!-- {/if} -->
</div>

<style>
  .colortext {
    color: #319795;
  }
  .measurement-input {
    border: none;
    width: 56px;
    border-bottom: 1px solid silver;
    background-color: rgba(1, 1, 1, 0);
  }
  .measurement-input:focus {
    border-bottom: 2px solid #319795;
  }
  .animation {
    animation-name: expand;
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
  }
  @keyframes expand {
    from {
      height: 40px;
    }
    to {
      height: fit-content;
    }
  }
  .animation-reverse {
    animation-name: contract;
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
  }
  @keyframes contract {
    from {
      height: 400px;
    }
    to {
      height: 40px;
    }
  }
  .title {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    /* width: 288px;  */
  }

  .chevron {
    /* ista tranzicija kao default svelte:slide */
    transition: transform 400ms linear;
  }
  .isExpanded {
    transform: rotate(180deg);
  }
</style>
