<script lang="ts">
  import {onMount} from "svelte";
  import {api, deleteWithJwt, dialogTypes, getWithJwt, translate} from "lib";
  import {dialogData, showAlert} from "stores";
  import {ButtonComponent, ExpansionPanel, More} from "ui";

  let defaultHabits: Array<any> = [];

  const onCreateDefaultHabit = (): void => {
    $dialogData.type = dialogTypes.CREATE_EDIT_HABIT;
    $dialogData.data = {
      isDefaultHabit: true,
      executeFunction (response: any) {
        defaultHabits = [...defaultHabits, response];
      }
    };
  };

  const fetchDefaultHabits = async (): Promise<void> => {
    try {
      defaultHabits = await getWithJwt(`${api}/habit/default`);
    } catch (error) {
      console.error(error);
    }
  };

  onMount(fetchDefaultHabits);
</script>

<ExpansionPanel
  title="{translate("DEFAULT_HABITS")}"
  subtitle="{translate("NUMBER_OF_ITEMS")}: {defaultHabits.length}"
>
  <div class="flex flex-col gap-4">
    <div class="text-xs text-justify">
      {translate("SETTINGS_DEFAULT_HABITS_NOTE")}
    </div>
    <div class="flex flex-col gap-2">
      {#each defaultHabits as defaultHabit}
        <div class="flex items-center justify-between">
          <div class="text-xs">{defaultHabit.name}</div>
          <More menuItems={[
            {
              title: "EDIT",
              icon: "edit",
              executeFunction () {
                $dialogData.type = dialogTypes.CREATE_EDIT_HABIT;
                $dialogData.data = {
                  isDefaultHabit: true,
                  defaultHabit,
                  executeFunction (response) {
                    const habit = defaultHabits.find(({id}) => id === response.id);
                    const index = defaultHabits.indexOf(habit);
                    defaultHabits = defaultHabits.with(index, response);
                  }
                };
              }
            }, {
              title: "DELETE",
              icon: "delete",
              executeFunction () {
                $dialogData.data = {
                  title: translate("HABIT_U"),
                  async executeFunction () {
                    try {
                      await deleteWithJwt(`${api}/habit/${defaultHabit.id}`);
                      defaultHabits = defaultHabits.filter(({id}) => id !== defaultHabit.id);
                      $showAlert.color = "black";
                      $showAlert.message = `
                        ${translate("SUCCESSFULLY_DELETED")}
                        ${translate("HABIT").toLowerCase()}
                      `;
                    } catch (error) {
                      console.error(error);
                    }
                  }
                };
                $dialogData.type = dialogTypes.CONFIRM_DELETE;
              }
            }
          ]}/>
        </div>
      {/each}
    </div>
    <div class="flex justify-center">
      <ButtonComponent isOutline on:click={onCreateDefaultHabit}>
        {translate("CREATE_HABIT")}
      </ButtonComponent>
    </div>
  </div>
</ExpansionPanel>
