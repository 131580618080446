<script lang="ts">
  export let label = "";
  export let items: Array<{value: string, name: string}>;
  export let value = "";
  export let disabled = false;
</script>

<div class="relative pb-4 flex flex-col">
  <label
    for={label}
    class="h-4 ml-1 text-xs flex items-center leading-none font-medium text-gray-900 dark:text-gray-100"
  >
    {label}:
  </label>

  <select
    {disabled}
    id="{label}"
    class="
      block w-full dark:bg-zinc-700 rounded-md border-0 py-2 px-4 text-gray-900 dark:text-white ring-1 ring-inset ring-gray-300 dark:ring-slate-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 dark:focus:ring-primary-500 sm:text-sm sm:leading-6
    "
    bind:value
    on:change
  >
    {#each items as {value, name}}
      <option {value}>{name}</option>
    {/each}
  </select>
</div>
