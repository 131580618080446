import {get, writable} from "svelte/store";
import {IngredientTemplate, serverlessRoutes} from "lib";
import {storeUtil} from "../lib/createItemsStore";
import type {ItemsStore} from "interfaces";
import { currentClient } from "./currentClient";

interface PaymentsStore extends ItemsStore<any> {}

const paymentsStoreCreate = () => {
  const {set, subscribe, update} = writable<PaymentsStore>({
    items: [],
    count: 0,
    hasMore: false,
    isFetching: false,
    isFetchingMore: false,
    skip: 0,
    searchTimeout: undefined
  });

  const createUrl = (): string => {
    const {
      skip
    } = get({subscribe});

    const params = new URLSearchParams();

    params.append("take", "8");
    params.append("skip", `${skip}`);
    params.append("clientId", `${get(currentClient).id}`);

    return `${serverlessRoutes.PAYMENT}/list?${params.toString()}`;
  }

  const {
    add,
    replace,
    remove,
    fetchData,
    fetchMoreData,
    search,
    loadCache
  } = storeUtil<any, PaymentsStore>(
    "paymentsCache", update, createUrl
  );

  const sort = () => {
    update((store) => {
      store.items.sort((a, b) => {
        const aDate = new Date(a.date);
        const bDate = new Date(b.date);
        return bDate - aDate;
      });

      return store;
    });
  };

  return {
    set,
    subscribe,
    update,
    add,
    replace,
    remove,
    fetchData,
    fetchMoreData,
    loadCache,
    search,
    sort
  };
};

const paymentsStore = paymentsStoreCreate();

export {paymentsStore};
