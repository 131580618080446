<script lang="ts">
  import { Capacitor } from "@capacitor/core";
  import Input from "../UI/Input.svelte";
  import ButtonComponent from "../UI/ButtonComponent.svelte";
  import { chatMessages } from "stores";
  import Svg from "../UI/Svg.svelte";

  const position = Capacitor.isNativePlatform() ? "fixed" : "sticky";
  let skippedMessages = [];
  let foundCount = 0;
  let searchString = "";

  export let searchMode;

  const search = () => {
    if (searchString.length > 0) {
      foundCount = $chatMessages.filter((message) =>
        message.payload?.toLowerCase().includes(searchString.toLowerCase())
      ).length;
      if (foundCount > 0) scrollUp();
    }
  };

  const scrollUp = () => {
    const foundMessage = $chatMessages.findLast(
      (message) =>
        message.payload?.toLowerCase().includes(searchString.toLowerCase()) &&
        !skippedMessages.find(
          (skippedMessage) => skippedMessage.id === message.id
        )
    );
    if (!foundMessage) return;
    skippedMessages.push(foundMessage);
    skippedMessages = skippedMessages;
    scroll(foundMessage.id);
  };

  const scrollDown = () => {
    if (skippedMessages.length === 0) return;
    const foundMessage = skippedMessages.pop();
    skippedMessages = skippedMessages;
    if (!foundMessage) return;
    scroll(foundMessage.id);
  };

  const scroll = (id: number) => {
    const el = document.getElementById(`${id}`);
    if (!el) return;
    const rect = el.getBoundingClientRect();
    const offset = 48;
    const targetPosition = window.scrollY + rect.top - offset;
    window.scrollTo({ top: targetPosition, behavior: "smooth" });
    el.innerHTML = el.innerHTML.replace(
      new RegExp(searchString, "gi"),
      (match) => {
        return `<span style="background-color: yellow; color: black;">${match}</span>`;
      }
    );
  };

  const reset = () => {
    searchString = "";
    skippedMessages = [];
    foundCount = 0;
  };
</script>

<div
  class={`px-4 menu ${position} flex-row center-space-between bg-white dark:bg-zinc-800 text-slate-900 dark:text-slate-100 border-b border-slate-300 dark:border-zinc-700`}
>
  <div class="w-48">
    <Input bind:value={searchString} />
  </div>
  <span>{skippedMessages.length}/{foundCount}</span>
  {#if skippedMessages.length > 0}
    <ButtonComponent isOutline on:click={() => scrollUp()}
      ><Svg name="up-caret" size={12} /></ButtonComponent
    >
    <ButtonComponent isOutline on:click={() => scrollDown()}
      ><Svg name="down-caret" size={12} /></ButtonComponent
    >
    <ButtonComponent on:click={() => reset()}
      ><Svg name="x" size={14} /></ButtonComponent
    >
  {:else}
    <div class="flex flex-row gap-2">
      <ButtonComponent on:click={() => search()}>Pretraži</ButtonComponent>
      <ButtonComponent on:click={() => (searchMode = false)}
        ><Svg name="x" size={14} /></ButtonComponent
      >
    </div>
  {/if}
</div>

<style>
  .menu {
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    max-width: 720px;
    height: 48px;
    width: 100%;
    z-index: 1;
    padding-top: calc(env(safe-area-inset-top) * 1.25);
    padding-bottom: calc(env(safe-area-inset-top) / 2);
  }
</style>
