<script lang="ts">
  import { serverlessRoutes, post } from "lib";
  import { onMount } from "svelte";
  import { translate } from "../lib/translate";
  import { replace } from "svelte-spa-router";
  import { user } from "../stores/userStore";
  import { showAlert } from "../stores/showAlertStore";
  import { ButtonComponent, Input } from "ui";
  import { fetchUser } from "../lib/fetchUser";
  import { isClient } from "../lib/roles";

  let gsiButton: any;
  let gsiSecButton: any;
  let email = "";
  let password = "";
  let isLoading = false;

  const renderButton = () => {
    gsiButton.setAttribute("id", "g_id_onload");
    gsiButton.setAttribute(
      "data-client_id",
      "581540261434-miks1j69jmru5j6g2q69kqqqv2pc54h9.apps.googleusercontent.com"
    );
    gsiButton.setAttribute("data-callback", "handleGoogleSignIn");
    gsiButton.setAttribute("data-auto_prompt", "false");
    gsiSecButton.setAttribute("class", "g_id_signin");
    gsiSecButton.setAttribute("data-type", "standard");
    gsiSecButton.setAttribute("data-size", "large");
    gsiSecButton.setAttribute("data-theme", "outline");
    gsiSecButton.setAttribute("data-text", "sign_in_with");
    gsiSecButton.setAttribute("data-shape", "rectangular");
    gsiSecButton.setAttribute("data-logo_alignment", "left");
  };

  const initGoogleScript = () => {
    let script = document.createElement("script");
    (window as any).handleGoogleSignIn = (res: any) => {
      console.log(res);
    };
    script.src = "https://accounts.google.com/gsi/client";
    renderButton();
    document.head.appendChild(script);
  };

  const scrollToTop = () => {
    const scrollBoxObj = document.getElementById("login-container");
    scrollBoxObj?.scrollIntoView(false);
  };

  user.subscribe((res) => {
    if (res) {
      if (isClient(res)) replace("/training/program");
      else replace("/clients/list");
    }
  });

  const signin = async () => {
    const data = { email, password };
    const url = serverlessRoutes.SIGNIN;
    scrollToTop();
    try {
      isLoading = true;
      const res = await post(url, data);
      if (res.accessToken) {
        localStorage.setItem("authJwt", res.accessToken);
        const fetchedUser = await fetchUser();
        isLoading = false;
        if (!isClient(fetchedUser)) {
          replace("/clients/list");
        } else {
          replace("/training/program");
        }
      } else {
        isLoading = false;
        $showAlert.message = res.error;
      }
    } catch (err) {
      isLoading = false;
      $showAlert.message = err.error;
    }
  };
</script>

<div
  id="login-container"
  class="bg-white dark:bg-zinc-800 text-slate-900 dark:text-slate-100 flex flex-col items-center justify-center gap-4"
>
  <img
    class="mt-8"
    style="border-radius: 64px;"
    src="/logo.png"
    alt="Logo"
    height={256}
    width={256}
  />
  <h1 class="font-bold" style="font-size: 1.25rem; font-weight: 400;">
    {translate("WELCOME_BACK")}!
  </h1>
  <div bind:this={gsiButton} />
  <div bind:this={gsiSecButton} />
  <!-- <p>{translate("OR_SIGN_IN_WITH_EMAIL")}:</p> -->
  <div style="width: 76%;">
    <label class="block mb-2 text-small" for="email">Email:</label>
    <Input
      type="email"
      name="email"
      placeholder="trainee@email.com"
      bind:value={email}
    />
    <div class="mt-2" />
    <label class="block mb-2 text-small" for="password">Password:</label>
    <Input
      type="password"
      name="password"
      placeholder="•••••••••"
      bind:value={password}
    />
  </div>
  <ButtonComponent {isLoading} myClasses="mt-6" on:click={signin}
    >{translate("SIGN_IN")}</ButtonComponent
  >
</div>

<style>
  .input {
    width: 80%;
    padding: 8px;
    border-radius: 0.375rem;
    border: 1px solid silver;
  }
</style>
