import { wrap } from "svelte-spa-router/wrap";
import { Roles } from "./constants";
import ClientTrainingScreen from "../screens/Training/ClientTrainingScreen.svelte";
import AuthScreen from "../screens/AuthScreen.svelte";
import Autologin from "../components/Autologin.svelte";
import ClientListScreen from "../screens/Clients/ClientListScreen.svelte";
import ClientGroupsScreen from "../screens/Clients/ClientGroupsScreen.svelte";
import ClientGroupScreen from "../screens/Clients/ClientGroupScreen.svelte";
import ClientPaymentsScreen from "../screens/Clients/ClientPaymentsScreen.svelte";
import TrainersScreen from "../screens/Clients/TrainersScreen.svelte";
import HabitsScreen from "../screens/Progress/HabitsScreen.svelte";
import GoalsScreen from "../screens/Progress/GoalsScreen.svelte";
import MeasurementsScreen from "../screens/Progress/MeasurementsScreen.svelte";
import GalleryScreen from "../screens/Progress/GalleryScreen.svelte";
import ClientDietScreen from "../screens/Diet/ClientDietScreen.svelte";
import DietJournalScreen from "../screens/Diet/DietJournalScreen.svelte";
import FastingScreen from "../screens/Diet/FastingScreen.svelte";
import MealPlansScreen from "../screens/Diet/MealPlansScreen.svelte";
import MealPlanScreen from "../screens/Diet/MealPlanScreen.svelte";
import RecipesScreen from "../screens/Diet/RecipesScreen.svelte";
import IngredientsScreen from "../screens/Diet/IngredientsScreen.svelte";
import ProgramsScreen from "../screens/Training/ProgramsScreen.svelte";
import ProgramScreen from "../screens/Training/ProgramScreen.svelte";
import WorkoutsScreen from "../screens/Training/WorkoutsScreen.svelte";
import ExercisesScreen from "../screens/Training/ExercisesScreen.svelte";
import TrainingJournalScreen from "../screens/Training/TrainingJournalScreen.svelte";
import ChatScreen from "../screens/Chat/ChatScreen.svelte";
import ChatSharedContentScreen from "../screens/Chat/ChatSharedContentScreen.svelte";
import ChatListScreen from "../screens/Chat/ChatListScreen.svelte";
import ProfileScreen from "../screens/Clients/ProfileScreen.svelte";
import NotificationsScreen from "../screens/Notifications/NotificationsScreen.svelte";
import ExpiryScreen from "../screens/Notifications/ExpiryScreen.svelte";
import RemindersScreen from "../screens/Notifications/RemindersScreen.svelte";
import SettingsScreen from "../screens/Settings/SettingsScreen.svelte";
import SettingsProfileScreen from "../screens/Settings/SettingsProfileScreen.svelte";
import AdvancedSettingsScreen from "../screens/Settings/AdvancedSettingsScreen/AdvancedSettingsScreen.svelte";
import BugSuggestionScreen from "../screens/Settings/BugSuggestionScreen.svelte";
import FileUploadScreen from "../screens/FileUploadScreen.svelte";

const userGuard = () => {
  if (localStorage.getItem("authJwt")) return true;
  return false;
};

const trainerGuard = () => {
  const role = localStorage.getItem("role");
  return +role === Roles.Trainer;
};

const clientGuard = () => {
  const role = localStorage.getItem("role");
  return +role === Roles.Client;
};

const routes = {
  "/": wrap({
    component: ClientTrainingScreen,
    conditions: [(detail) => userGuard()],
  }),
  "/auth": wrap({
    component: AuthScreen,
  }),
  "/autologin/:hash": wrap({
    component: Autologin,
  }),
  "/clients/list": wrap({
    component: ClientListScreen,
    conditions: [(detail) => userGuard(), (detail) => trainerGuard()],
  }),
  "/clients/groups": wrap({
    component: ClientGroupsScreen,
    conditions: [(detail) => userGuard(), (detail) => trainerGuard()],
  }),
  "/clients/groups/:id": wrap({
    component: ClientGroupScreen,
    conditions: [(detail) => userGuard(), (detail) => trainerGuard()],
  }),
  "/clients/payments": wrap({
    component: ClientPaymentsScreen,
    conditions: [(detail) => userGuard(), (detail) => trainerGuard()],
  }),
  "/clients/trainers": wrap({
    component: TrainersScreen,
    conditions: [(detail) => userGuard(), (detail) => trainerGuard()],
  }),
  "/progress/habits": wrap({
    component: HabitsScreen,
    conditions: [(detail) => userGuard()],
  }),
  "/progress/goals": wrap({
    component: GoalsScreen,
    conditions: [(detail) => userGuard()],
  }),
  "/progress/measurements": wrap({
    component: MeasurementsScreen,
    conditions: [(detail) => userGuard()],
  }),
  "/progress/gallery": wrap({
    component: GalleryScreen,
    conditions: [(detail) => userGuard()],
  }),
  "/diet/meal-plan": wrap({
    component: ClientDietScreen,
    conditions: [(detail) => userGuard()],
  }),
  "/diet/journal": wrap({
    component: DietJournalScreen,
    conditions: [(detail) => userGuard()],
  }),
  "/diet/fasting": wrap({
    component: FastingScreen,
    conditions: [(detail) => userGuard()],
  }),
  "/diet/meal-plans": wrap({
    component: MealPlansScreen,
    conditions: [(detail) => userGuard(), (detail) => trainerGuard()],
  }),
  "/diet/meal-plans/:id": wrap({
    component: MealPlanScreen,
    conditions: [(detail) => userGuard(), (detail) => trainerGuard()],
  }),
  "/diet/recipes": wrap({
    component: RecipesScreen,
    conditions: [(detail) => userGuard(), (detail) => trainerGuard()],
  }),
  "/diet/ingredients": wrap({
    component: IngredientsScreen,
    conditions: [(detail) => userGuard(), (detail) => trainerGuard()],
  }),
  "/training/program": wrap({
    component: ClientTrainingScreen,
    conditions: [(detail) => clientGuard()],
  }),
  "/training/programs": wrap({
    component: ProgramsScreen,
    conditions: [(detail) => userGuard(), (detail) => trainerGuard()],
  }),
  "/training/programs/:id": wrap({
    component: ProgramScreen,
    conditions: [(detail) => userGuard(), (detail) => trainerGuard()],
  }),
  "/training/workouts": wrap({
    component: WorkoutsScreen,
    conditions: [(detail) => userGuard(), (detail) => trainerGuard()],
  }),
  "/training/exercises": wrap({
    component: ExercisesScreen,
    conditions: [(detail) => userGuard(), (detail) => trainerGuard()],
  }),
  "/training/journal": wrap({
    component: TrainingJournalScreen,
    conditions: [(detail) => userGuard()],
  }),
  "/chat": wrap({
    component: ChatScreen,
    conditions: [(detail) => userGuard()],
  }),
  "/chat/shared/:sessionId": wrap({
    component: ChatSharedContentScreen,
    conditions: [(detail) => userGuard()],
  }),
  "/chat-list": wrap({
    component: ChatListScreen,
    conditions: [(detail) => userGuard(), (detail) => trainerGuard()],
  }),
  "/profile/:id": wrap({
    component: ProfileScreen,
    conditions: [(detail) => userGuard(), (detail) => trainerGuard()],
  }),
  "/profile/:clientId/training/programs/:id": wrap({
    component: ProgramScreen,
    conditions: [(detail) => userGuard(), (detail) => trainerGuard()],
  }),
  "/profile/:clientId/diet/meal-plans/:id": wrap({
    component: MealPlanScreen,
    conditions: [(detail) => userGuard(), (detail) => trainerGuard()],
  }),
  "/notifications": wrap({
    component: NotificationsScreen,
    conditions: [(detail) => userGuard()],
  }),
  "/notifications/expiry": wrap({
    component: ExpiryScreen,
    conditions: [(detail) => userGuard()],
  }),
  "/notifications/reminders": wrap({
    component: RemindersScreen,
    conditions: [(detail) => userGuard()],
  }),
  "/settings": wrap({
    component: SettingsScreen,
    conditions: [(detail) => userGuard()],
  }),
  "/settings/profile": wrap({
    component: SettingsProfileScreen,
    conditions: [(detail) => userGuard()],
  }),
  "/settings/advanced-settings": wrap({
    component: AdvancedSettingsScreen,
    conditions: [(detail) => userGuard()],
  }),
  "/settings/bug-suggestion": wrap({
    component: BugSuggestionScreen,
    conditions: [(detail) => userGuard()],
  }),
  "/file": wrap({
    component: FileUploadScreen,
    conditions: [(detail) => userGuard()],
  }),
};

// const routes = {
//   "/": wrap({
//     asyncComponent: () =>
//       import("../screens/Training/ClientTrainingScreen.svelte"),
//     conditions: [(detail) => userGuard()],
//   }),
//   "/auth": wrap({
//     asyncComponent: () => import("../screens/AuthScreen.svelte"),
//   }),
//   "/autologin/:hash": wrap({
//     asyncComponent: () => import("../components/Autologin.svelte"),
//   }),
//   "/clients/list": wrap({
//     asyncComponent: () => import("../screens/Clients/ClientListScreen.svelte"),
//     conditions: [(detail) => userGuard(), (detail) => trainerGuard()],
//   }),
//   "/clients/groups": wrap({
//     asyncComponent: () =>
//       import("../screens/Clients/ClientGroupsScreen.svelte"),
//     conditions: [(detail) => userGuard(), (detail) => trainerGuard()],
//   }),
//   "/clients/groups/:id": wrap({
//     asyncComponent: () => import("../screens/Clients/ClientGroupScreen.svelte"),
//     conditions: [(detail) => userGuard(), (detail) => trainerGuard()],
//   }),
//   "/clients/payments": wrap({
//     asyncComponent: () =>
//       import("../screens/Clients/ClientPaymentsScreen.svelte"),
//     conditions: [(detail) => userGuard(), (detail) => trainerGuard()],
//   }),
//   "/clients/trainers": wrap({
//     asyncComponent: () => import("../screens/Clients/TrainersScreen.svelte"),
//     conditions: [(detail) => userGuard(), (detail) => trainerGuard()],
//   }),
//   "/progress/habits": wrap({
//     asyncComponent: () => import("../screens/Progress/HabitsScreen.svelte"),
//     conditions: [(detail) => userGuard()],
//   }),
//   "/progress/goals": wrap({
//     asyncComponent: () => import("../screens/Progress/GoalsScreen.svelte"),
//     conditions: [(detail) => userGuard()],
//   }),
//   "/progress/measurements": wrap({
//     asyncComponent: () =>
//       import("../screens/Progress/MeasurementsScreen.svelte"),
//     conditions: [(detail) => userGuard()],
//   }),
//   "/progress/gallery": wrap({
//     asyncComponent: () => import("../screens/Progress/GalleryScreen.svelte"),
//     conditions: [(detail) => userGuard()],
//   }),
//   "/diet/meal-plan": wrap({
//     asyncComponent: () => import("../screens/Diet/ClientDietScreen.svelte"),
//     conditions: [(detail) => userGuard()],
//   }),
//   "/diet/journal": wrap({
//     asyncComponent: () => import("../screens/Diet/DietJournalScreen.svelte"),
//     conditions: [(detail) => userGuard()],
//   }),
//   "/diet/fasting": wrap({
//     asyncComponent: () => import("../screens/Diet/FastingScreen.svelte"),
//     conditions: [(detail) => userGuard()],
//   }),
//   "/diet/meal-plans": wrap({
//     asyncComponent: () => import("../screens/Diet/MealPlansScreen.svelte"),
//     conditions: [(detail) => userGuard(), (detail) => trainerGuard()],
//   }),
//   "/diet/meal-plans/:id": wrap({
//     asyncComponent: () => import("../screens/Diet/MealPlanScreen.svelte"),
//     conditions: [(detail) => userGuard(), (detail) => trainerGuard()],
//   }),
//   "/diet/recipes": wrap({
//     asyncComponent: () => import("../screens/Diet/RecipesScreen.svelte"),
//     conditions: [(detail) => userGuard(), (detail) => trainerGuard()],
//   }),
//   "/diet/ingredients": wrap({
//     asyncComponent: () => import("../screens/Diet/IngredientsScreen.svelte"),
//     conditions: [(detail) => userGuard(), (detail) => trainerGuard()],
//   }),
//   "/training/program": wrap({
//     asyncComponent: () =>
//       import("../screens/Training/ClientTrainingScreen.svelte"),
//     conditions: [(detail) => clientGuard()],
//   }),
//   "/training/programs": wrap({
//     asyncComponent: () => import("../screens/Training/ProgramsScreen.svelte"),
//     conditions: [(detail) => userGuard(), (detail) => trainerGuard()],
//   }),
//   "/training/programs/:id": wrap({
//     asyncComponent: () => import("../screens/Training/ProgramScreen.svelte"),
//     conditions: [(detail) => userGuard(), (detail) => trainerGuard()],
//   }),
//   "/training/workouts": wrap({
//     asyncComponent: () => import("../screens/Training/WorkoutsScreen.svelte"),
//     conditions: [(detail) => userGuard(), (detail) => trainerGuard()],
//   }),
//   "/training/exercises": wrap({
//     asyncComponent: () => import("../screens/Training/ExercisesScreen.svelte"),
//     conditions: [(detail) => userGuard(), (detail) => trainerGuard()],
//   }),
//   "/training/journal": wrap({
//     asyncComponent: () =>
//       import("../screens/Training/TrainingJournalScreen.svelte"),
//     conditions: [(detail) => userGuard()],
//   }),
//   "/chat": wrap({
//     asyncComponent: () => import("../screens/Chat/ChatScreen.svelte"),
//     conditions: [(detail) => userGuard()],
//   }),
//   "/chat/shared/:sessionId": wrap({
//     asyncComponent: () =>
//       import("../screens/Chat/ChatSharedContentScreen.svelte"),
//     conditions: [(detail) => userGuard()],
//   }),
//   "/chat-list": wrap({
//     asyncComponent: () => import("../screens/Chat/ChatListScreen.svelte"),
//     conditions: [(detail) => userGuard(), (detail) => trainerGuard()],
//   }),
//   "/profile/:id": wrap({
//     asyncComponent: () => import("../screens/Clients/ProfileScreen.svelte"),
//     conditions: [(detail) => userGuard(), (detail) => trainerGuard()],
//   }),
//   "/profile/:clientId/training/programs/:id": wrap({
//     asyncComponent: () => import("../screens/Training/ProgramScreen.svelte"),
//     conditions: [(detail) => userGuard(), (detail) => trainerGuard()],
//   }),
//   "/profile/:clientId/diet/meal-plans/:id": wrap({
//     asyncComponent: () => import("../screens/Diet/MealPlanScreen.svelte"),
//     conditions: [(detail) => userGuard(), (detail) => trainerGuard()],
//   }),
//   "/notifications": wrap({
//     asyncComponent: () =>
//       import("../screens/Notifications/NotificationsScreen.svelte"),
//     conditions: [(detail) => userGuard()],
//   }),
//   "/notifications/expiry": wrap({
//     asyncComponent: () =>
//       import("../screens/Notifications/ExpiryScreen.svelte"),
//     conditions: [(detail) => userGuard()],
//   }),
//   "/notifications/reminders": wrap({
//     asyncComponent: () =>
//       import("../screens/Notifications/RemindersScreen.svelte"),
//     conditions: [(detail) => userGuard()],
//   }),
//   "/settings": wrap({
//     asyncComponent: () => import("../screens/Settings/SettingsScreen.svelte"),
//     conditions: [(detail) => userGuard()],
//   }),
//   "/settings/profile": wrap({
//     asyncComponent: () =>
//       import("../screens/Settings/SettingsProfileScreen.svelte"),
//     conditions: [(detail) => userGuard()],
//   }),
//   "/settings/advanced-settings": wrap({
//     asyncComponent: () =>
//       import(
//         "../screens/Settings/AdvancedSettingsScreen/AdvancedSettingsScreen.svelte"
//       ),
//     conditions: [(detail) => userGuard()],
//   }),
//   "/settings/bug-suggestion": wrap({
//     asyncComponent: () =>
//       import("../screens/Settings/BugSuggestionScreen.svelte"),
//     conditions: [(detail) => userGuard()],
//   }),
//   "/file": wrap({
//     asyncComponent: () => import("../screens/FileUploadScreen.svelte"),
//     conditions: [(detail) => userGuard()],
//   }),
//   // // Using named parameters, with last being optional
//   // '/author/:first/:last?': Author,

//   // // Wildcard parameter
//   // '/book/*': Book,

//   // // Catch-all
//   // // This is optional, but if present it must be the last
//   // '*': NotFound,
// };

export { routes };
