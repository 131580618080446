import { api } from "./constants";
import { getPdfWithJwt } from "./requests";

export const savePdf = (blob: Blob, fileName: string) => {
  // if (this.checkPlatformService.isNative) {
  //     const blobToBase64 = await this.blobToBase64(res)
  //     await Filesystem.writeFile({
  //       path: `${fileName}.pdf`,
  //       data: blobToBase64 as string,
  //       directory: Directory.Documents,
  //     })
  //     alert('Uspešno preuzet PDF.')
  //   } else {
  const newBlob = new Blob([blob], { type: "application/pdf" });
  const data = window.URL.createObjectURL(newBlob);
  const link = document.createElement("a");
  link.href = data;
  link.download = `${fileName}.pdf`;
  link.dispatchEvent(
    new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
      view: window,
    })
  );
  setTimeout(function () {
    window.URL.revokeObjectURL(data);
    link.remove();
  }, 100);
  //   }
};

export const exportToPdf = async (
  id: number,
  path: string,
  fileName: string
) => {
  const url = `${api}/vanila-routes/${path}/${id}/export_to_pdf`;
  try {
    const data = await getPdfWithJwt(url);
    savePdf(data, fileName);
  } catch (err) {
    console.error(err);
  }
};
