<script lang="ts">
  import { translate } from "../../lib/translate";
  import Svg from "../UI/Svg.svelte";
  import { dialogTypes } from "../../lib/constants";
  import { dialogData } from "../../stores/dialogDataStore";
  import { payload } from "../../stores/payloadStore";
  import { isClient } from "../../lib/roles";
  import { user } from "../../stores/userStore";
  import { onDestroy, onMount } from "svelte";

  export let avatarUrl: string;
  export let clientName: string;
  export let isFileUploading: boolean;

  let keyboardIsOpen = false;
  let recordingStage: number = 1;
  let inputElement: HTMLInputElement;
  let chatInput: HTMLTextAreaElement;
  let actionsElement: HTMLDivElement;
  let files: File[] = [];
  let showChatHelper: boolean;

  const onFileSelected = (e: any): void => {
    files = e.target.files;
    uploadFile(files);
  };

  const incrementStage = (): void => {
    if (recordingStage === 3) {
      recordingStage = 0;
    }
    recordingStage += 1;
  };

  const autoResize = (): void => {
    chatInput.style.height = "0"; // reset scrollHeight
    const { scrollHeight } = chatInput;
    chatInput.style.height = `${scrollHeight > 64 ? 64 : scrollHeight}px`;
  };

  const payloadUnsubscribe = payload.subscribe((store): void => {
    if (chatInput) {
      chatInput.value = store;
      autoResize();
    }
  });

  onDestroy(payloadUnsubscribe);

  let isMobile = true;

  onMount((): void => {
    isMobile = /Mobi|Android/i.test(navigator.userAgent);
    showChatHelper = localStorage.getItem("showChatHelper") === "true";
    autoResize();
  });

  const onSubmit = () => {
    if ($payload !== "") {
      sendMessage($payload);
      $payload = "";
      chatInput.focus();
    }
  };

  export let uploadFile: (files: File[] | null) => Promise<void>;
  export let sendMessage: (payload: string) => Promise<void>;
</script>

<div
  class="
    sticky
    bottom-0
    left-0
    p-4
    {isFileUploading ? 'mt-10' : 'mt-4'}
    flex
    items-center
    gap-4
    bg-white
    text-slate-900
    border-t
    border-slate-300
    box-border
    dark:bg-zinc-800
    dark:border-zinc-700
    dark:text-slate-100
  "
  bind:this={actionsElement}
>
  <!-- class:mt-24={isFileUploading} -->

  <!-- <div>
    <span class="text-xxs">Video se otprema</span>
    <IndeterminateProgressBarComponent/>
  </div> -->

  {#if showChatHelper && keyboardIsOpen && ($user.id === 13 || $user.id === 15 || $user.id === 17833 || $user.id === 87649)}
    <div class="tooltip flex justify-center">
      <div class="myblur rounded-xl shadow-md flex flex-row items-center">
        <img
          class="mr-2"
          style="border-radius: 96px; width: 36px; height: 36px;"
          alt="Avatar"
          height="28"
          width="28"
          src={avatarUrl ? avatarUrl : "/default-avatar.png"}
        />
        <p class="ml-2">
          {clientName}
        </p>
      </div>
    </div>
  {/if}

  {#if recordingStage === 1}
    <textarea
      class="
          p-0
          grow
          leading-none
          bg-white
          text-slate-900
          border-0
          focus:ring-0
          dark:bg-zinc-800
          dark:text-slate-100
        "
      rows="1"
      placeholder={translate("TYPE_HERE")}
      bind:this={chatInput}
      bind:value={$payload}
      on:focus={() => (keyboardIsOpen = true)}
      on:blur={() => (keyboardIsOpen = false)}
      on:keypress={(event) => {
        if (event.key === "Enter") {
          if (isMobile) {
          } else {
            if (!event.shiftKey) {
              event.preventDefault();
              onSubmit();
            }
          }
        }
      }}
    />
  {/if}

  <div class="flex gap-4">
    {#if recordingStage === 1 && !isClient($user)}
      <Svg
        name="template-message"
        size={24}
        customColor="bg-black dark:bg-white"
        on:click={() => {
          $dialogData.type = dialogTypes.TEMPLATE_MESSAGES;
          $dialogData.data = { uploadFile, sendMessage };
        }}
      />
    {/if}
    {#if recordingStage === 1}
      <Svg
        name="camera"
        size={24}
        customColor="bg-black dark:bg-white"
        on:click={() => inputElement.click()}
      />
      <input
        multiple
        class="hidden"
        type="file"
        accept=".jpg, .jpeg, .png, .mp4"
        bind:this={inputElement}
        on:change={(e) => onFileSelected(e)}
      />
    {/if}
    {#if recordingStage === 1}
      <Svg
        name="right-arrow-button"
        size={24}
        customColor="bg-primary-500 dark:bg-primary-500"
        on:click={onSubmit}
      />
    {/if}
  </div>
</div>

<style>
  .tooltip {
    position: absolute;
    bottom: 256px;
    right: 8px;
  }

  .myblur {
    width: fit-content;
    padding: 8px;
    backdrop-filter: blur(8px);
  }

  .reset-css {
    all: unset;
  }
</style>
