<script lang="ts">
  import { Toggle } from "ui";
  import { clientFasts } from "../../../stores/clientStores";
  import { onDestroy, onMount } from "svelte";
  import {
    api,
    dialogTypes,
    getWithJwt,
    serverlessRoutes,
    translate,
  } from "lib";
  import { currentClient } from "../../../stores/currentClient";
  import { journalMealsStore, dialogData, mealPlansStore } from "stores";
  import { parseDate } from "../../../lib/parseDate";
  import SinglePlan from "../../../components/SinglePlan.svelte";
  import Macros from "../../../components/Diet/Macros.svelte";
  import MealExpansionPanel from "../../../components/Diet/MealExpansionPanel.svelte";
  import Svg from "../../../components/UI/Svg.svelte";
  import Gauge from "../../../components/UI/Gauge.svelte";
  import FastingPanel from "../../../components/Diet/FastingPanel.svelte";
  import FastingDetails from "../../../components/Diet/FastingDetails.svelte";
  import InfiniteScroll from "../../../components/UI/InfiniteScroll.svelte";
  import ButtonGroup from "../../../components/UI/ButtonGroup.svelte";
  import type { IButtonGroup } from "../../../interfaces/IButtonGroup";
  import { querystring, replace, location } from "svelte-spa-router";
  import { ButtonComponent } from "ui";
  import MealPlan from "../../../components/Diet/MealPlan.svelte";

  // const typeMap: { [key: string]: "ACTIVE" | "DIARY" | "IF-TRACKING" } = {
  //   active: "ACTIVE",
  //   diary: "DIARY",
  //   ifTracking: "IF-TRACKING",
  // };
  // const queryObject: URLSearchParams = new URLSearchParams($querystring);
  // const queryIterator: IterableIterator<string> = queryObject.values();
  // queryIterator.next();
  // const selectedType: string = queryIterator.next().value;

  let type: "ACTIVE" | "DIARY" | "IF-TRACKING" = "ACTIVE";
  // let type: "ACTIVE" | "DIARY" | "IF-TRACKING" =
  //   $querystring === "" ? "ACTIVE" : typeMap[selectedType];

  // const setType = (v: string) => {
  //   queryObject.set("type", v);
  //   replace(`/profile/${$currentClient.id}?${queryObject.toString()}`);
  // };

  // za active
  // let programs: any[] = [];
  let macros: any = { calories: 0, carbs: 0, protein: 0, fats: 0 };
  // za diary
  let mealMap: any = {};
  let journal: any[] = [];
  // za fasting
  let fasts: any[] = [];
  let detailedView: boolean = false;
  let detailedViewDiary = false;
  let fastingStats: any;
  let currentFast: any;
  let interval: NodeJS.Timeout;

  let journalCount = 0;
  let journalPage = 0;

  // ---------- DIARY ----------
  // const fetchData = async () => {
  //   const url = `${api}/diet_journal/new?take=15&skip=0&clientId=${$currentClient.id}&default=1&template=0`;
  //   const url2 = `${serverlessRoutes.MEAL}/client?clientId=${$currentClient.id}&finishedAt=${true}`;
  //   try {
  //     const data = await getWithJwt(url);
  //     const data2 = await getWithJwt(url2);
  //     console.log(data2);

  //     $clientJournalMeals = data.data;

  //     journal = data.data;
  //     journalCount = data.count;
  //   } catch (err) {}
  // };
  // const onFetchMoreData = async (): Promise<void> => {
  //   journalPage++;
  //   const url = `${api}/diet_journal/new?take=15&skip=${journalPage * 15}&clientId=${$currentClient.id}&default=1&template=0`;
  //   try {
  //     const data = await getWithJwt(url);
  //     $clientJournalMeals = [...$clientJournalMeals, data.data];
  //     journal = [...journal, ...data.data];
  //     generateMealMap();
  //   } catch (err) {}
  // };

  const onFetchMoreDiary = async () => {
    await journalMealsStore.fetchMoreData();
    journalMealsStore.generateMealMap();
  };

  // const generateMealMap = () => {
  //   mealMap = {};

  //   $clientJournalMeals.forEach((meal) => {
  //     if (!meal.finishedAt) return;
  //     const date = meal.finishedAt.slice(0, 10);
  //     if (!mealMap[date]) {
  //       mealMap[date] = [meal];
  //     } else {
  //       mealMap[date].push(meal);
  //     }
  //   });
  // };
  // const unsubscribeGenerateMap = clientJournalMeals.subscribe((res) => {
  //   generateMealMap();
  // });

  const fetchDataFasts = async () => {
    if (!$currentClient?.id) return;
    const url = `${api}/fasting_tracker/client/${$currentClient.id}/?take=15&skip=0`;
    try {
      const data = await getWithJwt(url);
      $clientFasts = data.data;
    } catch (err) {}
  };
  const fetchFastingStats = async () => {
    if (!$currentClient?.id) return;
    try {
      const url = `${api}/fasting_tracker/client/${$currentClient.id}/fastingStats`;
      const res = await getWithJwt(url);
      fastingStats = res;
    } catch (err) {}
  };

  const onActive = async (): Promise<void> => {
    if (type === "ACTIVE") {
      return;
    }

    type = "ACTIVE";
    // setType("active");
    await replace(`/profile/${$currentClient.id}?view=diet&type=active`);
  };

  const onDiary = async (): Promise<void> => {
    if (type === "DIARY") {
      return;
    }

    type = "DIARY";
    // setType("diary");
    await replace(`/profile/${$currentClient.id}?view=diet&type=diary`);
  };

  const onIfTracking = async (): Promise<void> => {
    if (type === "IF-TRACKING") {
      return;
    }

    type = "IF-TRACKING";
    // setType("ifTracking");
    await replace(`/profile/${$currentClient.id}?view=diet&type=ifTracking`);
  };

  const onCreateMealPlan = (): void => {
    $dialogData.data = { client: $currentClient };
    $dialogData.type = dialogTypes.CREATE_EDIT_MEAL_PLAN;
  };

  const onAssignMealPlan = (): void => {
    $dialogData.data = { client: $currentClient };
    $dialogData.type = dialogTypes.ASSIGN_MEAL_PLAN;
  };

  let mealPlansPage = 0;

  // const createMealPlansUrl = (): string => {
  //   const take = "take=5";
  //   const skip = `skip=${mealPlansPage * 5}`;
  //   const def = "default=1";
  //   const template = "template=0";

  //   return `${api}/trainer/client/${$currentClient.id}/meal_plan?${take}&${skip}&${def}&${template}`;
  // };

  // const onFetchMealPlans = async (): Promise<void> => {
  //   try {
  //     const response = await getWithJwt(createMealPlansUrl());
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // const onLoadMoreMealPlans = async (): Promise<void> => {
  //   mealPlansPage += 1;

  //   try {
  //     const response = await getWithJwt(createMealPlansUrl());
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const onCreateMeal = (): void => {
    $dialogData.data = { mealPlanId: $mealPlansStore.items[0].id };
    $dialogData.type = dialogTypes.CREATE_EDIT_MEAL;
  };

  const buttonGroup: IButtonGroup[] = [
    {
      title: translate("ACTIVE"),
      onClick: onActive,
    },
    {
      title: translate("DIARY"),
      onClick: onDiary,
    },
    {
      title: translate("IF_TRACKING"),
      onClick: onIfTracking,
    },
  ];

  let selectedButtonIndex = 0;

  onMount(async (): Promise<void> => {
    $mealPlansStore.items = [];
    await mealPlansStore.fetchData();

    await journalMealsStore.fetchData();
    journalMealsStore.generateMealMap();

    // await onFetchMealPlans();
    // await fetchData();

    await fetchDataFasts();
    await fetchFastingStats();

    if ($querystring?.includes("type=active")) {
      selectedButtonIndex = 0;
      type = "ACTIVE";
    } else if ($querystring?.includes("type=diary")) {
      selectedButtonIndex = 1;
      type = "DIARY";
    } else if ($querystring?.includes("type=ifTracking")) {
      selectedButtonIndex = 2;
      type = "IF-TRACKING";
    }

    if ($currentClient?.id) {
      const macrosResponse = await getWithJwt(
        `${serverlessRoutes.MACRO}?clientId=${$currentClient.id}`
      );

      if (macros?.data?.length > 0) macros = macrosResponse.data.pop();
    }
  });

  onDestroy(() => {
    // unsubscribeGenerateMap();
  });
</script>

<div class="flex flex-col gap-4">
  <div class="flex flex-col gap-4">
    <div class="flex items-center justify-center gap-2">
      {translate("SUGGESTED_MACROS")}:
      <div class="font-bold text-amber-400">
        {macros ? macros.calories : 0} kcal
      </div>
      <Svg name="edit" size={24} />
    </div>

    {#key macros}
      <Macros
        calories={macros ? macros.calories : 0}
        carbs={macros ? macros.carbs : 0}
        protein={macros ? macros.protein : 0}
        fats={macros ? macros.fats : 0}
        isBarVisible
      />
    {/key}
  </div>

  <div class="flex justify-center">
    <ButtonGroup buttons={buttonGroup} selectedIndex={selectedButtonIndex} />
  </div>

  {#if type === "ACTIVE"}
    <div class="flex justify-between">
      <ButtonComponent on:click={onCreateMealPlan}>
        {translate("CREATE")}
        {translate("MEAL_PLAN").toLowerCase()}
      </ButtonComponent>
      <ButtonComponent on:click={onAssignMealPlan}>
        {translate("ASSIGN_MEAL_PLAN")}
      </ButtonComponent>
    </div>

    {#each $mealPlansStore.items as mealPlan}
      <MealPlan {mealPlan} />
    {/each}

    <InfiniteScroll
      hasMore={$mealPlansStore.hasMore}
      on:loadMore={mealPlansStore.fetchMoreData}
    />
  {:else if type === "DIARY"}
    <div class="flex justify-between">
      <Toggle label="DETAILED_VIEW" bind:value={detailedViewDiary} toggleFunction={() => {}}/>
      {#if $mealPlansStore.items.length}
        <ButtonComponent on:click={onCreateMeal}>
          {translate("CREATE")}
          {translate("MEAL").toLowerCase()}
        </ButtonComponent>
      {/if}
    </div>

    {#each Object.values($journalMealsStore.map) as day}
      <div
        class="
        p-2
        flex
        flex-col
        gap-2
        border
        border-slate-200
        dark:border-zinc-600
        rounded-md
      "
      >
        <div class="flex justify-between">
          <div>{parseDate(day[0].finishedAt)}</div>
          <div>
            <span class="text-amber-400 font-bold">
              {day.reduce((sum, i) => (sum += i.calories), 0).toFixed(1)}
            </span>
            kcal
          </div>
        </div>

        <Macros
          isBarVisible
          calories={day.reduce((sum, i) => (sum += i.calories), 0)}
          carbs={day.reduce((sum, i) => (sum += i.carbs), 0)}
          protein={day.reduce((sum, i) => (sum += i.protein), 0)}
          fats={day.reduce((sum, i) => (sum += i.fats), 0)}
        />

        {#each day as meal}
          <MealExpansionPanel isCommentable {meal} />
        {/each}
      </div>
    {/each}

    <InfiniteScroll
      hasMore={$journalMealsStore.hasMore}
      isFetchingMore={$journalMealsStore.isFetchingMore}
      on:loadMore={onFetchMoreDiary}
    />
  {:else}
    <div class="flex flex-col gap-4">
      <div class="relative flex flex-col gap-4 items-center">
        <Toggle bind:checked={detailedView}>{translate("DETAILED_VIEW")}</Toggle
        >
      </div>

      <div class="flex flex-col gap-4">
        {#if !detailedView}
          {#each $clientFasts as fast}
            {#if fast.to !== null}
              <FastingPanel
                fastId={fast.id}
                totalTimeFasted={fast.totalTimeFasted}
                date={fast.to.slice(0, 10)}
              />
            {/if}
          {/each}
        {:else}
          <FastingDetails {fastingStats} />
        {/if}
      </div>
    </div>
  {/if}
</div>
