<script lang="ts">
  import { onMount } from "svelte";

  import { api, patchWithJwt, postFormDataWithJwt, postWithJwt, serverlessRoutes, translate } from "lib";
  import {
    dialogData,
    showAlert,
    trainerWorkouts,
    workoutsStore,
    alertStore,
    trainerPrograms,
    trainerClientPrograms,
    journalWorkoutsStore,
    trainerClientWorkouts,
  } from "stores";
  import { updateTrainerPrograms } from "../../../lib/updateTrainerPrograms";
  import { ButtonComponent, InputField, TextareaField } from "ui";

  let data: any;
  let isLoading = false;
  let disabled = true;

  const form = {
    name: { value: "", error: "" },
    description: { value: "", error: "" },
    protocol: { value: "", error: "" },
  };
  const type = data.workout ? translate("EDIT") : translate("CREATE");

  const onInput = (field: keyof typeof form): void => {
    const { length } = form[field].value;

    if (field === "name") {
      if (!length) {
        form[field].error = translate("FIELD_REQUIRED");
      } else if (length < 2) {
        form[field].error = translate("FIELD_MINIMUM_2");
      } else if (length > 320) {
        form[field].error = translate("FIELD_MAXIMUM_320");
      } else {
        form[field].error = "";
      }
    }

    if (field === "description") {
      if (length > 5000) {
        form[field].error = translate("FIELD_MAXIMUM_5000");
      } else {
        form[field].error = "";
      }
    }

    if (field === "protocol") {
      if (length > 5000) {
        form[field].error = translate("FIELD_MAXIMUM_5000");
      } else {
        form[field].error = "";
      }
    }

    disabled = Object.values(form).some(({ error }) => error !== "");
  };

  const editWorkout = async (): Promise<void> => {
    const { workout } = data;

    try {
      isLoading = true;

      const response = await patchWithJwt(serverlessRoutes.WORKOUT, {
        partialWorkoutMap: {
          [workout.id]: {
            name: form.name.value,
            description: form.description.value,
            protocol: form.protocol.value,
          }
        }
      });

      response.data.workouts[0].exercises = null;

      if (workout.default) {
        // Workout u Workouts
        workoutsStore.replace(response.data.workouts);
      } else {
        // Workout u Program
        $trainerPrograms.forEach((program) => {
          const w = program?.workouts?.find(({ id }) => id === workout.id);
          if (w) {
            const index = program.workouts.indexOf(w);
            program.workouts.splice(index, 1, response.data.workouts[0]);
          }
        });
        $trainerPrograms = $trainerPrograms;

        $trainerClientPrograms.forEach((program) => {
          const w = program?.workouts?.find(({ id }) => id === workout.id);
          if (w) {
            const index = program.workouts.indexOf(w);
            program.workouts.splice(index, 1, response.data.workouts[0]);
          }
        });
        $trainerClientPrograms = $trainerClientPrograms;

        const w = $trainerClientWorkouts.data.find((w) => w.id === workout.id);
        if (w) {
          const i = $trainerClientWorkouts.data.indexOf(w);
          response.data.workouts[0].exercises = null;
          $trainerClientWorkouts.data.splice(i, 1, response.data.workouts[0]);
          $trainerClientWorkouts = $trainerClientWorkouts;
        }
      }

      $showAlert.color = "black";
      $showAlert.message = `
        ${translate("SUCCESSFULLY_EDITED")}
        ${translate("WORKOUT").toLowerCase()}
      `;

      $dialogData.type = "";
      $dialogData.data = {};
    } catch (error) {
      console.error(error);
      isLoading = false;
      $showAlert.color = "red-400";
      $showAlert.message = translate("ERROR_EDITING_WORKOUT");
    }
  };

  const createWorkoutInProgram = async (): Promise<void> => {
    try {
      isLoading = true;

      const res = await postWithJwt(serverlessRoutes.WORKOUT, {
        partialWorkout: {
          name: form.name.value,
          description: form.description.value,
          entityType: 0,
          programId: data.programId,
          protocol: form.protocol.value,
        }
      });

      updateTrainerPrograms(data.programId, (p) => {
        p.workouts = [...p.workouts, res.data.workout];
      });

      $showAlert.color = "black";
      $showAlert.message = `
        ${translate("SUCCESSFULLY_CREATED")}
        ${translate("WORKOUT").toLowerCase()}
      `;

      $dialogData.data = {};
      $dialogData.type = "";
    } catch (error) {
      console.error(error);
      isLoading = false;
      $showAlert.color = "red-400";
      $showAlert.message = translate("ERROR_CREATING_WORKOUT");
    }
  };

  const createWorkout = async (): Promise<void> => {
    try {
      isLoading = true;

      const response = await postWithJwt(serverlessRoutes.WORKOUT, {
        partialWorkout: {
          name: form.name.value,
          description: form.description.value,
          entityType: 0,
          protocol: form.protocol.value,
          programId: null,
          default: 1,
          isTemplate: 0
        }
      });

      workoutsStore.add([response.data.workout]);
      alertStore.show(translate("SUCCESSFULLY_CREATED_WORKOUT"));

      $dialogData.type = "";
      $dialogData.data = {};
    } catch (error) {
      console.error(error);
      isLoading = false;
      $showAlert.color = "red-400";
      $showAlert.message = translate("ERROR_CREATING_WORKOUT");
    }
  };

  const onSubmit = async (): Promise<void> => {
    if (data.workout) {
      await editWorkout();
    } else if (data.programId) {
      await createWorkoutInProgram();
    } else {
      await createWorkout();
    }
  };

  onMount((): void => {
    if (data.workout) {
      const { name, description, protocol } = data.workout;
      if (name) {
        form.name.value = name;
        onInput("name");
      }

      if (description) {
        form.description.value = description;
        onInput("description");
      }

      if (protocol) {
        form.protocol.value = protocol;
        onInput("protocol");
      }
    }
  });

  export { data };
</script>

<div class="p-4 flex flex-col gap-4">
  <div class="text-center font-semibold">
    {type}
    {translate("WORKOUT").toLowerCase()}
  </div>

  <InputField
    label={translate("NAME")}
    error={form.name.error}
    bind:value={form.name.value}
    on:input={() => onInput("name")}
  />

  <TextareaField
    label={translate("DESCRIPTION")}
    error={form.description.error}
    bind:value={form.description.value}
    on:input={() => onInput("description")}
  />

  <div>* Opis treninga je vidljiv samo treneru</div>

  <TextareaField
    label={translate("PROTOCOL")}
    error={form.protocol.error}
    bind:value={form.protocol.value}
    on:input={() => onInput("protocol")}
  />

  <div>* Protokol treninga je vidljiv i klijentu</div>

  <div class="h-10 flex justify-center">
    <ButtonComponent cy="create-edit-workout-button" {disabled} {isLoading} on:click={onSubmit}>
      {type}
    </ButtonComponent>
  </div>
</div>
