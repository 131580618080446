<script lang="ts">
  import {onMount} from "svelte";
  import {fade, slide} from "svelte/transition";

  import {
    api,
    formStoreCreate,
    getWithJwt,
    patchWithJwt,
    postWithJwt,
    putWithJwt,
    serverlessRoutes,
    translate
  } from "lib";

  import {
    dialogData,
    showAlert,
    trainerExercisesStore,
    user,
    workoutsStore
  } from "stores";

  import {
    trainerClientPrograms,
    trainerPrograms,
    trainerWorkouts
  } from "../../../stores/trainerStores";

  import {SetEditTypeComponent} from "components";

  import {
    ButtonComponent,
    ButtonGroup,
    InputField,
    InfiniteScroll,
    SelectField,
    TextareaField,
    Search,
    TemplateTagComponent
  } from "ui";

  import ExercisesFilter from "../../../components/Training/ExercisesFilter.svelte";

  let data: any;

  const defaultSrc = "https://img.youtube.com/vi/null/hqdefault.jpg";
  const dialogType = data.exercise ? translate("EDIT") : translate("CREATE");
  const youtube = { src: "", name: "", description: "" };
  const exercise = { src: "", name: "", description: "" };

  let selectedExerciseId: number | null = null;
  let isLoading = false;
  let isYoutube = false;
  let isExpanded = false;
  let disabled = true;
  let scrollElement: HTMLDivElement;
  let youtubeId = "";
  let timeout: NodeJS.Timeout;
  let page = 0;

  // const selectedTypes = data.exercise
  //   ? data.exercise.muscleGroups
  //       .split(",")
  //       .map((tag: any) => reverseMuscleGroupRequest.get(tag))
  //   : [];
  const selectedTypes: Array<any> = [];

  const items = [
    { value: "Endurance", name: translate("ENDURANCE") },
    { value: "Strength", name: translate("STRENGTH") },
    { value: "Cardio", name: translate("CARDIO") },
    { value: "Timed Longer Better", name: translate("TIMED_LONGER_BETTER") },
    { value: "Timed Faster Better", name: translate("TIMED_FASTER_BETTER") },
    { value: "Timed Strength", name: translate("TIMED_STRENGTH") },
  ];

  const form = {
    youtube: { value: "", error: "" },
    exercise: { value: "", error: "" },
    name: { value: "", error: "" },
    type: { value: "Endurance", error: "" },
    reps: { value: "10", error: "" },
    sets: { value: 3, error: "" },
    rest: { value: "1 min", error: "" },
    time: { value: "3 min", error: "" },
    distance: { value: "500 m", error: "" },
    muscleGroups: { value: [] as Array<string>, error: "" },
    description: { value: "", error: "" },
  };
  const form2 = formStoreCreate({
    youtube: ["", "NONE"],
    exercise: ["", "NONE"],
    name: ["", "NAME"],
    type: ["Endurance", "NONE"],
    reps: ["10", "NONE"],
    sets: [3, "NONE"],
    rest: ["1 min", "NONE"],
    time: ["3 min", "NONE"],
    distance: ["500 m", "NONE"],
    muscleGroups: ["", "NONE"],
    description: ["", "DESCRIPTION"],
  });

  const youtubeRegex =
    /^(?:https?:)?(?:\/\/)?(?:youtu\.be\/|(?:www\.|m\.)?youtube\.com\/(?:watch|v|embed|shorts)(?:\.php)?(?:\?.*v=|\/))([a-zA-Z0-9\_-]{7,15})(?:[\?&][a-zA-Z0-9\_-]+=[a-zA-Z0-9\_-]+)*(?:[&\/\#].*)?$/;

  const onExpandExercises = (): void => {
    isExpanded = true;
  };

  const onExit = (): void => {
    $dialogData.type = "";
    $dialogData.data = {};
  };

  const onSelectExercise = async (item: any) => {
    isExpanded = false;

    try {
      const response = await getYoutubeData(item.youtubeId);

      youtubeId = response.id;

      exercise.src = response.snippet.thumbnails.medium.url;
      exercise.name = response.snippet.title;
      exercise.description = response.snippet.description;

      form.exercise.value = exercise.name;
      form.name.value = item.name;
      form.description.value = item.description;

      if (!item.type) item.type = "Strength";

      form.type.value = item.type;

      if (item.type === "Endurance" || item.type === "Strength") {
        form.reps.value = item?.details?.reps || "10";
        form.sets.value = item?.details?.sets || 3;
        form.rest.value = item?.details?.rest || "1 min";
      } else if (item.type === "Cardio") {
        form.time.value = item?.details?.time || "3 min";
        form.distance.value = item?.details?.distance || "500 m";
      } else {
        form.time.value = item?.details?.time || "3 min";
        form.sets.value = item?.details?.sets || 3;
        form.rest.value = item?.details?.rest || "1 min";
      }

      selectedExerciseId = item.id;

      onInput("name");
      onInput("description");
    } catch (error) {
      console.error(error);
    }
  };

  const onTypes = (event: CustomEvent<Array<string>>): void => {
    form.muscleGroups.value = event.detail;
  };

  const getYoutubeData = async (id: any): Promise<any> => {
    try {
      const response = await getWithJwt(`${serverlessRoutes.EXERCISE}/youtube-data/${id}`);
      return response.data.items[0];
    } catch (error) {
      console.error(error);
    }
  };

  const onInput = async (field: keyof typeof form): Promise<void> => {
    if (field === "youtube") {
      const isValid = youtubeRegex.test(form.youtube.value);

      if (isValid) {
        let id: string;

        if (form.youtube.value.includes("shorts")) {
          id = form.youtube.value.split("shorts/")[1];
        } else {
          id = form.youtube.value.split("watch?v=")[1];
        }

        try {
          const response = await getYoutubeData(id);

          youtubeId = response.id;

          youtube.src = response.snippet.thumbnails.medium.url;
          youtube.name = response.snippet.title;
          youtube.description = response.snippet.description;

          form.name.value = youtube.name;
          form.description.value = youtube.description;

          onInput("name");
          onInput("description");

          form.youtube.error = "";
        } catch (error) {
          console.error(error);
        }
      } else {
        form.youtube.error = translate("YOUTUBE_LINK_INVALID");
      }
    }

    if (field === "exercise") {
      clearTimeout(timeout);

      timeout = setTimeout(async (): Promise<void> => {
        $trainerExercisesStore.filter.search = form.exercise.value;
        await trainerExercisesStore.fetchData();
      }, 1000);
    }

    if (field === "name") {
      if (form.name.value.length === 0) {
        form.name.error = translate("FIELD_REQUIRED");
      } else if (form.name.value.length < 2) {
        form.name.error = translate("FIELD_MINIMUM_2");
      } else if (form.name.value.length > 320) {
        form.name.error = translate("FIELD_MAXIMUM_320");
      } else {
        form.name.error = "";
      }
    }

    if (field === "reps") {
      if (form[field].value.length > 10) {
        form[field].error = translate("FIELD_MAXIMUM_10");
      } else {
        form[field].error = "";
      }
    }

    if (field === "sets") {
      if (form[field].value < 0) {
        form[field].error = translate("FIELD_MINIMUM_AMOUNT_0");
      } else if (form[field].value > 5000) {
        form[field].error = translate("FIELD_MAXIMUM_AMOUNT_5000");
      } else {
        form[field].error = "";
      }
    }

    if (field === "rest") {
      if (form[field].value.length > 10) {
        form[field].error = translate("FIELD_MAXIMUM_10");
      } else {
        form[field].error = "";
      }
    }

    if (field === "time") {
      if (form[field].value.length > 10) {
        form[field].error = translate("FIELD_MAXIMUM_10");
      } else {
        form[field].error = "";
      }
    }

    if (field === "distance") {
      if (form[field].value.length > 10) {
        form[field].error = translate("FIELD_MAXIMUM_10");
      } else {
        form[field].error = "";
      }
    }

    if (field === "description") {
      if (form[field].value.length > 5000) {
        form[field].error = translate("FIELD_MAXIMUM_5000");
      } else {
        form[field].error = "";
      }
    }

    const formKeys = Object.keys(form) as Array<keyof typeof form>;

    // mora timeout jer mi treba form.type.value a on se refresuje posle onInput
    setTimeout((): void => {
      disabled = formKeys.some((key): boolean => {
        if (
          key === "reps" ||
          key === "sets" ||
          key === "rest" ||
          key === "time" ||
          key === "distance"
        ) {
          if (
            form.type.value === "Endurance" ||
            form.type.value === "Strength"
          ) {
            if (key === "reps" || key === "sets" || key === "rest") {
              return form[key].error !== "";
            } else {
              return false;
            }
          } else if (form.type.value === "Cardio") {
            if (key === "time" || key === "distance") {
              return form[key].error !== "";
            } else {
              return false;
            }
          } else {
            if (key === "time" || key === "sets" || key === "rest") {
              return form[key].error !== "";
            } else {
              return false;
            }
          }
        } else {
          return form[key].error !== "";
        }
      });
    }, 100);
  };

  const onSubmit = async (): Promise<void> => {

    isLoading = true;

    const name = form.name.value;
    const type = form.type.value;

    const reps = form.reps.value;
    const sets = form.sets.value;
    const rest = form.rest.value;
    const time = form.time.value;
    const distance = form.distance.value;

    // const muscleGroups = JSON.parse(form.muscleGroups.value).map((tag) =>
    //   muscleGroupRequestMap.get(tag)
    // ) || [];

    const description = form.description.value;

    let details = {};

    if (type === "Strength" || type === "Endurance") {
      details = { reps, sets, rest };
    } else if (type === "Cardio") {
      details = { time, distance };
    } else {
      details = { time, sets, rest };
    }

    if (!selectedExerciseId) {
      return;
    }

    if (data.exercise) { // edit in workout
      const response = await patchWithJwt(serverlessRoutes.EXERCISE, {
        partialExerciseMap: {
          [data.exercise.id]: {name, description, youtubeId, type, details}
        }
      });

      response.data.exercises.forEach((exercise: any): void => {
        exercise.details = JSON.parse(exercise.details);
      });

      if (data.programId) {
        const _program = $trainerPrograms.find(
          (_program): boolean => _program.id === data.programId
        );
        const _program2 = $trainerClientPrograms.find(
          (_program): boolean => _program.id === data.programId
        );

        if (data.supersetId) {
          if (_program?.workouts) {
            _program.workouts.forEach((w) => {
              const se = w.exercises.find((e) => e.id === data.supersetId);
              if (!se) return;
              const i = se.supersetExercises.indexOf(data.exercise);
              se.supersetExercises = se.supersetExercises.with(i, response.data.exercises[0]);
            });
            $trainerPrograms = $trainerPrograms;
          }
          if (_program2?.workouts) {
            _program2.workouts.forEach((w) => {
              const se = w.exercises.find((e) => e.id === data.supersetId);
              if (!se) return;
              const i = se.supersetExercises.indexOf(data.exercise);
              se.supersetExercises = se.supersetExercises.with(i, response.data.exercises[0]);
            });
            $trainerClientPrograms = $trainerClientPrograms;
          }
        } else {
          if (_program?.workouts) {
            const _workout = _program.workouts.find(
              (_workout: any): boolean => _workout.id === data.workoutId
            );

            if (_workout.exercises) {
              const i = _workout.exercises.indexOf(data.exercise);
              _workout.exercises = _workout.exercises.with(i, response.data.exercises[0])
            }

            $trainerPrograms = $trainerPrograms;

            if (data.executeFunction) {
              data.executeFunction();
            }
          }
          if (_program2?.workouts) {
            const _workout = _program2.workouts.find(
              (_workout: any): boolean => _workout.id === data.workoutId
            );

            if (_workout.exercises) {
              const i = _workout.exercises.indexOf(data.exercise);
              _workout.exercises = _workout.exercises.with(i, response.data.exercises[0])
            }

            $trainerClientPrograms = $trainerClientPrograms;

            // if (data.executeFunction) {
            //   data.executeFunction();
            // }
          }
        }
      } else {
        if (data.supersetId) {
          workoutsStore.replaceSupersetExercises(data.supersetId, response.data.exercises);
        } else {
          workoutsStore.replaceExercises(data.workoutId, response.data.exercises);
        }
      }
    } else { // create in workout
      const response = await postWithJwt(
        `${serverlessRoutes.EXERCISE}/copy/v2`,
        {
          partialExerciseMap: {
            [selectedExerciseId]: {
              // name,
              type,
              details: JSON.stringify(details),
              workoutId: data.workoutId,
              parentExerciseId: selectedExerciseId,
              // position: data.position,
              default: 0,
              isTemplate: 0,
              trainerId: null,
            }
          }
        }
      );

      if (data.programId) {
        const _program = $trainerPrograms.find(
          (_program): boolean => _program.id === data.programId
        );
        const _program2 = $trainerClientPrograms.find(
          (_program): boolean => _program.id === data.programId
        );

        if (_program?.workouts) {
          const _workout = _program.workouts.find(
            (_workout: any): boolean => _workout.id === data.workoutId
          );

          _workout.exercises = [..._workout.exercises, ...response.data.exercises];
          $trainerPrograms = $trainerPrograms;

          if (data.executeFunction) {
            data.executeFunction();
          }
        }
        if (_program2?.workouts) {
          const _workout = _program2.workouts.find(
            (_workout: any): boolean => _workout.id === data.workoutId
          );

          _workout.exercises = [..._workout.exercises, ...response.data.exercises];
          $trainerClientPrograms = $trainerClientPrograms;

          if (data.executeFunction) {
            data.executeFunction();
          }
        }
      } else {
        workoutsStore.addExercises(data.workoutId, response.data.exercises);
      }
    }

    $showAlert.color = "black";

    if (data.exercise) {
      $showAlert.message = `
        ${translate("SUCCESSFULLY_EDITED")} ${translate("EXERCISE")}
      `;
    } else {
      $showAlert.message = `
        ${translate("SUCCESSFULLY_CREATED")} ${translate("EXERCISE")}
      `;
    }

    $dialogData.type = "";
    $dialogData.data = {};
  };

  const mountScrollElement = (element: HTMLDivElement): void => {
    scrollElement = element;
  };

  onMount(async (): Promise<void> => {
    if (data.exercise) {
      await onSelectExercise(data.exercise);
      isYoutube = false;

      form.name.value = data.exercise.name;
      form.description.value = data.exercise.description;

      onInput("name");
      onInput("description");

      // const { name, type, muscleGroups, description, details } = data.exercise;

      // if (name) {
      //   form.name.value = name;
      //   onInput("name");
      // }

      // if (type) {
      //   form.type.value = type;
      //   onInput("type");
      // }

      // if (muscleGroups) {
      //   form.muscleGroups.value = muscleGroups;
      //   onInput("muscleGroups");
      // }

      // if (description) {
      //   form.description.value = description;
      //   onInput("description");
      // }

      // if (details) {
      //   const detailKeys = Object.keys(data.exercise.details) as [
      //     "time",
      //     "sets",
      //     "rest",
      //     "reps",
      //     "distance",
      //   ];

      //   detailKeys.forEach((key): void => {
      //     form[key].value = data.exercise.details[key];
      //     onInput(key);
      //   });
      // }

      // try {
      //   const response = await getYoutubeData(data.exercise.youtubeId);

      //   youtubeId = response.id;

      //   exercise.src = response.snippet.thumbnails.medium.url;
      //   exercise.name = response.snippet.title;
      //   exercise.description = response.snippet.description;
      // } catch (error) {
      //   console.error(error);
      // }
    } else {
      isYoutube = false;
    }

    await trainerExercisesStore.fetchData();
  });

  export { data };
</script>

<div class="p-4 flex flex-col gap-4">
  <div class="text-center font-semibold">
    {dialogType}
    {translate("EXERCISE_U").toLowerCase()}
  </div>

  <form class="flex flex-col gap-4" on:submit|preventDefault={onSubmit}>
    {#if selectedExerciseId}
      <div class="flex justify-center">
        <ButtonComponent isOutline on:click={() => selectedExerciseId = null}>Izaberi drugu vezbu</ButtonComponent>
      </div>

      {#if exercise.src}
        <img src={exercise.src} alt="Exercise" />
      {:else}
        <img src={defaultSrc} alt="Exercise" />
      {/if}

      <InputField
        label="{translate("NAME")}"
        disabled
        error={form.name.error}
        bind:value={form.name.value}
        on:input={() => onInput("name")}
      />

      <SelectField
        label={translate("TYPE")}
        {items}
        bind:value={form.type.value}
      />

      <div class="flex gap-4">
        {#if form.type.value === "Strength" || form.type.value === "Endurance"}
          <InputField
            label="{translate("REPETITION")}"
            error={form.reps.error}
            bind:value={form.reps.value}
            on:input={() => onInput("reps")}
          />

          <InputField
            label="{translate("SERIES")}"
            type="number"
            error="{form.sets.error}"
            bind:value="{form.sets.value}"
            on:input="{() => onInput("sets")}"
          />

          <InputField
            label="{translate("BREAK")}"
            error={form.rest.error}
            bind:value={form.rest.value}
            on:input={() => onInput("rest")}
          />
        {:else if form.type.value === "Cardio"}
          <InputField
            label="{translate("TIME")}"
            error={form.time.error}
            bind:value={form.time.value}
            on:input={() => onInput("time")}
          />

          <InputField
            label="{translate("DISTANCE")}"
            error={form.distance.error}
            bind:value={form.distance.value}
            on:input={() => onInput("distance")}
          />
        {:else if
          form.type.value === "Timed Longer Better" ||
          form.type.value === "Timed Faster Better" ||
          form.type.value === "Timed Strength"
        }
          <InputField
            label="{translate("TIME")}"
            error={form.time.error}
            bind:value={form.time.value}
            on:input={() => onInput("time")}
          />

          <InputField
            label="{translate("SERIES")}"
            type="number"
            error={form.sets.error}
            bind:value={form.sets.value}
            on:input={() => onInput("sets")}
          />

          <InputField
            label="{translate("BREAK")}"
            error={form.rest.error}
            bind:value={form.rest.value}
            on:input={() => onInput("rest")}
          />
        {/if}
      </div>

      <!-- <SetEditTypeComponent
        types={[
          "Trbuh",
          "Biceps",
          "Listovi",
          "Grudi",
          "Podlaktica",
          "Zadnjica",
          "Zadnja loža",
          "Donja leđa",
          "Prednja loža",
          "Ramena",
          "Triceps",
          "Gornja leđa",
        ]}
        {selectedTypes}
        bind:value={$form.field.muscleGroups.value}
      /> -->

      <!-- <TextareaField
        label={translate("DESCRIPTION")}
        disabled
        error={form.description.error}
        value={form.description.value}
      /> -->

      <div class="h-10 flex justify-center">
        <ButtonComponent cy="create-edit-exercise-button" type="submit" {disabled} {isLoading}>
          {dialogType}
        </ButtonComponent>
      </div>
    {:else}
      <div>
      <!-- <InputField
        label="{translate("EXERCISE")}"
        error=""
        bind:value={form.exercise.value}
        on:click={onExpandExercises}
      /> -->

      <!-- {#if isExpanded} -->
        <div class="flex flex-col gap-4">
          <Search
            placeholder={translate("SEARCH_EXERCISES")}
            bind:value={$trainerExercisesStore.filter.search}
            on:input={trainerExercisesStore.search}
          />
          <ExercisesFilter />

          <div
            class="h-64 flex flex-col gap-2 overflow-y-scroll"
            use:mountScrollElement
          >
            {#each $trainerExercisesStore.items as exercise}
              <!-- svelte-ignore a11y-click-events-have-key-events -->
              <!-- svelte-ignore a11y-no-static-element-interactions -->
              <div
                class="relative selectable-exercise flex items-center gap-2 border rounded-md bg-slate-100 dark:bg-zinc-800 dark:border-zinc-500"
                on:click={() => onSelectExercise(exercise)}
              >
                {#if exercise.isTemplate}
                  <TemplateTagComponent/>
                {/if}
                <img
                  class="h-12 rounded-l-md"
                  src="https://i.ytimg.com/vi/{exercise.youtubeId}/mqdefault.jpg"
                  alt="Youtube"
                />
                <div class="text-xs">
                  <div data-cy="import-exercise-name">{exercise.name}</div>
                  {#if exercise.type}
                    <div data-cy="import-exercise-type">{exercise.type}</div>
                  {/if}
                </div>
              </div>
            {/each}

            <InfiniteScroll
              {scrollElement}
              hasMore={$trainerExercisesStore.hasMore}
              isFetchingMore={$trainerExercisesStore.isFetchingMore}
              on:loadMore={trainerExercisesStore.fetchMoreData}
            />
          </div>

          <div class="flex justify-center">
            <ButtonComponent isOutline on:click={onExit}>
              {translate("CLOSE")}
            </ButtonComponent>
          </div>
        </div>
      <!-- {/if} -->
      </div>
    {/if}
  </form>
</div>
