<script>
  import { getWithJwt, serverlessRoutes } from "lib";
  import Svg from "../UI/Svg.svelte";
  import MiniSpinner from "../UI/MiniSpinner.svelte";

  export let sessionId;

  let isExpanded = false;

  let summary = "";

  const fetchSummary = async () => {
    const response = await getWithJwt(
      `${serverlessRoutes.SESSION}/${sessionId}/summary`
    );
    summary = response.data;
  };

  $: {
    if (isExpanded) {
      fetchSummary();
    } else {
    }
  }
</script>

{#if !isExpanded}
  <div
    class="fixed flex items-center justify-center bottom-28 right-4 h-10 w-10 bg-white dark:bg-zinc-900 rounded-xl"
    tabindex="0"
    role="button"
    on:click={() => (isExpanded = !isExpanded)}
    on:keydown={() => (isExpanded = !isExpanded)}
  >
    <Svg name="note" size={24} />
  </div>
{:else}
  <div
    class="fixed flex flex-col text-center justify-center bottom-16 right-0 left-0 h-fit w-11/12 text-xs bg-white dark:bg-zinc-900 rounded-xl m-auto gap-4 p-4"
  >
    <div class="flex flex-row justify-between">
      <div></div>
      <h6>Šta ste propustili</h6>
      <div
        tabindex="0"
        role="button"
        on:click={() => (isExpanded = !isExpanded)}
        on:keypress={() => (isExpanded = !isExpanded)}
      >
        <Svg name="close" size={24} />
      </div>
    </div>
    {#if summary === ""}
      <div class="w-full flex items-center justify-center">
        <MiniSpinner />
      </div>
    {:else}
      <p style="white-space: pre-line">
        {summary}
      </p>
    {/if}
  </div>
{/if}
