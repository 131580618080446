<script lang="ts">
  import type { Notification } from "../interfaces/Notification";
  import Divider from "./UI/Divider.svelte";
  import Avatar from "./UI/Avatar.svelte";
  import { translate } from "../lib/translate";
  import { parseDate } from "../lib/parseDate";
  import { api, Roles, serverlessRoutes } from "../lib/constants";
  import { patchWithJwt } from "../lib/requests";
  import { unreadNotifications } from "../stores/notificationStore";
  import { link } from "svelte-spa-router";
  import { user } from "stores";
  import { clientTrainerAvatarUrl } from "../stores/clientStores";
  import SeenButton from "./SeenButton.svelte";
  import { isClient } from "../lib/roles";

  const avatarUrl = isClient($user) ? $clientTrainerAvatarUrl : "";

  const parseNotification = (text: string) => {
    if (text.includes("za danas ima podsetnik")) return text;
    if (text.includes("ti je dodelio")) return text;
    if (text.includes("je završio")) return text;
    if (text.includes("ACCOUNT_EXPIRES_TOMORROW") && text.length < 28) {
      return "Tvoj nalog ističe sutra.";
    }
    const start = text.search("{");
    const end = text.search("}");
    const textToTranslate = text.slice(start + 1, end);
    const additionalText = text.slice(end + 1);
    return `${text.slice(0, start)} ${translate(textToTranslate)} ${
      additionalText ? additionalText : ""
    }`;
  };

  const seenOnly = () => {
    if (notification.seenAt) return;
    const url = `${serverlessRoutes.NOTIFICATIONS}/toggle-seen`;
    try {
      const res = patchWithJwt(url, { notificationId: notification.id });
      notification.seenAt = new Date().toISOString();
      $unreadNotifications -= 1;
    } catch (err) {}
  };

  const toggleSeen = () => {
    const url = `${serverlessRoutes.NOTIFICATIONS}/toggle-seen`;
    try {
      const res = patchWithJwt(url, { notificationId: notification.id });
      if (!notification.seenAt) {
        notification.seenAt = new Date().toISOString();
        $unreadNotifications -= 1;
      } else {
        notification.seenAt = null;
        $unreadNotifications += 1;
      }
    } catch (err) {}
  };

  const parseLinkClient = (link: string) => {
    // NOTIFIKACIJE CLIENT
    //   {name} je komentarisao vezbu {name} #journal & #active
    //   {name} je komentarisao trening {name} #journal & #active
    //   {name} je komentarisao obrok {name} #journal & #active
    //   Nalog ti istice dana {day}
    //   {name} ti je dodelio novi trening program
    //   {name} ti jedodelio novi plan ishrane

    //   ??? {name} ti je dodelio/la plan treninga
    //   ??? {name} ti je dodelio/la plan ishrane

    if (link === "workout-plan") {
      // {name} ti je dodelio novi trening program
      return "training/program";
    } else if (link === "meal-plan") {
      // {name} ti jedodelio novi plan ishrane
      return "diet/meal-plan";
    } else if (link.includes("workout-plan")) {
      if (link.includes("#active")) {
        if (link.includes("#exercise")) {
          // {name} je komentarisao vezbu {name} #active
          const workoutId = link.split("#")[1].split("-")[1];
          const exerciseId = link.split("#")[1].split("-")[2];
          return `training/program?workoutId=${workoutId}&exerciseId=${exerciseId}&scrollToExerciseComments=true`;
        } else if (link.includes("#workout")) {
          // {name} je komentarisao trening {name} #active
          const workoutId = parseInt(link.split("#")[1].split("-")[1]);
          return `training/program?workoutId=${workoutId}&scrollToWorkoutComments=true`;
        }
      } else if (link.includes("#journal")) {
        if (link.includes("#exercise")) {
          // {name} je komentarisao vezbu {name} #active
          const workoutId = link.split("#")[1].split("-")[1];
          const exerciseId = link.split("#")[1].split("-")[2];
          return `training/journal?workoutId=${workoutId}&exerciseId=${exerciseId}&scrollToExerciseComments=true`;
        } else if (link.includes("#workout")) {
          // {name} je komentarisao trening {name} #active
          const workoutId = parseInt(link.split("#")[1].split("-")[1]);
          return `training/journal?workoutId=${workoutId}&scrollToWorkoutComments=true`;
        }
      }
    } else if (link.includes("meal-plan")) {
      if (link.includes("#active")) {
        // {name} je komentarisao obrok {name} #active
        const mealId = parseInt(link.split("#")[1].split("-")[1]);
        return `diet/meal-plan?mealId=${mealId}&scrollToMealComments=true`;
      } else if (link.includes("#journal")) {
        // {name} je komentarisao obrok {name} #journal
        const mealId = parseInt(link.split("#")[1].split("-")[1]);
        return `diet/journal?mealId=${mealId}&scrollToMealComments=true`;
      }
    } else if (link.includes("account-expires")) {
      // Nalog ti istice dana {day}
      return "notifications";
    } else {
      return link;
    }
    // if (link.includes("workout-plan")) {
    //   const linkArr = link.split("-");
    //   const workoutId = linkArr[linkArr.length - 2];
    //   let foundWorkout;
    //   $clientPrograms.forEach((p) => {
    //     foundWorkout = p.workouts.find((w) => w.id === +workoutId);
    //   });
    //   const notificationString = foundWorkout
    //     ? "training/program?"
    //     : "training/journal?";

    //   return link
    //     .replace("#", "?")
    //     .replace("?workout-", "")
    //     .replace("?exercise-", "")
    //     .replace("workout-plan", notificationString);
    // }
    // return link
    //   .replace("client-chat", "chat")
    //   .replace("#", "?")
    //   .replace("meal-plan", "mp")
    //   .replace("meal-", "")
    //   .replace("mp", "diet/meal-plan");
  };

  const parseLinkTrainer = (link: string) => {
    // NOTIFIKACIJE TRENER
    //   Klijent {name} je zavrsio trening {name}
    //   Klijent {name} je zavrsio sve treninge
    //   {name} nalog istice danas & sutra
    //   {name} plan ishrane istice danas & sutra
    //   {name} plan treninga istice danas & sutra
    //   {name} je zavrsio/la obrok {name}
    //   {name} za danas ima podsetnik {name}
    //   {name} je dodao/la novi unos u galeriju
    //   {name} je komentarisao/la vezbu {name} #journal & #active
    //   {name} je komentarisao/la obrok {name} #journal & #active
    //   {name} je komentarisao/la trening {name} #active & #journal

    if (link.endsWith("#workout-plans")) {
      // {name} za danas ima podsetnik {name}
      const clientId = link.split("/")[1].split("#")[0];
      return `profile/${clientId}`;
    } else if (
      link.includes("workout-plans") &&
      !link.endsWith("#workout-plans")
    ) {
      if (
        link.includes("#workout") &&
        !link.includes("#active") &&
        !link.includes("#journal")
      ) {
        // Klijent {name} je zavrsio trening {name}
        const clientId = link.split("/")[1];
        const workoutId = parseInt(link.split("#")[1].split("-")[1]);
        return `profile/${clientId}?view=training&type=diary&workoutId=${workoutId}`;
      } else if (link.includes("#active")) {
        if (link.includes("#exercise")) {
          // {name} je komentarisao/la vezbu {name} #active
          const clientId = parseInt(link.split("/")[1]);
          const programId = parseInt(link.split("/")[3].split("#")[0]);
          const workoutId = parseInt(
            link.split("/")[3].split("#")[1].split("-")[1]
          );
          const exerciseId = parseInt(
            link.split("/")[3].split("#")[1].split("-")[2]
          );
          return `profile/${clientId}/training/programs/${programId}?workoutId=${workoutId}&exerciseId=${exerciseId}&scrollToExerciseComments=true`;
        } else {
          // {name} je komentarisao/la trening {name} #active
          const clientId = parseInt(link.split("/")[1]);
          const programId = parseInt(link.split("/")[3].split("#")[0]);
          const workoutId = parseInt(
            link.split("/")[3].split("#")[1].split("-")[1]
          );
          return `profile/${clientId}/training/programs/${programId}?workoutId=${workoutId}&scrollToWorkoutComments=true`;
        }
      } else if (link.includes("#journal")) {
        if (link.includes("#exercise")) {
          // {name} je komentarisao/la vezbu {name} #journal
          const clientId = parseInt(link.split("/")[1]);
          const programId = parseInt(link.split("/")[3].split("#")[0]);
          const workoutId = parseInt(
            link.split("/")[3].split("#")[1].split("-")[1]
          );
          const exerciseId = parseInt(
            link.split("/")[3].split("#")[1].split("-")[2]
          );
          return `profile/${clientId}?view=training&type=diary&programId=${programId}&workoutId=${workoutId}&exerciseId=${exerciseId}&scrollToExerciseComments=true`;
        } else {
          // {name} je komentarisao/la trening {name} #journal
          const clientId = parseInt(link.split("/")[1]);
          const workoutId = parseInt(
            link.split("/")[3].split("#")[1].split("-")[1]
          );
          return `profile/${clientId}?view=training&type=diary&workoutId=${workoutId}&scrollToWorkoutComments=true`;
        }
      } else {
        // Klijent {name} je zavrsio sve treninge
        // {name} plan treninga istice danas & sutra
        const clientId = parseInt(link.split("/")[1]);
        const programId = parseInt(link.split("/")[3]);
        return `profile/${clientId}/training/programs/${programId}`;
      }
    } else if (link.includes("meal-plans")) {
      if (link.includes("#meal-")) {
        if (notification.text.includes("HAS_FINISHED_MEAL")) {
          // {name} je zavrsio/la obrok {name}
          const linkArray = link.split("/");
          const clientId = linkArray[1];
          const idsArray = linkArray[3].split("#");
          const mealPlanId = idsArray[0];
          const mealId = idsArray[1].split("-")[1];

          return `profile/${clientId}/diet/meal-plans/${mealPlanId}?mealId=${mealId}`;
        } else if (notification.text.includes("COMMENTED_MEAL")) {
          if (link.includes("#active")) {
            // clients/33121/meal-plans/113546#meal-743976#active
            const clientId = link.split("/")[1];
            const mealPlanId = link.split("/")[3].split("#")[0];
            const mealId = link.split("/")[3].split("#")[1].split("-")[1];
            return `profile/${clientId}/diet/meal-plans/${mealPlanId}?mealId=${mealId}&scrollToMealComments=true`;
          } else {
            // 'clients/33121/meal-plans/113546#meal-806357'
            const clientId = link.split("/")[1];
            const mealId = link.split("/")[3].split("-")[1].split("#")[0];
            return `profile/${clientId}?view=diet&type=diary&mealId=${mealId}&scrollToMealComments=true`;
          }
        }
      } else {
        // {name} plan ishrane istice danas & sutra
        const clientId = link.split("/")[1];
        const mealPlanId = parseInt(link.split("/")[3]);
        return `profile/${clientId}/diet/meal-plans/${mealPlanId}`;
      }
    } else if (link.includes("client") && link.split("/").length === 2) {
      // {name} nalog istice danas & sutra
      const linkArray = link.split("/");
      return `profile/${linkArray[1]}`;
    } else if (link.includes("profile")) {
      // {name} je dodao/la novi unos u galeriju
      const clientId = parseInt(link.split("/")[1].split("?")[0]);
      return `profile/${clientId}?view=progress&type=gallery`;
    } else {
      return link;
    }

    // if (link.includes("workout-plans")) {
    //   const linkArray = link.split("/");
    //   return `training/programs/${linkArray[linkArray.length - 1]}`;
    // }

    // if (link.includes("clients") && link.includes("workout-plans")) {
    //   const splitArr = link.split("/");
    //   const userId = splitArr[1];
    //   const programId = splitArr[splitArr.length - 1].split("#")[0];
    //   const workoutId = splitArr[splitArr.length - 1].split("-")[1];
    //   return `profile/${userId}?view=training&type=active&programId=${programId}&workoutId=${workoutId}`;
    // }
    // clients/33121/workout-plans/116720#workout-607824
    // profile/33121?view=training&type=active&programId=116720&workoutId=607824
  };

  const parseRelativeLink = (link: string) => {
    if (isClient($user)) {
      return parseLinkClient(link);
    } else {
      return parseLinkTrainer(link);
    }
  };

  export let notification: Notification;
</script>

<div class="flex-col center-center text-slate-900 dark:text-slate-100">
  <div style="width: 100%;" class="flex-row center-space-between">
    <a
      on:click={() => seenOnly()}
      class="flex-row custom-row"
      href={`/${parseRelativeLink(notification.relativeLink)}`}
      use:link
    >
      <Avatar {avatarUrl} alt="User Avatar" size={48} myClasses="mg-8" />
      <span class="payload">{parseNotification(notification.text)}</span>
    </a>
    <SeenButton isSeen={notification.seenAt} {toggleSeen} />
    <!-- <pre>{notification.relativeLink}</pre> -->
  </div>
  <span class="date-text text-xxs">{parseDate(notification.createdAt)}</span>
  <Divider />
</div>

<style>
  .date-text {
    align-self: flex-end;
    margin-top: -16px;
    margin-right: 12px;
    margin-bottom: 8px;
  }
  .payload {
    font-size: small;
    max-width: 76%;
  }
  .custom-row {
    align-items: center;
    justify-content: flex-start;
  }
</style>
