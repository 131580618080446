<script lang="ts">
  import Svg from "../UI/Svg.svelte";
  import dateString from "../../lib/dateString";
    import { parseDate } from "lib";

  export let incrementDate;
  export let selectedDateDecremented;
  export let skip: number = 1;
</script>

<div class="flex-row center-center mt-3">
  <div
    on:click={() => incrementDate(-1 * skip)}
    on:keydown={() => incrementDate(-1 * skip)}
  >
    <Svg name="left-caret" size={16} myClass="mg-8" />
  </div>
  <h3 class="date">
    {parseDate( dateString(selectedDateDecremented) )}
  </h3>
  <Svg name="calendar" size={16} myClass="ml-2"/>
  <div
    on:click={() => incrementDate(skip)}
    on:keydown={() => incrementDate(skip)}
  >
    <Svg name="right-caret" size={16} myClass="mg-8" />
  </div>
</div>
