<script lang="ts">
  import { onDestroy } from "svelte";
  import { showMenu } from "../../stores/menuStore";
  import { link, location } from "svelte-spa-router";
  import { user } from "../../stores/userStore";
  import Svg from "../UI/Svg.svelte";
  import {
    notifications,
    unreadNotifications,
  } from "../../stores/notificationStore";
  import { unreadMessages } from "../../stores/chatSessionsStore";
  import { Capacitor } from "@capacitor/core";
  import { isClient } from "../../lib/roles";
  import { socketV2 } from "../../stores/socketStore";
  import { loadedWorkouts } from "stores";

  let active: string;
  let isTrainer: boolean;
  let dietLink: string;
  let trainingLink: string;
  let chatLink: string;

  const position = Capacitor.isNativePlatform() ? "fixed" : "sticky";

  const unsubscribeLocation = location.subscribe((res) => {
    active = $location;
    if (res === "/chat" || res === "/auth" || res.includes("/chat-with")) {
      $showMenu = false;
    } else {
      $showMenu = true;
    }
  });

  const unsubscribeUser = user.subscribe((res) => {
    isTrainer = !isClient($user);
    dietLink = isTrainer ? "/diet/meal-plans" : "/diet/meal-plan";
    trainingLink = isTrainer ? "/training/programs" : "/training/program";
    chatLink = isTrainer ? "/chat-list" : "/chat";
  });

  const unsubscribeSocket = socketV2.subscribe((sock) => {
    if (sock && sock.connected === true) {
      $socketV2.emit("subscribe", { event: "unreadCount", id: $user.id });
      $socketV2.emit("subscribe", { event: "notification", id: $user.id });
      $socketV2.on("unreadCount", (updatedUnreadCount) => {
        $unreadMessages = updatedUnreadCount.unreadCount;
      });
      $socketV2.on("notification", (receivedNotification) => {
        $unreadNotifications += 1;
        console.log("receivedNotification", receivedNotification);
        $notifications = [receivedNotification, ...$notifications];
        $loadedWorkouts = {};
      });
    }
  });

  onDestroy(() => {
    $socketV2.emit("unsubscribe", { event: "notification", id: $user.id });
    $socketV2.emit("unsubscribe", { event: "unreadCount", id: $user.id });
    $socketV2.off("notification");
    $socketV2.off("unreadCount");
    unsubscribeSocket();
    unsubscribeLocation();
    unsubscribeUser();
  });
</script>

{#if $showMenu}
  <div
    class={`menu ${position} flex-row center-space-around bg-white dark:bg-zinc-800 border-t border-slate-300 dark:border-zinc-700`}
  >
    {#if !isTrainer}
      <a href="/progress/measurements" use:link>
        <Svg
          name={active.includes("/progress") ? "progress-fill" : "progress"}
          size={24}
          customColor={active.includes("/progress")
            ? `bg-teal-500 dark:bg-primary-500`
            : ""}
        />
      </a>
    {/if}
    <a href={dietLink} use:link>
      <Svg
        name={active.includes("/diet") ? "utensils-fill" : "utensils"}
        size={24}
        customColor={active.includes("/diet")
          ? `bg-teal-500 dark:bg-primary-500`
          : ""}
      />
    </a>
    <a href={trainingLink} use:link>
      <Svg
        name={active.includes("/training") ? "dumbbell-fill" : "dumbbell"}
        size={24}
        customColor={active.includes("/training")
          ? `bg-teal-500 dark:bg-primary-500`
          : ""}
      />
    </a>
    {#if isTrainer}
      <a href="/clients/list" use:link>
        <Svg
          name={active.includes("/clients") ? "users-fill" : "users"}
          size={24}
          customColor={active.includes("/clients")
            ? `bg-teal-500 dark:bg-primary-500`
            : ""}
        />
      </a>
    {/if}
    <a href={chatLink} use:link>
      <div style="position: relative;">
        <Svg
          name={active.includes("/chat") ? "message-fill" : "message"}
          size={24}
          customColor={active.includes("/chat")
            ? `bg-teal-500 dark:bg-primary-500`
            : ""}
        />
        {#if $unreadMessages > 0}
          <div class="notification-bubble flex-row center-center">
            {$unreadMessages}
          </div>
        {/if}
      </div>
    </a>
    <a href="/notifications" use:link>
      <div style="position: relative;">
        <Svg
          name={active.includes("/notifications") ? "bell-fill" : "bell"}
          size={23}
          customColor={active.includes("/notifications")
            ? `bg-teal-500 dark:bg-primary-500`
            : ""}
        />
        {#if $unreadNotifications > 0}
          <div class="notification-bubble flex-row center-center">
            {$unreadNotifications}
          </div>
        {/if}
      </div>
    </a>
    <a href="/settings" use:link>
      <Svg
        name={active.includes("/settings") ? "gear-fill" : "gear"}
        size={24}
        customColor={active.includes("/settings")
          ? `bg-teal-500 dark:bg-primary-500`
          : ""}
      />
    </a>
  </div>
{/if}

<style>
  a {
    -webkit-tap-highlight-color: transparent;
    text-decoration: none;
  }
  .menu {
    bottom: 0;
    left: 0;
    max-width: 720px;
    height: 48px;
    width: 100%;
    padding-top: calc(10px + calc(env(safe-area-inset-bottom) / 2));
    padding-bottom: calc(6px + env(safe-area-inset-bottom));
  }
  .notification-bubble {
    position: absolute;
    bottom: 12px;
    left: 12px;
    height: 20px;
    width: 20px;
    background-color: red;
    border-radius: 64px;
    color: #fff;
    font-size: 10px;
    font-weight: 500;
  }
</style>
