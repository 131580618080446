export const urlify = (text: string) => {
  if (!text) return "";
  const urlRegex = /(https?:\/\/[^\s]+)/g;

  text = text.replace(/</g, "");

  return text.replace(urlRegex, (url) => {
    return (
      '<a target="_blank" style="text-decoration: underline;" href="' +
      url +
      '">' +
      url +
      "</a>"
    );
  });
};
