<script lang="ts">
  import { api, getWithJwt } from "lib";
  import { currentClient } from "../../stores/currentClient";
  import { filtersStore2 } from "../../stores/filtersStore2";
  import { onMount } from "svelte";
  import Notification from "../Notification.svelte";
  import Filter from "../Filter.svelte";

  let notifications: any[] = [];
  let notificationsCount = 0;
  let notificationsPage = 0;

  const createNotificationsUrl = (): string => {
    const take = "take=10";
    const skip = `skip=${notificationsPage * 10}`;
    const clientId = `clientId=${$currentClient.id}`;
    let unopened = "";
    let comments = "";

    if ($filtersStore2.tags.includes("Prikaži samo neotvorene")) {
      unopened = "unopened=1";
    }

    if ($filtersStore2.tags.includes("Prikaži samo komentare")) {
      comments = "comments=1";
    }

    const params = `${take}&${skip}&${clientId}&${unopened}&${comments}`;

    return `${api}/notification?${params}`;
  };

  const onFetchNotifications = async (): Promise<void> => {
    notificationsPage = 0;
    const response = await getWithJwt(createNotificationsUrl());
    notifications = response.data.data;
    notificationsCount = response.data.count;
  };

  const onLoadMoreNotifications = async (): Promise<void> => {
    notificationsPage += 1;
    const response = await getWithJwt(createNotificationsUrl());
    notifications = [...notifications, ...response.data.data];
  };

  onMount(() => {
    onFetchNotifications();
  });
</script>

<Filter type="NOTIFICATIONS" on:fetchData={onFetchNotifications} />
{#each notifications as notification}
  <Notification {notification} />
{/each}
