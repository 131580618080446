<script lang="ts">
  import WorkoutExpansionPanel from "../../components/UI/WorkoutExpansionPanel.svelte";
  import TopMenu from "../../components/TopMenu.svelte";
  import { getWithJwt, postFormDataWithJwt } from "../../lib/requests";
  import { api, dialogTypes } from "../../lib/constants";
  import { onDestroy, onMount } from "svelte";
  import { user } from "../../stores/userStore";
  import { parseDate } from "../../lib/parseDate";
  import { exportToPdf } from "../../lib/pdf";
  import Spinner from "../../components/UI/Spinner.svelte";
  import { dialogData } from "../../stores/dialogDataStore";
  import { translate } from "../../lib/translate";
  import { showAlert } from "../../stores/showAlertStore";
  import { Capacitor } from "@capacitor/core";
  import LoadMoreButton from "../../components/LoadMoreButton.svelte";
  import { isLoadingMore } from "../../stores/isLoadingMoreStore";
  import { ButtonComponent } from "ui";
    import { journalWorkoutsStore } from "stores";

  let workouts: any = [];
  let workoutCount = 0;
  let page = 0;
  let isLoading = false;

  $: hasMore = page * 15 <= workoutCount;

  const fetchData = async () => {
    if (!isLoading && $user) {
      isLoading = true;
      const url = `${api}/program_journal/new?take=15&skip=0&clientId=${$user.id}&default=1&template=0`;
      try {
        const data = await getWithJwt(url);
        $journalWorkoutsStore = data.data;
        workouts = data.data;
        workoutCount = data.count;
        isLoading = false;
      } catch (err) {}
    }
  };

  const onLoadMore = async (): Promise<void> => {
    if (!$user) {
      return;
    }

    $isLoadingMore = true;
    page += 1;

    const url = `${api}/program_journal/new?take=15&skip=${
      page * 15
    }&clientId=${$user.id}&default=1&template=0`;

    try {
      const response = await getWithJwt(url);
      workouts = [...workouts, ...response.data];
    } catch (error) {
      $isLoadingMore = false;
      console.error(error);
    }

    $isLoadingMore = false;
  };

  const submit = async (data) => {
    const url = `${api}/program_journal/workout`;
    const workout = {
      name: data.name,
      description: data.description,
      entityType: 0,
      programId: workouts[0]?.programId,
      protocoll: null,
      showMacros: true,
      finishedAt: data.finishedAt
        ? new Date(data.finishedAt).toISOString()
        : new Date().toISOString(),
    };
    const formData = new FormData();
    formData.append("programId", workouts[0]?.programId);
    formData.append("workout", JSON.stringify(workout));
    try {
      const res = await postFormDataWithJwt(url, formData);
      workouts = [res, ...workouts];
      $dialogData = {};
      $showAlert.message = `${translate("SUCCESSFULLY_CREATED")} ${translate(
        "WORKOUT"
      ).toLowerCase()}.`;
    } catch (err) {}
  };

  const openCreateEditWorkoutDialog = () => {
    $dialogData.data = {
      executeFunction: submit,
    };
    $dialogData.type = dialogTypes.CREATE_EDIT_FINISHED_WORKOUT;
  };

  const unsubscribe = user.subscribe((res) => fetchData());

  onDestroy(unsubscribe);
</script>

<TopMenu entity="TRAINING" />
<div
  class="p-4 flex flex-col gap-4 bg-white dark:bg-zinc-800 text-slate-900 dark:text-slate-100"
>
  {#if isLoading}
    <Spinner />
  {:else}
    <div class="flex justify-between">
      <ButtonComponent on:click={openCreateEditWorkoutDialog}
        >{translate("ADD_WORKOUT")}</ButtonComponent
      >
      {#if workouts && workouts.length > 0}
        <ButtonComponent
          on:click={() =>
            exportToPdf($user.id, "program_journal", "TrainingJournal.pdf")}
          >{translate("EXPORT_TO_PDF")}</ButtonComponent
        >
      {/if}
    </div>
    <!-- <div style="margin-bottom: -8px;" /> -->
    <div class="flex flex-col gap-4">
      {#each workouts as workout}
        <div>
          <!-- <span class="finished-at mt-2 mgl-12">
            {parseDate(workout.finishedAt)}
          </span> -->
          <WorkoutExpansionPanel showComments={true} isFinished {workout} />
        </div>
      {/each}
    </div>
    <!-- <InfiniteScroll {hasMore} on:loadMore={onLoadMore} /> -->
    {#if hasMore}
      <LoadMoreButton fetchMoreData={onLoadMore} />
    {/if}
  {/if}
  {#if Capacitor.isNativePlatform()}
    <div class="mb-12" />
  {/if}
  {#if Capacitor.getPlatform() === "ios"}
    <div class="pb-16" />
  {/if}
</div>

<style>
  .training-container {
    min-height: 100%;
  }
  .finished-at {
    align-self: flex-start;
    font-size: small;
  }
</style>
