<script lang="ts">
  import { logout } from "../../lib/logout";
  import { dialogData } from "stores";
  import { onDestroy } from "svelte";
  import Avatar from "../../components/UI/Avatar.svelte";
  import Button from "../../components/UI/Button.svelte";
  import Svg from "../../components/UI/Svg.svelte";
  import { api, dialogTypes } from "../../lib/constants";
  import { deleteWithJwt, postFormDataWithJwt } from "../../lib/requests";
  import { translate } from "../../lib/translate";
  import { showAlert } from "../../stores/showAlertStore";
  import { user } from "../../stores/userStore";
  import { fetchUser } from "../../lib/fetchUser";
  import { clientAvatarUrl } from "../../stores/clientStores";
  import Input from "../../components/UI/Input.svelte";
    import { ButtonComponent } from "ui";

  let name: string = "";
  let email: string = "";
  let password: string = "";
  let repeatPassword: string;
  let fileInput;
  let file: File;

  const onFileSelected = (e) => {
    file = e.target.files[0];
    updateAvatar();
  };

  const unsubscribe = user.subscribe((res) => {
    if (res) {
      name = res.name;
      email = res.email;
    }
  });

  onDestroy(unsubscribe);

  const update = async () => {
    const url = `${api}/user`;
    const formData = new FormData();
    if (password !== "" && password !== repeatPassword) {
      $showAlert.message = "Passwords don't match.";
      return;
    }
    if (password !== "" && password === repeatPassword) {
      formData.append("user", JSON.stringify({ name, email, password }));
    } else {
      formData.append("user", JSON.stringify({ name, email }));
    }
    try {
      await postFormDataWithJwt(url, formData, "PUT");
      $showAlert.message = "User profile updated successfully.";
      $user.name = name;
      $user.email = email;
    } catch (err: any) {
      $showAlert.message = err.data.message;
    }
  };

  const updateAvatar = async () => {
    const url = `${api}/user`;
    const formData = new FormData();
    formData.append("user", JSON.stringify({ name }));
    formData.append("avatar", file);
    try {
      await postFormDataWithJwt(url, formData, "PUT");
      $showAlert.message = "User profile updated successfully.";
      await fetchUser();
    } catch (err: any) {
      $showAlert.message = err.data.message;
    }
  };

  const deleteProfile = async () => {
    const url = `${api}/v2/user`;
    try {
      const deletedUser = await deleteWithJwt(url);
      logout();
    } catch (err) {}
  };

  const toggleDeleteProfileDialog = () => {
    $dialogData.type = dialogTypes.CONFIRM_DELETE_ACCOUNT;
    $dialogData.data = { executeFunction: deleteProfile };
  };
</script>

<svelte:head>
  <style>
    body {
      overflow: hidden;
    }
  </style>
</svelte:head>
<div
  class="flex flex-col items-center bg-white dark:bg-zinc-800 text-slate-900 dark:text-slate-100"
>
  <div
    class="flex-col center-center w-full mt-20"
    on:click={() => fileInput.click()}
    on:keypress={() => fileInput.click()}
  >
    <Avatar alt="User Avatar" size={88} avatarUrl={$clientAvatarUrl} />
    <div
      style="position: relative; top: -36px; left: 36px; border-radius: 64px; padding: 4px"
    >
      <Svg name="edit" customColor="red" size={16} />
    </div>
    <input
      style="display:none"
      type="file"
      accept=".jpg, .jpeg, .png"
      on:change={(e) => onFileSelected(e)}
      bind:this={fileInput}
    />
  </div>
  <br />
  <div class="w-5/6 gap-4">
    <Input label="NAME" bind:value={name} />
    <div class="h-4" />
    <Input label="EMAIL" bind:value={email} />
    <div class="h-4" />
    <Input label="PASSWORD" bind:value={password} />
    <div class="h-4" />
    <Input label="REPEAT_PASSWORD" bind:value={repeatPassword} />
    <div class="h-4" />
  </div>
  <!-- <input type="text" class="input mt-2 mb-2" bind:value={name} /> -->

  <ButtonComponent myClasses="mt-4 mb-1" on:click={update}
    >{translate("UPDATE")}</ButtonComponent
  >
  <ButtonComponent
    myClasses="mt-6"
    isOutline
    on:click={toggleDeleteProfileDialog}
    ><span class="flex flex-row">
      {translate("DELETE_ACCOUNT")}<Svg
        name="delete"
        size={16}
        myClass="ml-2"
        customColor="bg-red-500"
      />
    </span></ButtonComponent
  >
</div>
