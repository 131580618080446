<script lang="ts">
  import { showAlert } from "stores";
  import Button from "../components/UI/Button.svelte";
  import { uploadAndSignImageCloudflare } from "../lib/cloudflare/uploadImageCloudflare";

  let fileInput: HTMLInputElement;
  let files: File[] = [];
  let uploadedImgUrl: string;

  const onFileSelected = async (e: any) => {
    files = e.target.files;
    const uploadRes = await uploadAndSignImageCloudflare(files[0]);
    if (uploadRes.imageUrl) uploadedImgUrl = uploadRes.imageUrl;
    else
      $showAlert.message = uploadRes.error
        ? uploadRes.error
        : "Otpremanje slike nije uspelo";
  };
</script>

<div>
  <Button execFunction={() => fileInput.click()}>Select file...</Button>
  <input
    style="display:none"
    type="file"
    multiple="multiple"
    accept=".png, .gif, .jpeg, .jpg, .webp, .svg"
    on:change={(e) => onFileSelected(e)}
    bind:this={fileInput}
  />
  <img src={uploadedImgUrl} alt="user img" />
</div>
