<script lang="ts">
  import { onDestroy, onMount, type ComponentType } from "svelte";
  import {
    api,
    dialogTypes,
    getWithJwt,
    isClient,
    postFormDataWithJwt,
    serverlessRoutes,
    translate,
  } from "lib";
  import {
    cantDoExercises,
    countersStore,
    currentClient,
    dialogData,
    showAlert,
    trainerClientPrograms,
    trainerClientWorkouts,
    user,
  } from "stores";
  import WorkoutExpansionPanel from "../../../components/UI/WorkoutExpansionPanel.svelte";
  import InfiniteScroll from "../../../components/UI/InfiniteScroll.svelte";
  import ButtonGroup from "../../../components/UI/ButtonGroup.svelte";
  import ProgramComponent from "../../../components/Training/ProgramComponent.svelte";
  import type { IButtonGroup } from "src/interfaces/IButtonGroup";
  import { querystring, replace } from "svelte-spa-router";
  import { ButtonComponent } from "ui";

  const typeMap: { [key: string]: "ACTIVE" | "DIARY" } = {
    active: "ACTIVE",
    diary: "DIARY",
  };
  const queryObject: URLSearchParams = new URLSearchParams($querystring);
  const queryIterator: IterableIterator<string> = queryObject.values();
  queryIterator.next();
  const selectedType: string = queryIterator.next().value;

  let type: "ACTIVE" | "DIARY" =
    $querystring === "" ? "ACTIVE" : typeMap[selectedType];

  // const setType = (v: string) => {
  //   queryObject.set("type", v);
  //   replace(`/profile/${$currentClient.id}?${queryObject.toString()}`);
  // };

  // let workouts = [] as any[];
  // let workoutsCount = 0;
  let programsPage = 0;
  // let workoutsPage = 0;

  const createProgramsUrl = (): string => {
    const take = "take=5";
    const skip = `skip=${programsPage * 5}`;
    const def = "default=1";
    const template = "template=0";
    const params = `${take}&${skip}&${def}&${template}`;

    return `${api}/trainer/client/${$currentClient.id}/program?${params}`;
  };

  const fetchPrograms = async (): Promise<void> => {
    try {
      const response = await getWithJwt(createProgramsUrl());
      $trainerClientPrograms = response.data;
      $countersStore.trainerClientPrograms = response.count;
    } catch (error) {
      console.error(error);
    }
  };

  const onLoadMorePrograms = async (): Promise<void> => {
    programsPage += 1;

    try {
      const response = await getWithJwt(createProgramsUrl());
      $trainerClientPrograms = [...$trainerClientPrograms, ...response.data];
    } catch (error) {
      console.error(error);
    }
  };

  const onActive = async (): Promise<void> => {
    if (type === "ACTIVE") {
      return;
    }

    type = "ACTIVE";
    // setType("active");
    await replace(`/profile/${$currentClient.id}?view=training&type=active`);
  };

  const onDiary = async (): Promise<void> => {
    if (type === "DIARY") {
      return;
    }

    type = "DIARY";
    // setType("diary");
    await replace(`/profile/${$currentClient.id}?view=training&type=diary`);
  };

  const onCreateWorkoutPlan = (): void => {
    $dialogData.data = { clientId: $currentClient.id };
    $dialogData.type = dialogTypes.TRAINING_PROGRAM;
  };

  const onAssignTrainingProgram = (): void => {
    $dialogData.data = {
      client: $currentClient,
    };

    $dialogData.type = dialogTypes.ASSIGN_WORKOUT_PLAN;
  };


  const submit = async (data) => {
    const url = `${api}/program_journal/workout`;
    const workout = {
      name: data.name,
      description: data.description,
      entityType: 0,
      programId: $trainerClientPrograms[0].id,
      protocoll: null,
      showMacros: true,
      finishedAt: data.finishedAt
        ? new Date(data.finishedAt).toISOString()
        : new Date().toISOString(),
    };
    const formData = new FormData();
    formData.append("programId", $trainerClientPrograms[0].id);
    formData.append("workout", JSON.stringify(workout));
    try {
      const res = await postFormDataWithJwt(url, formData);
      // workouts = [res, ...workouts];
      $trainerClientWorkouts.data = [res, ...$trainerClientWorkouts.data]

      $dialogData.type = "";
      $dialogData.data = {};

      $showAlert.message = `${translate("SUCCESSFULLY_CREATED")} ${translate(
        "WORKOUT"
      ).toLowerCase()}.`;
    } catch (err) {}
  };
  const onAssignWorkout = (): void => {
    $dialogData.data = {executeFunction: submit};
    $dialogData.type = dialogTypes.CREATE_EDIT_FINISHED_WORKOUT;
  };

  let selectedButtonIndex = 0;

  // let cantDo: Array<any> = [];

  onMount(async (): Promise<void> => {
    const { href } = window.location;

    if (href.includes("type=active")) {
      selectedButtonIndex = 0;
    } else if (href.includes("type=diary")) {
      selectedButtonIndex = 1;
    }

    await fetchPrograms();
    await trainerClientWorkouts.fetchData();

    if (window.location.href.includes("/profile") && !isClient($user)) {
      const userId = window.location.href.split("/")[5];
      const workoutIds = $trainerClientWorkouts.data.map(
        (workout) => workout.id
      );

      const response = await getWithJwt(
        `${serverlessRoutes.WORKOUT}/cant-do-exercises?workoutIds=%5B${workoutIds.join(",")}%5D&userId=${userId}`
      );
      $cantDoExercises.workoutIds = [
        ...$cantDoExercises.workoutIds,
        ...response.data.cantDo,
      ];
      // cantDo = response.data.cantDo;
    }
  });

  const buttonGroup: IButtonGroup[] = [
    {
      title: translate("ACTIVE"),
      onClick: onActive,
    },
    {
      title: translate("DIARY"),
      onClick: onDiary,
    },
  ];

  onDestroy(() => {
    $trainerClientWorkouts.data = [];
  });
</script>

<div class="flex flex-col gap-4">
  <div class="flex justify-center">
    <ButtonGroup buttons={buttonGroup} selectedIndex={selectedButtonIndex} />
  </div>

  {#if type === "ACTIVE"}
    <div class="flex justify-between">
      <ButtonComponent on:click={onCreateWorkoutPlan}>
        {translate("CREATE")}
        {translate("PROGRAM").toLowerCase()}
      </ButtonComponent>
      <ButtonComponent on:click={onAssignTrainingProgram}>
        {translate("ASSIGN_TRAINING_PROGRAM")}
      </ButtonComponent>
    </div>

    {#each $trainerClientPrograms as program}
      <ProgramComponent {program} />
    {/each}

    <InfiniteScroll
      hasMore={$trainerClientPrograms.length <
        $countersStore.trainerClientPrograms}
      on:loadMore={onLoadMorePrograms}
    />
  {:else}
    <div class="flex justify-between">
      <ButtonComponent on:click={onCreateWorkoutPlan}>
        {translate("EXPORT_TO_PDF")}
      </ButtonComponent>
      <ButtonComponent on:click={onAssignWorkout}>
        {translate("ADD")}
        {translate("WORKOUT").toLowerCase()}
      </ButtonComponent>
    </div>

    {#each $trainerClientWorkouts.data as workout}
      <WorkoutExpansionPanel
        {workout}
        showComments
        isFinished={workout.finishedAt !== null}
      />
    {/each}

    <InfiniteScroll
      isFetchingMore={$trainerClientWorkouts.isFetchingMore}
      hasMore={$trainerClientWorkouts.hasMore}
      on:loadMore={trainerClientWorkouts.fetchMoreData}
    />
  {/if}
</div>
