<script lang="ts">
  import {onMount} from "svelte";

  import {
    formStoreCreate,
    patchWithJwt,
    postWithJwt,
    serverlessRoutes,
    translate,
  } from "lib";

  import {
    dialogData,
    showAlert,
    trainerClientPrograms,
    trainerPrograms,
    workoutsStore,
  } from "stores";

  import { ButtonComponent, InputField, TextareaField } from "ui";
  import { updateTrainerPrograms } from "../../../lib/updateTrainerPrograms";

  let data: any;
  const dialogType = data.workout ? translate("EDIT") : translate("CREATE");

  const form = formStoreCreate({
    name: ["", "NAME"],
    description: ["", "DESCRIPTION"],
  });

  const createTextWorkout = async (): Promise<void> => {
    const {programId} = data;

    const res = await postWithJwt(serverlessRoutes.WORKOUT, {
      partialWorkout: {
        name: $form.field.name.value,
        description: $form.field.description.value,
        entityType: 2,
        programId,
      }
    });

    if (res.error) {
      $showAlert.color = "red-400";
      $showAlert.message = translate("ERROR_CREATING_WORKOUT");
      $form.isLoading = false;
      return;
    }

    updateTrainerPrograms(programId, (p) => {
      p.workouts.push(res.data.workout);
    });

    $showAlert.color = "black";
    $showAlert.message = translate("SUCCESSFULLY_CREATED_WORKOUT");

    $dialogData.type = "";
    $dialogData.data = {};
  };

  const editTextWorkout = async (): Promise<void> => {
    const { workout, programId } = data;
    const { name, description } = $form.field;

    const res = await patchWithJwt(serverlessRoutes.WORKOUT, {
      partialWorkoutMap: {
        [workout.id]: {
          name: name.value,
          description: description.value,
        }
      }
    });

    if (res.error) {
      $showAlert.color = "red-400";
      $showAlert.message = translate("ERROR_EDITING_WORKOUT");
      $form.isLoading = false;
      return;
    }

    res.data.workouts[0].exercises = []; // neki bug fix, ne secam se za sta

    if (workout.default) {
      workoutsStore.replace([res.data.workouts[0]]);
    } else {
      $trainerPrograms.forEach((program) => {
        const w = program?.workouts?.find(({ id }) => id === workout.id);
        if (w) {
          const index = program.workouts.indexOf(w);
          program.workouts.splice(index, 1, res.data.workouts[0]);
        }
      });
      $trainerPrograms = $trainerPrograms;

      $trainerClientPrograms.forEach((program) => {
        const w = program?.workouts?.find(({ id }) => id === workout.id);
        if (w) {
          const index = program.workouts.indexOf(w);
          program.workouts.splice(index, 1, res.data.workouts[0]);
        }
      });
      $trainerClientPrograms = $trainerClientPrograms;
    }

    $showAlert.color = "black";
    $showAlert.message = translate("SUCCESSFULLY_EDITED_WORKOUT");

    $dialogData.type = "";
    $dialogData.data = {};
  };

  const onSubmit = async (): Promise<void> => {
    $form.isLoading = true;

    if (data.workout) {
      await editTextWorkout();
    } else {
      await createTextWorkout();
    }
  };

  onMount((): void => {
    if (data.workout) {
      const {name, description} = data.workout;

      form.setValue("name", name);
      form.setValue("description", description);
    }
  });

  export { data };
</script>

<div class="p-4 flex flex-col gap-4">
  <div class="text-center font-semibold">
    {dialogType} {translate("WORKOUT").toLowerCase()}
  </div>

  <form class="flex flex-col gap-4" on:submit|preventDefault={onSubmit}>
    <InputField
      label={translate("NAME")}
      error={$form.field.name.error}
      bind:value={$form.field.name.value}
      on:input={() => form.validate("name")}
    />

    <TextareaField
      label={translate("DESCRIPTION")}
      error={$form.field.description.error}
      bind:value={$form.field.description.value}
      on:input={() => form.validate("description")}
    />

    <div class="flex justify-center">
      <ButtonComponent
        cy="create-edit-text-workout-button"
        type="submit"
        isLoading={$form.isLoading}
        disabled={$form.isDisabled}
      >
        {dialogType}
      </ButtonComponent>
    </div>
  </form>
</div>
