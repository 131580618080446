<script lang="ts">
  import ChatHelper from "./ExpansionPanels/ChatHelper.svelte";
  import ClientSettings from "./ExpansionPanels/ClientSettings.svelte";
  import ClientTags from "./ExpansionPanels/ClientTags.svelte";
  import DefaultHabits from "./ExpansionPanels/DefaultHabits.svelte";
  import DefaultMeasurements from "./ExpansionPanels/DefaultMeasurements.svelte";
  import IngredientTypes from "./ExpansionPanels/IngredientTypes.svelte";
  import MealPlanTypes from "./ExpansionPanels/MealPlanTypes.svelte";
  import OmitFromAutomaticMessage from "./ExpansionPanels/OmitFromAutomaticMessage.svelte";
  import RecipeTypes from "./ExpansionPanels/RecipeTypes.svelte";
  import SearchEmail from "./ExpansionPanels/SearchEmail.svelte";
  import TemplateMessages from "./ExpansionPanels/TemplateMessages.svelte";
</script>

<div class="p-4 h-full flex flex-col justify-center gap-4">
  <ChatHelper/>
  <SearchEmail/>
  <OmitFromAutomaticMessage/>
  <ClientTags/>
  <DefaultHabits/>
  <DefaultMeasurements/>
  <IngredientTypes/>
  <RecipeTypes/>
  <MealPlanTypes/>
  <ClientSettings/>
  <TemplateMessages/>
</div>
