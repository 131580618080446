<script lang="ts">
  import { location } from "svelte-spa-router";
  import { dialogData, trainerClientGroups } from "stores";
  import { dialogTypes, translate } from "lib";
  import SinglePlan from "../../../components/SinglePlan.svelte";
  import { ButtonComponent } from "ui";

  const groupId = parseInt($location.split("/clients/groups/")[1]);

  const group = $trainerClientGroups.find(
    (group): boolean => group.id === groupId
  );

  const { clientGroupPrograms } = group;

  const onAssignWorkoutPlan = (): void => {
    $dialogData.data = { group };
    $dialogData.type = dialogTypes.ASSIGN_WORKOUT_PLAN;
  };
</script>

<div class="p-4 flex flex-col gap-4">
  <div class="flex justify-center">
    <ButtonComponent on:click={onAssignWorkoutPlan}>
      {translate("ASSIGN_WORKOUT_PLAN")}
    </ButtonComponent>
  </div>

  <p class="text-center">
    {translate("NUMBER_OF_TRAININGS")}:
    <b>{clientGroupPrograms.length}</b>
  </p>

  {#each clientGroupPrograms as program}
    <SinglePlan {program} />
  {/each}
</div>
