<script>
  import { api, entities, getWithJwt, translate } from "lib";
  import TopMenu from "../../components/TopMenu.svelte";
  import { onMount } from "svelte";
  import ExpansionPanel from "../../components/UI/ExpansionPanel.svelte";
  import ClientListItem from "../../components/ClientListItem.svelte";
  import Select from "../../components/UI/Select.svelte";

  let data = [];
  let selectDayData = [];
  let selectedOption = "ACCOUNT";

  const onFetchWorkout = async () => {
    const url = `${api}/trainer/client/overview/workout`;
    try {
      const res = await getWithJwt(url);
      data = res.map((item) => ({
        count: item.occurrenceNumber,
        dayCount: item.trainingCount,
      }));
    } catch (error) {
      console.error(error);
    }
  };

  const onFetchData = async () => {
    const url = `${api}/trainer/client/overview/date`;
    try {
      const res = await getWithJwt(url);
      const today = new Date();
      today.setDate(today.getDate() - 1);
      res.forEach((r) => {
        r.dayCount = Math.floor(
          (new Date(r.expireAt) - today) / (1000 * 60 * 60 * 24)
        );
      });
      data = res;
      data = data.sort((a, b) => a.dayCount - b.dayCount);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchForDay = async (count) => {
    try {
      const res = await getWithJwt(
        `${api}/trainer/client/overview/${selectedOption === "ACCOUNT" ? "date" : "workout"}/${count}?take=15&skip=0`
      );
      selectDayData = res;
    } catch (err) {
      console.error(err);
    }
  };

  const fetchData = (option) => {
    if (option === "ACCOUNT") {
      onFetchData();
    } else if (option === "TRAINING") {
      onFetchWorkout();
    }
    // else if (option === "TRAINING_PLAN") {
    // } else if (option === "MEAL_PLAN") {
    // }
  };

  const selectItems = [
    { name: "ACCOUNT", selected: selectedOption === "ACCOUNT" },
    { name: "TRAINING", selected: selectedOption === "TRAINING" },
    // { name: "TRAINING_PLAN", selected: selectedOption === "TRAINING_PLAN" },
    // { name: "MEAL_PLAN", selected: selectedOption === "MEAL_PLAN" },
  ];

  const onOptionChange = (changeValue) => {
    selectedOption = changeValue;
    selectDayData = [];
    fetchData(selectedOption);
  };

  const generateString = (dayCount) => {
    if (selectedOption === "ACCOUNT")
      return `${translate("EXPIRING")} ${dayCount === 0 ? translate("TODAY").toLowerCase() : dayCount === 1 ? translate("TOMORROW").toLowerCase() : `${translate("IN")}: ${dayCount} ${translate("DAYS").toLowerCase()}`}`;
    else return `${translate("NUMBER_OF_WORKOUTS")}:`;
  };

  onMount(onFetchData);
</script>

<TopMenu entity={entities.NOTIFICATIONS} />
<div class="mt-3 mb-4">
  <Select {onOptionChange} label="EXPIRY" {selectItems} />
</div>
<div class="gap-4">
  {#each data as reminder}
    <div
      class="m-4"
      tabindex="0"
      role="button"
      on:click={() => fetchForDay(+reminder.dayCount)}
      on:keypress={() => fetchForDay(reminder.dayCount)}
    >
      <ExpansionPanel
        title={generateString(reminder.dayCount)}
        subtitle={`${translate("ITEM_COUNT")}: ${reminder.count}`}
      >
        {#each selectDayData as item}
          <ClientListItem client={item} />
        {/each}
      </ExpansionPanel>
    </div>
  {/each}
</div>
