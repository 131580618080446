<script lang="ts">
  import { onDestroy, onMount } from "svelte";
  import TopMenu from "../../components/TopMenu.svelte";
  import Spinner from "../../components/UI/Spinner.svelte";
  import {
    api,
    dialogTypes,
    entities,
    serverlessRoutes,
  } from "../../lib/constants";
  import { getWithJwt, postWithJwt } from "../../lib/requests";
  import { user } from "../../stores/userStore";
  import HabitExpansionPanel from "../../components/Progress/HabitExpansionPanel.svelte";
  import DateIncrementer from "../../components/Progress/DateIncrementer.svelte";
  import dateString from "../../lib/dateString";
  import Gauge from "../../components/UI/Gauge.svelte";
  import { translate } from "../../lib/translate";
  import { Capacitor } from "@capacitor/core";
  import { currentClient } from "../../stores/currentClient";
  import { dialogData, showAlert } from "stores";
  import { isClient } from "../../lib/roles";
  import { ButtonComponent } from "ui";

  let habits = [];
  let isLoading = false;
  let selectedDate: Date = new Date();
  let map: object = {};
  let habitData = [];
  const today = new Date();
  let totalSteps = 0;
  let totalCompletedSteps = 0;

  $: totalCompletedSteps = calculateCompletedSteps(selectedDate);

  const incrementDate = (n: number) => {
    if (n > 0 && selectedDate >= today) {
      return;
    }
    selectedDate.setDate(selectedDate.getDate() + n);
    selectedDate = selectedDate;
    fetchHabitData();
  };

  const calculateCompletedSteps = (date: Date) => {
    if (!map[dateString(selectedDate)]) return 0;
    return map[dateString(selectedDate)].reduce(
      (acc, curr) => (acc += curr[Object.keys(curr)[0]].value),
      0
    );
  };

  const fetchData = async () => {
    if (!isLoading && $user && habits.length === 0) {
      const id = $currentClient.id ? $currentClient.id : $user.id;
      isLoading = true;
      const url = `${serverlessRoutes.HABITS}?userId=${id}`;
      try {
        const data = await getWithJwt(url);
        habits = data.data;
        habits.forEach((habit) => (totalSteps += habit.steps));
        fetchHabitData();
        isLoading = false;
      } catch (err) {}
    }
  };

  const updateDataMap = () => {
    map = {};
    habitData.forEach((item) => {
      item[`habitData`].forEach((dataItem) => {
        const date = new Date(dataItem.createdAt);
        const obj = {};
        obj[dataItem[`habitId`]] = {
          value: dataItem.value,
          id: dataItem.id,
        };
        if (map[dateString(date)]) {
          map[dateString(date)] = [...map[dateString(date)], obj];
        } else {
          map[dateString(date)] = [obj];
        }
      });
    });
    totalCompletedSteps = calculateCompletedSteps(selectedDate);
  };

  const fetchHabitData = async () => {
    // TODO za klijenta treba da se promeni $user.id
    const prevDate = new Date(selectedDate);
    prevDate.setDate(prevDate.getDate() - 7);
    const id = $currentClient.id ? $currentClient.id : $user.id;
    const url = `${api}/habit/date?clientId=${id}&dateFrom=${dateString(
      prevDate
    )}&dateTo=${dateString(selectedDate)}`;
    try {
      const data = await getWithJwt(url);
      habitData = data;
      updateDataMap();
    } catch (err) {}
  };

  const trackNewHabit = async (habit) => {
    const id = isClient($user) ? $user.id : $currentClient.id;
    if (!id) return;
    try {
      const url = serverlessRoutes.HABITS;
      const result = await postWithJwt(url, {
        habit,
        clientId: id,
      });
      if (result.error) {
        $showAlert.message = translate("SOMETHING_WENT_WRONG");
        return;
      }
      $showAlert.message = translate("HABIT_SUCCESSFULLY_ADDED");
      habits = [...habits, result.data];
    } catch (err) {}
  };

  const unsubscribe = user.subscribe((res) => fetchData());

  onDestroy(unsubscribe);

  onMount(() => {
    fetchData();
  });

  const deleteHabit = (event: CustomEvent) => {
    habits = habits.filter((habit) => habit.id !== event.detail.id);
    $showAlert.message = translate("HABIT_SUCCESSFULLY_DELETED");
  };

  const updateHabit = (event: CustomEvent) => {
    const updatedHabit = event.detail;
    const index = habits.findIndex((habit) => habit.id === updatedHabit.id);
    habits[index] = { ...habits[index], ...updatedHabit };
    habits = habits;
  };
</script>

{#if !$currentClient.id}
  <TopMenu entity={entities.PROGRESS} />
{/if}
{#if isLoading}
  <Spinner />
{:else}
  <div
    class={`bg-white dark:bg-zinc-800 text-slate-900 dark:text-slate-100 ${!$currentClient.id ? "p-4 " : ""}`}
  >
    <div class="flex-col center-center mt-2 mgb-32">
      <Gauge
        animate={true}
        complete={totalCompletedSteps === 0
          ? 1
          : (totalCompletedSteps / totalSteps) * 100}
        width={256}
      />
      <div class="flex-col center-center" style="margin-top: -62px;">
        <h2>{translate("COMMITMENT")}</h2>
        <h3 class="text-bold">{totalCompletedSteps}/{totalSteps}</h3>
      </div>
    </div>
    <DateIncrementer {incrementDate} selectedDateDecremented={selectedDate} />
    <div class="flex flex-col gap-4">
      {#each habits as habit}
        <HabitExpansionPanel
          on:deleteHabit={deleteHabit}
          on:updateHabit={updateHabit}
          steps={habit.steps}
          title={habit.name}
          isDraggable={true}
          entityId={habit.id}
          {selectedDate}
          {map}
          {fetchHabitData}
        />
      {/each}
    </div>
    <div class="flex flex-row justify-between">
      <div>
        {#if !$currentClient.id}
          <ButtonComponent on:click={() => ""} isOutline myClasses="mt-4">
            {translate("EXPORT_TO_PDF")}
          </ButtonComponent>
        {/if}
      </div>
      <ButtonComponent
        on:click={() => {
          $dialogData.type = dialogTypes.CREATE_EDIT_HABIT;
          $dialogData.data = { executeFunction: trackNewHabit };
        }}
        isOutline
        myClasses="mt-4"
      >
        {translate("TRACK_NEW_HABIT")}
      </ButtonComponent>
    </div>
    {#if Capacitor.isNativePlatform()}
      <div class="mb-12" />
    {/if}
  </div>
{/if}
