<script lang="ts">
  import ChatSession from "../../components/Chat/ChatSession.svelte";
  import {
    chatSessions,
    chatSessionsCount,
    clientChatSessionId,
    notSeenSessionIds,
    unreadMessages,
  } from "../../stores/chatSessionsStore";
  import { fetchChatSessions } from "../../lib/fetchSessions";
  import { CheckboxComponent } from "ui";
  import { showOnlyUnreadChat } from "../../stores/chatSessionsStore";
  import { getWithJwt, isClient, serverlessRoutes, translate } from "lib";
  import { Capacitor } from "@capacitor/core";
  import { onDestroy, onMount } from "svelte";
  import { isLoadingMore } from "../../stores/isLoadingMoreStore";
  import ChatSessionSkeleton from "../../components/Chat/ChatSessionSkeleton.svelte";
  import InfiniteScroll from "../../components/UI/InfiniteScroll.svelte";
  import { socketV2 } from "../../stores/socketStore.js";
  import { user } from "stores";

  let scrollElem: HTMLDivElement;
  let isLoading: boolean = true;

  chatSessions.subscribe((res) => {
    if (res.length > 0) {
      isLoading = false;
    }
  });

  const unsubscribeSocket = socketV2.subscribe((sock) => {
    if (sock && sock.connected === true) {
      if (typeof $socketV2.emit === "function") {
        $socketV2.emit("subscribe", { event: "session", id: $user.id });

        $socketV2.off("session"); // Ensure no duplicate listeners

        $socketV2.on("session", (updatedSession) => {
          $notSeenSessionIds = [...$notSeenSessionIds, updatedSession.id];
          const index = $chatSessions.findIndex(
            (session) => session.sessionId === updatedSession.id
          );
          if (index === -1) {
            $chatSessions = [updatedSession, ...$chatSessions];
          } else {
            const mergedSession = {
              ...$chatSessions[index],
              ...updatedSession,
            };
            $chatSessions.splice(index, 1, mergedSession);
            $chatSessions.sort((a, b) => {
              const aDate = new Date(a.updatedAt);
              const bDate = new Date(b.updatedAt);
              return bDate - aDate;
            });
            $chatSessions = $chatSessions;
          }
        });
      } else {
        console.error("Socket emit function is not available:", $socketV2);
      }
    } else {
      console.error("Socket is not connected or not available:", sock);
    }
  });

  const toggle = () => {
    $showOnlyUnreadChat = $showOnlyUnreadChat === 1 ? 0 : 1;
  };

  const fetchChatSessionsMount = async () => {
    const url = isClient($user)
      ? serverlessRoutes.FETCH_CLIENT_SESSION
      : `${serverlessRoutes.SESSION}?take=15&skip=0&unread=${$showOnlyUnreadChat}`;
    try {
      isLoadingMore.set(true);
      const data = await getWithJwt(url);
      if (isClient($user)) {
        $clientChatSessionId = data.data.sessionId;
      } else {
        $chatSessions = data.data.chatSessions;
        if ($chatSessionsCount === 0) {
          $chatSessionsCount = data.data.chatSessionsCount;
        }
        $notSeenSessionIds = data.data.notSeenSessionIds;
      }
      $isLoadingMore = false;
      $unreadMessages = data.data.notSeenCount;
    } catch (err) {
      isLoadingMore.set(false);
    }
  };

  onMount(() => {
    fetchChatSessionsMount();
  });

  onDestroy(() => {
    if ($socketV2 && typeof $socketV2.emit === "function") {
      $socketV2.emit("unsubscribe", { event: "session", id: $user.id });
    } else {
      console.error("Socket emit function is not available:", $socketV2);
    }
    if ($socketV2 && typeof $socketV2.off === "function") {
      $socketV2.off("session");
    } else {
      console.error("Socket off function is not available:", $socketV2);
    }
    unsubscribeSocket();
    $isLoadingMore = false;
  });
</script>

<div
  class={`bg-white dark:bg-zinc-800 text-slate-900 dark:text-slate-100 ${Capacitor.isNativePlatform() ? "chat-container" : ""}`}
  bind:this={scrollElem}
>
  {#if Capacitor.getPlatform() === "ios"}
    <div class="mt-20" />
  {/if}
  <div class="flex flex-row p-3 ml-1">
    <div on:click={() => toggle()}>
      <CheckboxComponent value={$showOnlyUnreadChat === 1 ? true : false} />
    </div>
    <span class="ml-2">
      {translate("SHOW_ONLY_UNREAD")}
    </span>
  </div>
  {#if isLoading}
    <div class="flex-col center-center">
      <ChatSessionSkeleton />
      <ChatSessionSkeleton />
      <ChatSessionSkeleton />
      <ChatSessionSkeleton />
      <ChatSessionSkeleton />
      <ChatSessionSkeleton />
      <ChatSessionSkeleton />
      <ChatSessionSkeleton />
      <ChatSessionSkeleton />
      <ChatSessionSkeleton />
      <ChatSessionSkeleton />
    </div>
  {:else}
    <div class="flex-col center-center">
      {#each $chatSessions as session}
        <ChatSession {session} />
      {/each}
    </div>
    <!-- {#if $chatSessions.length < $chatSessionsCount}
      <LoadMoreButton fetchMoreData={Mount} />
      <div class="m-3"></div>
    {/if} -->
    <InfiniteScroll
      hasMore={$chatSessions.length < $chatSessionsCount}
      on:loadMore={fetchChatSessions}
    />
  {/if}
</div>

<style>
  .chat-container {
    min-height: 100%;
    margin-top: 48px;
  }
</style>
