<script lang="ts">
  import WorkoutExpansionPanel from "../../components/UI/WorkoutExpansionPanel.svelte";
  import TopMenu from "../../components/TopMenu.svelte";
  import { deleteWithJwt, getWithJwt, postWithJwt } from "../../lib/requests";
  import { api, dialogTypes, serverlessRoutes } from "../../lib/constants";
  import { onDestroy, onMount } from "svelte";
  import { user } from "../../stores/userStore";
  import { trainerWorkouts } from "../../stores/trainerStores";
  import { ButtonComponent, InfiniteScroll, MiniSpinner, Search } from "ui";
  import { dialogData } from "../../stores/dialogDataStore";
  import { translate } from "../../lib/translate";
  import Filter from "../../components/Filter.svelte";
  import { trainerWorkoutsCount } from "../../stores/trainerWorkoutsCount";
  import { selectedWorkoutIds } from "../../stores/idSelectorStore";
  import SelectMany from "../../components/Training/SelectMany.svelte";
  import { filtersStore2, showAlert, workoutsStore } from "stores";
  import { Capacitor } from "@capacitor/core";
  import { isLoadingMore } from "../../stores/isLoadingMoreStore";
  import WorkoutsToolbarComponent from "../../components/Training/WorkoutsToolbarComponent.svelte";

  // let isFetching = false;
  // let searchValue = "";
  // let timeout: NodeJS.Timeout;
  // let page = 0;
  let isSelectMode = false;

  // $: hasMore = $trainerWorkouts.length < $trainerWorkoutsCount;

  // const createUrl = (): string => {
  //   let template = "";
  //   let sort = "";
  //   let name = "";
  //   let muscleGroup = "";

  //   if ($filtersStore2.template === 1) {
  //     template = "&default=1&template=1";
  //   } else {
  //     template = "&default=1&template=0";
  //   }

  //   if ($filtersStore2.sort !== undefined) {
  //     if ($filtersStore2.sort === "NAME_ASC") {
  //       sort = "&orderBy=name&order=ASC";
  //     } else if ($filtersStore2.sort === "NAME_DESC") {
  //       sort = "&orderBy=name&order=DESC";
  //     } else if ($filtersStore2.sort === "DATE_ASC") {
  //       sort = "&orderBy=id&order=ASC";
  //     } else {
  //       sort = "&orderBy=id&order=DESC";
  //     }
  //   }

  //   if (searchValue) {
  //     name = `&name=${searchValue}`;
  //   }

  //   if ($filtersStore2.tags.length) {
  //     muscleGroup = `&muscleGroup=%5B${$filtersStore2.tags
  //       .map((filter): string => `%22${filter}%22`)
  //       .join(",")}%5D`;
  //   }

  //   return `${serverlessRoutes.WORKOUT}/list?take=15&skip=${
  //     page * 15
  //   }${template}${name}${muscleGroup}${sort}`;
  // };

  // const onFetchData = async (): Promise<void> => {
  //   if (!$user || isFetching) {
  //     return;
  //   }

  //   isFetching = true;
  //   page = 0;

  //   try {
  //     const response = await getWithJwt(createUrl());
  //     $trainerWorkouts = response.data.data;
  //     $trainerWorkoutsCount = response.data.count;
  //     isFetching = false;

  //     localStorage.setItem(
  //       "trainerWorkoutsCache",
  //       JSON.stringify({
  //         workouts: $trainerWorkouts,
  //         count: $trainerWorkoutsCount,
  //       })
  //     );
  //   } catch (error) {
  //     console.error(error);
  //     isFetching = false;
  //   }
  // };

  // const onLoadMore = async (): Promise<void> => {
  //   if (!$user) {
  //     return;
  //   }

  //   $isLoadingMore = true;
  //   page += 1;

  //   try {
  //     const response = await getWithJwt(createUrl());
  //     $trainerWorkouts = [...$trainerWorkouts, ...response.data.data];
  //   } catch (error) {
  //     $isLoadingMore = false;
  //     console.error(error);
  //   }

  //   $isLoadingMore = false;
  // };

  const onSelectMany = (event: CustomEvent<string>): void => {
    const selectValue = event.detail;

    if (selectValue === "DELETE") {
      $dialogData.data = {
        title: "više vežbi",
        async executeFunction(): Promise<void> {
          try {
            await deleteWithJwt(serverlessRoutes.WORKOUT, {
              ids: $selectedWorkoutIds,
            });
            $trainerWorkouts = $trainerWorkouts.filter(
              ({ id }) => !$selectedWorkoutIds.includes(id)
            );
            $trainerWorkoutsCount -= $selectedWorkoutIds.length;
            $selectedWorkoutIds = [];

            $showAlert.color = "black";
            $showAlert.message = `Obrisano više vežbi.`;
          } catch (error) {
            console.error(error);
            $showAlert.color = "red-400";
            $showAlert.message = `Greška pri brisanju.`;
          }
        },
      };
      $dialogData.type = dialogTypes.CONFIRM_DELETE;
    } else if (selectValue === "SAVE") {
      // $dialogData.data = {};
      // $dialogData.type = dialogTypes.SELECT_MUSCLE_GROUP;
      // open create training plan dialog
    }
  };

  // const onSearch = (): void => {
  //   clearTimeout(timeout);
  //   timeout = setTimeout(async (): Promise<void> => {
  //     onFetchData();
  //   }, 1000);
  // };

  const onEditMode = (): void => {
    $selectedWorkoutIds = [];
    isSelectMode = !isSelectMode;
  };

  // const onAddWorkout = (): void => {
  //   $dialogData.data = {};
  //   $dialogData.type = dialogTypes.CREATE_EDIT_WORKOUT;
  // };

  const unsubscribeUserStore = user.subscribe(workoutsStore.fetchData);
  // const unsubscribe = user.subscribe((res) => onFetchData());

  onDestroy(() => {
    unsubscribeUserStore();
    $isLoadingMore = false;
  });

  onMount(() => {
    workoutsStore.loadCache();
    // const trainerWorkoutsCache = localStorage.getItem("trainerWorkoutsCache");
    // if (trainerWorkoutsCache) {
    //   const { workouts, count } = JSON.parse(trainerWorkoutsCache);
    //   $trainerWorkouts = workouts;
    //   $trainerWorkoutsCount = count;
    //   isFetching = false;
    // }
  });
</script>

<svelte:head>
  {#if Capacitor.isNativePlatform()}
    <style>
      body {
        overflow: hidden;
      }
    </style>
  {/if}
</svelte:head>

<TopMenu entity="TRAINING"/>

<div class="p-4 flex flex-col gap-4">
  <!-- <div class="flex gap-4">
    <Search
      placeholder={translate("SEARCH_WORKOUTS")}
      bind:value={searchValue}
      on:input={onSearch}
    />
    <ButtonComponent myClasses="whitespace-nowrap" on:click={onAddWorkout}>
      {translate("ADD")}
      {translate("WORKOUT").toLowerCase()}
    </ButtonComponent>
  </div>

  <Filter
    type="WORKOUTS"
    isCountVisible
    isModifyVisible={false}
    on:editMode={onEditMode}
    on:fetchData={onFetchData}
  /> -->
  <WorkoutsToolbarComponent isAddVisible/>

  <!-- <div class="flex flex-col gap-4" class:mb-48={isSelectMode}> -->
    {#each $workoutsStore.items as workout}
      <WorkoutExpansionPanel {workout} {isSelectMode} />
    {/each}

    <!-- {#if $isLoadingMore}
      <div class="flex justify-center">
        <MiniSpinner />
      </div>
    {/if} -->

    <InfiniteScroll
      hasMore={$workoutsStore.hasMore}
      isFetchingMore={$workoutsStore.isFetchingMore}
      on:loadMore={workoutsStore.fetchMoreData} />
  <!-- </div> -->

  {#if isSelectMode}
    <SelectMany
      on:selectMany={onSelectMany}
      count={$selectedWorkoutIds.length}
      items={[
        { name: "Sačuvaj u novi program", value: "SAVE" },
        { name: "Obriši", value: "DELETE" },
      ]}
    />
  {/if}

  {#if Capacitor.isNativePlatform()}
    <div class="mb-12"></div>
  {/if}
</div>
