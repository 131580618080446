<script lang="ts">
  import GalleryScreen from "../../screens/Progress/GalleryScreen.svelte";
  import MeasurementsScreen from "../../screens/Progress/MeasurementsScreen.svelte";
  import HabitsScreen from "../../screens/Progress/HabitsScreen.svelte";
  import Timeline from "../../components/Progress/Timeline.svelte";
  import ProgressMenu from "./ProgressMenu.svelte";
  import Compliance from "./Compliance.svelte";
  import { replace } from "svelte-spa-router";
  import { currentClient } from "stores";
  import { onMount, type ComponentType } from "svelte";

  let view: ComponentType = MeasurementsScreen;

  const navLinks = [
    {
      icon: "camera",
      name: "GALLERY",
      isActive: false,
      async onView(): Promise<void> {
        resetActive();
        navLinks[0].isActive = true;
        await replace(`/profile/${$currentClient.id}?view=progress&type=gallery`);
        view = GalleryScreen;
      },
    },
    {
      name: "MEASUREMENTS",
      icon: "ruler",
      isActive: true,
      async onView(): Promise<void> {
        resetActive();
        navLinks[1].isActive = true;
        await replace(`/profile/${$currentClient.id}?view=progress&type=measurements`);
        view = MeasurementsScreen;
      },
    },
    {
      icon: "line-segments",
      name: "HABITS",
      isActive: false,
      async onView(): Promise<void> {
        resetActive();
        navLinks[2].isActive = true;
        await replace(`/profile/${$currentClient.id}?view=progress&type=habits`);
        view = HabitsScreen;
      },
    },
    {
      icon: "lists-check",
      name: "COMPLIANCE",
      isActive: false,
      async onView(): Promise<void> {
        resetActive();
        navLinks[3].isActive = true;
        await replace(`/profile/${$currentClient.id}?view=progress&type=compliance`);
        view = Compliance;
      },
    },
    {
      icon: "line-segments",
      name: "TIMELINE",
      isActive: false,
      async onView(): Promise<void> {
        resetActive();
        navLinks[4].isActive = true;
        await replace(`/profile/${$currentClient.id}?view=progress&type=timeline`);
        view = Timeline;
      },
    },
  ];

  const resetActive = (): void => {
    navLinks.forEach((navLink): void => {
      navLink.isActive = false;
    });
  };

  onMount(async () => {
    const {href} = window.location;

    if (href.includes("type")) {
      const type = href.split("&")[1].split("=")[1];

      if (type === "gallery") {
        await navLinks[0].onView();
      } else if (type === "measurements") {
        await navLinks[1].onView();
      } else if (type === "habits") {
        await navLinks[2].onView();
      } else if (type === "compliance") {
        await navLinks[3].onView();
      } else if (type === "timeline") {
        await navLinks[4].onView();
      }
    } else {
      await replace(`/profile/${$currentClient.id}?view=progress&type=measurements`);
    }
  });
</script>

<ProgressMenu {navLinks} />
<div class="mb-4" />
<svelte:component this={view} />
