<script>
  import { api, entities, getWithJwt, translate } from "lib";
  import TopMenu from "../../components/TopMenu.svelte";
  import { onMount } from "svelte";
  import ExpansionPanel from "../../components/UI/ExpansionPanel.svelte";
  import ClientListItem from "../../components/ClientListItem.svelte";

  let data = [];
  let selectDayData = [];

  const fetchData = async () => {
    try {
      const res = await getWithJwt(
        `${api}/trainer/client/overview/customReminderDate`
      );
      const today = new Date();
      today.setDate(today.getDate() - 1);
      res.forEach((r) => {
        r.dayCount = Math.floor(
          (new Date(r.date) - today) / (1000 * 60 * 60 * 24)
        );
      });
      data = res;
      data = data.sort((a, b) => a.dayCount - b.dayCount);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchForDay = async (count) => {
    try {
      const res = await getWithJwt(
        `${api}/trainer/client/overview/customReminderDate/${count}?take=100&skip=0`
      );
      selectDayData = res;
    } catch (err) {
      console.error(err);
    }
  };

  onMount(() => {
    fetchData();
  });
</script>

<TopMenu entity={entities.NOTIFICATIONS} />
<div class="gap-4">
  {#each data as reminder}
    <div
      class="m-4"
      tabindex="0"
      role="button"
      on:click={() => fetchForDay(reminder.dayCount)}
      on:keypress={() => fetchForDay(reminder.dayCount)}
    >
      <ExpansionPanel
        title={`${translate("EXPIRING")} ${+reminder.dayCount === 0 ? translate("TODAY").toLowerCase() : +reminder.dayCount === 1 ? translate("TOMORROW").toLowerCase() : `${translate("IN")}: ${reminder.dayCount} ${translate("DAYS").toLowerCase()}`}`}
        subtitle={`${translate("ITEM_COUNT")}: ${reminder.count}`}
      >
        {#each selectDayData as item}
          <ClientListItem client={item} />
        {/each}
      </ExpansionPanel>
    </div>
  {/each}
</div>
