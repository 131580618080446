<script lang="ts">
  import { onDestroy, onMount } from "svelte";
  import { slide } from "svelte/transition";
  import { Radio } from "flowbite-svelte";
  import { dialogTypes, translate } from "lib";
  import { dialogData, tags as tagStore } from "stores";
  import { workoutsStore } from "stores";
  import {ButtonComponent, CheckboxComponent, Search} from "ui";
  import FilterTag from "../FilterTag.svelte";

  let isExpanded = false;
  let isAddVisible = false;
  let tags: Array<string> = [];
  let templateGroup: "ALL" | "MY" | undefined = "ALL";
  let sortGroup: string | undefined;

  const templateTags = ["ALL", "MY"];
  const sortTags = ["NAME_ASC", "NAME_DESC", "DATE_ASC", "DATE_DESC"];

  const unsubscribeTagStore = tagStore.subscribe((store): void => {
    tags = store.map(({note}): string => note);
  });

  const fetchExerciseTags = async () => {
    try {
      tags = [
        "Trbuh",
        "Biceps",
        "Listovi",
        "Grudi",
        "Podlaktica",
        "Zadnjica",
        "Zadnja loža",
        "Donja leđa",
        "Prednja loža",
        "Ramena",
        "Triceps",
        "Gornja leđa",
      ];
    } catch (error) {
      console.error(error);
    }
  };

  const onAddWorkout = (): void => {
    $dialogData.data = {};
    $dialogData.type = dialogTypes.CREATE_EDIT_WORKOUT;
  };

  const onRemoveTemplateTag = async (): Promise<void> => {
    $workoutsStore.filter.template = undefined;
    templateGroup = undefined;
    await workoutsStore.fetchData();
  };

  const onRemoveSortTag = async (): Promise<void> => {
    $workoutsStore.filter.sort = undefined;
    sortGroup = undefined;
    await workoutsStore.fetchData();
  };

  const onRemoveTag = async (toRemove: string): Promise<void> => {
    $workoutsStore.filter.tags = $workoutsStore.filter.tags
      .filter((tag): boolean => tag !== toRemove);

    await workoutsStore.fetchData();
  };

  const onTemplateTagChange = async (): Promise<void> => {
    const {filter} = $workoutsStore;

    if (templateGroup === "ALL") {
      filter.template = 1;
    } else {
      filter.template = 0;
    }

    await workoutsStore.fetchData();
  };

  const onSortTagChange = async (): Promise<void> => {
    $workoutsStore.filter.sort = sortGroup;
    await workoutsStore.fetchData();
  };

  const onTagChange = (checkedTag: string): void => {
    if ($workoutsStore.filter.tags.includes(checkedTag)) {
      $workoutsStore.filter.tags = $workoutsStore.filter.tags.filter(
        (tag) => tag !== checkedTag
      );
    } else {
      $workoutsStore.filter.tags = [checkedTag, ...$workoutsStore.filter.tags];
    }
  };

  onDestroy(unsubscribeTagStore);
  onMount(fetchExerciseTags);

  export {isAddVisible};
</script>

<div class="flex flex-col gap-4">
  <div class="flex gap-4">
    <Search
      cy="search-workouts-input"
      placeholder={translate("SEARCH_WORKOUTS")}
      bind:value={$workoutsStore.filter.search}
      on:input={workoutsStore.search}
    />
    {#if isAddVisible}
      <ButtonComponent cy="add-workout-button" myClasses="whitespace-nowrap" on:click={onAddWorkout}>
        {translate("ADD")}
        {translate("WORKOUT").toLowerCase()}
      </ButtonComponent>
    {/if}
  </div>

  <div class="flex items-start gap-4">
    <div class="flex flex-wrap grow gap-2">
      {#if $workoutsStore.filter.template !== undefined}
        <FilterTag
          on:removeFilter={onRemoveTemplateTag}
          title={translate($workoutsStore.filter.template === 0 ? "MY" : "ALL")}
        />
      {/if}
      {#if $workoutsStore.filter.sort !== undefined}
        <FilterTag
          on:removeFilter={onRemoveSortTag}
          title={translate($workoutsStore.filter.sort)}
        />
      {/if}
      {#each $workoutsStore.filter.tags as filter}
        <FilterTag on:removeFilter={() => onRemoveTag(filter)} title={filter} />
      {/each}
    </div>

    <ButtonComponent isOutline on:click={() => (isExpanded = !isExpanded)}>
      {translate("FILTER")}
    </ButtonComponent>
  </div>

  {#if isExpanded}
    <div
      class="flex flex-col border border-slate-200 dark:border-zinc-600 rounded-md"
      in:slide
      out:slide
    >

      <div class="flex">
        <div class="flex flex-col basis-1/2">
          <div class="p-2 flex flex-col gap-2 border-b">
            {#each sortTags as sortTag}
              <Radio
                class="text-xs"
                value={sortTag}
                bind:group={sortGroup}
                on:change={onSortTagChange}
              >
                {translate(sortTag)}
              </Radio>
            {/each}
          </div>

          <div class="p-2 flex flex-col gap-2">
            {#each templateTags as templateTag}
              <Radio
                class="text-xs"
                value={templateTag}
                bind:group={templateGroup}
                on:change={onTemplateTagChange}
              >
                {translate(templateTag)}
              </Radio>
            {/each}
          </div>
        </div>

        <div
          class="p-2 flex flex-col basis-1/2 gap-2 border-l border-slate-200 dark:border-zinc-600"
        >
          {#each tags as tag}
            <div class="flex flex-row">
              <div
                on:click={() => onTagChange(tag)}
                on:keypress={() => onTagChange(tag)}
                tabindex="0"
                role="button"
              >
                <CheckboxComponent value={$workoutsStore.filter.tags.includes(tag)} />
              </div>
              <span class="text-xs ml-1 dark:text-slate-300">{tag}</span>
            </div>
          {/each}
        </div>
      </div>
    </div>
  {/if}

  <div class="text-center text-xs">
    {translate("TOTAL_WORKOUT_COUNT")}:
    <span class="font-bold">{$workoutsStore.count}</span>
  </div>
</div>
