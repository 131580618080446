<script lang="ts">
  import { fade } from "svelte/transition";
  import { Svg } from "ui";

  let cy = "";
  let value: string;
  let placeholder: string;
  let inputElement: HTMLInputElement;

  const onClear = (): void => {
    inputElement.value = "";
    inputElement.dispatchEvent(new Event("input"));
  };

  export { cy, value, placeholder };
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-no-static-element-interactions -->
<div class="w-full relative flex items-center">
  <input
    data-cy={cy}
    class="
      w-full
      py-2
      px-4
      pr-8
      border-0
      rounded-md
      box-border
      text-black
      bg-white
      ring-1
      ring-inset
      ring-slate-200
      focus:ring-2
      focus:ring-inset
      focus:ring-primary-500
      placeholder:text-xs
      placeholder:text-gray-400
      dark:text-white
      dark:ring-slate-500
      dark:focus:ring-primary-500
      dark:bg-zinc-700
    "
    type="text"
    {placeholder}
    bind:this={inputElement}
    bind:value
    on:input
  />
  {#if value}
    <div
      in:fade
      out:fade
      class="absolute top-[50%] right-2 translate-y-[-50%]"
      on:click={onClear}
    >
      <Svg name="close" size={16} />
    </div>
  {/if}
</div>
