<script lang="ts">
  import { onMount } from "svelte";
  import { slide } from "svelte/transition";
  import { api, postFormDataWithJwt, translate } from "lib";
  import {
    dialogData,
    showAlert,
    trainerPrograms,
    trainerProgramsCount,
  } from "stores";
  import { trainerClientPrograms } from "../../../stores/trainerStores";
  import Textarea from "../../UI/Textarea.svelte";
  import Input from "../../UI/Input.svelte";
  import MiniSpinner from "../../UI/MiniSpinner.svelte";
  import {
    ButtonComponent,
    CheckboxComponent,
    InputField,
    TextareaField,
  } from "ui";

  let data: any;

  let imageElem: HTMLImageElement;
  let imageInputElem: HTMLInputElement;

  const form = {
    name: { value: "", error: "" },
    image: { value: "", error: "" },
    description: { value: "", error: "" },
    duration: { value: null, error: "" },
    autoWorkoutDisplaying: { value: false, error: "" },
    numberOfWorkouts: { value: 1, error: "" },
  };

  let imageUrl: string;
  let isImageSelected = false;
  let disabled = true;
  let isLoading = false;

  const onSetImage = (): void => {
    if (imageInputElem.files && imageInputElem.files[0]) {
      const reader = new FileReader();

      reader.onload = (event): void => {
        imageElem.src = event.target?.result as string;
      };

      reader.readAsDataURL(imageInputElem.files[0]);

      isImageSelected = true;
    }
  };

  const onInput = (field: keyof typeof form): void => {
    if (field === "name") {
      const { length } = form[field].value;

      if (!length) {
        form[field].error = translate("FIELD_REQUIRED");
      } else if (length < 2) {
        form[field].error = translate("FIELD_MINIMUM_2");
      } else if (length > 320) {
        form[field].error = translate("FIELD_MAXIMUM_320");
      } else {
        form[field].error = "";
      }
    }

    if (field === "description") {
      const { length } = form[field].value;

      if (length > 5000) {
        form[field].error = translate("FIELD_MAXIMUM_5000");
      } else {
        form[field].error = "";
      }
    }

    if (field === "duration") {
      const { value } = form[field];

      if (value < 1) {
        form[field].error = translate("FIELD_MINIMUM_AMOUNT_1");
      } else if (value > 5000) {
        form[field].error = translate("FIELD_MAXIMUM_AMOUNT_5000");
      } else {
        form[field].error = "";
      }
    }

    if (field === "autoWorkoutDisplaying") {
      if (!form[field].value) {
        form.numberOfWorkouts.error = "";
      } else {
        if (form.numberOfWorkouts.value < 1) {
          form.numberOfWorkouts.error = translate("FIELD_MINIMUM_AMOUNT_1");
        } else if (form.numberOfWorkouts.value > 200) {
          form.numberOfWorkouts.error = translate("FIELD_MAXIMUM_AMOUNT_200");
        } else {
          form.numberOfWorkouts.error = "";
        }
      }
    }

    if (field === "numberOfWorkouts") {
      if (!form.autoWorkoutDisplaying.value) {
        form[field].error = "";
      } else {
        if (form[field].value < 1) {
          form[field].error = translate("FIELD_MINIMUM_AMOUNT_1");
        } else if (form[field].value > 200) {
          form[field].error = translate("FIELD_MAXIMUM_AMOUNT_200");
        } else {
          form[field].error = "";
        }
      }
    }

    disabled = Object.values(form).some(({ error }) => error !== "");
  };

  const onSubmit = async (): Promise<void> => {
    isLoading = true;

    try {
      const formData = new FormData();

      if (data.program) {
        // edit
        formData.append(
          "workoutDisplayOptions",
          JSON.stringify(form.autoWorkoutDisplaying.value)
        );

        formData.append(
          "program",
          JSON.stringify({
            name: form.name.value,
            description: form.description.value,
            visibleWorkouts: +form.numberOfWorkouts.value,
            duration: form.duration.value,
          })
        );

        const response = await postFormDataWithJwt(
          `${api}/program/${data.program.id}`,
          formData,
          "PUT"
        );

        const _program = $trainerPrograms.find(
          (_program): boolean => _program.id === data.program.id
        );
        const _program2 = $trainerClientPrograms.find(
          (_program): boolean => _program.id === data.program.id
        );

        if (_program) {
          const index = $trainerPrograms.indexOf(_program);

          $trainerPrograms.splice(index, 1, response);
          $trainerPrograms = $trainerPrograms;
        } else if (_program2) {
          const index = $trainerClientPrograms.indexOf(_program2);
          $trainerClientPrograms.splice(index, 1, response);
          $trainerClientPrograms = $trainerClientPrograms;
        }
      } else {
        // create
        formData.append(
          "program",
          JSON.stringify({
            name: form.name.value,
            description: form.description.value,
            visibleWorkouts: +form.numberOfWorkouts.value,
            duration: form.duration.value,
          })
        );

        formData.append(
          "workoutDisplayOptions",
          JSON.stringify(form.autoWorkoutDisplaying.value)
        );

        if (data.clientId) {
          formData.append("clientId", data.clientId);
        } else {
          formData.append("ownerType", "USER");
        }

        if (imageInputElem.files) {
          const file = imageInputElem.files[0];

          if (file) {
            formData.append("thumbnail", file, "png");
          }
        }

        const response = await postFormDataWithJwt(`${api}/program`, formData);

        if (data.clientId) {
          $trainerClientPrograms = [response, ...$trainerClientPrograms];
        } else {
          $trainerPrograms = [response, ...$trainerPrograms];
          $trainerProgramsCount += 1;
        }
      }

      $showAlert.color = "black";

      if (data.program) {
        $showAlert.message = `
          ${translate("SUCCESSFULLY_EDITED")}
          ${translate("TRAINING_PROGRAM").toLowerCase()}
        `;
      } else {
        $showAlert.message = `
          ${translate("SUCCESSFULLY_CREATED")}
          ${translate("TRAINING_PROGRAM").toLowerCase()}
        `;
      }

      $dialogData.type = "";
      $dialogData.data = {};
    } catch (error) {
      console.error(error);
      isLoading = false;
      $showAlert.color = "red-400";

      if (data.program) {
        $showAlert.message = translate("ERROR_EDITING_TRAINING_PROGRAM");
      } else {
        $showAlert.message = translate("ERROR_CREATING_TRAINING_PROGRAM");
      }
    }
  };

  onMount((): void => {
    if (data.clientId) {
      form;
    }
    if (data.program) {
      const {
        name,
        description,
        duration,
        workoutDisplayOptions,
        visibleWorkouts,
        thumbnailUrl,
      } = data.program;

      if (name) {
        form.name.value = name;
        onInput("name");
      }

      if (description) {
        form.description.value = description;
        onInput("description");
      }

      if (duration) {
        form.duration.value = duration;
        onInput("duration");
      }

      if (workoutDisplayOptions) {
        form.autoWorkoutDisplaying.value = workoutDisplayOptions;
        onInput("autoWorkoutDisplaying");
      }

      if (visibleWorkouts !== null || visibleWorkouts !== undefined) {
        form.numberOfWorkouts.value = visibleWorkouts;
        onInput("numberOfWorkouts");
      }

      if (thumbnailUrl) {
        imageUrl = thumbnailUrl;
      }
    }
  });

  export { data };
</script>

<div class="p-4 flex flex-col gap-4">
  <h3 class="text-center text-lg font-semibold">
    {data.program ? translate("EDIT") : translate("CREATE")}
    {translate("PROGRAM").toLowerCase()}
  </h3>

  <div class="flex flex-col gap-4">
    <InputField
      label={translate("NAME")}
      error={form.name.error}
      bind:value={form.name.value}
      on:input={() => onInput("name")}
    />

    <!-- <div>
      <p class="mb-1">{translate("NAME")}:</p>
      <Input
        name="name"
        placeholder={translate("NAME")}
        bind:value={form.name.value}
        onInput={() => onInput("name")}
      />
      {#if form.name.error}
        <span class="text-red-500 text-xs">{form.name.error}</span>
      {/if}
    </div> -->

    <div class="flex flex-col items-center justify-center">
      <img
        alt="Training Program"
        src={imageUrl ? imageUrl : "logo.png"}
        bind:this={imageElem}
      />

      <input
        class="hidden"
        type="file"
        accept=".png"
        bind:value={form.image.value}
        bind:this={imageInputElem}
        on:change={onSetImage}
      />

      <p class="leading-4 text-center text-gray-400 text-xs mt-2">
        {#if !data.executeFunction && !isImageSelected}
          {translate("DEFAULT_IMAGE_LOADED_AUTOMATICALLY")}<br />
        {/if}
        {translate("CLICK_ON_IMAGE_TO_CHANGE")}
      </p>
    </div>

    <TextareaField
      label={translate("DESCRIPTION")}
      error={form.description.error}
      bind:value={form.description.value}
      on:input={() => onInput("description")}
    />

    <!-- <div>
      <p class="mb-1">{translate("DESCRIPTION")}:</p>
      <Textarea
        placeholder={translate("DESCRIPTION")}
        bind:value={form.description.value}
        on:input={() => onInput("description")}
      />
      {#if form.description.error}
        <span class="text-red-500 text-xs">{form.description.error}</span>
      {/if}
    </div> -->

    <InputField
      label={translate("DURATION_IN_DAYS")}
      error={form.duration.error}
      bind:value={form.duration.value}
      onInput={() => onInput("duration")}
    />

    <!-- <div>
      <p class="mb-1">{translate("DURATION_IN_DAYS")}:</p>
      <Input
        id="duration"
        placeholder={translate("DURATION_IN_DAYS")}
        bind:value={form.duration.value}
        onInput={() => onInput("duration")}
      />
      {#if form.duration.error}
        <span class="text-red-500 text-xs">{form.duration.error}</span>
      {/if}
    </div> -->

    <!-- <Checkbox
      bind:checked={form.autoWorkoutDisplaying.value}
      on:change={() => onInput("autoWorkoutDisplaying")}
    >
      Automatsko prikazivanje treninga
    </Checkbox> -->
    <div
      class="flex flex-row"
      role="checkbox"
      aria-checked={form.autoWorkoutDisplaying.value}
      tabindex="0"
      on:click={() =>
        (form.autoWorkoutDisplaying.value = !form.autoWorkoutDisplaying.value)}
      on:keydown={() =>
        (form.autoWorkoutDisplaying.value = !form.autoWorkoutDisplaying.value)}
    >
      <CheckboxComponent value={form.autoWorkoutDisplaying.value} />
      <span class="ml-2"> Automatsko prikazivanje treninga </span>
    </div>
    {#if form.autoWorkoutDisplaying.value}
      <div in:slide>
        <p class="mb-1">Broj treninga:</p>
        <Input
          id="numberOfWorkouts"
          placeholder="Broj treninga"
          bind:value={form.numberOfWorkouts.value}
          onInput={() => onInput("numberOfWorkouts")}
        />
        {#if form.numberOfWorkouts.error}
          <span class="text-red-500 text-xs">{form.numberOfWorkouts.error}</span
          >
        {/if}
      </div>
    {/if}

    <p class="leading-4 text-justify text-gray-400 text-xs">
      <strong class="font-semibold text-black dark:text-slate-100">
        *{translate("NOTE")}:
      </strong>
      Uključivanjem opcije automatskog prikazivanja treninga, klijentu će biti vidljiva
      onoliko treninga koliko ste uneli u polje 'Broj treninga' (na primer za unos
      3, prva 3 treninga iz plana treninga koji si napravio/la) na nivou dana. Klijent
      automatski dobija (postaje mu vidljiv) sledeći trening sledećeg dana u 00:00.
      Na trenerovoj strani može biti definisan proizvoljan broj treninga. Unošenjem
      trajanja, brojač unetih dana se pokreće odmah nakon dodele plana nekom klijentu.
      Jedino obavezno polje je naziv plana treninga.
    </p>
  </div>

  <div class="h-10 flex justify-center">
    {#if isLoading}
      <MiniSpinner />
    {:else}
      <ButtonComponent
        cy="create-edit-program-button"
        {disabled}
        on:click={onSubmit}
      >
        {data.program ? translate("EDIT") : translate("CREATE")}
      </ButtonComponent>
    {/if}
  </div>
</div>
