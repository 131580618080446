import { get } from "svelte/store";
import { isClient } from "./roles";
import { serverlessRoutes } from "./constants";
import { getWithJwt } from "./requests";
import {
  chatSessions,
  chatSessionsCount,
  clientChatSessionId,
  notSeenSessionIds,
  unreadMessages,
  showOnlyUnreadChat,
} from "../stores/chatSessionsStore";
import { user } from "stores";
import { isLoadingMore } from "../stores/isLoadingMoreStore";

export const fetchChatSessions = async () => {
  const userRef = get(user);
  const chatSessionsRef = get(chatSessions);
  const showOnlyUnreadChatRef = get(showOnlyUnreadChat);
  const url = isClient(userRef)
    ? serverlessRoutes.FETCH_CLIENT_SESSION
    : `${serverlessRoutes.SESSION}?take=15&skip=${chatSessionsRef.length}&unread=${showOnlyUnreadChatRef}`;
  try {
    isLoadingMore.set(true);
    const data = await getWithJwt(url);
    if (isClient(userRef)) {
      clientChatSessionId.set(data.data.sessionId);
    } else {
      // await Promise.all(
      //   data.data.chatSessions.map(async (session, index) => {
      //     if (session.clientHash) {
      //       const res = await getWithJwt(
      //         `${serverlessRoutes.FETCH_S3_IMAGE_URL}?key=${session.clientHash}`
      //       );
      //       const fetchBlob = fetch(res.url);
      //       const blob = fetchBlob.then((response) => response.blob());
      //       const avatarUrl = blob.then((blob) => URL.createObjectURL(blob));
      //       data.data.chatSessions[index].avatarUrl = await avatarUrl;
      //     }
      //   })
      // );
      const sessionsToAdd = [];
      data.data.chatSessions.forEach((session) => {
        if (!chatSessionsRef.includes(session.id)) {
          sessionsToAdd.push(session);
        }
      });
      chatSessions.set([...chatSessionsRef, ...sessionsToAdd]);
      if (get(chatSessionsCount) === 0) {
        chatSessionsCount.set(data.data.chatSessionsCount);
      }
      notSeenSessionIds.set(data.data.notSeenSessionIds);
    }
    isLoadingMore.set(false);
    unreadMessages.set(data.data.notSeenCount);
  } catch (err) {
    isLoadingMore.set(false);
  }
};

// obrisati load na početku
// load samo na screen
// na početku load samo count
