<script lang="ts">
  import { Chart } from "chart.js/auto";
  import { onMount } from "svelte";
  import { api, serverlessRoutes } from "../../lib/constants";
  import { parseDate } from "../../lib/parseDate";
  import { getWithJwt, postWithJwt } from "../../lib/requests";
  import { translate } from "../../lib/translate";
  import { currentClient, theme } from "stores";
  import DateIncrementer from "./DateIncrementer.svelte";
  // import InputMini from "../UI/InputMini.svelte";
  import MeasurementItem from "./MeasurementItem.svelte";

  export let clientId: number;
  export let date;
  export let measurementId: number;

  let c: HTMLCanvasElement;

  const timelines = {
    WEEK: "WEEK",
    MONTH: "MONTH",
    THREE_MONTHS: "THREE_MONTHS",
  };
  const timelineDays = {
    WEEK: 7,
    MONTH: 30,
    THREE_MONTHS: 90,
  };
  const WEEKLY_LABELS = [
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY",
    "SUNDAY",
  ];
  const MONTH_LABELS = [
    "JANUARY",
    "FEBRUARY",
    "MARCH",
    "APRIL",
    "MAY",
    "JUNE",
    "JULY",
    "AUGUST",
    "SEPTEMBER",
    "OCTOBER",
    "NOVEMBER",
    "DECEMBER",
  ];
  const LABELS = {
    WEEK: WEEKLY_LABELS.map((day) => `${translate(day).slice(0, 3)}.`),
    MONTH: Array.from(Array(30).keys()),
    THREE_MONTHS: [],
  };

  let chart;
  let data: Array<any> = [];
  let timelineSelected = timelines.WEEK;

  const getMonday = (d) => {
    d = new Date(d);
    const day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  };

  let selectedDate: Date = getMonday(new Date(date));

  const dateString = (date: Date) => {
    return date.toISOString().slice(0, 10);
  };

  const parseForThreeMonths = (data) => {
    const result = {};
    LABELS.THREE_MONTHS = [];
    data.forEach((item) => {
      const month = new Date(item.createdAt).getMonth();
      if (!result[month]) {
        LABELS.THREE_MONTHS.push(translate(MONTH_LABELS[month]));
        result[month] = {
          value: item.value,
          createdAt: `MONTH-${month}`,
          count: 1,
        };
      } else {
        result[month].value += item.value;
        result[month].count += 1;
      }
    });
    const parseToArray = Object.values(result);
    parseToArray.forEach(
      (item) => (item.value = (item.value / item.count).toFixed(1))
    );
    return parseToArray;
  };

  const fetchData = async () => {
    const tmpDate = new Date(selectedDate);

    let incrementAmount = 0;

    if (timelineSelected === "WEEK") {
      incrementAmount = 7;
    } else if (timelineSelected === "MONTH") {
      incrementAmount = daysInMonth(tmpDate);
    } else {
      const curMonth = new Date(selectedDate);
      const nextMonth = new Date(curMonth);
      const thirdMonth = new Date(curMonth);

      nextMonth.setMonth(curMonth.getDate());
      thirdMonth.setMonth(curMonth.getDate() + 1);

      incrementAmount =
        daysInMonth(curMonth) +
        daysInMonth(nextMonth) +
        daysInMonth(thirdMonth);
    }

    // nzm zasto al kad stavim -2 radi lepo
    if (timelineSelected === "WEEK") {
      tmpDate.setDate(tmpDate.getDate() - 2 + incrementAmount);
    } else if (timelineSelected === "MONTH") {
      tmpDate.setDate(tmpDate.getDate() - 2 + incrementAmount);
    } else {
      tmpDate.setDate(tmpDate.getDate() - 2 + incrementAmount);
    }

    try {
      const response = await postWithJwt(
        `${serverlessRoutes.MEASUREMENT}/date`,
        {
          dateFrom: dateString(selectedDate),
          dateTo: dateString(tmpDate),
          clientId: $currentClient.id || clientId,
        }
      );

      const foundRes = response.data.measurements.find(
        (item) => item.id === measurementId
      );
      if (foundRes) {
        data = foundRes.measurements;

        if (timelineSelected === timelines.THREE_MONTHS) {
          data = parseForThreeMonths(data);
        }
      } else {
        data = [];
      }
      initChart();
    } catch (err) {}
  };

  const daysInMonth = (date: Date): number => {
    const year = date.getFullYear();
    const month = date.getMonth(); // January is 0, December is 11

    // Array with the number of days in each month
    const daysInMonthArray = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    // Check for leap year for February
    if (month === 1) {
      // February
      if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
        return 29; // Leap year February
      }
    }

    // Return the number of days for the given month
    return daysInMonthArray[month];
  };

  const onSelectWeeklyTimeframe = async (): Promise<void> => {
    timelineSelected = timelines.WEEK;
    selectedDate = getMonday2(selectedDate);
    await fetchData();
  };

  const onSelectMonthlyTimeframe = async (): Promise<void> => {
    timelineSelected = timelines.MONTH;
    selectedDate.setDate(1);
    selectedDate = selectedDate;
    await fetchData();
  };

  const onSelectThreeMonthsTimeframe = async (): Promise<void> => {
    timelineSelected = timelines.THREE_MONTHS;

    const month = selectedDate.getMonth();
    let set: number;

    if (month < 3) {
      // 0, 1, 2
      set = 0;
    } else if (month > 2 && month < 6) {
      // 3, 4, 5
      set = 3;
    } else if (month > 5 && month < 9) {
      // 6, 7, 8
      set = 6;
    } else {
      // 9, 10, 11
      set = 9;
    }

    selectedDate.setMonth(set);
    selectedDate.setDate(1);
    selectedDate = selectedDate;

    await fetchData();
  };

  // TODO
  // measurement item ponekad nije lepo sortiran
  // tri meseca ne povecavaju/smanjuju tacno kolko treba
  const initChart = () => {
    if (chart) {
      chart.destroy();
    }

    const color = $theme === "dark" ? ["#22897B"] : ["#319795"];
    let parsedData: Array<number | undefined> = [];
    let customLabels: Array<string> = [];

    data.forEach((item, i): void => {
      const d = new Date(item.createdAt);
      const dInMonth = daysInMonth(d);

      if (i === 0) {
        if (timelineSelected === "WEEK") {
          parsedData = Array.from({ length: 7 }, () => undefined);
        } else if (timelineSelected === "MONTH") {
          parsedData = Array.from({ length: dInMonth }, () => undefined);
          customLabels = Array.from(Array(dInMonth).keys()).map(
            (it) => `${it + 1}`
          );
        } else {
          const curMonth = selectedDate.getMonth();
          const nextMonth = curMonth + 1;
          const thirdMonth = curMonth + 2;

          parsedData = Array.from({ length: 3 }, () => undefined);
          customLabels = [
            translate(MONTH_LABELS[curMonth]),
            translate(MONTH_LABELS[nextMonth]),
            translate(MONTH_LABELS[thirdMonth]),
          ];
        }
      }

      const day = d.getDay();
      const date = d.getDate();
      const parsedWeekDay = day === 0 ? 6 : day - 1;
      const parsedDate = date === 0 ? dInMonth : date - 1;
      let index = 0;

      if (timelineSelected === "WEEK") {
        index = parsedWeekDay;
      } else if (timelineSelected === "MONTH") {
        index = parsedDate;
      } else {
        index = i;
      }

      parsedData[index] = item.value;
    });

    chart = new Chart(c, {
      type: "line",
      data: {
        labels:
          timelineSelected === "WEEK" ? LABELS[timelineSelected] : customLabels,
        datasets: [
          {
            label: "Trežina",
            data: parsedData,
            backgroundColor: color,
            borderColor: color,
            borderDash: [9, 9],
            borderWidth: 2,
            tension: 0.4,
          },
        ],
      },
      options: {
        spanGaps: true,
        plugins: {
          legend: false,
        },
        responsive: true,
        scales: {
          y: {
            beginAtZero: false,
            grid: {
              display: false,
            },
          },
          x: {
            beginAtZero: false,
            grid: {
              display: false,
            },
          },
        },
      },
    });
  };

  onMount(() => {
    fetchData();
    Chart.defaults.color = $theme === "dark" ? "#fff" : "#000";
  });

  const getMonday2 = (date: Date) => {
    const day = date.getDay();
    const diff = date.getDate() - day + (day == 0 ? -6 : 1);
    return new Date(date.setDate(diff));
  };

  const incrementDate = (n: number): void => {
    if (n === 7 || n === -7) {
      const currentDate = selectedDate.getDate();
      selectedDate.setDate(currentDate + n);
    } else if (n === 30 || n === -30) {
      const month = selectedDate.getMonth();
      selectedDate.setMonth(n === 30 ? month + 1 : month - 1);
      selectedDate.setDate(1);
    } else {
      const month = selectedDate.getMonth();
      selectedDate.setMonth(n === 90 ? month + 3 : month - 3);
      selectedDate.setDate(1);
    }

    selectedDate = selectedDate;

    fetchData();
  };

  const fixDate = (createdAt: string) => {
    const date = new Date(createdAt);
    return parseDate(date.toISOString());
  };

  const onUpdatedItem = (event: CustomEvent<any>): void => {
    const { id, value } = event.detail;
    const i = data.find((d) => d.id === id);
    const index = data.indexOf(i);

    data[index].value = value;

    initChart();
  };

  const onDeletedItem = (event: CustomEvent<{ id: number }>): void => {
    const { id } = event.detail;
    const item = data.find((item): boolean => item.id === id);
    const index = data.indexOf(item);

    data.splice(index, 1);
    data = data;

    initChart();
  };

  const light = "text-primary-500 dark:text-primary-500";
  const dark = "text-slate-900 dark:text-slate-100";
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-no-static-element-interactions -->
<div>
  <DateIncrementer
    {incrementDate}
    skip={timelineDays[timelineSelected]}
    selectedDateDecremented={selectedDate}
  />

  <div class="mt-2 mb-8 flex items-center justify-center gap-4">
    <span
      class={timelineSelected === timelines.WEEK ? light : dark}
      on:click={onSelectWeeklyTimeframe}
    >
      {translate(timelines.WEEK)}
    </span>

    <span
      class={timelineSelected === timelines.MONTH ? light : dark}
      on:click={onSelectMonthlyTimeframe}
    >
      {translate(timelines.MONTH)}
    </span>

    <span
      class={timelineSelected === timelines.THREE_MONTHS ? light : dark}
      on:click={onSelectThreeMonthsTimeframe}
    >
      {translate(timelines.THREE_MONTHS)} ({translate("AVERAGE")})
    </span>
  </div>

  <div class="w-full">
    <canvas bind:this={c} id="chart-{measurementId}" />
  </div>

  <div class="mt-3"></div>

  {#each data as item}
    {#key item}
      <MeasurementItem
        {item}
        on:updatedItem={onUpdatedItem}
        on:deletedItem={onDeletedItem}
      />
    {/key}
  {/each}

  <div class="mt-3"></div>
</div>
