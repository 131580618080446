<script lang="ts">
  import { getWithJwt, parseDate, serverlessRoutes, translate } from "lib";
  import { Badge, ButtonComponent, ExpansionPanel, Input } from "ui";

  let emailLookup = "";
  let lookupResult: any = {};

  const lookupEmail = async (): Promise<void> => {
    try {
      const result = await getWithJwt(
        `${serverlessRoutes.EMAIL_LOOKUP}?email=${emailLookup}`
      );
      lookupResult = result.data;
    } catch (error) {
      console.error(error);
    }
  };
</script>

<ExpansionPanel title="{translate("SEARCH")} email">
  <div class="mb-1">{translate("SEARCH")} email:</div>
  <div class="flex flex-row">
    <Input bind:value={emailLookup}/>
    <ButtonComponent myClasses="ml-2" on:click={lookupEmail}>
      {translate("SEARCH")}
    </ButtonComponent>
  </div>
  {#if lookupResult?.trainerEmail}
    <div class="text-center">
      {#if lookupResult?.trainerEmail.includes("@")}
        <p>Korisnik pripada treneru:</p>
        <p class="font-semibold">{lookupResult?.trainerEmail}</p>
        {#if lookupResult?.lastLogin}
          <div>
            Poslednji put vidjen online: {parseDate(lookupResult?.lastLogin)}
          </div>
        {/if}
        <div class="flex flex-row items-center justify-center mt-3">
          {#if lookupResult?.deletedAt}
            <Badge color="red">Obrisan</Badge>
          {/if}
          {#if lookupResult?.archivedAt}
            <Badge color="gray">Arhiviran</Badge>
          {/if}
          {#if lookupResult?.expireAt && new Date(lookupResult?.expireAt) < new Date()}
            <Badge color="purple">Istekao</Badge>
          {/if}
        </div>
      {:else}
        <p class="font-semibold">{translate(lookupResult?.trainerEmail)}</p>
      {/if}
    </div>
  {/if}
</ExpansionPanel>

