<script lang="ts">
  import { onMount } from "svelte";
  import { dialogTypes, translate } from "lib";
  import { currentClient, dialogData, paymentsStore} from "stores";
  import { ButtonComponent } from "ui";
  import PaymentExpansionPanel from "../PaymentExpansionPanel.svelte";

  const onCreatePayment = (): void => {
    $dialogData.data = {
      client: $currentClient,
    };

    $dialogData.type = dialogTypes.CREATE_EDIT_PAYMENT;
  };

  onMount(paymentsStore.fetchData);
</script>

<div class="flex flex-col gap-4">
  <div class="py-4 flex justify-center">
    <ButtonComponent on:click={onCreatePayment}>
      {translate("ADD_NEW_PAYMENT")}
    </ButtonComponent>
  </div>
  {#each $paymentsStore.items as payment}
    <PaymentExpansionPanel {payment}/>
  {/each}
</div>
