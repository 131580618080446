<script lang="ts">
  export let toggleSeen: Function;
  export let isSeen: boolean;
</script>

<div
  class="flex-col center-center mg-12"
  role="button"
  tabindex="0"
  on:click|stopPropagation|preventDefault={() => toggleSeen()}
  on:keypress|stopPropagation|preventDefault={() => toggleSeen()}
>
  {#if !isSeen}
    <div class="read-button bg-slate-900 dark:bg-zinc-100" />
  {:else}
    <div class="unread-button border-2 border-slate-900 dark:border-zinc-100" />
  {/if}
</div>

<style>
  .read-button {
    height: 16px;
    width: 16px;
    border-radius: 96px;
  }
  .unread-button {
    height: 15px;
    width: 15px;
    border-radius: 96px;
  }
</style>
