<script lang="ts">
  import {Button, P} from "flowbite-svelte";
  import {dialogData} from "stores";
  import {dialogTypes, translate} from "lib";
  import SinglePlan from "../../../components/SinglePlan.svelte";
  import {trainerList} from "../../../stores/trainerStores";
  import { ButtonComponent } from "ui";

  const onAssignMealPlan = (): void => {
    $dialogData.data = {};
    $dialogData.type = dialogTypes.ASSIGN_WORKOUT_PLAN;
  };
</script>

<div class="p-4 flex flex-col gap-4">
  <div class="flex justify-center">
    <ButtonComponent on:click="{onAssignMealPlan}">
      {translate("ASSIGN_WORKOUT_PLAN")}
    </ButtonComponent>
  </div>

  <P align="center">
    {translate("NUMBER_OF_TRAININGS")}:
    <b>{$trainerList.programs.length}</b>
  </P>

  {#each $trainerList.programs as program}
    <SinglePlan {program}/>
  {/each}
</div>
