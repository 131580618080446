<script lang="ts">
  import { location } from "svelte-spa-router";
  import { dialogData, trainerClientGroups } from "stores";
  import { dialogTypes, translate } from "lib";
  import SinglePlan from "../../../components/SinglePlan.svelte";
  import { ButtonComponent } from "ui";

  const groupId = parseInt($location.split("/clients/groups/")[1]);

  const group = $trainerClientGroups.find(
    (group): boolean => group.id === groupId
  );

  const { clientGroupMealPlans } = group;

  const onAssignMealPlan = (): void => {
    $dialogData.data = { group };
    $dialogData.type = dialogTypes.ASSIGN_MEAL_PLAN;
  };
</script>

<div class="p-4 flex flex-col gap-4">
  <div class="flex justify-center">
    <ButtonComponent on:click={onAssignMealPlan}>
      {translate("ASSIGN_MEAL_PLAN")}
    </ButtonComponent>
  </div>

  <p class="text-center">
    {translate("NUMBER_OF_MEAL_PLANS")}:
    <b>{clientGroupMealPlans.length}</b>
  </p>

  {#each clientGroupMealPlans as mealPlan}
    <SinglePlan {mealPlan} />
  {/each}
</div>
