<script lang="ts">
  import { api, getWithJwt, translate } from "lib";
  import { onMount } from "svelte";
  import { currentClient } from "../../stores/currentClient";
  import Badge from "../UI/Badge.svelte";
  import Macros from "../Diet/Macros.svelte";
  import { parseDate } from "../../lib/parseDate";

  let timelines: any[] = [];
  let timelinePage = 0;
  let timelinesCount = 0;

  const createTimelineUrl = (): string => {
    const take = "take=10";
    const skip = `skip=${timelinePage * 10}`;
    const params = `${take}&${skip}`;

    return `${api}/timeline/${$currentClient.id}?${params}`;
  };

  const convertTimelines = (data: any) => {
    return data.map((timeline: any) => {
      const parts = timeline.data.split("{");
      return {
        date: [],
        programs: [],
        mealPlans: [],
        weight: "",
        calories: "",
        carbs: "",
        protein: "",
        fats: "",
      };
      return {
        date: timeline.date,
        programs: parts[1].split(":")[1].split(", ,"),
        mealPlans: parts[2].split(":")[1].split(", ,"),
        weight: parseInt(parts[3].split(":")[1]),
        calories: parseFloat(parts[4].split(":")[1]),
        carbs: parseFloat(parts[5].split(":")[1]),
        protein: parseFloat(parts[6].split(":")[1]),
        fats: parseFloat(parts[7].split(":")[1]),
      };
    });
  };

  const fetchTimelines = async (): Promise<void> => {
    timelinePage = 0;
    const response = await getWithJwt(createTimelineUrl());
    timelines = convertTimelines(response.data);
    timelinesCount = response.count;
  };

  const onLoadMoreTimelines = async (): Promise<void> => {
    timelinePage += 1;
    const response = await getWithJwt(createTimelineUrl());
    timelines = [...timelines, ...convertTimelines(response.data)];
  };

  onMount(() => {
    fetchTimelines();
  });
</script>

<div class="flex flex-col gap-4 text-slate-900 dark:text-slate-100">
  {#each timelines as timeline}
    <div class="p-2 flex flex-col gap-2 border rounded-md">
      <div class="text-emerald-400">{parseDate(timeline.date)}</div>
      <div>
        <p class="font-semibold">{translate("PROGRAMS")}:</p>
        <div class="flex flex-wrap gap-2">
          {#each timeline.programs as program}
            <Badge color="dark" rounded>{program}</Badge>
          {/each}
        </div>
      </div>
      <div>
        <p class="font-semibold">{translate("MEAL_PLANS")}:</p>
        <div class="flex flex-wrap gap-2">
          {#each timeline.mealPlans as mealPlan}
            <Badge color="dark" rounded>{mealPlan}</Badge>
          {/each}
        </div>
      </div>
      <p class="font-semibold">{translate("WEIGHT")}:</p>
      <p class="indent-4">{timeline.weight}</p>
      <Macros
        calories={timeline.calories}
        carbs={timeline.carbs}
        protein={timeline.protein}
        fats={timeline.fats}
        isCaloriesVisible
      />
    </div>
  {/each}
</div>
