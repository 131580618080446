<script lang="ts">
  import { onMount } from "svelte";
  import { deleteWithJwt, getWithJwt, postWithJwt, serverlessRoutes, translate } from "lib";
  import { showAlert, user } from "stores";
  import { ButtonComponent, ExpansionPanel, Input, Svg } from "ui";

  let cronExceptions: Array<any> = [];
  let emailInput = "";
  let isDeletingEmail: number | null = null;

  const typeMap: any = {
    "non-training-clients": "Poruka za klijente koji ne treniraju",
    "three-day-free-inactive": "Poruka za klijente koji nisu pisali 3 dana",
    "five-day-paid-expire": "Poruka za klijente kojima nalog ističe za 5 dana",
    "five-day-paid-inactive": "Poruka za klijente koji nisu pisali 5 dana",
    "message-many": "Izaberi sve -> pošalji automatsku poruku",
  };

  const fetchCronExceptions = async () => {
    if ($user.id !== 13 && $user.id !== 15 && $user.id !== 87649) return;
    try {
      const response = await getWithJwt(serverlessRoutes.CRON_EXCEPTIONS);
      cronExceptions = response.data;
      if ($user.id === 13 || $user.id === 87649) {
        cronExceptions = cronExceptions.filter(
          (cronException) => cronException.id !== 2
        );
      } else {
        cronExceptions = cronExceptions.filter(
          (cronException) => cronException.id === 2
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const addCronException = async (type: string, email: string) => {
    try {
      const response = await postWithJwt(serverlessRoutes.CRON_EXCEPTIONS, {
        type,
        email,
      });
      const index = cronExceptions.findIndex(
        (cronException) => cronException.type === type
      );
      cronExceptions[index].emails = [...cronExceptions[index].emails, email];
      emailInput = "";
      $showAlert.message = translate("SUCCESSFULLY_ADDED_EMAIL_EXCEPTION");
    } catch (err) {
      console.error(err);
    }
  };

  const deleteCronException = async (type: string, email: string) => {
    isDeletingEmail = null;
    try {
      const response = await deleteWithJwt(serverlessRoutes.CRON_EXCEPTIONS, {
        type,
        email,
      });
      const index = cronExceptions.findIndex(
        (cronException) => cronException.type === type
      );
      cronExceptions[index].emails = cronExceptions[index].emails.filter(
        (cronExceptionEmail: any) => cronExceptionEmail !== email
      );
      $showAlert.message = translate("SUCCESSFULLY_DELETED_EMAIL_EXCEPTION");
    } catch (err) {
      console.error(err);
    }
  };

  onMount(fetchCronExceptions);
</script>

<ExpansionPanel title="{translate("OMIT_FROM_AUTOMATIC_MESSAGE")}">
  <div class="flex flex-col gap-4">
    {#each cronExceptions as cronException}
      <ExpansionPanel title={typeMap[cronException.type]}>
        <div class="flex flex-col gap-2">
          {#each cronException.emails as email}
            <div class="flex flex-row items-center">
              {#if isDeletingEmail === email}
                <div
                  on:click={() =>
                    deleteCronException(cronException.type, email)}
                  on:keypress={() =>
                    deleteCronException(cronException.type, email)}
                  tabindex="0"
                  role="button"
                >
                  <Svg name="delete" customColor="bg-red-500" size={20} />
                </div>
              {:else}
                <div
                  on:click={() => (isDeletingEmail = email)}
                  on:keypress={() => (isDeletingEmail = email)}
                  tabindex="0"
                  role="button"
                >
                  <Svg name="minus" size={16} />
                </div>
              {/if}
              <div class="ml-2">{email}</div>
            </div>
          {/each}
          <div class="flex flex-row justify-between items-center my-2">
            <Input bind:value={emailInput} />
            <ButtonComponent
              on:click={
                () => addCronException(cronException.type, emailInput)
              }
            >
              Dodaj
            </ButtonComponent>
          </div>
        </div>
      </ExpansionPanel>
    {/each}
  </div>
</ExpansionPanel>
