<script lang="ts">
  import { onMount } from "svelte";
  import { ButtonComponent, Search } from "ui";
  import { api, dialogTypes, entities, getWithJwt, translate } from "lib";
  import {
    trainerClientGroups,
    dialogData,
    trainerClientGroupsCount,
  } from "stores";
  import TopMenu from "../../components/TopMenu.svelte";
  import ClientGroup from "../../components/ClientGroup.svelte";
  import InfiniteScroll from "../../components/UI/InfiniteScroll.svelte";

  let value = "";
  let timeout: NodeJS.Timeout;

  const onInput = (): void => {
    clearTimeout(timeout);
    timeout = setTimeout(async (): Promise<void> => {
      fetchData();
    }, 1000);
  };

  const onCreateGroup = (): void => {
    $dialogData.data = {};
    $dialogData.type = dialogTypes.CREATE_EDIT_GROUP;
  };

  const fetchData = async (): Promise<void> => {
    try {
      const response = await getWithJwt(
        `${api}/client_group?take=15&skip=0&name=${value}`
      );
      $trainerClientGroups = response.data;
      $trainerClientGroupsCount = response.count;
    } catch (error) {
      console.error(error);
    }
  };

  const onLoadMore = async (): Promise<void> => {};

  onMount(async (): Promise<void> => {
    await fetchData();
  });
</script>

<TopMenu entity={entities.CLIENTS} />

<div class="p-4 flex flex-col gap-4">
  <div class="flex gap-4">
    <Search
      placeholder={translate("SEARCH_GROUPS")}
      bind:value
      on:input={onInput}
    />
    <ButtonComponent myClasses="whitespace-nowrap" on:click={onCreateGroup}>
      {translate("CREATE_GROUP")}
    </ButtonComponent>
  </div>

  <div class="text-center">
    {translate("TOTAL_NUMBER_OF_GROUPS")}:
    <b>{$trainerClientGroupsCount}</b>
  </div>

  {#each $trainerClientGroups as group}
    <ClientGroup {group} />
  {/each}

  <InfiniteScroll
    hasMore={$trainerClientGroups.length < $trainerClientGroupsCount}
    on:loadMore={onLoadMore}
  />
</div>
