<script lang="ts">
  import SettingsItem from "../../components/SettingsItem.svelte";
  import { user } from "../../stores/userStore";
  import { theme } from "../../stores/themeStore";
  import { translate } from "../../lib/translate";
  import { push } from "svelte-spa-router";
  import { language } from "../../stores/languageStore";
  import Avatar from "../../components/UI/Avatar.svelte";
  import { api, serverlessRoutes } from "../../lib/constants";
  import { getWithJwt } from "../../lib/requests";
  import { logout } from "../../lib/logout";
  import { clientAvatarUrl } from "../../stores/clientStores";
  import { isClient } from "../../lib/roles";

  let finishedWorkoutCount: number = 0;

  const fetchFinishedWorkoutCount = async () => {
    let url = `${serverlessRoutes.WORKOUT}/finished-count`;
    try {
      const res = await getWithJwt(url);
      finishedWorkoutCount = res.data.count;
    } catch (err) {
      console.error(err);
    }
  };

  user.subscribe((res) => {
    if (res && isClient(res)) fetchFinishedWorkoutCount();
  });

  // @ts-ignore
</script>

<svelte:head>
  <style>
    body {
      overflow: hidden;
    }
  </style>
</svelte:head>
{#key $language}
  {#key $theme}
    <!-- Ovo je da se force updatuje settings screen kada se promeni jezik -->
    <div
      class="flex flex-col items-center justify-center h-screen -mt-12 bg-white dark:bg-zinc-800 text-slate-900 dark:text-slate-100"
    >
      <Avatar avatarUrl={$clientAvatarUrl} alt="User Avatar" size={88} />
      <br />
      <h3>
        {translate("WELCOME")}, {$user?.name}
      </h3>
      <br />
      <br />
      {#if $user && isClient($user)}
        <div class="flex-row center-center" style="width: 97%;">
          <SettingsItem
            title={"NUMBER_OF_WORKOUTS"}
            iconName={"trophy"}
            custom={"workout_count"}
            execFunction={() => {}}
          /><span class="text-bold">{finishedWorkoutCount}</span>
        </div>
      {/if}
      <SettingsItem
        title={"PROFILE"}
        iconName={"user"}
        execFunction={() => {
          push("/settings/profile");
        }}
      />
      {#if $user?.id === 15 || $user?.id === 13 || $user?.id === 17833 || $user?.id === 87649}
        <SettingsItem
          title={"ADVANCED_SETTINGS"}
          iconName={"advanced-settings"}
          execFunction={() => {
            push("/settings/advanced-settings");
          }}
        />
      {/if}
      <!-- <SettingsItem
      title={"NOTIFICATIONS"}
      iconName={"bell"}
      execFunction={() => {}}
    /> -->
      <SettingsItem
        title={"LANGUAGE"}
        iconName={"globe"}
        custom={"language"}
        execFunction={() => {}}
      />
      <SettingsItem
        title={"THEME"}
        iconName={"theme"}
        custom={"theme"}
        execFunction={() => {}}
      />
      <SettingsItem
        title={"REPORT_A_BUG_SUGGESTION"}
        iconName={"bug"}
        execFunction={() => {
          push("/settings/bug-suggestion");
        }}
      />
      <!-- <SettingsItem title={"HELP"} iconName={"help"} execFunction={() => {}} /> -->
      <SettingsItem
        title={"SIGN_OUT"}
        iconName={"logout"}
        execFunction={logout}
      />
    </div>
  {/key}
{/key}
