<script lang="ts">
  import ClientListItem from "../../../components/ClientListItem.svelte";
  import { trainerList } from "../../../stores/trainerStores";
</script>

<div class="p-4 flex flex-col gap-4">
  <p class="text-center">
    Ukupan broj trenera u grupi: <b>{$trainerList.participants.length}</b>
  </p>

  {#each $trainerList.participants as participant}
    <ClientListItem isInsideTrainers client={participant} />
  {/each}
</div>
