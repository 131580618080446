<script lang="ts">
  import { getWithJwt } from "lib";
  import { afterUpdate, onMount } from "svelte";
  import { dialogTypes, serverlessRoutes } from "../../lib/constants";
  import { dialogData } from "../../stores/dialogDataStore";
  import { signCloudflareImage } from "../../lib/cloudflare/uploadImageCloudflare";

  export let id: number;
  export let files: any;
  export let timestamp: string;
  export let payload: string = "";
  export let isMine: boolean;
  export let scrollToBottom = () => {};
  export let isBottomImage = false;

  export let touchstart: (
    messageId: number,
    messagePayload: string,
    timestamp: any,
    isMine: boolean
  ) => void;
  export let touchend: () => void;

  let imgUrl = "";

  const fetchCloudflareStreamsVideo = async (uid: string) => {
    const fetchUrl = `https://streams.trainme-api.workers.dev/api/video?videoId=${uid}`;
    try {
      const res = await getWithJwt(fetchUrl);
      return res;
    } catch (err) {
      console.error(err);
    }
  };

  const align = isMine ? "flex-end" : "flex-start";

  onMount(async () => {
    /*if (payload.includes("video:")) {
      const fetchVideo = await fetchCloudflareStreamsVideo(
        payload.slice(7, payload.length)
      );
      payload = fetchVideo.previewWithToken;
      return;
      
    } else */
    if (payload?.includes(":cloudflareimg:")) {
      imgUrl = await signCloudflareImage(payload);
      return;
    } else if (!payload?.includes("cloudflarestream")) {
      imgUrl = files[0]?.imageUrl;
    }
  });
</script>

<!-- svelte-ignore a11y-media-has-caption -->
<!-- svelte-ignore a11y-no-static-element-interactions -->
<div
  class="message mgl-8 mgr-8 mt-2"
  style={`align-self: ${align};`}
  class:video-height={payload?.includes("cloudflarestream")}
  class:image-height={payload?.includes(":cloudflareimg:")}
  on:mousedown={() => touchstart(id, "FILE_MESSAGE", timestamp, isMine)}
  on:mouseup={() => touchend()}
  on:touchend={() => touchend()}
  on:touchstart={() => touchstart(id, "FILE_MESSAGE", timestamp, isMine)}
>
  <!-- && payload.includes("cloudflarestream") -->
  <!-- <pre>{JSON.stringify(payload)}</pre> -->

  {#if payload && payload.includes("cloudflarestream")}
    <div>
      <!-- loading="lazy" -->
      <iframe
        title="Video player"
        src={payload}
        style="width: 100%; height: auto;"
        allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
        allowfullscreen={true}
        on:load={() => (isBottomImage === true ? scrollToBottom() : {})}
      ></iframe>
    </div>
  {:else if imgUrl !== ""}
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <div
      class="gallery-entry-image"
      role="button"
      tabindex="0"
      on:click={() => {
        $dialogData.data = { src: imgUrl };
        $dialogData.type = dialogTypes.IMAGE_ZOOM;
      }}
    >
      <img
        src={imgUrl}
        alt={"img"}
        class="image border rounded-md border-slate-200 dark:border-zinc-600"
        on:load={() => (isBottomImage === true ? scrollToBottom() : {})}
      />
    </div>
  {/if}
</div>

<style>
  .message {
    align-self: flex-end;
    width: fit-content;
    max-width: 70%;
  }
  .video-height {
    height: 150px;
  }
  .image-height {
    height: 384px;
  }
  .image {
    /*max-width: 256px;
    max-height: 384px;*/
    object-fit: contain;
    height: 384px;
    width: auto;
  }
  /* .video {
    width: 256px;
    height: fit-content;
  } */
</style>
