<script lang="ts">
  import { onDestroy, onMount } from "svelte";
  import TopMenu from "../../components/TopMenu.svelte";
  import MeasurementExpansionPanel from "../../components/Progress/MeasurementExpansionPanel.svelte";
  import Spinner from "../../components/UI/Spinner.svelte";
  import {
    api,
    dialogTypes,
    entities,
    serverlessRoutes,
  } from "../../lib/constants";
  import dateString from "../../lib/dateString";
  import { getWithJwt, postWithJwt } from "../../lib/requests";
  import { isClient } from "../../lib/roles";
  import { user } from "../../stores/userStore";
  import DateIncrementer from "../../components/Progress/DateIncrementer.svelte";
  import {
    clientGoals,
    clientMeasurementDataMap,
    clientMeasurements,
  } from "../../stores/clientStores";
  import { translate } from "lib";
  import { exportToPdf } from "../../lib/pdf";
  import { Capacitor } from "@capacitor/core";
  import { currentClient } from "../../stores/currentClient";
  import { dialogData, showAlert } from "../../stores";
  import { ButtonComponent, Svg } from "ui";
  import { DateInput, DatePicker } from 'date-picker-svelte'
	let date = new Date()

  let isLoading: boolean = false;
  let selectedDate: Date = new Date();
  let measurementsData: any;
  let incrementWeekCounter = 1;
  const today = new Date();

  const updateDataMap = (): void => {
    $clientMeasurementDataMap = {};

    measurementsData.forEach((measurementData: any): void => {
      measurementData.measurements.forEach((measurement: any): void => {
        const date = new Date(measurement.createdAt);
        const obj = {};
        date.setDate(date.getDate() + 1);
        obj[measurement.measurementId] = {measurementDataId: measurement.id, value: measurement.value};
        if ($clientMeasurementDataMap[dateString(date)]) {
          $clientMeasurementDataMap[dateString(date)] = [
            ...$clientMeasurementDataMap[dateString(date)],
            obj,
          ];
        } else {
          $clientMeasurementDataMap[dateString(date)] = [obj];
        }
      });
    });
  };

  const fetchMeasurements = async (): Promise<void> => {
    if (!isLoading && $user && $clientMeasurements.length === 0) {
      isLoading = true;
      const clientId = $currentClient.id || $user.id;

      try {
        const response = await getWithJwt(
          `${serverlessRoutes.MEASUREMENT}?clientId=${clientId}`
        );

        $clientMeasurements = response.data.measurements;
        fetchMeasurementData();
      } catch (error) {
        console.error(error)
      } finally {
        isLoading = false;
      }
    }
  };

  const getValueFromMap = (id: number, date) => {
    const objects = $clientMeasurementDataMap[dateString(selectedDate)];

    if (objects) {
      const foundObject = objects.find((obj) => Object.keys(obj)[0] === `${id}`);

      if (foundObject) {
        return foundObject[id].value;
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  const incrementDate = (n: number) => {
    if (n > 0 && selectedDate >= today) {
      return;
    }

    selectedDate.setDate(selectedDate.getDate() + n);
    selectedDate = selectedDate;
    const passedWeek = new Date();
    passedWeek.setDate(passedWeek.getDate() - incrementWeekCounter * 7);
    if (selectedDate < passedWeek) {
      incrementWeekCounter += 1;
      fetchMeasurementData();
    }
  };

  const fetchMeasurementData = async () => {
    // TODO za klijenta treba da se promeni $user.id
    const prevDate = new Date(selectedDate);
    prevDate.setDate(prevDate.getDate() - 7);
    const clientId = $currentClient.id ? $currentClient.id : $user.id;
    // const url = `${api}/measurement/date?clientId=${clientId}&dateFrom=${dateString(prevDate)}&dateTo=${dateString(selectedDate)}`;
    try {
      // measurementsData = await getWithJwt(url);
      const response = await postWithJwt(`${serverlessRoutes.MEASUREMENT}/date`, {
        dateFrom: dateString(prevDate),
        dateTo: dateString(selectedDate),
        clientId
      });

      measurementsData = response.data.measurements;

      updateDataMap();
    } catch (err) {}
  };

  const fetchGoals = async () => {
    if (!isLoading && $user && Object.keys($clientGoals).length === 0) {
      isLoading = true;
      const url = `${serverlessRoutes.GOAL}?clientId=${$user.id}`;
      try {
        const data = await getWithJwt(url);
        $clientGoals = data.data;
        isLoading = false;
      } catch (err) {}
    }
  };

  const trackNewMeasurement = async (measurement) => {
    if (!$currentClient.id) return;
    if (measurement.id === 0) delete measurement.id;
    try {
      const result = await postWithJwt(serverlessRoutes.MEASUREMENT, {
        measurement,
        clientId: $currentClient.id
      });
      if (result.error) {
        $showAlert.message = translate("SOMETHING_WENT_WRONG");
        return;
      }
      $showAlert.message = translate("HABIT_SUCCESSFULLY_ADDED");
      $clientMeasurements = [...$clientMeasurements, result.data];
    } catch (err) {}
  };

  const unsubscribe = user.subscribe((res) =>
    fetchMeasurements().then(() => fetchGoals())
  );

  onDestroy(() => {
    unsubscribe();
    if (!isClient($user)) {
      clientMeasurements.set([]);
      clientMeasurementDataMap.set({});
    }
  });

  // import Datepicker from 'flowbite-datepicker/Datepicker';
  // let newDate: HTMLInputElement;

  let isCalendarVisible = false;

  onMount(() => {
    fetchMeasurements().then(() => fetchGoals());

    // setTimeout(() => new Datepicker(newDate), 5000);
  });
</script>

<style>
  :global(.date-time-picker) {
    width: 288px;
    border-radius: 8px !important;
    padding: 16px !important;
    padding-top: 20px !important;
  }

  :global(.date-time-picker select) {
    box-shadow: none !important;
  }

  :global(.date-time-picker option) {
    background-color: var(--date-picker-background);
    color: var(--date-picker-foreground);
  }
</style>

{#if !$currentClient.id}
  <TopMenu entity={entities.PROGRESS} />
{/if}
{#if isLoading}
  <Spinner />
{:else}
  <div class="p-4 bg-white dark:bg-zinc-800 text-slate-900 dark:text-slate-100">
    <div class="flex justify-center" on:click={() => { isCalendarVisible = !isCalendarVisible; }}>
      <!-- <div class="w-[24px]"></div> -->
      <DateIncrementer {incrementDate} selectedDateDecremented={selectedDate} />
      <!-- <Svg name="calendar" size={24} on:click={() => { isCalendarVisible = !isCalendarVisible; }}/> -->
    </div>

    {#if isCalendarVisible}
      <div class="absolute z-50 left-[50%] translate-x-[-50%]">
        <DatePicker bind:value={selectedDate} max={new Date()} on:select={() => isCalendarVisible = false}/>
      </div>
    {/if}
    <!-- <div bind:this={newDate}>
      <input type="date" />
    </div> -->

    <!-- <input type="date" bind:this={newDate}/> -->

    <div class="flex flex-col gap-4">
      {#key $clientMeasurementDataMap}
        {#each $clientMeasurements as measurement}
          <MeasurementExpansionPanel
            hasGoal={$clientGoals.find(
              (goal) => goal?.measurement?.id === measurement.id
            )
              ? true
              : false}
            title={measurement.name}
            entityValue={getValueFromMap(measurement.id, selectedDate)}
            isDraggable={!isClient($user)}
            entityId={measurement.id}
            refetchMeasurementData={fetchMeasurementData}
            {selectedDate}
          />
        {/each}
      {/key}
    </div>

    <div class="flex flex-row justify-between items-center mt-4">
      <ButtonComponent
        on:click={() =>
          exportToPdf($user.id, "measurements", "Measurements.pdf")}
      >
        {translate("EXPORT_TO_PDF")}
      </ButtonComponent>
      {#if $user && !isClient($user)}
        <ButtonComponent
          on:click={() => {
            $dialogData = {
              data: { executeFunction: trackNewMeasurement },
              type: dialogTypes.CREATE_EDIT_MEASUREMENT,
            };
          }}
        >
          {translate("TRACK_NEW_MEASUREMENT")}
        </ButtonComponent>
      {/if}
      <!-- {/if} -->
    </div>

    {#if Capacitor.isNativePlatform()}
      <div class="mb-12" />
    {/if}
  </div>
{/if}
