<script lang="ts">
  import { onMount } from "svelte";
  import { translate } from "lib";
  import { ExpansionPanel, Toggle } from "ui";

  let showChatHelper: boolean;
  let showAiSummary: boolean;
  let showAiSuggestion: boolean;

  const toggleChatHelper = (): void => {
    showChatHelper = !showChatHelper;
    localStorage.setItem("showChatHelper", `${showChatHelper}`);
  };

  const toggleAiSummary = (): void => {
    showAiSummary = !showAiSummary;
    localStorage.setItem("showAiSummary", `${showAiSummary}`);
  };

  const toggleAiSuggestion = (): void => {
    showAiSuggestion = !showAiSuggestion;
    localStorage.setItem("showAiSuggestion", `${showAiSuggestion}`);
  };

  onMount((): void => {
    showChatHelper = localStorage.getItem("showChatHelper") === "true";
    showAiSummary = localStorage.getItem("showAiSummary") === "true";
    showAiSuggestion = localStorage.getItem("showAiSuggestion") === "true";
  });
</script>

<ExpansionPanel title={translate("CHAT_HELPERS")}>
  <Toggle
    label="SHOW_CHAT_HELPER"
    value={showChatHelper}
    toggleFunction={toggleChatHelper}
  />
  <Toggle
    label="SHOW_AI_SUMMARY"
    value={showAiSummary}
    toggleFunction={toggleAiSummary}
  />
  <Toggle
    label="SHOW_AI_SUGGESTION"
    value={showAiSuggestion}
    toggleFunction={toggleAiSuggestion}
  />
</ExpansionPanel>
