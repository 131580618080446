<script lang="ts">
  import { onDestroy, onMount } from "svelte";
  import { slide } from "svelte/transition";
  import { translate } from "lib";
  import { tags as tagStore } from "stores";
  import { trainerExercisesStore } from "stores";
  import {ButtonComponent, CheckboxComponent, RadioField} from "ui";
  import FilterTag from "../FilterTag.svelte";

  let isExpanded = false;
  let tags: Array<string> = [];
  let templateGroup: "ALL" | "MY" | undefined = "ALL";
  let sortGroup: string | undefined;

  const templateTags = ["ALL", "MY"];
  const sortTags = ["NAME_ASC", "NAME_DESC", "DATE_ASC", "DATE_DESC"];

  const unsubscribeTagStore = tagStore.subscribe((store): void => {
    tags = store.map(({note}): string => note);
  });

  const fetchExerciseTags = async () => {
    try {
      tags = [
        "Trbuh",
        "Biceps",
        "Listovi",
        "Grudi",
        "Podlaktica",
        "Zadnjica",
        "Zadnja loža",
        "Donja leđa",
        "Prednja loža",
        "Ramena",
        "Triceps",
        "Gornja leđa",
      ];
    } catch (error) {
      console.error(error);
    }
  };

  const onRemoveTemplateTag = async (): Promise<void> => {
    $trainerExercisesStore.filter.template = undefined;
    templateGroup = undefined;
    await trainerExercisesStore.fetchData();
  };

  const onRemoveSortTag = async (): Promise<void> => {
    $trainerExercisesStore.filter.sort = undefined;
    sortGroup = undefined;
    await trainerExercisesStore.fetchData();
  };

  const onRemoveTag = async (toRemove: string): Promise<void> => {
    $trainerExercisesStore.filter.tags = $trainerExercisesStore.filter.tags
      .filter((tag): boolean => tag !== toRemove);

    await trainerExercisesStore.fetchData();
  };

  const onTemplateTagChange = async (): Promise<void> => {
    const {filter} = $trainerExercisesStore;

    if (templateGroup === "ALL") {
      filter.template = 1;
    } else {
      filter.template = 0;
    }

    await trainerExercisesStore.fetchData();
  };

  const onSortTagChange = async (): Promise<void> => {
    $trainerExercisesStore.filter.sort = sortGroup;
    await trainerExercisesStore.fetchData();
  };

  const onTagChange = (checkedTag: string): void => {
    if ($trainerExercisesStore.filter.tags.includes(checkedTag)) {
      $trainerExercisesStore.filter.tags = $trainerExercisesStore.filter.tags.filter(
        (tag) => tag !== checkedTag
      );
    } else {
      $trainerExercisesStore.filter.tags = [checkedTag, ...$trainerExercisesStore.filter.tags];
    }
  };

  onDestroy(unsubscribeTagStore);
  onMount(fetchExerciseTags);
</script>

<div class="flex flex-col gap-4">

  <div class="flex items-start gap-4">
    <div class="flex flex-wrap grow gap-2">
      {#if $trainerExercisesStore.filter.template !== undefined}
        <FilterTag
          on:removeFilter={onRemoveTemplateTag}
          title={translate($trainerExercisesStore.filter.template === 0 ? "MY" : "ALL")}
        />
      {/if}
      {#if $trainerExercisesStore.filter.sort !== undefined}
        <FilterTag
          on:removeFilter={onRemoveSortTag}
          title={translate($trainerExercisesStore.filter.sort)}
        />
      {/if}
      {#each $trainerExercisesStore.filter.tags as filter}
        <FilterTag on:removeFilter={() => onRemoveTag(filter)} title={filter} />
      {/each}
    </div>

    <ButtonComponent isOutline on:click={() => (isExpanded = !isExpanded)}>
      {translate("FILTER")}
    </ButtonComponent>
  </div>

  {#if isExpanded}
    <div
      class="flex flex-col border border-slate-200 dark:border-zinc-600 rounded-md"
      in:slide
      out:slide
    >

      <div class="flex">
        <div class="flex flex-col basis-1/2">
          <div class="p-2 flex flex-col gap-2 border-b border-slate-200 dark:border-zinc-600">
            {#each sortTags as sortTag}
              <RadioField
                value={sortTag}
                bind:group={sortGroup}
              >
                <!-- on:change={onSortTagChange} -->
                {translate(sortTag)}
              </RadioField>
            {/each}
          </div>

          <div class="p-2 flex flex-col gap-2">
            {#each templateTags as templateTag}
              <RadioField
                value={templateTag}
                bind:group={templateGroup}
              >
                <!-- on:change={onTemplateTagChange} -->
                {translate(templateTag)}
              </RadioField>
            {/each}
          </div>
        </div>

        <div
          class="p-2 flex flex-col basis-1/2 gap-2 border-l border-slate-200 dark:border-zinc-600"
        >
          {#each tags as tag}
            <div class="flex flex-row">
              <div
                on:click={() => onTagChange(tag)}
                on:keypress={() => onTagChange(tag)}
                tabindex="0"
                role="button"
              >
                <CheckboxComponent value={$trainerExercisesStore.filter.tags.includes(tag)} />
              </div>
              <span class="text-xs ml-1 dark:text-slate-300">{tag}</span>
            </div>
          {/each}
        </div>
      </div>
    </div>
  {/if}

  <div class="text-center text-xs">
    {translate("TOTAL_EXERCISES_COUNT")}:
    <span class="font-bold">{$trainerExercisesStore.count}</span>
  </div>
</div>
