<script lang="ts">
  import {onDestroy, onMount} from "svelte";
  import {flip} from "svelte/animate";
  import {Capacitor} from "@capacitor/core";
  import {animations, entities} from "lib";
  import {ingredientsStore, user} from "stores";
  import {IngredientComponent, IngredientsToolbarComponent} from "diet";
  import {InfiniteScroll} from "ui";
  import TopMenu from "../../components/TopMenu.svelte";

  const unsubscribeUserStore = user.subscribe(ingredientsStore.fetchData);

  onDestroy(unsubscribeUserStore);
  onMount(ingredientsStore.loadCache);
</script>

<svelte:head>
  {#if Capacitor.isNativePlatform()}
    <style>
      body {
        overflow: hidden;
      }
    </style>
  {/if}
</svelte:head>

<TopMenu entity={entities.DIET}/>

<div class="p-4 flex flex-col gap-4">
  <IngredientsToolbarComponent isAddVisible/>

  {#each $ingredientsStore.items as ingredient (ingredient.id)}
    <div animate:flip={animations.flip}>
      <IngredientComponent {ingredient}/>
    </div>
  {/each}

  <InfiniteScroll
    hasMore={$ingredientsStore.hasMore}
    isFetchingMore={$ingredientsStore.isFetchingMore}
    on:loadMore={ingredientsStore.fetchMoreData}
  />

  {#if Capacitor.isNativePlatform()}
    <div class="mb-12"></div>
  {/if}
</div>
