<script lang="ts">
  import {createEventDispatcher, onMount} from "svelte";
  import {deleteWithJwt, parseDate, patchWithJwt, putWithJwt, serverlessRoutes, translate} from "lib";
  import {currentClient, showAlert, user} from "stores";
  import InputMini from "../UI/InputMini.svelte";

  let item: any;
  let value: number;

  const dispatch = createEventDispatcher();

  const MONTH_LABELS = [
    "JANUARY",
    "FEBRUARY",
    "MARCH",
    "APRIL",
    "MAY",
    "JUNE",
    "JULY",
    "AUGUST",
    "SEPTEMBER",
    "OCTOBER",
    "NOVEMBER",
    "DECEMBER",
  ];

  const deleteMeasurementData = async (): Promise<void> => {
    const {id} = item;
    const clientId = $currentClient.id || $user.id;

    try {
      const response = await deleteWithJwt(
        serverlessRoutes.MEASUREMENT_DATA,
        {clientId, id}
      );

      dispatch("deletedItem", response.data);

      $showAlert.color = "black";
      $showAlert.message = translate("SUCCESSFULLY_DELETED_VALUE");
    } catch (error) {
      console.error(error);
    }
  };

  const updateMeasurementData = async (): Promise<void> => {
    const clientId = $currentClient.id || $user.id;
    const {id} = item;

    try {
      const response = await patchWithJwt(
        serverlessRoutes.MEASUREMENT_DATA,
        {clientId, id, value}
      );

      dispatch("updatedItem", response.data);

      $showAlert.color = "black";
      $showAlert.message = translate("SUCCESSFULLY_UPDATED_VALUE");
    } catch (error) {
      console.error(error);
    }
  };

  const onFocusout = async (): Promise<void> => {
    if (value === item.value) {
      return;
    }

    if (!value) {
      await deleteMeasurementData();
    } else {
      await updateMeasurementData();
    }
  };

  onMount((): void => {
    value = item.value;
  });

  export {item};
</script>

<div class="flex-row center-space-around mg-8">
  <div>
    {#if item.createdAt.includes("MONTH")}
      {translate(MONTH_LABELS[item.createdAt.split("-")[1]])}
    {:else}
      {parseDate(new Date(item.createdAt))}
    {/if}
  </div>
  <div>
    <InputMini type="number" bind:value on:focusout={onFocusout}/>
  </div>
</div>
