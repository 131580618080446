<script lang="ts">
  import type {MenuItem} from "../../interfaces/MenuItem";
  import { translate } from "../../lib/translate";
  import Svg from "./Svg.svelte";
  import { slide } from "svelte/transition";
  import { onMount } from "svelte";

  export let myClasses: string = "";
  export let menuItems: MenuItem[];
  export let selectSuperset: () => void = () => {};
  export let size = 16;

  let isExpanded: boolean = false;

  const onToggleExpanded = (): void => {
    isExpanded = !isExpanded;
  };

  // let iconRect: DOMRect;
  let iconElement: HTMLDivElement;

  const onLoad = (menuElement: HTMLDivElement): void => {
    // const {offsetWidth, offsetHeight, clientHeight} = document.body;

    // const iconRect = iconElement.getBoundingClientRect();

    // console.log(window.screen.width, window.screen.height);
    // console.log({offsetWidth, offsetHeight, clientHeight});

    // menuElement.style.top = `${iconRect.top}px`;
    // menuElement.style.right = `${document.body.offsetWidth - iconRect.right + 16}px`;

    // const menuRect = menuElement.getBoundingClientRect();

    // console.log({iconRect, menuRect});

    // if (menuRect.bottom > window.screen.height) {
    //   menuElement.style.top = `unset`;
    //   menuElement.style.bottom = `4px`;
    // }







    // if (menuRect.height + menuRect.bottom > document.body.offsetHeight) {
    //   menuElement.style.top = `${-(menuRect.bottom - menuRect.height + 150)}px`;
    // } else {
    //   menuElement.style.top = `${iconRect.top}px`;
    // }

    // menuElement.style.left = `${-menuRect.width}px`;


    // const px = menuRect.left + menuRect.width > document.body.clientWidth ? menuRect.width : menuRect.width;
    // element.style.left = `${px}px`;

    // console.log({iconRect, menuRect});
    // console.log({clientHeight: document.body.clientHeight, clientWidth: document.body.clientWidth});
  };

  const onLoadIcon = (element: HTMLDivElement): void => {
    // iconRect = element.getBoundingClientRect();
  };


</script>

<!-- 
  Dodat je |stopPropagation, sada moze na ceo expansion panel header
  da se stavi da bude on:click=expand (i resava bag gde se ne expanduje
  kad se klikne na ivicu), a i menu se otvara bez da expanduje panel
 -->
{#if menuItems.length > 0}
  {#if isExpanded}
    <!-- svelte-ignore a11y-no-static-element-interactions -->
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <div class="overlay" on:click|stopPropagation={onToggleExpanded} />
  {/if}
  <!-- svelte-ignore a11y-no-static-element-interactions -->
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <div
    class="dropdown"
    data-cy="menu"
    on:click|stopPropagation={onToggleExpanded}
  >
    <div bind:this={iconElement}>
      <Svg name="more" {size} myClass={myClasses} />
    </div>

    {#if isExpanded}
      <!-- class={`dropdown-content ${isExpanded ? "display-dropdown" : "hide-dropdown"}`} -->
      <div
        class="dropdown-content shadow-lg shadow-slate-200 dark:shadow-slate-700 dark:border-zinc-600 bg-white dark:bg-zinc-800 text-slate-900 dark:text-slate-100"
        in:slide
        out:slide
        use:onLoad
      >
        {#each menuItems as menuItem, i}
          <div
            data-cy="menu-item"
            on:click={() => {
              selectSuperset();
              menuItem.executeFunction();
            }}
            on:keydown={() => {
              selectSuperset();
              menuItem.executeFunction();
            }}
          >
            <div class="flex flex-row items-center">
              <Svg
                name={menuItem.icon}
                size={20}
                myClass="ml-1 mr-2 mt-2 mb-2"
              />
              <span>{translate(menuItem.title)}</span>
            </div>
            {#if i !== menuItems.length - 1}
              <div class="flex-col center-center mgt-8 mgb-8" />
            {/if}
          </div>
        {/each}
      </div>
    {/if}
  </div>
{/if}

<style>
  .overlay {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    background-color: rgb(0, 0, 0, 0);
  }
  .display-dropdown {
    display: block;
  }
  .hide-dropdown {
    display: none;
  }
  .dropdown {
    position: relative;
    /* display: block; */
  }
  .dropdown-content {
    position: absolute;
    top: 0;
    right: 100%;
    /* top: 50%;
    left: 20%; */
    min-width: 256px;
    font-size: smaller;
    border-radius: 0.375rem;
    padding: 12px 16px;
    z-index: 3;
  }
</style>
