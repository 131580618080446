<script lang="ts">
  import { translate } from "lib";
  import Svg from "../UI/Svg.svelte";

  export let navLinks;
</script>

<div class="flex justify-center">
  <div class="flex text-xs rounded-md border overflow-hidden">
    {#each navLinks as link}
      <div
        tabindex="0"
        role="button"
        class={`p-2 flex flex-col gap-2 border-r ${link.isActive ? "bg-gray-200 dark:bg-zinc-700" : ""}`}
        on:click={() => link.onView()}
        on:keypress={() => link.onView()}
      >
        <Svg name={link.icon} size={24} />
        {translate(link.name)}
      </div>
    {/each}
    <!--     
    <div
      tabindex="0"
      role="button"
      class={`p-2 flex flex-col gap-2 border-r  ${view === "MEASUREMENTS" ? "bg-gray-200 dark:bg-zinc-700" : ""}`}
      on:click={() => (view = "MEASUREMENTS")}
      on:keypress={() => (view = "MEASUREMENTS")}
    >
      <Svg name="ruler" size={24} />
      Mere
    </div>
    <div
      tabindex="0"
      role="button"
      class={`p-2 flex flex-col gap-2 border-r  ${view === "HABITS" ? "bg-gray-200 dark:bg-zinc-700" : ""}`}
      on:click={() => (view = "HABITS")}
      on:keypress={() => (view = "HABITS")}
    >
      <Svg name="line-segments" size={24} />
      Navike
    </div>
    <div
      tabindex="0"
      role="button"
      class={`p-2 flex flex-col gap-2 border-r ${view === "COMPLIANCE" ? "bg-gray-200 dark:bg-zinc-700" : ""}`}
      on:click={() => (view = "COMPLIANCE")}
      on:keypress={() => (view = "COMPLIANCE")}
    >
      <Svg name="lists-check" size={24} />
      Pridrzavanje
    </div>
    <div
      tabindex="0"
      role="button"
      class={`p-2 flex flex-col gap-2 ${view === "TIMELINE" ? "bg-gray-200 dark:bg-zinc-700" : ""}`}
      on:click={() => (view = "TIMELINE")}
      on:keypress={() => (view = "TIMELINE")}
    >
      <Svg name="line-segments" size={24} />
      Tajmlajn
    </div> -->
  </div>
</div>
