<script lang="ts">
  import { dialogTypes, MealPlanTemplate, translate } from "lib";
  import { dialogData, mealPlansStore } from "stores";
  import { ButtonComponent, ButtonGroup, Search } from "ui";
  import type { IButtonGroup } from "interfaces";

  let templateType: MealPlanTemplate = MealPlanTemplate.MY;
  let isAddVisible = false;

  const switchTemplate = async (type: MealPlanTemplate): Promise<void> => {
    if (templateType !== type) {
      templateType = type;
      $mealPlansStore.filter.template = type;
      await mealPlansStore.fetchData();
    }
  };

  const buttonGroup: IButtonGroup[] = [
    {
      title: `${translate("MY")} ${translate("PLANS").toLowerCase()}`,
      async onClick (): Promise<void> {
        await switchTemplate(MealPlanTemplate.MY);
      },
    },
    {
      title: translate("LIBRARY"),
      async onClick (): Promise<void> {
        await switchTemplate(MealPlanTemplate.LIBRARY);
      },
    },
    {
      title: translate("GROUPS"),
      async onClick (): Promise<void> {
        await switchTemplate(MealPlanTemplate.GROUPS);
      },
    },
  ];

  const onAddMealPlan = (): void => {
    $dialogData.data = {};
    $dialogData.type = dialogTypes.CREATE_EDIT_MEAL_PLAN;
  };

  export {isAddVisible};
</script>

<div class="flex gap-4">
  <Search
    placeholder={translate("SEARCH_MEAL_PLANS")}
    bind:value={$mealPlansStore.filter.search}
    on:input={mealPlansStore.search}
  />

  {#if isAddVisible}
    <ButtonComponent myClasses="whitespace-nowrap" on:click={onAddMealPlan}>
      {translate("ADD")}
      {translate("MEAL_PLAN").toLowerCase()}
    </ButtonComponent>
  {/if}
</div>

<div class="flex flex-col gap-4">
  <div class="flex justify-center">
    <ButtonGroup buttons={buttonGroup} />
  </div>

  <div class="text-center text-xs">
    {translate("NUMBER_OF_MEAL_PLANS")}:
    <span class="font-bold">{$mealPlansStore.count}</span>
  </div>
</div>
