<script lang="ts">
  import { onDestroy, onMount } from "svelte";
  import { flip } from "svelte/animate";
  import { Capacitor } from "@capacitor/core";
  import { dragHandleZone, type DndEvent } from "svelte-dnd-action";
  import { pop } from "svelte-spa-router";
  import { alertStore, dialogData, mealPlanStore } from "stores";

  import {
    animations,
    api,
    dialogTypes,
    getNewPositions,
    patchWithJwt,
    serverlessRoutes,
    translate,
  } from "lib";

  import { getPdfWithJwt } from "../../lib/requests";
  import { Badge, ButtonComponent } from "ui";
  import Svg from "../../components/UI/Svg.svelte";
  import MealExpansionPanel from "../../components/Diet/MealExpansionPanel.svelte";
  import More from "../../components/UI/More.svelte";
  import Spinner from "../../components/UI/Spinner.svelte";
  import type { Meal, MenuItem } from "interfaces";

  let params; // spa-router postavlja params.id (string)

  const onCreateTextMeal = (): void => {
    if (!$mealPlanStore) {
      return;
    }

    $dialogData.data = {
      mealPlanId: $mealPlanStore.id,
    };

    $dialogData.type = dialogTypes.CREATE_EDIT_TEXT_MEAL;
  };

  const onCreatePDFMeal = (): void => {
    if (!$mealPlanStore) {
      return;
    }

    $dialogData.data = {
      mealPlanId: $mealPlanStore.id,
    };

    $dialogData.type = dialogTypes.CREATE_EDIT_PDF_MEAL;
  };

  const onCreateHeaderMeal = (): void => {
    if (!$mealPlanStore) {
      return;
    }

    $dialogData.data = {
      mealPlanId: $mealPlanStore.id,
    };

    $dialogData.type = dialogTypes.CREATE_EDIT_HEADER_MEAL;
  };

  const onCreateRecipeMeal = (): void => {
    if (!$mealPlanStore) {
      return;
    }

    $dialogData.data = {
      mealPlanId: $mealPlanStore.id,
    };

    $dialogData.type = dialogTypes.CREATE_EDIT_RECIPE_MEAL;
  };

  const downloadBlob = (
    blob: Blob,
    name = "StrongmanPlanIshrane.pdf"
  ): void => {
    const blobUrl = URL.createObjectURL(blob);
    const link = document.createElement("a");

    link.href = blobUrl;
    link.download = name;

    document.body.appendChild(link);

    link.dispatchEvent(
      new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    );

    document.body.removeChild(link);
  };

  const getCount = (): number => {
    let count = 0;

    if ($mealPlanStore?.meals) {
      count = $mealPlanStore.meals.reduce(
        (acc, meal) =>
          meal.entityType === 1 || meal.finishedAt ? acc : (acc += 1),
        0
      );
    }

    return count;
  };

  const onExportToPDF = async (): Promise<void> => {
    const response = await getPdfWithJwt(
      `${api}/vanila-routes/meal_plan/${$mealPlanStore?.id}/export_to_pdf`
    );
    downloadBlob(response);
  };

  const onAssignToClients = (): void => {
    $dialogData.data = {
      plan: $mealPlanStore,
      executeFunction() {},
    };
    $dialogData.type = dialogTypes.ASSIGN_TO_CLIENTS;
  };

  const onEdit = (): void => {
    if (!$mealPlanStore) {
      return;
    }

    $dialogData.data = {
      id: $mealPlanStore.id,
      name: $mealPlanStore.name,
      description: $mealPlanStore.description,
      imageUrl: $mealPlanStore.thumbnailUrl,
      executeFunction() {},
    };
    $dialogData.type = dialogTypes.CREATE_EDIT_MEAL_PLAN;
  };

  const onConfirmDelete = (): void => {
    if (!$mealPlanStore) {
      return;
    }

    $dialogData.data = {
      title: $mealPlanStore.name,
      executeFunction() {},
    };
    $dialogData.type = dialogTypes.CONFIRM_DELETE;
  };

  const menuItemsTrainer: MenuItem[] = [
    {
      icon: "assign",
      title: "ASSIGN_TO_CLIENTS",
      executeFunction: onAssignToClients,
    },
    {
      icon: "edit",
      title: "EDIT",
      executeFunction: onEdit,
    },
    {
      icon: "delete",
      title: "DELETE",
      executeFunction: onConfirmDelete,
    },
  ];

  const mealPlansDndConsider = (event: CustomEvent<DndEvent<Meal>>): void => {
    mealPlanStore.setMeals(event.detail.items);
  };

  const mealPlansDndFinalize = async (
    event: CustomEvent<DndEvent<Meal>>
  ): Promise<void> => {
    const partialMealMap = getNewPositions(event.detail.items);

    if (!partialMealMap) {
      return;
    }

    const { error, data } = await patchWithJwt(serverlessRoutes.MEAL, {
      partialMealMap,
    });

    if (error && !data) {
      alertStore.show(translate("ERROR_CHANGING_ORDER"), "error");
      return console.error(error);
    }

    mealPlanStore.replaceMeals(data.meals);
    alertStore.show(translate("SUCCESSFULLY_CHANGED_ORDER"));
  };

  onDestroy((): void => {
    $mealPlanStore = null;
  });

  onMount(async (): Promise<void> => {
    await mealPlanStore.fetchMealPlan(params.id);
  });

  export { params };
</script>

{#if !$mealPlanStore}
  <Spinner />
{:else}
  {#if Capacitor.getPlatform() === "ios"}
    <div class="mt-16" />
  {/if}

  <div class="flex-col gap-4">
    <div class="relative">
      <img
        class="h-64"
        src={$mealPlanStore.fileHash || $mealPlanStore.imageUrl || "logo.png"}
        alt="Thumbnail"
      />

      <div
        class="bg-black bg-opacity-75 absolute top-0 left-0 w-full h-full flex flex-col"
      >
        <div class="p-4 flex items-center justify-between text-white">
          <Svg
            name="left-caret"
            customColor="bg-white"
            size={24}
            on:click={pop}
          />
          <div class="font-semibold">{$mealPlanStore.name}</div>
          <More myClasses="bg-white" menuItems={menuItemsTrainer} />
        </div>

        <div class="p-2 flex flex-col gap-2">
          <div>
            <div class="font-semibold">{translate("DURATION")}:</div>
            <div class="text-justify text-xs">
              {#if $mealPlanStore.duration}
                {$mealPlanStore.duration}
                {translate("DAYS").toLowerCase()}
              {:else}
                Nema
              {/if}
            </div>
          </div>

          <div>
            <div class="font-semibold">{translate("TYPE")}:</div>
            <div class="text-justify text-xs">
              {#if $mealPlanStore.foodType}
                <div class="flex flex-wrap gap-2">
                  {#each $mealPlanStore.foodType.split(",") as foodType}
                    <Badge>{foodType}</Badge>
                  {/each}
                </div>
              {:else}
                Nema
              {/if}
            </div>
          </div>
          <!-- <div class="flex flex-wrap gap-2">
            {#each $mealPlanStore?.foodType.split(",") as foodType}
              <Badge>{foodType}</Badge>
            {/each}
          </div> -->
          <div>
            <div class="font-semibold">{translate("DESCRIPTION")}:</div>
            <div class="text-justify text-xs leading-tight line-clamp-2">
              {#if $mealPlanStore.description}
                {$mealPlanStore.description}
              {:else}
                Nema
              {/if}
            </div>
          </div>
          <!-- <div class="text-xs text-justifyleading-tight line-clamp-6 text-white">
            {$mealPlanStore.description ? $mealPlanStore.description : ""}
          </div> -->
        </div>
      </div>
    </div>

    <div class="px-4 flex justify-between">
      <div class="flex flex-col items-start gap-4">
        <ButtonComponent on:click={onCreateTextMeal}>
          {translate("TYPE_TEXTUALLY")}
        </ButtonComponent>
        <ButtonComponent on:click={onCreatePDFMeal}>
          {translate("UPLOAD_PDF")}
        </ButtonComponent>
        <ButtonComponent on:click={onExportToPDF}>
          {translate("EXPORT_TO_PDF")}
        </ButtonComponent>
      </div>
      <div class="flex-col items-end gap-4">
        <ButtonComponent on:click={onCreateHeaderMeal}>
          {translate("ADD_HEADER")}
        </ButtonComponent>
        <ButtonComponent on:click={onCreateRecipeMeal}>
          {translate("CREATE_A_MEAL")}
        </ButtonComponent>
      </div>
    </div>

    <div class="text-center text-xs">
      {translate("TOTAL_MEAL_COUNT")}:
      <span class="font-bold">{getCount()}</span>
    </div>

    {#if $mealPlanStore.meals?.length}
      <div
        class="px-4 flex-col gap-4 mb-4"
        use:dragHandleZone={{
          items: $mealPlanStore.meals,
          flipDurationMs: 0,
          dropTargetStyle: {},
          dropFromOthersDisabled: true,
          type: "meals",
        }}
        on:consider={mealPlansDndConsider}
        on:finalize={mealPlansDndFinalize}
      >
        {#each $mealPlanStore.meals as meal (meal.id)}
          <div class="outline-none" animate:flip={animations.flip}>
            <MealExpansionPanel
              {meal}
              isDraggable
              isCommentable={window.location.href.includes("/profile")}
              isExpanded={false}
            />
          </div>
        {/each}
      </div>
    {/if}
  </div>

  {#if Capacitor.getPlatform() === "ios"}
    <div class="mb-2"></div>
  {/if}
{/if}
