<script lang="ts">
  import {onDestroy, onMount} from "svelte";
  import {flip} from "svelte/animate";
  import {Capacitor} from "@capacitor/core";
  import {animations, entities} from "lib";
  import {recipesStore, user} from "stores";
  import {RecipeComponent, RecipesToolbarComponent} from "diet";
  import {InfiniteScroll} from "ui";
  import TopMenu from "../../components/TopMenu.svelte";

  const unsubscribeUserStore = user.subscribe(recipesStore.fetchData);

  onDestroy(unsubscribeUserStore);
  onMount(recipesStore.loadCache);
</script>

<svelte:head>
  {#if Capacitor.isNativePlatform()}
    <style>
      body {
        overflow: hidden;
      }
    </style>
  {/if}
</svelte:head>

<TopMenu entity={entities.DIET}/>

<div class="p-4 flex flex-col gap-4">
  <RecipesToolbarComponent isAddVisible/>

  {#each $recipesStore.items as recipe (recipe.id)}
    <div animate:flip={animations.flip}>
      <RecipeComponent {recipe}/>
    </div>
  {/each}

  <InfiniteScroll
    hasMore={$recipesStore.hasMore}
    isFetchingMore={$recipesStore.isFetchingMore}
    on:loadMore={recipesStore.fetchMoreData}
  />

  {#if Capacitor.isNativePlatform()}
    <div class="mb-12"></div>
  {/if}
</div>
