<script lang="ts">
  export let barColor: string;
  export let percentage: number;
</script>

<div
  class="color-bar"
  style:width="{percentage}%"
  style={`background-color: ${barColor}`}
/>

<style>
  .color-bar {
    height: 4px;
  }
</style>
