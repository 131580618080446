<script lang="ts">
  import { onMount } from "svelte";
  import { Button, Checkbox, Heading, Search, Spinner } from "flowbite-svelte";
  import {
    api,
    getWithJwt,
    isClient,
    postWithJwt,
    serverlessRoutes,
    translate,
  } from "lib";
  import {
    cantDoExercises,
    dialogData,
    filtersStore2,
    showAlert,
    trainerWorkouts,
    trainerWorkoutsCount,
    user,
  } from "stores";
  import Filter from "../../../components/Filter.svelte";
  import InfiniteScroll from "../../../components/UI/InfiniteScroll.svelte";
  import { updateTrainerPrograms } from "../../../lib/updateTrainerPrograms";
  import { ButtonComponent } from "ui";

  let data: any;
  let value = "";
  let isLoading = false;
  let page = 0;
  let scrollElem: HTMLDivElement;
  let selectedIds: Array<number> = [];
  // let timeout: NodeJS.Timeout;
  $: disabled = !selectedIds.length;
  let mutexRequestId = 0;

  const createUrl = (): string => {
    let template = "";
    let sort = "";
    let name = "";
    let muscleGroup = "";

    if ($filtersStore2.template === 1) {
      template = "&default=1&template=1";
    } else {
      template = "&default=1&template=0";
    }

    if ($filtersStore2.sort !== undefined) {
      if ($filtersStore2.sort === "NAME_ASC") {
        sort = "&orderBy=name&order=ASC";
      } else if ($filtersStore2.sort === "NAME_DESC") {
        sort = "&orderBy=name&order=DESC";
      } else if ($filtersStore2.sort === "DATE_ASC") {
        sort = "&orderBy=id&order=ASC";
      } else {
        sort = "&orderBy=id&order=DESC";
      }
    }

    if (value) {
      name = `&name=${value}`;
    }

    if ($filtersStore2.tags.length) {
      muscleGroup = `&muscleGroup=%5B${$filtersStore2.tags
        .map((filter): string => `%22${filter}%22`)
        .join(",")}%5D`;
    }

    return `${serverlessRoutes.WORKOUT}/list?take=15&skip=${
      page * 15
    }${template}${name}${muscleGroup}${sort}`;
  };

  const onFetchData = async (): Promise<void> => {
    if (!$user) {
      return;
    }

    // isLoading = true;
    page = 0;
    mutexRequestId++;
    const currentMutexRequestId = mutexRequestId;

    try {
      const response = await getWithJwt(createUrl());
      if (currentMutexRequestId === mutexRequestId) {
        $trainerWorkouts = response.data.data;
        $trainerWorkoutsCount = response.data.count;
      }
    } catch (error) {
      console.error(error);
    }

    // isLoading = false;
  };

  const onLoadMore = async (): Promise<void> => {
    if (!$user) {
      return;
    }

    // isLoading = true;
    page += 1;

    try {
      const response = await getWithJwt(createUrl());
      $trainerWorkouts = [...$trainerWorkouts, ...response.data.data];
    } catch (error) {
      console.error(error);
    }

    // isLoading = false;
  };

  const onInput = (): void => {
    // clearTimeout(timeout);
    // timeout = setTimeout(async (): Promise<void> => {
    onFetchData();
    // }, 500);
  };

  // const fetchCantDo = async (): Promise<void> => {
  //   if (window.location.href.includes("/profile") && !isClient($user)) {
  //     const userId = window.location.href.split("/")[5];
  //     const workoutIds = $program.workouts.map((workout) => workout.id);

  //     const response = await getWithJwt(`${serverlessRoutes.WORKOUT}/cant-do-exercises?workoutIds=%5B${workoutIds.join(",")}%5D&userId=${userId}`);
  //     cantDo = response.data.cantDo;
  //   }
  // };

  const onSubmit = async (): Promise<void> => {
    try {
      isLoading = true;

      const url = `${serverlessRoutes.WORKOUT}/copy`;
      const response = await postWithJwt(url, {
        workoutIds: selectedIds,
        propertiesToOverride: {
          programId: data.programId,
          position: data.position,
          default: 0,
          isTemplate: 0,
          trainerId: null,
        },
      });

      // if (response.data) {
      updateTrainerPrograms(
        data.programId,
        (p) => (p.workouts = [...p.workouts, ...response.data])
      );

      if (window.location.href.includes("/profile") && !isClient($user)) {
        const userId = window.location.href.split("/")[5];
        const workoutIds = [response.data[0].id];
        const res = await getWithJwt(
          `${serverlessRoutes.WORKOUT}/cant-do-exercises?workoutIds=%5B${workoutIds.join(",")}%5D&userId=${userId}`
        );

        $cantDoExercises.workoutIds = [
          ...$cantDoExercises.workoutIds,
          ...res.data.cantDo,
        ];
      }
      // }

      $showAlert.color = "black";
      $showAlert.message = `
        ${translate("SUCCESSFULLY_IMPORTED")}
        ${translate("WORKOUT")}
      `;

      $dialogData.type = "";
      $dialogData.data = {};
    } catch (error) {
      console.error(error);
      isLoading = false;
      $showAlert.color = "red-400";
      $showAlert.message = translate("ERROR_IMPORTING_WORKOUT");
    }
  };

  const onChange = (id: number): void => {
    if (selectedIds.includes(id)) {
      selectedIds = selectedIds.filter(
        (selectedId): boolean => selectedId !== id
      );
    } else {
      selectedIds = [...selectedIds, id];
    }
  };

  onMount(onFetchData);

  export { data };
</script>

<div class="p-4 flex flex-col gap-4 overflow-hidden">
  <Heading align="center" tag="h6">{translate("IMPORT_WORKOUT")}</Heading>

  <Search
    size="md"
    placeholder="{translate('SEARCH')} {translate('WORKOUT').toLowerCase()}"
    bind:value
    on:input={onInput}
  />

  <Filter type="WORKOUTS" isCountVisible on:fetchData={onFetchData} />

  <div
    class="h-80 pr-2 flex flex-col gap-4 overflow-y-scroll"
    bind:this={scrollElem}
  >
    {#each $trainerWorkouts as workout}
      <Checkbox
        checked={selectedIds.includes(workout.id)}
        on:change={() => onChange(workout.id)}>{workout.name}</Checkbox
      >
    {/each}

    <InfiniteScroll
      hasMore={$trainerWorkouts.length <= $trainerWorkoutsCount}
      on:loadMore={onLoadMore}
    />
  </div>

  <div class="h-10 flex justify-center">
    {#if isLoading}
      <Spinner size="10" color="green" />
    {:else}
      <ButtonComponent
        {disabled}
        cy="import-workouts-button"
        on:click={onSubmit}
      >
        {translate("IMPORT")}
      </ButtonComponent>
    {/if}
  </div>
</div>
