<script lang="ts">
  import {push} from "svelte-spa-router";
  import {Button} from "flowbite-svelte";
  import {dialogTypes, translate} from "lib";
  import {dialogData, trainerClientGroups} from "stores";
  import Svg from "../../components/UI/Svg.svelte";
  import More from "../../components/UI/More.svelte";
  import type {MenuItem} from "interfaces";
  import GroupClients from "./GroupScreenViews/GroupClients.svelte";
  import MealPlans from "./GroupScreenViews/MealPlans.svelte";
  import WorkoutPlans from "./GroupScreenViews/WorkoutPlans.svelte";
  import { ButtonComponent } from "ui";

  let params: any; // spa-router postavlja params.id (string)
  const group = $trainerClientGroups.find(({id}) => id === parseInt(params.id));
  let props = {};

  let menuItems: MenuItem[] = [{
    title: "ASSIGN_MEAL_PLAN",
    icon: "utensils",
    executeFunction (): void {
      $dialogData.data = {};
      $dialogData.type = dialogTypes.ASSIGN_MEAL_PLAN;
    }
  }, {
    title: "ASSIGN_TRAINING_PROGRAM",
    icon: "dumbbell",
    executeFunction (): void {
      $dialogData.data = {};
      $dialogData.type = dialogTypes.ASSIGN_WORKOUT_PLAN;
    }
  }, {
    title: "EDIT",
    icon: "edit",
    executeFunction (): void {
      $dialogData.data = {group};
      $dialogData.type = dialogTypes.CREATE_EDIT_GROUP;
    }
  }, {
    title: "DELETE",
    icon: "delete",
    executeFunction (): void {
      $dialogData.data = {
        executeFunction (): void {},
        title: translate("GROUP_U"),
      };
      $dialogData.type = dialogTypes.CONFIRM_DELETE;
    }
  }];

  let view = GroupClients;

  const navLinks = [{
    icon: "users",
    isActive: true,
    onView (): void {
      navLinks[0].isActive = true;
      view = GroupClients;
    }
  }, {
    icon: "utensils",
    isActive: false,
    onView (): void {
      navLinks[1].isActive = true;
      view = MealPlans;
    }
  }, {
    icon: "dumbbell",
    isActive: false,
    onView (): void {
      navLinks[2].isActive = true;
      view = WorkoutPlans;
    }
  }, {
    icon: "message",
    isActive: false,
    onView (): void {
      push(`/chat/group/${group.chatSessionId}`);
    }
  }];

  const resetActive = (): void => {
    navLinks.forEach((navLink): void => { navLink.isActive = false; });
  };

  const onGoBack = (): void => {
    push("/clients/groups");
  };

  export {params};
</script>

<div class="mt-12">

  <div class="p-4 relative flex flex-col border-b">
    <div class="absolute top-4 left-4">
      <Svg name="left-arrow-button" size="{16}" on:click={onGoBack}/>
    </div>
    <div class="flex flex-col items-center justify-center">
      <img class="h-32 w-32" src="default-group.png" alt="Group Avatar"/>
      <div>{group.name}</div>
    </div>
    <div class="absolute top-4 right-4">
      <More {menuItems}/>
    </div>
  </div>

  <div class="mt-4 flex flex-col gap-4">
    <div class="flex justify-evenly">
      {#each navLinks as navLink}
        <ButtonComponent
          isPill
          on:click="{() => {
            resetActive();
            navLink.onView();
          }}">
          <Svg
            name="{navLink.icon}"
            size="{16}"
            customColor="{navLink.isActive ? "bg-white" : "bg-black"}"/>
        </ButtonComponent>
      {/each}
    </div>

    <div>
      <svelte:component this="{view}" {...props}/>
    </div>
  </div>
</div>
