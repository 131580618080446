<script lang="ts">
  import { slide } from "svelte/transition";
  import {
    api,
    deleteWithJwt,
    dialogTypes,
    getWithJwt,
    postWithJwt,
    putWithJwt,
    serverlessRoutes,
    translate,
  } from "lib";
  import Svg from "./Svg.svelte";
  import { onMount } from "svelte";
  import { currentClient, dialogData, showAlert, theme } from "stores";
  import MiniSpinner from "./MiniSpinner.svelte";
  import Tag from "../Tag.svelte";
  import ExpansionPanel from "./ExpansionPanel.svelte";
  import { parseDate } from "../../lib/parseDate";
  import {
    trainerClients,
    remindersMap,
    clientTagsMap,
  } from "../../stores/trainerStores";
    import { ButtonComponent } from "ui";

  const form = {
    notes: { value: "", error: "" },
  };

  const generateReminderMenuItems = (reminder) => {
    return [
      {
        title: "EDIT",
        icon: "edit",
        executeFunction: () => {
          $dialogData.data = {
            id: reminder.id,
            note: reminder.note,
            date: reminder.date,
            clientId: $currentClient.id,
          };
          $dialogData.type = dialogTypes.CREATE_NEW_REMINDER;
        },
      },
      {
        title: "DELETE",
        icon: "delete",
        executeFunction: () => {
          $dialogData.data = {
            title: translate("REMINDER"),
            executeFunction: () => {
              deleteReminder(reminder.id);
            },
          };
          $dialogData.type = dialogTypes.CONFIRM_DELETE;
        },
      },
    ];
  };

  let note;
  let isExpanded = false;
  let isLoading = false;
  let disabled = true;

  const onToggleExpanded = (): void => {
    isExpanded = !isExpanded;
  };

  const deleteReminder = async (reminderId: number) => {
    try {
      const res = await deleteWithJwt(
        `${serverlessRoutes.REMINDERS}?reminderId=${reminderId}`
      );
      if (res && res.data === 1) {
        $remindersMap[$currentClient.id] = $remindersMap[
          $currentClient.id
        ].filter((r) => r.id !== reminderId);
        $currentClient = $currentClient;
        $trainerClients = $trainerClients;
        $dialogData.data = {};
        $dialogData.type = "";
        setTimeout(() => {
          $showAlert.message = translate("REMINDER_DELETED_SUCCESSFULLY");
        });
      }
    } catch (error) {
      $showAlert.color = "red-400";
      $showAlert.message = translate("SOMETHING_WENT_WRONG");
      console.error(error);
    }
  };

  const onInput = (field: keyof typeof form): void => {
    if (field === "notes") {
      const { value } = form[field];

      if (value.length > 5000) {
        form[field].error = translate("FIELD_MAXIMUM_5000");
      } else {
        form[field].error = "";
      }
    }

    disabled = Object.values(form).some((field) => field.error !== "");
  };

  const fetchNotes = async () => {
    try {
      const res = await getWithJwt(
        `${api}/trainer/client/${$currentClient.id}/note`
      );
      if (res.length > 0) {
        note = res[0];
        form.notes.value = note.note;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchReminders = async (clientIds: number[]) => {
    try {
      const result = await postWithJwt(`${serverlessRoutes.REMINDERS}/map`, {
        clientIds,
      });
      $remindersMap = { ...result.data };
      return result;
    } catch (error) {
      console.error(error);
    }
  };

  const fetchTags = async (clientIds: number[]) => {
    try {
      const result = await postWithJwt(`${serverlessRoutes.TAGS}/map`, {
        clientIds,
      });
      $clientTagsMap = { ...result.data };
    } catch (error) {
      console.error(error);
    }
  };

  onMount(() => {
    if ($remindersMap && $remindersMap[$currentClient.id]) {
    } else {
      fetchReminders([$currentClient.id]);
    }
    if ($clientTagsMap && $clientTagsMap[$currentClient.id]) {
    } else {
      fetchTags([$currentClient.id]);
    }
    fetchNotes();
  });

  const onSubmit = async (): Promise<void> => {
    if (note && note.id) {
      try {
        isLoading = true;
        const res = putWithJwt(
          `${api}/trainer/client/${$currentClient.id}/note/${note.id}`,
          {
            note: form.notes.value,
            clientId: $currentClient.id,
          }
        );
        isLoading = false;
        $showAlert.message = translate("NOTE_UPDATED_SUCCESSFULLY");
      } catch (error) {
        $showAlert.color = "red-400";
        $showAlert.message = translate("SOMETHING_WENT_WRONG");
        isLoading = false;
        console.error(error);
      }
    } else {
      try {
        isLoading = true;
        const res = await postWithJwt(
          `${api}/trainer/client/${$currentClient.id}/note`,
          {
            note: form.notes.value,
            clientId: $currentClient.id,
          }
        );
        note = res;
        form.notes.value = note.note;
        // reminders[$currentClient.id] = [...res];
        $showAlert.message = translate("NOTE_CREATED_SUCCESSFULLY");
        isLoading = false;
      } catch (error) {
        $showAlert.color = "red-400";
        $showAlert.message = translate("SOMETHING_WENT_WRONG");
        console.error(error);
        isLoading = false;
      }
    }
  };
</script>

<div
  class="
    flex
    flex-col
    border
    rounded-md
    border-slate-200
    dark:border-zinc-600
    transition-shadow
    duration-[400ms]
    ease-linear
  "
  class:shadow={isExpanded}
>
  <!-- svelte-ignore a11y-no-static-element-interactions -->
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <div
    class="p-2 flex items-center justify-between"
    on:click={onToggleExpanded}
  >
    <span class="flex flex-row items-center">
      <span>Beleške i oznake</span>
      <span class="ml-1 text-xxs">(vidljivo samo treneru)</span>
      {#if $clientTagsMap[$currentClient.id] && $clientTagsMap[$currentClient.id].length > 0}
        {#each $clientTagsMap[$currentClient.id] as tag}
          <Tag color={tag.color} />
        {/each}
      {/if}
      {#if $remindersMap[$currentClient.id]?.length}
        <Svg name="reminder" size={16} />
      {/if}
    </span>
    <div class="chevron ml-2" class:isExpanded>
      <Svg name="down-caret" size={16} />
    </div>
  </div>
  {#if isExpanded}
    <form
      class="p-4 flex flex-col gap-4"
      in:slide
      on:submit|preventDefault={onSubmit}
    >
      <div>
        <label for="notes">{translate("CLIENT_NOTES")}</label>
        <textarea
          class="w-full p-2 border border-slate-200 dark:border-zinc-600 rounded-md dark:bg-zinc-800 dark:text-slate-100 focus:ring-2 focus:ring-primary-500 dark:focus:ring-primary-500 focus:outline-0"
          id="notes"
          placeholder={translate("NOTES")}
          rows={4}
          bind:value={form.notes.value}
          on:input={() => onInput("notes")}
        />
      </div>

      {#if $currentClient && $clientTagsMap[$currentClient.id] && $clientTagsMap[$currentClient.id].length > 0}
        <div class="mb-1">
          <p class="my-3">{translate("CLIENT_TAGS")}:</p>
          <div class="flex flex-col items-center gap-2">
            {#if $clientTagsMap[$currentClient.id] && $clientTagsMap[$currentClient.id].length > 0}
              {#each $clientTagsMap[$currentClient.id] as tag}
                <div class="flex flex-row">
                  <Tag color={tag.color} /> <span>{tag.note}</span>
                </div>
              {/each}
            {/if}
          </div>
        </div>
      {/if}

      {#if $remindersMap[$currentClient.id] && $remindersMap[$currentClient.id]?.length > 0}
        <div class="mb-1 w-full">
          <p class="my-3">{translate("REMINDERS")}:</p>
          <div class="flex flex-col w-full gap-2">
            {#each $remindersMap[$currentClient.id] as reminder}
              <ExpansionPanel
                title={parseDate(reminder.date)}
                subtitle={reminder.note}
                isExpandable={false}
                menuItems={generateReminderMenuItems(reminder)}
              />
            {/each}
          </div>
        </div>
      {/if}

      <div class="h-10 flex justify-center">
        {#if isLoading}
          <MiniSpinner />
        {:else}
          <ButtonComponent on:click={onSubmit} {disabled}>{translate("SAVE")}</ButtonComponent
          >
        {/if}
      </div>
    </form>
  {/if}
</div>

<style>
  .chevron {
    /* ista tranzicija kao default svelte:slide */
    transition: transform 400ms linear;
  }
  .isExpanded {
    transform: rotate(180deg);
  }
  textarea:focus {
    border: 0;
  }
</style>
