<script lang="ts">
  import { onMount } from "svelte";
  import { api, deleteWithJwt, dialogTypes, getWithJwt, postFormDataWithJwt, translate } from "lib";
  import { dialogData, user } from "stores";
  import { ButtonComponent, Divider, ExpansionPanel, More, Svg } from "ui";

  let templateMessages: any[] = [];
  let filter: string | null = null;
  let messageToAdd: string | null = null;
  let file: File;
  let fileInput: HTMLInputElement;
  let isImageEdit = false;
  let templateMessageId: number | null = null;

  const fetchTemplateMessages = async () => {
    try {
      const res = await getWithJwt(
        `${api}/chat/template_message${filter ? `?filterBy=${filter}` : ``}`
      );
      templateMessages = res.data;
    } catch (error) {
      console.error(error);
    }
  };

  const deleteTemplateMessage = async (id: any) => {
    try {
      await deleteWithJwt(`${api}/chat/template_message/${id}`);

      templateMessages = templateMessages.filter(
        (templateMessage) => templateMessage.id !== id
      );
    } catch (error) {
      console.error(error);
    }
  };

  const onAddMessage = async () => {
    $dialogData.data = {
      executeFunction (response) {
        templateMessages = [response, ...templateMessages];
      }
    };
    $dialogData.type = dialogTypes.CREATE_EDIT_TEMPLATE_MESSAGE;
  };

  const uploadImage = async () => {
    try {
      const formData = new FormData();

      formData.append("text", JSON.stringify(null));
      formData.append("trainerId", $user.id);
      formData.append("image", file);

      const res = await postFormDataWithJwt(
        `${api}/chat/template_message`,
        formData
      );

      fetchTemplateMessages();
    } catch (error) {
      console.error(error);
    }
  };

  const editImage = async () => {
    try {
      const formData = new FormData();

      formData.append("text", JSON.stringify(null));
      formData.append("trainerId", $user.id);
      formData.append("image", file);

      const res = await postFormDataWithJwt(
        `${api}/chat/template_message/${templateMessageId}`,
        formData, "PUT"
      );

      fetchTemplateMessages();
    } catch (error) {
      console.error(error);
    }
  };

  const onFileSelected = async (e: any) => {
    file = e.target.files[0];

    if (isImageEdit) {
      await editImage();
    } else {
      await uploadImage();
    }
  };

  const onAddImage = () => {
    fileInput.click();
  };

  onMount(fetchTemplateMessages);
</script>

<ExpansionPanel title="{translate("TEMPLATE_MESSAGES")}">
  <div class="flex flex-col gap-4">
    {#each templateMessages as templateMessage}
      <div>
        <div class="flex gap-4 items-center">
          <div class="grow">
            {#if templateMessage.fileable}
              <img
                alt="Template Message"
                class="w-20 h-20"
                src={templateMessage.imageUrl}
              />
            {:else}
              <div class="text-xxs text-justify">{templateMessage.text}</div>
            {/if}
          </div>
          <More menuItems={[
            {
              title: "EDIT",
              icon: "edit",
              executeFunction () {
                if (templateMessage.fileable) {
                  isImageEdit = true;
                  templateMessageId = templateMessage.id;
                  onAddImage();
                } else {
                  $dialogData.data = {
                    templateMessage,
                    executeFunction (response) {
                      const msg = templateMessages.find(({id}) => id === response.id);
                      const index = templateMessages.indexOf(msg);
                      templateMessages = templateMessages.with(index, response);
                    }
                  };
                  $dialogData.type = dialogTypes.CREATE_EDIT_TEMPLATE_MESSAGE;
                }
              }
            }, {
              title: "DELETE",
              icon: "delete",
              executeFunction() {
                $dialogData.data = {
                  title: translate("TEMPLATE_MESSAGE_U"),
                  async executeFunction () {
                    await deleteTemplateMessage(templateMessage.id);
                  }
                };
                $dialogData.type = dialogTypes.CONFIRM_DELETE;
              }
            }
          ]}/>
        </div>
        <Divider width="100%"/>
      </div>
    {/each}
    <div class="flex justify-center gap-4">
      <ButtonComponent isOutline on:click={onAddMessage}>
        {translate("ADD_MESSAGE")}
      </ButtonComponent>
      <ButtonComponent isOutline on:click={() => {isImageEdit = false; onAddImage();}}>
        {translate("ADD_IMAGE")}
      </ButtonComponent>
      <input
        class="hidden"
        type="file"
        accept=".jpg, .jpeg, .png, .mp4"
        on:change={(e) => onFileSelected(e)}
        bind:this={fileInput}
      />
    </div>
  </div>
</ExpansionPanel>
