<script lang="ts">
  import Notification from "../../components/Notification.svelte";
  import {
    notifications,
    notificationCount,
    unreadNotifications,
    notificationQuery,
  } from "../../stores/notificationStore";
  import { user } from "../../stores/userStore";
  import { translate } from "../../lib/translate";
  import Spinner from "../../components/UI/Spinner.svelte";
  import { onDestroy, onMount } from "svelte";
  import { fetchNotifications } from "../../lib/fetchNotifications";
  import { isClient } from "../../lib/roles";
  import {CheckboxComponent} from "ui";
  import Divider from "../../components/UI/Divider.svelte";
  import { entities, patchWithJwt, postWithJwt, serverlessRoutes } from "lib";
  import { showAlert } from "stores";
  import { Capacitor } from "@capacitor/core";
  import LoadMoreButton from "../../components/LoadMoreButton.svelte";
  import TopMenu from "../../components/TopMenu.svelte";

  let isLoading: boolean = true;
  let scrollElem: HTMLDivElement;
  let unreadFilter: boolean = false;
  let commentFilter: boolean = false;
  let isMounted = false;

  $: {
    if (isMounted) {
      $notificationQuery = "";
      if (unreadFilter) $notificationQuery += "&unopened=1";
      if (commentFilter) $notificationQuery += "&comments=1";
      $notifications = [];
      fetchNotifications();
    }
    isMounted = true;
  }

  const unsubscribe = notifications.subscribe((res) => {
    if (res.length > 0) {
      isLoading = false;
    }

    setTimeout(() => (isLoading = false), 500);
  });

  const onLoadMore = () => {
    // skip ne mora da postoji jer je skip notifications.length
    fetchNotifications();
  };

  const readAll = async () => {
    const url = `${serverlessRoutes.NOTIFICATIONS}/read-all`;
    try {
      const res = await patchWithJwt(url, {});
      if (res.data.message === "Success") {
        $unreadNotifications = 0;
        $notifications = $notifications.map((n) => {
          n.seenAt = true;
          return n;
        });
        $showAlert.message = translate("ALL_NOTIFICATIONS_MARKED_AS_READ");
      }
    } catch (e) {
      console.error(e);
    }
  };

  onDestroy(() => {
    if (commentFilter || unreadFilter) {
      $notificationQuery = "";
      $notifications = [];
      fetchNotifications();
    }
    unsubscribe();
  });

  onMount(async () => {
    // const response = await postWithJwt(
    //   `${serverlessRoutes.NOTIFICATIONS}/fetch-comments-status`,
    //   {notificationIds: $notifications.map(({id}) => id)}
    // );

    // const notificationCache = localStorage.getItem("notificationCache");
    // if (notificationCache) {
    //   const { notifications, count, unreadNotifications } =
    //     JSON.parse(notificationCache);
    //   $notifications = notifications;
    //   $notificationCount = count;
    //   $unreadNotifications = unreadNotifications;
    // }
  });
</script>

{#if $user && !isClient($user)}
  <TopMenu entity={entities.NOTIFICATIONS} />
{/if}
<div
  class="bg-white dark:bg-zinc-800 text-slate-900 dark:text-slate-100"
  style={Capacitor.isNativePlatform()
    ? isClient($user)
      ? "margin-top: 48px;"
      : "margin-top: 24px;"
    : ""}
  bind:this={scrollElem}
>
  {#if isLoading}
    <Spinner />
  {:else}
    {#if $user && !isClient($user)}
      <div class="flex flex-row justify-between">
        <div class="pl-3 pt-3 pb-3">
          <span class="flex flex-row">
            <div
              role="button"
              tabindex="0"
              on:click={() => (unreadFilter = !unreadFilter)}
              on:keydown={() => (unreadFilter = !unreadFilter)}
            >
              <CheckboxComponent bind:value={unreadFilter} />
            </div>
            <p class="ml-1 text-smaller">{translate("SHOW_ONLY_UNREAD")}</p>
          </span>
          <div class="h-2" />
          <span class="flex flex-row">
            <div
              role="button"
              tabindex="1"
              on:click={() => (commentFilter = !commentFilter)}
              on:keydown={() => (commentFilter = !commentFilter)}
            >
              <CheckboxComponent bind:value={commentFilter} />
            </div>
            <p class="ml-1 text-smaller">{translate("SHOW_ONLY_COMMENTS")}</p>
          </span>
        </div>
        <div class="flex">
          <div
            role="button"
            tabindex="0"
            on:click={async () => await readAll()}
            on:keypress={async () => await readAll()}
            class="pr-3 underline text-smaller self-center"
          >
            Označi sve kao pročitano
          </div>
        </div>
      </div>

      <Divider />
    {/if}

    {#if Capacitor.getPlatform() === "ios"}
      <div class="pt-5" />
    {/if}

    {#each $notifications as notification}
      <Notification {notification} />
    {/each}

    {#if Capacitor.getPlatform() === "ios"}
      <div class="pb-16" />
    {/if}
    {#if Capacitor.getPlatform() === "android"}
      <div class="pb-12" />
    {/if}

    {#if $notifications.length < $notificationCount}
      <div class="mt-3"></div>
      <LoadMoreButton fetchMoreData={onLoadMore} />
      <div class="mb-3"></div>
    {/if}

    {#if $notifications.length === 0}
      <h3 class="text-center" style="margin-top: 64px;">
        {translate("NO_NOTIFICATIONS")}
      </h3>
    {:else}
      <!-- <InfiniteScroll
        hasMore={$notifications.length < $notificationCount}
        on:loadMore={onLoadMore}
      /> -->
    {/if}

    {#if $notifications.length === 0}
      <h3 class="text-center" style="margin-top: 64px;">
        {translate("NO_NOTIFICATIONS")}
      </h3>
    {:else}
      <!-- <InfiniteScroll
        hasMore={$notifications.length < $notificationCount}
        on:loadMore={onLoadMore}
      /> -->
    {/if}
  {/if}
</div>
