<script lang="ts">
  import { alertStore } from "stores";

  import {
    deleteWithJwt,
    dialogTypes,
    parseDate,
    serverlessRoutes,
    translate
  } from "lib";

  import { clientGoals, dialogData, showAlert, theme } from "stores";
  import { More } from "ui";
  import ColorBar from "../Diet/ColorBar.svelte";
  import type { MenuItem } from "../../interfaces/MenuItem";

  export let goal: any;

  const currentPercentage =
    goal.startingValue === goal.currentValue
      ? 0
      : goal.startingValue > goal.currentValue
        ? ((goal.startingValue - goal.goalValue) /
            (goal.startingValue - goal.currentValue)) *
          100
        : ((goal.startingValue - goal.currentValue) /
            (goal.startingValue - goal.goalValue)) *
          100;

  const editGoalDialog = (): void => {
    $dialogData.data = {goal};
    $dialogData.type = dialogTypes.CREATE_EDIT_GOAL;
  };

  const deleteGoal = async (): Promise<void> => {
    const {data, error} = await deleteWithJwt(serverlessRoutes.GOAL, {
      ids: [goal.id]
    });

    if (error && !data) {
      alertStore.show(translate("ERROR_DELETING_GOAL"), "error");
      return console.error(error);
    }

    $clientGoals = $clientGoals.filter(({id}) => id !== goal.id);
    $showAlert.message = `${translate("SUCCESSFULLY_DELETED")} ${translate(
      "GOAL"
    ).toLowerCase()}.`;
  };

  const deleteGoalDialog = (): void => {
    $dialogData.data = {
      title: translate("GOAL"),
      executeFunction: deleteGoal
    };

    $dialogData.type = dialogTypes.CONFIRM_DELETE;
  };

  const menuItems: MenuItem[] = [{
    icon: "edit",
    title: "EDIT",
    executeFunction: editGoalDialog
  }, {
    icon: "delete",
    title: "DELETE",
    executeFunction: deleteGoalDialog
  }];
</script>

<!-- class="expansion-panel flex-col"
  style="height: 144px; justify-content: space-around" -->
<div
  class="p-2 flex flex-col border rounded-md border-slate-200 dark:border-zinc-600 transition-shadow duration-[400ms] ease-linear"
>
  <div class="flex-row center-space-between">
    <span class="mgl-8 mt-1">
      {goal.measurement.name}
    </span>
    <div class="flex-row">
      <span class="mt-2">
        {goal.startingValue} > {goal.goalValue}
      </span>
      <div class="ml-2 mr-3 mt-3">
        <More {menuItems} />
      </div>
    </div>
  </div>
  <div class="mgl-8 mb-1">{goal.description}</div>
  <div class="flex-row center-space-between">
    <span class="mgl-8 text-smaller">
      {parseDate(goal.createdAt)}
    </span>
    <span class="mgr-8 text-smaller">
      {parseDate(goal.expireAt)}
    </span>
  </div>
  <div class="flex-row center-center">
    <span class="text-bold"
      >{goal.currentValue}{goal.measurement.measurementUnit}</span
    >
  </div>
  <div class="flex-col center-center">
    <div class="flex-row mgb-4" style="width: 96%;">
      <ColorBar
        barColor={$theme === "dark" ? "rgb(99 102 241)" : "#319795"}
        percentage={currentPercentage}
      />
      <ColorBar barColor="#cbf2f2" percentage={100 - currentPercentage} />
    </div>
    <div class="flex-row center-space-between" style="width: 96%;">
      <span class="text-bold"
        >{goal.startingValue}{goal.measurement.measurementUnit}</span
      >
      <span class="text-bold"
        >{goal.goalValue}{goal.measurement.measurementUnit}</span
      >
    </div>
  </div>
</div>
