<script lang="ts">
  import { slide } from "svelte/transition";
  import { animations, deleteWithJwt, dialogTypes, serverlessRoutes, translate } from "lib";
  import { alertStore, currentClient, dialogData, paymentsStore } from "stores";
  import { More, ExpandCollapseComponent } from "ui";

  let payment: any;
  let isExpanded = false;

  const editPaymentDialog = (): void => {
    $dialogData.data = {
      client: $currentClient,
      payment
    };

    $dialogData.type = dialogTypes.CREATE_EDIT_PAYMENT;
  };

  const deletePayment = async (): Promise<void> => {
    const {id} = payment;

    const {data, error} = await deleteWithJwt(serverlessRoutes.PAYMENT, {
      ids: [id]
    });

    if (error && !data) {
      alertStore.show(translate("ERROR_DELETING_PAYMENT"), "error");
      return console.error(error);
    }

    paymentsStore.remove([id]);
    alertStore.show(translate("SUCCESSFULLY_DELETED_PAYMENT"));
  };

  const deletePaymentDialog = (): void => {
    $dialogData.data = {
      title: translate("PAYMENT_U"),
      executeFunction: deletePayment
    };

    $dialogData.type = dialogTypes.CONFIRM_DELETE;
  };

  const menuItems = [{
    title: "EDIT",
    icon: "edit",
    executeFunction: editPaymentDialog,
  }, {
    title: "DELETE",
    icon: "delete",
    executeFunction: deletePaymentDialog,
  }];

  const onToggleExpanded = (): void => {
    isExpanded = !isExpanded;
  };

  export { payment };
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-no-static-element-interactions -->
<div
  class="
    flex
    flex-col
    border
    border-slate-200
    dark:border-zinc-600
    rounded-md
  "
>
  <div class="p-2 flex justify-between" on:click={onToggleExpanded}>
    <div class="flex gap-4">
      <div>{payment.amount} {payment.currency}</div>
      <div>{new Date(payment.date).toLocaleDateString("en-GB")}</div>
    </div>
    <div class="flex items-center gap-4">
      <More {menuItems} />
      <ExpandCollapseComponent {isExpanded}/>
    </div>
  </div>

  {#if isExpanded}
    <div
      class="p-4"
      in:slide={animations.slide}
      out:slide={animations.slide}
    >
      {payment.description}
    </div>
  {/if}
</div>
