<script>
  import WorkoutExpansionPanel from "../../components/UI/WorkoutExpansionPanel.svelte";
  import TopMenu from "../../components/TopMenu.svelte";
  import Spinner from "../../components/UI/Spinner.svelte";
  import { getWithJwt } from "../../lib/requests";
  import { api, serverlessRoutes } from "../../lib/constants";
  import { onDestroy, onMount } from "svelte";
  import { user } from "../../stores/userStore";
  import { exportToPdf } from "../../lib/pdf";
  import { clientWorkouts } from "../../stores/clientStores";
  import { translate } from "lib";
  import { Capacitor } from "@capacitor/core";
  import { ButtonComponent } from "ui";
    import { loadedWorkouts } from "stores";

  let isLoading = false;
  let programs = [];

  const fetchData = async () => {
    if (!isLoading && $user) {
      isLoading = true;
      const url = serverlessRoutes.FETCH_CLIENT_PROGRAMS;
      try {
        const data = await getWithJwt(url);
        programs = data.data;
        data.data.forEach((p) => {
          $clientWorkouts = [...p.workouts];
          p.workouts.forEach((workout) => {
            $loadedWorkouts[workout.id] = {...workout};
          });
        });

        isLoading = false;
      } catch (err) {}
    }
  };

  const unsubscribe = user.subscribe((res) => fetchData());

  onDestroy(unsubscribe);

  onMount(() => {
    fetchData();
  });
</script>

<TopMenu entity="TRAINING" />
<div class="p-4 flex flex-col gap-4 bg-white dark:bg-zinc-800">
  {#if isLoading || !$user}
    <Spinner />
  {:else if $user.expireAt && new Date($user.expireAt) < new Date()}
    <div class="text-center mt-8">
      {translate("ACCOUNT_EXPIRED_CONTACT_TRAINER")}
    </div>
  {:else}
    {#if $clientWorkouts && $clientWorkouts.length > 0}
      <div class="flex justify-end">
        <ButtonComponent
          myClasses=""
          on:click={() =>
            exportToPdf(
              $clientWorkouts[0].id,
              "program",
              "TrainingProgram.pdf"
            )}
        >
          {translate("EXPORT_TO_PDF")}</ButtonComponent
        >
      </div>
    {/if}
    <!-- <div style="margin-bottom: -8px;" /> -->
    <div class="flex flex-col gap-4">
      {#if programs.length > 0}
        {#each $clientWorkouts as workout, i}
          {#if i < (programs.find((p) => p.id === workout.programId)?.workoutDisplayOptions ? programs.find((p) => p.id === workout.programId)?.visibleWorkouts : programs.find((p) => p.id === workout.programId)?.workouts?.length)}
            <WorkoutExpansionPanel
              showComments={true}
              {workout}
              isExpanded={false}
            />
          {/if}
        {/each}
      {/if}
    </div>
  {/if}
  {#if Capacitor.isNativePlatform()}
    <div class="mb-12" />
  {/if}
</div>
