import { get, writable } from "svelte/store";
import { user } from "./userStore";
import { currentClient } from "./currentClient";
import { api, getWithJwt } from "lib";

export const clientWorkouts = writable([]);
export const clientMeasurements = writable([]);
export const clientMeasurementDataMap = writable({});
export const clientGoals = writable<any[]>([]);
export const clientFasts = writable([]);

export const clientGalleryEntries = writable<Array<any>>([]);
export const clientGalleryEntriesCount = writable(0);

const createClientGalleryEntriesStore = () => {
  const { set, update, subscribe } = writable<{
    count: number;
    data: Array<any>;
  }>({
    count: 0,
    data: [],
  });

  const fetch = async (): Promise<void> => {
    const userStore = get(user);
    const currentClientStore = get(currentClient);

    if (userStore) {
      const clientId = currentClientStore?.id || userStore.id;
      const url = `${api}/gallery/all?take=${4}&skip=${0}&clientId=${clientId}`;

      try {
        const response = await getWithJwt(url);

        update((store) => {
          store.count = response.count;
          store.data = response.data;
          return store;
        });
      } catch (error) {
        console.error(error);
      }
    }
  };

  const fetchMore = async (): Promise<void> => {
    const userStore = get(user);
    const currentClientStore = get(currentClient);

    if (userStore) {
      const clientId = currentClientStore?.id || userStore.id;
      const url = `${api}/gallery/all?take=${4}&skip=${4}&clientId=${clientId}`;

      try {
        const response = await getWithJwt(url);

        update((store) => {
          store.data = [...store.data, ...response.data];
          return store;
        });
      } catch (error) {
        console.error(error);
      }
    }
  };

  return { set, update, subscribe, fetch, fetchMore };
};

const clientGalleryEntriesStore = createClientGalleryEntriesStore();

export const clientTrainerAvatarUrl = writable<any>("");
export const clientAvatarUrl = writable<string>("");
export const clientTrainerId = writable<string>("");
