<script lang="ts">
  import { parseDateWithTime } from "../../lib/parseDateWithTime";
  import Avatar from "../UI/Avatar.svelte";
  import SeenButton from "../SeenButton.svelte";
  import { theme } from "../../stores/themeStore";

  const now = new Date();
</script>

<a
  class={`link bg-white dark:bg-zinc-800 blur-sm ${$theme === "light" ? "skeleton" : "skeleton-dark"}`}
>
  <div class="flex-row center-space-between">
    <div class="flex-row center-center">
      <Avatar avatarUrl={""} alt="User Avatar" size={56} myClasses="mg-8" />
      <div class="flex-col">
        <div class="flex-row">
          <span>Lorem Ipsum </span>
        </div>
        <span class={`last-message`}>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry.
        </span>
      </div>
    </div>
    <div class="flex flex-col items-end">
      <SeenButton isSeen={true} />
      <div class="mr-2 text-xxs">
        {parseDateWithTime(now)}
      </div>
    </div>
  </div>
</a>

<style>
  .last-message {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 160px;
    height: 1.2em;
    white-space: nowrap;
    font-size: small;
  }
  .link {
    width: 100%;
    text-decoration: none;
  }
</style>
