<script lang="ts">
  import { parseDateWithTime } from "../../lib/parseDateWithTime";
  import { urlify } from "../../lib/urlify";
  import { user } from "stores";

  export let id: number;
  export let payload: string;
  export let timestamp: string;
  export let isMine: boolean;
  export let touchstart: (
    messageId: number,
    messagePayload: string,
    timestamp: any,
    isMine: boolean
  ) => void;
  export let touchend: () => void;

  let showTimestamp = false;

  $: align = isMine ? "flex-end" : "flex-start";
  $: backgroundColor = isMine
    ? showTimestamp
      ? "bg-primary-700 dark:bg-primary-700"
      : "bg-primary-500 dark:bg-primary-500"
    : showTimestamp
      ? "bg-slate-100 dark:bg-zinc-900"
      : "";
  $: color = isMine
    ? "text-slate-100 dark:text-slate-100"
    : "text-slate-900 dark:text-slate-100";
  $: border = isMine ? "" : "border border-slate-300 dark:border-zinc-600";
</script>

<div
  unselectable="on"
  id={`${id}`}
  on:mousedown={touchstart(id, payload, timestamp, isMine)}
  on:mouseup={touchend()}
  on:touchend={touchend()}
  on:touchstart={touchstart(id, payload, timestamp, isMine)}
  on:click={() => (showTimestamp = !showTimestamp)}
  class={`message max-w-80 font-sans whitespace-pre-wrap mgl-8 mgr-8 mt-1 ${color} ${backgroundColor} ${border} `}
  style={`
  font-size: ${$user.id === 13 ? 13 : 14}px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
  align-self: ${align};`}
  class:hidden={payload?.includes(":video:")}
>
  {@html urlify(payload)}
</div>
{#if showTimestamp}
  <span
    class={`ml-3 mr-3 mt-1 text-xs font-medium text-slate-900 dark:text-slate-100`}
    style={`align-self: ${align}`}>{parseDateWithTime(timestamp)}</span
  >
{/if}

<style>
  .message {
    align-self: flex-end;
    padding: 6px 10px 6px 10px;
    border-radius: 16px;
    width: fit-content;
  }
</style>
