<script lang="ts">
  import { api, deleteWithJwt, dialogTypes, patchWithJwt, postWithJwt, serverlessRoutes, translate } from "lib";
  import More from "../UI/More.svelte";
  import type { MenuItem } from "interfaces";
  import { dialogData } from "../../stores/dialogDataStore";
  import ExerciseInWorkout from "./ExerciseInWorkout.svelte";
  import { isClient } from "../../lib/roles";
  import { user } from "../../stores/userStore";
  import { currentClient, selectedIdsStore, showAlert, trainerClientPrograms, trainerClientWorkouts, trainerPrograms, workoutsStore } from "stores";
  import { createEventDispatcher } from "svelte";
  import Svg from "../UI/Svg.svelte";
  import {
    dragHandle,
    dragHandleZone,
    type DndEvent,
    type Item,
  } from "svelte-dnd-action";
  import { flip } from "svelte/animate";

  const dispatch = createEventDispatcher();

  export let exercise: any;
  export let workoutId: any;
  export let expandedMoreSupersetId;
  export let isBeingEdited = false;

  let dragItemId: number | null = null;

  let clientMenuItems: MenuItem[] = [
    {
      title: "ENTER_RESULTS",
      icon: "results",
      executeFunction(): void {
        // const workout = $trainerClientWorkouts.data.find((w) => w.id === workoutId);
        // console.log({workout, expandedMoreSupersetId});
        // let supersetExercise = workout?.exercises.find(
        //   (ex: any) => ex.id === expandedMoreSupersetId
        // );
        // if (!supersetExercise) return;
        $dialogData.type = dialogTypes.FINISH_EXERCISE_V2;
        $dialogData.data = {
          exercise: /*supersetExercise*/exercise,
          exeriseId: workoutId,
        };
      },
    },
  ];

  let notFinishedMenuItems: MenuItem[] = [
    {
      title: "EDIT",
      icon: "edit",
      executeFunction(): void {
        $selectedIdsStore = exercise.supersetExercises.map(({ id }) => id);
        dispatch("editMode", exercise);
      },
    },
    {
      title: "BREAK_SUPERSET",
      icon: "break",
      executeFunction(): void {
        breakSuperset();
      },
    },
    {
      title: "DELETE",
      icon: "delete",
      executeFunction(): void {
        $dialogData.data = {
          title: "superset",
          executeFunction: deleteSuperset,
        };
        $dialogData.type = dialogTypes.CONFIRM_DELETE;
      },
    },
  ];

  let trainerMenuItems: MenuItem[] = exercise.finishedAt
    ? clientMenuItems
    : notFinishedMenuItems;

  let menuItems: MenuItem[] = isClient($user)
    ? clientMenuItems
    : trainerMenuItems;

  const breakSuperset = async (): Promise<void> => {
    const partialExerciseMap: any = {};

    exercise.supersetExercises.forEach((supersetExercise) => {
      partialExerciseMap[supersetExercise.id] = {
        supersetId: null,
        workoutId: exercise.workoutId,
      }
    });

    const res1 = await patchWithJwt(
      serverlessRoutes.EXERCISE,
      {partialExerciseMap}
    );

    const res2 = await deleteWithJwt(serverlessRoutes.EXERCISE, {
      ids: [exercise.id]
    });

    res1.data.exercises.forEach((exercise: any): void => {
      exercise.details = JSON.parse(exercise.details);
    });

    if (exercise.default) {
      // ovo se vrv nikad nece desi, default ne moze da bude superset?
    } else if (exercise.workoutId) {
      workoutsStore.removeExercises(exercise.workoutId, res2.data.ids);
      workoutsStore.addExercises(exercise.workoutId, res1.data.exercises);

      $trainerPrograms.forEach((program) => {
        const w = program.workouts?.find(({id}) => id === exercise.workoutId);
        if (!w?.exercises) return;
        w.exercises = w.exercises.filter((e) => !res2.data.ids.includes(e.id));
        w.exercises = [...w.exercises, ...res1.data.exercises];
      });
      $trainerPrograms = $trainerPrograms;

      $trainerClientPrograms.forEach((program) => {
        const w = program.workouts?.find(({id}) => id === exercise.workoutId);
        if (!w?.exercises) return;
        w.exercises = w.exercises.filter((e) => !res2.data.ids.includes(e.id));
        w.exercises = [...w.exercises, ...res1.data.exercises];
      });
      $trainerClientPrograms = $trainerClientPrograms;
    }

    $showAlert.message = translate("SUPERSET_BROKEN_SUCCESSFULLY");
  };

  const deleteSuperset = async () => {
    // const url = `https://train-me-api.online/api/super_set/${exercise.id}/delete`;
    try {
      // const result = await deleteWithJwt(url);

      const res1 = await deleteWithJwt(serverlessRoutes.EXERCISE, {
        ids: exercise.supersetExercises.map(({id}) => id)
      });
      const res2 = await deleteWithJwt(serverlessRoutes.EXERCISE, {
        ids: [exercise.id]
      });

      $showAlert.message = translate("SUPERSET_DELETED_SUCCESSFULLY");

      workoutsStore.removeExercises(exercise.workoutId, [exercise.id]);

      $trainerPrograms.forEach((program) => {
        const w = program.workouts?.find(({id}) => id === exercise.workoutId);
        if (!w?.exercises) return;
        w.exercises = w.exercises.filter((e) => e.id !== exercise.id);
      });
      $trainerPrograms = $trainerPrograms;

      $trainerClientPrograms.forEach((program) => {
        const w = program.workouts?.find(({id}) => id === exercise.workoutId);
        if (!w?.exercises) return;
        w.exercises = w.exercises.filter((e) => e.id !== exercise.id);
      });
      $trainerClientPrograms = $trainerClientPrograms;
    } catch (error) {
      console.error(error);
    }
  };

  const supersetExercisesDndConsider = (
    event: CustomEvent<DndEvent<Item>>
  ): void => {
    exercise.supersetExercises = event.detail.items;
  };

  const supersetExercisesDndFinalize = async (
    event: CustomEvent<DndEvent<Item>>
  ): Promise<void> => {
    exercise.supersetExercises = event.detail.items;
  };
</script>

<div
  class="superset p-2 flex flex-col gap-2 border rounded-md border-slate-200 dark:border-zinc-600 bg-white dark:bg-zinc-800"
  data-cy="superset"
>

  <div class="flex items-center">

    {#if !isBeingEdited}
      <div class="text-bold">Superset</div>
    {/if}

    <div class="flex justify-evenly grow">
      <div class="leading-tight flex flex-col text-center text-xxs">
        <div>{translate("SETS")}</div>

        {#if !isBeingEdited}
          <div class="font-bold" data-cy="superset-sets">{exercise.details.sets}</div>
        {:else}
          <input
            data-cy="superset-sets-input"
            type="number"
            bind:value={exercise.details.sets}
            class="z-1001 h-4 w-10 p-0 text-center font-bold border-t-0 border-x-0 border-b-1 focus:border-b-2 focus:border-primary-500 dark:focus:border-primary-500 dark:bg-zinc-800"
          />
        {/if}
      </div>

      <div class="leading-tight flex flex-col text-center text-xxs">
        <div>{translate("REST")}</div>

        {#if !isBeingEdited}
          <div class="font-bold" data-cy="superset-rest">{exercise.details.rest}</div>
        {:else}
          <input
            data-cy="superset-rest-input"
            type="text"
            bind:value={exercise.details.rest}
            class="z-1001 h-4 w-10 p-0 text-center font-bold border-t-0 border-x-0 border-b-1 focus:border-b-2 focus:border-primary-500 dark:focus:border-primary-500 dark:bg-zinc-800"
          />
        {/if}
      </div>
    </div>

    <div class="flex gap-4">
      {#if !isBeingEdited}
        {#if $currentClient.id}
          <More menuItems={clientMenuItems} myClasses="mgr-12" />
        {:else}
          <More
            {menuItems}
            selectSuperset={() => (expandedMoreSupersetId = exercise.id)}
            myClasses="mgr-4"
          />
          {#if !isClient($user)}
            <div use:dragHandle>
              <Svg name="drag" size={16} />
            </div>
          {/if}
        {/if}
      {/if}
    </div>
  </div>

  <div
    class="flex flex-col gap-2"
    use:dragHandleZone={{
      items: exercise.supersetExercises,
      flipDurationMs: 0,
      dropTargetStyle: {},
      dropFromOthersDisabled: true,
      type: "supersetExercises",
    }}
    on:consider={supersetExercisesDndConsider}
    on:finalize={supersetExercisesDndFinalize}
  >
    {#each exercise.supersetExercises as supersetExercise (supersetExercise.id)}
      <div class="outline-none" animate:flip={{ duration: 333 }}>
        <ExerciseInWorkout
          isSupersetBeingEdited={isBeingEdited}
          isInExpansionPanel
          exercise={supersetExercise}
          isSelectMode={$selectedIdsStore.includes(supersetExercise.id)}
        />
      </div>
    {/each}
  </div>

</div>

<style>
  input:focus {
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
</style>
