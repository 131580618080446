<script lang="ts">
  import { push } from "svelte-spa-router";
  import { translate } from "../lib/translate";
  import { dialogData } from "../stores/dialogDataStore";
  import {
    trainerClientPrograms,
    trainerPrograms,
  } from "../stores/trainerStores";
  import {
    api,
    deleteWithJwt,
    dialogTypes,
    postWithJwt,
    serverlessRoutes,
  } from "lib";
  import {
    alertStore,
    currentClient,
    selectedIds,
    showAlert,
    mealPlansStore,
    trainerProgramsCount,
  } from "stores";
  import More from "./UI/More.svelte";

  import { ButtonComponent, CheckboxComponent, Svg, TemplateTagComponent } from "ui";
  import { onMount } from "svelte";
  import type { MenuItem } from "../interfaces/MenuItem";
  import type { MealPlan, PartialItemMap } from "interfaces";

  export let program: any = undefined;
  export let mealPlan: MealPlan | undefined = undefined;
  export let isSelectMode = false;

  let isSelected = false;

  const defaultImage =
    "https://train-me-api.online/files/images/defaults/moj-trening/default-mealplans.png";

  const saveToDatabase = async () => {
    try {
      if (program) {
        const partialProgramMap: PartialItemMap<any> = {
          [program.id]: {
            isDefault: 1,
            isTemplate: 0,
            clientId: null
          }
        };

        await postWithJwt(
          `${serverlessRoutes.FETCH_CLIENT_PROGRAMS}/copy`,
          {partialProgramMap}
        );
      } else if (mealPlan) {
        const partialMealPlanMap: PartialItemMap<MealPlan> = {
          [mealPlan.id]: {
            isDefault: 1,
            isTemplate: 0,
            clientId: null
          }
        };

        await postWithJwt(
          `${serverlessRoutes.MEAL_PLANS}/copy`,
          {partialMealPlanMap}
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const assignToClientsDialog = (): void => {
    if (program) {
      $dialogData.data = {program};
    } else {
      $dialogData.data = {mealPlan};
    }

    $dialogData.type = dialogTypes.ASSIGN_TO_CLIENTS;
  };

  const editDialog = (): void => {
    if (program) {
      $dialogData.data = {program};
      $dialogData.type = dialogTypes.TRAINING_PROGRAM; // CREATE_EDIT_TRAINING_PROGRAM?
    } else if (mealPlan) {
      $dialogData.data = {mealPlan};
      $dialogData.type = dialogTypes.CREATE_EDIT_MEAL_PLAN;
    }
  };

  const copy = async (): Promise<void> => {
    $showAlert.color = "black";

    if (program) {
      const {data, error} = await postWithJwt(
        `${serverlessRoutes.FETCH_CLIENT_PROGRAMS}/copy`,
        {partialProgramMap: {[program.id]: {isTemplate: 0}}}
      );

      $trainerPrograms = [...data.programs, ...$trainerPrograms];
      $trainerProgramsCount += 1;
      $showAlert.message = `${translate("SUCCESSFULLY_COPIED")} program.`;
    } else if (mealPlan) {
      const partialMealPlanMap = { [mealPlan.id]: {isTemplate: 0} };

      const { data, error } = await postWithJwt(
        `${serverlessRoutes.MEAL_PLANS}/copy`,
        { partialMealPlanMap }
      );

      if (error && !data) {
        alertStore.show(translate("ERROR_COPYING_MEAL_PLAN"), "error");
        return console.error(error);
      }

      mealPlansStore.add(data.mealPlans);
      alertStore.show(translate("SUCCESSFULLY_COPIED_MEAL_PLAN"));
    }
  };

  const deleteDialog = () => {
    if (program) {
        $dialogData.data = {
          title: program.name,
          async executeFunction(): Promise<void> {
            try {
              if (program.name.includes("Cypress Program")) {
                await deleteWithJwt(`${serverlessRoutes.FETCH_CLIENT_PROGRAMS}/test`, {id: program.id});
              } else {
                await deleteWithJwt(serverlessRoutes.FETCH_CLIENT_PROGRAMS, {ids: [program.id]});
              }

              let p = $trainerPrograms.find((p) => p.id === program.id);
              if (p) {
                $trainerPrograms.splice($trainerPrograms.indexOf(p), 1);
                $trainerPrograms = $trainerPrograms;
                $trainerProgramsCount -= 1;
              }

              $showAlert.color = "black";
              $showAlert.message = `
                ${translate("SUCCESSFULLY_DELETED")} program
              `;
            } catch (error) {
              console.error(error);
              $showAlert.color = "red-400";
              $showAlert.message = translate(
                "ERROR_DELETING_TRAINING_PROGRAM"
              );
            }
          },
        };
      } else if (mealPlan) {
        $dialogData.data = {
          title: mealPlan.name,
          async executeFunction(): Promise<void> {
            try {
              const { id, isDefault } = mealPlan;

              await deleteWithJwt(serverlessRoutes.MEAL_PLANS, {
                ids: [id],
              });

              if (isDefault) {
                mealPlansStore.remove([id]);
              }

              alertStore.show(translate("SUCCESSFULLY_DELETED_MEAL_PLAN"));
            } catch (error) {
              console.error(error);
              alertStore.show(
                translate("ERROR_DELETING_MEAL_PLAN"),
                "error"
              );
            }
          },
        };
      }

      $dialogData.type = dialogTypes.CONFIRM_DELETE;
  };

  const unfilteredMenuItems: Array<MenuItem> = [{
    title: "ASSIGN_TO_CLIENTS",
    icon: "assign",
    executeFunction: assignToClientsDialog
  }, {
    title: "ASSIGN_TO_ANOTHER_CLIENT",
    icon: "assign",
    executeFunction: assignToClientsDialog
  }, {
    title: "SAVE_TO_DATABASE",
    icon: "save",
    executeFunction: saveToDatabase
  }, {
    title: "EDIT",
    icon: "edit",
    executeFunction: editDialog
  }, {
    title: "CREATE_COPY",
    icon: "copy",
    executeFunction: copy
  }, {
    title: "DELETE",
    icon: "delete",
    executeFunction: deleteDialog
  }];

  const menuItems: Array<MenuItem> = [
    {
      title: "EDIT",
      icon: "edit",
      executeFunction(): void {
        if (program) {
          $dialogData.data = { program };
          $dialogData.type = dialogTypes.TRAINING_PROGRAM; // CREATE_EDIT_TRAINING_PROGRAM?
        } else {
          $dialogData.data = { mealPlan };
          $dialogData.type = dialogTypes.CREATE_EDIT_MEAL_PLAN;
        }
      },
    },
    {
      title: "ASSIGN_TO_CLIENTS",
      icon: "assign",
      executeFunction(): void {
        if (program) {
          $dialogData.data = { program };
        } else {
          $dialogData.data = { mealPlan };
        }

        $dialogData.type = dialogTypes.ASSIGN_TO_CLIENTS;
      },
    },
    {
      title: "CREATE_COPY",
      icon: "copy",
      async executeFunction(): Promise<void> {
        try {
          $showAlert.color = "black";

          if (program) {
            const partialProgramMap = { [program.id]: {isTemplate: 0} };

            const response = await postWithJwt(
              `${serverlessRoutes.FETCH_CLIENT_PROGRAMS}/copy`,
              {partialProgramMap}
            );

            $trainerPrograms = [...response.data.programs, ...$trainerPrograms];
            $trainerProgramsCount += 1;
            $showAlert.message = `${translate("SUCCESSFULLY_COPIED")} program.`;
          } else if (mealPlan) {
            const partialMealPlanMap = { [mealPlan.id]: {isTemplate: 0} };

            const response = await postWithJwt(
              `${serverlessRoutes.MEAL_PLANS}/copy`,
              { partialMealPlanMap }
            );

            const { data, error } = response;

            if (data) {
              mealPlansStore.add(data.mealPlans);
              alertStore.show(translate("SUCCESSFULLY_COPIED_MEAL_PLAN"));
            } else if (error) {
              console.error(error);
              alertStore.show(translate("ERROR_COPYING_MEAL_PLAN"), "error");
            }
          }
        } catch (error) {
          console.error(error);
        }
      },
    },
    {
      title: "DELETE",
      icon: "delete",
      executeFunction(): void {
        if (program) {
          $dialogData.data = {
            title: program.name,
            async executeFunction(): Promise<void> {
              try {
                await deleteWithJwt(serverlessRoutes.FETCH_CLIENT_PROGRAMS, {ids: [program.id]});

                let p = $trainerPrograms.find((p) => p.id === program.id);
                if (p) {
                  $trainerPrograms.splice($trainerPrograms.indexOf(p), 1);
                  $trainerPrograms = $trainerPrograms;
                  $trainerProgramsCount -= 1;
                }

                $showAlert.color = "black";
                $showAlert.message = `
                  ${translate("SUCCESSFULLY_DELETED")} program
                `;
              } catch (error) {
                console.error(error);
                $showAlert.color = "red-400";
                $showAlert.message = translate(
                  "ERROR_DELETING_TRAINING_PROGRAM"
                );
              }
            },
          };
        } else if (mealPlan) {
          $dialogData.data = {
            title: mealPlan.name,
            async executeFunction(): Promise<void> {
              try {
                const { id, isDefault } = mealPlan;

                await deleteWithJwt(serverlessRoutes.MEAL_PLANS, {
                  ids: [id],
                });

                if (isDefault) {
                  mealPlansStore.remove([id]);
                }

                alertStore.show(translate("SUCCESSFULLY_DELETED_MEAL_PLAN"));
              } catch (error) {
                console.error(error);
                alertStore.show(
                  translate("ERROR_DELETING_MEAL_PLAN"),
                  "error"
                );
              }
            },
          };
        }

        $dialogData.type = dialogTypes.CONFIRM_DELETE;
      },
    },
  ];

  const clientMenuItems: Array<MenuItem> = [
    {
      title: "ASSIGN_TO_ANOTHER_CLIENT",
      icon: "assign",
      executeFunction(): void {
        if (program) {
          $dialogData.data = { program };
        } else {
          $dialogData.data = { mealPlan };
        }

        $dialogData.type = dialogTypes.ASSIGN_TO_CLIENTS;
      },
    },
    {
      title: "SAVE_TO_DATABASE",
      icon: "save",
      async executeFunction(): Promise<void> {
        try {
          if (program) {
            const partialProgramMap: PartialItemMap<any> = {
              [program.id]: {
                default: 1,
                isTemplate: 0,
                clientId: null
              }
            };

            await postWithJwt(
              `${serverlessRoutes.FETCH_CLIENT_PROGRAMS}/copy`,
              {partialProgramMap}
            );

            alertStore.show(translate("SUCCESSFULLY_SAVED_TO_DATABASE"));
          } else if (mealPlan) {
            const partialMealPlanMap: PartialItemMap<MealPlan> = {
              [mealPlan.id]: {
                isDefault: 1,
                isTemplate: 0,
                clientId: null
              }
            };

            await postWithJwt(
              `${serverlessRoutes.MEAL_PLANS}/copy`,
              {partialMealPlanMap}
            );
          }
        } catch (error) {
          console.error(error);
        }
      },
    },
    {
      title: "EDIT",
      icon: "edit",
      executeFunction(): void {
        if (program) {
          $dialogData.data = { program };
          $dialogData.type = dialogTypes.TRAINING_PROGRAM; // CREATE_EDIT_TRAINING_PROGRAM?
        } else {
          $dialogData.data = { mealPlan };
          $dialogData.type = dialogTypes.CREATE_EDIT_MEAL_PLAN;
        }
      },
    },
    {
      title: "CREATE_COPY",
      icon: "copy",
      async executeFunction(): Promise<void> {
        try {
          $showAlert.color = "black";

          if (program) {
            const { id } = $currentClient;
            const response = await postWithJwt(
              `${serverlessRoutes.FETCH_CLIENT_PROGRAMS}/copy`,
              {
                partialProgramMap: {
                  [program.id]: {
                    default: 0,
                    isTemplate: 0,
                    clientId: id,
                  }
                }
              }
            );

            $trainerClientPrograms = [...response.data.programs, ...$trainerClientPrograms];
            $showAlert.message = `${translate("SUCCESSFULLY_COPIED")} program.`;
          } else if (mealPlan) {
            const partialMealPlanMap = { [mealPlan.id]: {isTemplate: 0} };

            const response = await postWithJwt(
              `${serverlessRoutes.MEAL_PLANS}/copy`,
              { partialMealPlanMap }
            );

            const { data, error } = response;

            if (data) {
              mealPlansStore.add(data.mealPlans);
              alertStore.show(translate("SUCCESSFULLY_COPIED_MEAL_PLAN"));
            } else if (error) {
              console.error(error);
              alertStore.show(translate("ERROR_COPYING_MEAL_PLAN"), "error");
            }
          }
        } catch (error) {
          console.error(error);
        }
      },
    },
    {
      title: "DELETE",
      icon: "delete",
      executeFunction(): void {
        if (mealPlan) {
          $dialogData.data = {
            title: mealPlan.name,
            async executeFunction(): Promise<void> {
              try {
                await deleteWithJwt(serverlessRoutes.MEAL_PLANS, {
                  ids: [mealPlan.id],
                });

                mealPlansStore.remove([mealPlan.id]);

                $showAlert.color = "black";
                $showAlert.message = `
                ${translate("SUCCESSFULLY_DELETED")}
                ${translate("MEAL_PLAN").toLowerCase()}
              `;
              } catch (error) {
                console.error(error);
                $showAlert.color = "red-400";
                $showAlert.message = translate("ERROR_DELETING_MEAL_PLAN");
              }
            },
          };
        } else {
          $dialogData.data = {
            title: program.name,
            async executeFunction(): Promise<void> {
              try {
                await deleteWithJwt(serverlessRoutes.FETCH_CLIENT_PROGRAMS, {ids: [program.id]});
                let p = $trainerClientPrograms.find((p) => p.id === program.id);
                if (p) {
                  $trainerClientPrograms.splice($trainerClientPrograms.indexOf(p), 1);
                  $trainerClientPrograms = $trainerClientPrograms;
                }

                $showAlert.color = "black";
                $showAlert.message = `
                  ${translate("SUCCESSFULLY_DELETED")} program
                `;
              } catch (error) {
                console.error(error);
                $showAlert.color = "red-400";
                $showAlert.message = translate(
                  "ERROR_DELETING_TRAINING_PROGRAM"
                );
              }
            },
          };
        }

        $dialogData.type = dialogTypes.CONFIRM_DELETE;
      },
    },
  ];

  const groupMenuItems: Array<MenuItem> = [
    {
      title: "EDIT",
      icon: "edit",
      executeFunction(): void {},
    },
    {
      title: "DELETE",
      icon: "delete",
      executeFunction(): void {},
    },
  ];

  const toggleSelect = (): void => {
    let id: number;

    if (program) {
      id = program.id;
    } else if (mealPlan) {
      id = mealPlan.id;
    }

    if (isSelected) {
      $selectedIds = [...$selectedIds, id];
    } else {
      $selectedIds = $selectedIds.filter((f) => f !== id);
    }
  };

  const onGotoPlanPage = (): void => {
    if (isSelectMode) {
      isSelected = !isSelected;
      return toggleSelect();
    }

    const { id } = $currentClient;
    const isProfile = window.location.href.includes("/profile");

    if (program) {
      if (isProfile) {
        push(`/profile/${id}/training/programs/${program.id}`);
      } else {
        push(`/training/programs/${program.id}`);
      }
    } else if (mealPlan) {
      if (isProfile) {
        push(`/profile/${id}/diet/meal-plans/${mealPlan.id}`);
      } else {
        push(`/diet/meal-plans/${mealPlan.id}`);
      }
    }
  };

  const importProgram = async (): Promise<void> => {};

  const importPlan = async (): Promise<void> => {};

  const onImport = async (): Promise<void> => {
    if (program) {
      await importProgram();
    } else if (mealPlan) {
      await importPlan();
    }
  };

  onMount(() => {});
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
<!-- svelte-ignore a11y-no-static-element-interactions -->
<div
  data-cy="program"
  class="relative flex flex-col border border-gray-200 rounded-md shadow-md bg-gray-100 dark:bg-zinc-800 dark:border-zinc-600
    transition-[border-color, opacity]
    linear
    duration-[333ms]"
  on:click={onGotoPlanPage}
  class:isSelected
>
  {#if (program && program.isTemplate) || (mealPlan && mealPlan.isTemplate)}
    <TemplateTagComponent />
  {/if}

  <div class="flex items-center justify-center">
    <img
      src={program
        ? program.imageUrl
          ? program.imageUrl
          : defaultImage
        : mealPlan.imageUrl
          ? mealPlan.imageUrl
          : defaultImage}
      class="max-h-64 rounded-t-md"
      alt="Program Thumbnail"
    />
  </div>

  <div class="p-4 flex justify-between gap-4">
    <div class="flex flex-col gap-4">
      <!-- {program ? program.name : mealPlan.name} -->
      {#if program}
        <div data-cy="program-name">{program.name}</div>
        {#if program.duration}
          <div class="text-xs">
            {translate("DURATION")}: {program.duration}
            {translate("DAYS").toLowerCase()}
          </div>
        {/if}
        {#if program.description}
          <div class="text-xs text-justify line-clamp-3">
            {program.description}
          </div>
        {/if}
        {#if program.default || program.isTemplate}
          <div>
            <ButtonComponent on:click={assignToClientsDialog}>
              <div class="flex flex-row items-center">
                <Svg
                  myClass="mr-2"
                  name="assign"
                  size={20}
                  customColor="bg-white"
                />
                {translate("ASSIGN_TO_CLIENTS")}
              </div>
            </ButtonComponent>
          </div>
        {/if}
      {:else if mealPlan}
        <div>{mealPlan.name}</div>
        {#if mealPlan.duration}
          <div class="text-xs">
            {translate("DURATION")}: {mealPlan.duration}
            {translate("DAYS").toLowerCase()}
          </div>
        {/if}
        {#if mealPlan.description}
          <div class="text-xs text-justify line-clamp-3">
            {mealPlan.description}
          </div>
        {/if}
        {#if mealPlan.isDefault || mealPlan.isTemplate}
          <div>
            <ButtonComponent on:click={assignToClientsDialog}>
              <div class="flex flex-row items-center">
                <Svg
                  myClass="mr-2"
                  name="assign"
                  size={20}
                  customColor="bg-white"
                />
                {translate("ASSIGN_TO_CLIENTS")}
              </div>
            </ButtonComponent>
          </div>
        {/if}
      {/if}
    </div>

    <div class="flex items-start justify-between">
      {#if program}
        {#if program.clientId}
          <!-- Ovako More uvek ostaje levo, nisam znao kako drugacije. -->
          <div></div>
          <More menuItems={clientMenuItems} />
        {:else if program.clientGroupId || program.groupId}
          <!-- <div></div>
          <More menuItems={groupMenuItems} /> -->
          <!-- <ButtonComponent on:click={assignToClients}>
            <div class="flex flex-row items-center">
              <Svg
                myClass="mr-2"
                name="assign"
                size={20}
                customColor="bg-white"
              />
              {translate("ASSIGN_TO_CLIENTS")}
            </div>
          </ButtonComponent> -->
          <More {menuItems} />
        {:else if program.isTemplate}
          <ButtonComponent on:click={onImport}>
            <div class="flex flex-row items-center">
              <Svg
                myClass="mr-2"
                name="import"
                size={20}
                customColor="bg-white"
              />
              {translate("IMPORT")}
            </div>
          </ButtonComponent>
        {:else}
          <!-- <ButtonComponent on:click={assignToClients}>
            <div class="flex flex-row items-center">
              <Svg
                myClass="mr-2"
                name="assign"
                size={20}
                customColor="bg-white"
              />
              {translate("ASSIGN_TO_CLIENTS")}
            </div>
          </ButtonComponent> -->
          <More {menuItems} />
        {/if}
      {:else if mealPlan}
        {#if mealPlan.isTemplate}
          <ButtonComponent on:click={onImport}>
            <div class="flex flex-row items-center">
              <Svg
                myClass="mr-2"
                name="import"
                size={20}
                customColor="bg-white"
              />
              {translate("IMPORT")}
            </div>
          </ButtonComponent>

          <!-- {#if !mealPlan.isDefault && !mealPlan.clientId}
          <ButtonComponent on:click={assignToClients}>
            <div class="flex flex-row items-center">
              <Svg
                myClass="mr-2"
                name="assign"
                size={20}
                customColor="bg-white"
              />
              {translate("ASSIGN_TO_CLIENTS")}
            </div>
          </ButtonComponent>
          <More {menuItems} /> -->
        {:else if $currentClient.id}
          <!-- Ovako More uvek ostaje levo, nisam znao kako drugacije. -->
          <div></div>
          <More menuItems={clientMenuItems} />
          <!-- {:else if mealPlan.clientGroupId || mealPlan.groupId}
          <div></div>
          <More menuItems={groupMenuItems} /> -->
        {:else if isSelectMode}
          <CheckboxComponent bind:value={isSelected} on:change={toggleSelect} />
        {:else}
          <!-- <ButtonComponent on:click={assignToClients}>
              <div class="flex flex-row items-center">
                <Svg
                  myClass="mr-2"
                  name="assign"
                  size={20}
                  customColor="bg-white"
                />
                {translate("ASSIGN_TO_CLIENTS")}
              </div>
            </ButtonComponent> -->
          <More {menuItems} />
        {/if}
      {/if}
    </div>
  </div>
</div>

<style>
  .isSelected {
    border-color: rgb(var(--primary));
  }
</style>
