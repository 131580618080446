<script lang="ts">
  import { showAlert } from "stores";
  import { pop } from "svelte-spa-router";
  import Svg from "../../components/UI/Svg.svelte";
  import { api } from "../../lib/constants";
  import { postWithJwt } from "../../lib/requests";
  import { translate } from "../../lib/translate";
  import { theme } from "../../stores/themeStore";
  import { ButtonComponent } from "ui";

  let description: string = "";

  const submit = async () => {
    const url = `${api}/bug_suggestion`;
    const bugSuggestion = {
      data: description,
      info: "[]",
      reportType: 0,
      title: "V2",
    };
    try {
      const res = await postWithJwt(url, { bugSuggestion });
      $showAlert.message = `${translate("SUCCESSFULLY_CREATED")} ${translate(
        "BUG_SUGGESTION"
      ).toLowerCase()}`;
    } catch (err) {
      console.error(err);
    }
  };
</script>

<svelte:head>
  <style>
    body {
      overflow: hidden;
    }
  </style>
</svelte:head>
<div class="bg-white dark:bg-zinc-800 text-slate-900 dark:text-slate-100">
  <div class="mt-20" />
  <div class="flex-col items-center">
    <div class="flex flex-row w-full">
      <div class="ml-8" on:click={() => pop()} on:keypress={() => pop()}>
        <Svg name="left-arrow-button" size={24} customColor="bg-black" />
      </div>
    </div>
    <h1
      style="font-size: 1.25rem; font-weight: 400;"
      class="align-self-center text-center justify-self-center mt-16"
    >
      {translate("REPORT_BUG_SUGGESTION")}
    </h1>
    <br />
    <textarea
      class="input pd-8 mt-2 mgb-8"
      placeholder={translate("BUG_SUGGESTION_EXAMPLE")}
      rows="6"
      bind:value={description}
      style="align-self: center;"
    />
    <ButtonComponent myClasses="mt-4" on:click={submit}
      >{translate("SUBMIT")}</ButtonComponent
    >
  </div>
</div>
