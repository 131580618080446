<script lang="ts">
  export let avatarUrl: string;
  export let size: number;
  export let alt: string;
  export let myClasses: string = "";
</script>

<img
  src={avatarUrl ? avatarUrl : "/default-avatar.png"}
  {alt}
  class={myClasses}
  style={`border-radius: 64px; height: ${size}px; width: ${size}px`}
/>
