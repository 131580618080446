<script lang="ts">
  import { dialogData } from "stores";
  import { dialogTypes, translate } from "lib";
  import SinglePlan from "../../../components/SinglePlan.svelte";
  import { trainerList } from "../../../stores/trainerStores";
  import { ButtonComponent } from "ui";

  const onAssignMealPlan = (): void => {
    $dialogData.data = {};
    $dialogData.type = dialogTypes.ASSIGN_MEAL_PLAN;
  };
</script>

<div class="p-4 flex flex-col gap-4">
  <div class="flex justify-center">
    <ButtonComponent on:click={onAssignMealPlan}>
      {translate("ASSIGN_MEAL_PLAN")}
    </ButtonComponent>
  </div>

  <p class="text-center">
    {translate("NUMBER_OF_MEAL_PLANS")}:
    <b>{$trainerList.mealPlans.length}</b>
  </p>

  {#each $trainerList.mealPlans as mealPlan}
    <SinglePlan {mealPlan} />
  {/each}
</div>
