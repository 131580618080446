<script lang="ts">
  import { slide } from "svelte/transition";
  import More from "./More.svelte";
  import Svg from "./Svg.svelte";
  import type { MenuItem } from "interfaces";
  import { createEventDispatcher } from "svelte";
  import {CheckboxComponent} from "ui";
  import { selectedIds } from "stores";

  const dispatch = createEventDispatcher();

  let isExpanded = false;
  export let title: string;
  export let subtitle: string = "";
  export let isExpandable: boolean = true;
  export let menuItems: MenuItem[] = [];
  export let expansionType: string = "";
  export let isSelectMode = false;
  export let isExpansionChecked = false;

  // export let clientsByDayMap: any;
  // export let day: any;
  // $: isChecked = clientsByDayMap[day.date] && clientsByDayMap[day.date].length && (clientsByDayMap[day.date].reduce((acc, client) => $selectedIds.includes(client.id) ? acc += 1 : acc, 0) >= clientsByDayMap[day.date].length ? true : false);

  const onChange = () => {
    isExpansionChecked = !isExpansionChecked;
    dispatch("change");
  };

  const onToggleExpanded = (): void => {
    if (!isExpandable) return;
    dispatch("expanded");
    isExpanded = !isExpanded;
  };
</script>

<div
  class="
      flex
      flex-col
      border
      rounded-md
      border-slate-200
      dark:border-zinc-600
      transition-shadow
      duration-[400ms]
      ease-linear
    "
  class:shadow={isExpanded}
>
  <!-- svelte-ignore a11y-no-static-element-interactions -->
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <div class="p-2 flex gap-4">
    {#if expansionType === "CLIENTS_BY_DAY" && isSelectMode}
      <CheckboxComponent value={isExpansionChecked} on:change={onChange} />
    {/if}
    <div class="flex justify-between grow" on:click={onToggleExpanded}>
      <div class="flex gap-4">
        {title}
      </div>
      {#if subtitle !== ""}
        <div class="dark:text-slate-300 text-slate-500">
          {subtitle}
        </div>
      {/if}
      <div class="flex items-center gap-4">
        <More {menuItems} />
        {#if isExpandable}
          <div class="chevron" class:isExpanded>
            <Svg name="down-caret" size={16} />
          </div>
        {/if}
      </div>
    </div>
  </div>

  {#if isExpanded}
    <div class="p-4" in:slide><slot /></div>
  {/if}
</div>

<style>
  .chevron {
    /* ista tranzicija kao default svelte:slide */
    transition: transform 400ms linear;
  }
  .isExpanded {
    transform: rotate(180deg);
  }
</style>
