<script lang="ts">
  import { translate } from "lib";
  import { ExpansionPanel } from "ui";
</script>

<ExpansionPanel
  title="{translate("MEAL_PLAN_TYPES")}"
  subtitle="{translate("NUMBER_OF_ITEMS")}: 0"
>
  <div class="flex flex-col gap-4">
    <div class="text-xs mb-2">
      {translate("SETTINGS_MEAL_TYPES_NOTE")}
    </div>
  </div>
</ExpansionPanel>
