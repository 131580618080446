<script lang="ts">
  import { onMount } from "svelte";
  import type { FinishWorkoutData } from "../../../interfaces/FinishWorkoutStats";
  import {
    currentClient,
    dialogData,
    loadedWorkouts,
    showAlert,
    user,
  } from "stores";
  import {
    getWithJwt,
    isClient,
    postWithJwt,
    serverlessRoutes,
    translate,
  } from "lib";
  import ButtonComponent from "../../../components/UI/ButtonComponent.svelte";
  import Gauge from "../../../components/UI/Gauge.svelte";
  import Heart from "../../../components/UI/Heart.svelte";
  import { Svg } from "ui";
  import { Confetti } from "svelte-confetti";
  import { clientWorkouts } from "../../../stores/clientStores";
  import { trainerClientPrograms, trainerPrograms } from "../../../stores";
  import { stats } from "../../../stores/finishWorkoutStats";
  import { generateStats } from "../../../lib/generateStats";

  export let data: any;

  let history = {};
  let step = 1;
  let rangeSliderValue = 50;
  let hasSkippedFirstStep = false;
  let showConfetti = false;
  let isLoading = false;

  const RATE_WORKOUT_LABELS = [
    "NO_EFFORT",
    "VERY_EASY",
    "SOMEWHAT_EASY",
    "EASY",
    "MEDIUM",
    "SOMEWHAT_HARD",
    "HARD",
    "VERY_HARD",
    "TOO_HARD",
    "EXTREMELY_HARD",
    "EXTREMELY_HARD",
  ];

  let finishData: FinishWorkoutData = {
    workoutId: data.workoutId,
    heartRate: 80,
    rating: 50,
    stats: $stats,
  };

  const fetchHistory = async () => {
    data.data.exercises.forEach(async (ex) => {
      const url = `${serverlessRoutes.EXERCISE}/history/${$currentClient?.id || $user.id}/${ex.parentExerciseId}`;
      try {
        const res = await getWithJwt(url);
        if (res.data.length > 0) {
          history[ex.id] = res.data[0];
        }
      } catch (err) {}
    });
  };

  const finishWorkout = async () => {
    showConfetti = true;
    isLoading = true;

    setTimeout(async () => {
      const url = `${serverlessRoutes.WORKOUT}/finish`;

      finishData.stats = $stats;

      if (hasSkippedFirstStep) {
        finishData.stats.forEach((stat) => {
          stat.stats = [];
        });
      }

      try {
        await postWithJwt(url, finishData);
        $showAlert.message = translate("SUCCESSFULLY_FINISHED_WORKOUT");
        $stats = [];
        $clientWorkouts = $clientWorkouts.filter(
          (workout) => workout.id !== data.workoutId
        );
        $loadedWorkouts[data.workoutId] = null;
        if (!isClient($user)) {
          $trainerPrograms.forEach((program) => {
            program.workouts = program.workouts.filter(
              (workout) => workout.id !== data.workoutId
            );
          });
          $trainerClientPrograms.forEach((program) => {
            program.workouts = program.workouts.filter(
              (workout) => workout.id !== data.workoutId
            );
          });
          $trainerPrograms = $trainerPrograms;
          $trainerClientPrograms = $trainerClientPrograms;
        }
        isLoading = false;
      } catch (err) {
        $showAlert.message = translate("FINISH_WORKOUT_FAILED");
        $showAlert.color = "red";
        isLoading = false;
      }

      $showAlert.message = translate("SUCCESSFULLY_FINISHED_WORKOUT");
      $stats = [];
      $clientWorkouts = $clientWorkouts.filter(
        (workout) => workout.id !== data.workoutId
      );
      $loadedWorkouts[data.workoutId] = null;
      if (!isClient($user)) {
        $trainerPrograms.forEach((program) => {
          if (!program.workouts) return;
          program.workouts = program.workouts.filter(
            (workout) => workout.id !== data.workoutId
          );
        });
        $trainerClientPrograms.forEach((program) => {
          if (!program.workouts) return;
          program.workouts = program.workouts.filter(
            (workout) => workout.id !== data.workoutId
          );
        });
        $trainerPrograms = $trainerPrograms;
        $trainerClientPrograms = $trainerClientPrograms;
      }

      $dialogData = { type: null, data: {} };
    }, 500);
  };

  const addSet = (exerciseId) => {
    $stats.forEach((stat) => {
      if (stat.exerciseId === exerciseId) {
        stat.stats.push({ ...stat.stats[stat.stats.length - 1] });
      }
    });
    $stats = $stats;
  };

  onMount(() => {
    fetchHistory();
    generateStats(data.data.id);
  });
</script>

<div class="flex-col text-center items-center">
  {#if step === 1}
    {#if $stats.length > 0}
      {#each $stats as stat, i}
        <div class="w-full" data-cy="history">

          <h3 class="text-md mt-10 mb-10">
            {translate("ENTER_RESULTS_FOR")}:
            <span class="font-semibold" data-cy="history-name">{stat.name}</span>
          </h3>

          <div class="w-full" >
            <div class="flex flex-row justify-around">
              <div class="flex flex-col">
                <div class="font-medium mb-2 flex flex-row">
                  Serija
                  {#if stat.stats.length > 0}
                    <div
                      on:click={() => addSet(stat.stats[0].exerciseId)}
                      class="font-medium ml-1 mt-1 cursor-pointer"
                    >
                      <Svg name="plus" size={16} />
                    </div>
                  {/if}
                </div>
                {#each stat.stats as set, index}
                  <div class="font-medium mt-1">{index + 1}</div>
                {/each}
              </div>

              <div class="flex flex-col">
                <div class="font-medium mb-2">Prethodno</div>
                {#if history[stat.exerciseId]?.length > 0}
                  {#each history[stat.exerciseId] as exerciseHistory}
                    <div class="font-medium mt-1">
                      {#if exerciseHistory.weight || exerciseHistory.reps}
                        {exerciseHistory.weight} kg x {exerciseHistory.reps}
                      {/if}
                    </div>
                  {/each}
                {:else}
                  {#each stat.stats as set, index}
                    <div class="font-medium mt-2 text-xs">Nema unosa</div>
                  {/each}
                {/if}
              </div>

              <div class="flex flex-col">
                <div class="font-medium mb-2">{translate("WEIGHT")}</div>
                {#each stat.stats as set, index}
                  <input
                    bind:value={$stats[i].stats[index].weight}
                    type="number"
                    class="p-0 pl-1 w-12 h-6 border-0 rounded-md bg-slate-100 dark:bg-zinc-950 font-medium mt-1"
                  />
                  <!-- placeholder={history[index]?.weight} -->
                {/each}
              </div>

              <div>
                <div class="font-medium">{translate("REPS")}</div>
                <div class="flex flex-col gap-1 mt-3 items-center">
                  {#each stat.stats as set, index}
                    <input
                      bind:value={$stats[i].stats[index].reps}
                      type="number"
                      class="p-0 pl-1 w-12 h-6 border-0 rounded-md bg-slate-100 dark:bg-zinc-950 font-medium"
                    />
                    <!-- placeholder={history[index]?.reps} -->
                  {/each}
                </div>
              </div>
            </div>
          </div>

        </div>
      {/each}
    {/if}
  {/if}
  {#if step === 2}
    <p class="mb-6 mt-6 font-semibold">{translate("RATE_WORKOUT")}</p>
    <div class="flex-row center-space-around" style="width: 96%;">
      <div class="flex-col center-center mt-3">
        <Gauge animate={false} complete={rangeSliderValue} width={192} />
        <div class="flex-col center-center" style="margin-top: -62px;">
          <h2>{rangeSliderValue}%</h2>
          <p>
            {translate(RATE_WORKOUT_LABELS[Math.floor(rangeSliderValue / 10)])}
          </p>
        </div>
      </div>
      <input
        type="range"
        min="1"
        max="100"
        bind:value={rangeSliderValue}
        class="slider"
      />
    </div>
    <br />
  {/if}
  <div class="mt-6"></div>
  {#if step === 3}
    <Heart speed={6000 - finishData.heartRate * 30} />
    <br />
    <div class="flex-row">
      <div
        class="mr-3"
        on:click={() =>
          finishData.heartRate > 45 ? (finishData.heartRate -= 1) : {}}
        on:keydown={() =>
          finishData.heartRate > 45 ? (finishData.heartRate -= 1) : {}}
      >
        <Svg
          customColor="bg-primary-500 dark:bg-primary-500"
          name="minus"
          size={24}
        />
      </div>
      <input
        bind:value={finishData.heartRate}
        type="number"
        class="p-0 pl-1 w-12 h-6 border-0 rounded-md bg-slate-100 dark:bg-zinc-950 font-medium"
      />
      <div
        class="ml-3"
        on:click={() =>
          finishData.heartRate < 190 ? (finishData.heartRate += 1) : {}}
        on:keydown={() =>
          finishData.heartRate < 190 ? (finishData.heartRate += 1) : {}}
      >
        <Svg
          customColor="bg-primary-500 dark:bg-primary-500"
          name="plus"
          size={24}
        />
      </div>
    </div>
    <br />
    {#if showConfetti}
      <Confetti />
    {/if}
  {/if}
  <div class="mt-6 mb-6 flex flex-row w-full justify-around">
    {#if step === 2 || step === 3}
      <ButtonComponent on:click={() => (step -= 1)}
        >{translate("PREVIOUS_STEP")}</ButtonComponent
      >
    {/if}
    {#if isClient($user) && step === 1 && $user.trainer.id === 47803}
      <ButtonComponent
        isOutline
        on:click={() => {
          hasSkippedFirstStep = true;
          step += 1;
        }}
      >
        {translate("SKIP")}
      </ButtonComponent>
    {/if}
    {#if step === 1 || step === 2}
      <ButtonComponent on:click={() => (step += 1)}
        >{translate("NEXT_STEP")}</ButtonComponent
      >
    {/if}
    {#if step === 3}
      <ButtonComponent bind:isLoading on:click={() => finishWorkout()}
        >{translate("FINISH_WORKOUT")}</ButtonComponent
      >
    {/if}
  </div>
</div>

<style>
  ::placeholder {
    color: silver;
    opacity: 1; /* Firefox */
  }

  ::-ms-input-placeholder {
    /* Edge 12 -18 */
    color: silver;
  }
  .slider {
    -webkit-appearance: slider-vertical;
    writing-mode: bt-lr;
    width: 15px; /* Full-width */
    height: 100px;
  }
</style>
