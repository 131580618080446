<script lang="ts">
  import {createEventDispatcher} from "svelte";
  import {fly} from "svelte/transition";
  import {Button, Checkbox, Label, Select, type SelectOptionType} from "flowbite-svelte";
  import { ButtonComponent } from "ui";

  const dispatch = createEventDispatcher();

  let items: Array<SelectOptionType>;
  let value = "";
  let count = 0;

  const onSubmit = (): void => {
    dispatch("selectMany", value);
  };

  export {items, count};
</script>

<div class="fixed bottom-12 left-0 w-full p-4 flex flex-col gap-4 bg-slate-100 shadow-lg" in:fly out:fly>
  <div class="flex justify-between">
    <div>
      Ukupan broj izabranih: {count}
    </div>
    <Label class="flex center-end text-xs">
      Izaberi Sve
      <Checkbox class="ml-2"/>
    </Label>
  </div>
  <Select placeholder="Akcija" {items} bind:value/>
  <div class="flex justify-center">
    <ButtonComponent on:click="{onSubmit}">Potvrdi</ButtonComponent>
  </div>
</div>
