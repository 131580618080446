<script lang="ts">
  import { onDestroy, onMount } from "svelte";
  import Router, { replace } from "svelte-spa-router";
  import Menu from "./components/Menu/Menu.svelte";
  import {
    apiV2BaseUrl,
    dialogTypes,
    serverlessRoutes,
    version,
  } from "./lib/constants";
  import { getWithJwt } from "./lib/requests";
  import { user } from "./stores/userStore";
  import { theme } from "./stores/themeStore";
  import { language } from "./stores/languageStore";
  import { routes } from "./lib/routes";
  import Dialog from "./components/Dialogs/Dialog.svelte";
  import { dialogData } from "./stores/dialogDataStore";
  import { socket, socketV2 } from "./stores/socketStore";
  import Overlay from "./components/UI/Overlay.svelte";
  import io from "socket.io-client-v2"; // Ensure this is installed separately
  import ioV4 from "socket.io-client"; // The latest version
  import { setupAllSocketEvents } from "./lib/setupSocketEvents";
  import Alert from "./components/UI/Alert.svelte";
  import { showAlert } from "./stores/showAlertStore";
  import { Capacitor } from "@capacitor/core";
  import { location } from "svelte-spa-router";
  import { StatusBar, Style } from "@capacitor/status-bar";
  import { Keyboard } from "@capacitor/keyboard";
  import initNativePushNotifications from "./lib/initPushNotifications";
  import * as Sentry from "@sentry/browser";
  import { App } from "@capacitor/app";
  import { fetchUser } from "./lib/fetchUser";
  import { setupAvatars } from "./lib/setupAvatars";
  import { fetchNotifications } from "./lib/fetchNotifications";
  import { fetchChatSessions } from "./lib/fetchSessions";
  import {
    chatSessions,
    chatSessionsCount,
    showOnlyUnreadChat,
  } from "./stores/chatSessionsStore";
  import { fetchTags } from "./lib/fetchTags";
  import { isClient } from "./lib/roles";
  import { alertStore } from "stores";
  import { fade, fly, scale, slide } from "svelte/transition";
  import { flip } from "svelte/animate";

  let interval;

  // const initSwipes = () => {
  //   let isLocked = false;
  //   let touchstartX = 0;
  //   let touchendX = 0;

  //   function checkDirection() {
  //     if (touchendX < touchstartX) alert("swiped left!");
  //     if (touchendX > touchstartX) alert("swiped right!");
  //   }

  //   document.addEventListener("touchstart", (e) => {
  //     isLocked = true;
  //     setTimeout(() => (isLocked = false), 150);
  //     touchstartX = e.changedTouches[0].screenX;
  //   });

  //   document.addEventListener("touchend", (e) => {
  //     if (isLocked) return;
  //     touchendX = e.changedTouches[0].screenX;
  //     checkDirection();
  //   });
  // };

  const socketConnection = () => {
    const token = localStorage.getItem("authJwt");

    // const prodApi = "ws://localhost:3003";
    const prodApi = "wss://train-me-api.online";

    // const socketV2 = new WebSocket("ws://localhost:8090/ws");
    // // Connection opened
    // socketV2.addEventListener("open", (event) => {
    //   const auth = { type: "auth", payload: token };
    //   socketV2.send(JSON.stringify(auth));
    // });

    // setTimeout(() => {
    //   const msg = {
    //     type: "Message",
    //     payload: "Ludiloooo",
    //   };
    //   socketV2.send(JSON.stringify(msg));
    // }, 1000);

    const socketUser = {
      id: $user.id,
      name: $user.name,
      avatar: "",
      role: "Client",
    };
    const socket = io(prodApi, {
      query: { token, user: JSON.stringify(socketUser) },
    });
    setupAllSocketEvents(socket);
    socket.on("change_trainer", (event) => {
      fetchUser();
      replace("/training/program");
    });
    $socket = socket;
  };

  const socketConnectionV2 = () => {
    const token = localStorage.getItem("authJwt");
    const socket = ioV4(apiV2BaseUrl, {
      query: { authToken: token },
    });

    socket.on("connect", () => {
      console.log("Connected to server:", socket.id);
      $socketV2 = socket;
    });

    socket.on("disconnect", () => {
      console.log("Disconnected from server");
      $socketV2 = {};
    });
  };

  const setTheme = async () => {
    $theme = localStorage.getItem("theme");
    if ($theme === "dark") {
      document.documentElement.classList.add("dark");
      if (Capacitor.isNativePlatform())
        await StatusBar.setStyle({ style: Style.Dark });
    } else {
      document.documentElement.classList.remove("dark");
      if (Capacitor.isNativePlatform())
        await StatusBar.setStyle({ style: Style.Light });
    }
  };

  const setLanguage = () => {
    const myLanguage = localStorage.getItem("language");
    $language = myLanguage;
  };

  let unsubscribeLocation;

  const refreshToken = async () => {
    const url = `${serverlessRoutes.FETCH_USER}/refresh-token`;
    try {
      const result = await getWithJwt(url);
      if (result?.accessToken)
        localStorage.setItem("authJwt", result.accessToken);
    } catch (err) {}
  };

  onMount(async () => {
    const jwt = localStorage.getItem("authJwt");

    if (jwt) {
      await fetchUser();
      refreshToken();
    }

    if (isClient($user)) {
      if ($user.id !== 216072) {
        // cypress
        const dontShowSetDateOfBirth = localStorage.getItem(
          "dontShowSetDateOfBirth"
        );
        const response = await getWithJwt(serverlessRoutes.USER_DATA);

        if (!response.data.user?.dateOfBirth) {
          if (
            !dontShowSetDateOfBirth ||
            (dontShowSetDateOfBirth &&
              JSON.parse(dontShowSetDateOfBirth) === false)
          ) {
            $dialogData.data = {};
            $dialogData.type = dialogTypes.SET_DATE_OF_BIRTH;
          }
        }
      }
    }

    // $dialogData.isCloseable = false;
    // $dialogData.type = dialogTypes.FORCE_UPDATE;

    unsubscribeLocation = location.subscribe((): void => {
      window.scrollTo({ top: 0 });
    });

    if (Capacitor.isNativePlatform()) {
      App.addListener("appStateChange", async ({ isActive }) => {
        if (isActive) {
          await fetchUser();
        }
      });
    }
    App.addListener("backButton", ({ canGoBack }) => {
      if (!canGoBack) {
        App.exitApp();
      } else {
        window.history.back();
      }
    });
    // initSwipes();
    if ($location === "/" && $user) {
      if (isClient($user)) replace("/training/program");
      else replace("/clients/list");
    }

    const root = document.documentElement;
    if ($theme === "light") {
      root.setAttribute("data-theme", "light");
      root.style.setProperty("--date-picker-background", "#FFFFFF");
      root.style.setProperty("--date-picker-foreground", "#27272A");
    } else {
      root.setAttribute("data-theme", "dark");
      root.style.setProperty("--date-picker-background", "#27272A");
      root.style.setProperty("--date-picker-foreground", "#FFFFFF");
    }
  });

  const askForPhonenumber = () => {
    if (
      $user &&
      isClient($user) &&
      $user.trainer.id === 13 &&
      !$user.phoneNumber &&
      localStorage.getItem("showPhonenumberDialog") !== "false"
    )
      $dialogData.type = dialogTypes.ASK_FOR_PHONENUMBER;
  };

  const updateVersionInfo = async () => {
    const url = serverlessRoutes.PATCH_VERSION;
    try {
      await putWithJwt(url, { version });
    } catch (err) {}
  };

  const unsubscribe = user.subscribe(async (res) => {
    if (res && res.id) {
      Sentry.setUser({ email: res.email });
      await setTheme();
      setLanguage();
      if (Capacitor.isNativePlatform()) {
        initNativePushNotifications();
      }
      askForPhonenumber();
      setupAvatars();
      fetchNotifications().then(() =>
        fetchChatSessions().then(() => {
          socketConnection();
          socketConnectionV2();
        })
      );
      updateVersionInfo();
      fetchTags();
    }
  });

  setTimeout(() => {
    showOnlyUnreadChat.subscribe((res) => {
      if (res !== -1) {
        chatSessions.set([]);
        chatSessionsCount.set(0);
        fetchChatSessions();
      }
    });
  }, 3000);

  onDestroy(() => {
    unsubscribeLocation();
    unsubscribe();
    $socket.disconnect();
    clearInterval(interval);
  });

  const conditionsFailed = (event) => {
    // console.error('conditionsFailed event', event.detail)
    replace("/auth");
  };
</script>

<div
  class={`${Capacitor.getPlatform() === "android" ? "android-margin" : ""} relative flex-col center-center bg-white dark:bg-zinc-800 text-slate-900 dark:text-slate-100 ${Capacitor.isNativePlatform() ? "extra-height" : ""}`}
>
  <div
    class="content-container w-full min-h-screen max-w-screen-sm"
    id="contentContainer"
  >
    <Router {routes} on:conditionsFailed={conditionsFailed} />
  </div>
  {#if $dialogData.type}
    <Overlay />
    <Dialog
      isScrollable={$dialogData.type === dialogTypes.COMPARE_PHOTOS
        ? false
        : true}
    />
  {/if}
  <Menu />
  {#if $showAlert.message !== ""}
    <Alert />
  {/if}
  {#if $alertStore.length > 0}
    <div class="p-2 fixed bottom-12 left-0 w-full flex flex-col gap-2">
      {#each $alertStore as alert (alert.id)}
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <!-- svelte-ignore a11y-no-static-element-interactions -->
        <div
          class="p-2 bg-white text-center border border-slate-300 rounded shadow-lg text-{alert.type ===
          'primary'
            ? 'black'
            : 'red-400'}"
          animate:flip={{ duration: 333 }}
          on:click={() => alertStore.hide(alert.id)}
        >
          {alert.message}
        </div>
      {/each}
    </div>
  {/if}
</div>

<style>
  .android-margin {
    margin-top: env(safe-area-inset-top);
  }
  .extra-height {
    height: calc(100vh - 48px);
  }
</style>
